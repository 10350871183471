var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.parentContactName)?_c('div',{staticClass:"mt-3 relative p-2 border rounded text-gray-700 dark:text-gray-200 dark:border-gray-700 dark:bg-gray-800"},[_c('div',{staticClass:"leading-none font-semibold text-gray-900 dark:text-white mb-2"},[_vm._v("\n    "+_vm._s(_vm.$t('MERGE_CONTACTS.SUMMARY.TITLE'))+"\n  ")]),_vm._v(" "),_c('ul',{staticClass:"ml-0 list-none text-sm"},[_c('li',{staticClass:"flex gap-1"},[_c('span',{staticClass:"inline-block mr-1"},[_vm._v("❌")]),_vm._v(" "),_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
          _vm.$t('MERGE_CONTACTS.SUMMARY.DELETE_WARNING', {
            primaryContactName: _vm.primaryContactName,
          })
        ),expression:"\n          $t('MERGE_CONTACTS.SUMMARY.DELETE_WARNING', {\n            primaryContactName,\n          })\n        "}]})]),_vm._v(" "),_c('li',{staticClass:"flex gap-1"},[_c('span',{staticClass:"inline-block mr-1"},[_vm._v("✅")]),_vm._v(" "),_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
          _vm.$t('MERGE_CONTACTS.SUMMARY.ATTRIBUTE_WARNING', {
            primaryContactName: _vm.primaryContactName,
            parentContactName: _vm.parentContactName,
          })
        ),expression:"\n          $t('MERGE_CONTACTS.SUMMARY.ATTRIBUTE_WARNING', {\n            primaryContactName,\n            parentContactName,\n          })\n        "}]})])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }