<template>
  <div v-if="hasOpenedAtleastOnce" class="dashboard-app--container">
    <div
      v-for="(configItem, index) in config"
      :key="index"
      class="dashboard-app--list"
    >
      <loading-state
        v-if="iframeLoading"
        :message="$t('DASHBOARD_APPS.LOADING_MESSAGE')"
        class="dashboard-app_loading-container"
      />
      <iframe
        v-if="configItem.type === 'frame' && configItem.url"
        :id="getFrameId(index)"
        :src="configItem.url"
        @load="() => onIframeLoad(index)"
      />
    </div>
  </div>
</template>

<script>
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import { formatValidation } from '../../../helper/formatValidation';
import JwtAuth from '../../../api/jwtAuth';
import adminMixin from 'dashboard/mixins/isAdmin';
export default {
  components: {
    LoadingState,
  },
  mixins: [adminMixin],
  props: {
    config: {
      type: Array,
      default: () => [],
    },
    currentChat: {
      type: Object,
      default: () => ({}),
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      hasOpenedAtleastOnce: false,
      iframeLoading: true,
    };
  },
  computed: {
    dashboardAppContext() {
      return {
        conversation: this.currentChat,
        contact: this.$store.getters['contacts/getContact'](this.contactId),
        currentAgent: this.currentAgent,
      };
    },
    contactId() {
      return this.currentChat?.meta?.sender?.id;
    },
    currentAgent() {
      const { id, name, email } = this.$store.getters.getCurrentUser;
      return { id, name, email };
    },
  },
  watch: {
    isVisible() {
      if (this.isVisible) {
        this.hasOpenedAtleastOnce = true;
      }
    },
  },

  mounted() {
    window.onmessage = (e) => {
      if (
        typeof e.data !== 'string' ||
        e.data !== 'chatlyn-dashboard-app:fetch-info'
      ) {
        return;
      }
      this.onIframeLoad(0);
    };
  },
  methods: {
    findContactApps(word, str) {
      return str.search(word);
    },
    getFrameId(index) {
      return `dashboard-app--frame-${this.position}-${index}`;
    },
    async onIframeLoad(index) {
      // A possible alternative is to use ref instead of document.getElementById
      // However, when ref is used together with v-for, the ref you get will be
      // an array containing the child components mirroring the data source.
      const frameElement = document.getElementById(this.getFrameId(index));
      if (
        // Replace current string 'https://staging.chatlyn.com/apps' to your dashboard app url
        this.findContactApps('chatlyn.com/contactapps/', this.config[0].url)
      ) {
        if (this.config[0].url.includes('flexipass')) {
          const jwtAuthTokens = await JwtAuth.getUserTokens();

          await frameElement.contentWindow.postMessage(
            JSON.stringify({
              accountId: this.currentChat.account_id,
              contactId: this.contactId,
              token: jwtAuthTokens.access_token,
              email: this.dashboardAppContext.contact.email,
              phoneNumber: this.dashboardAppContext.contact.phone_number,
            }),
            '*'
          );
        } else {
          const validation = formatValidation(this.currentChat.account_id);
          frameElement.contentWindow.postMessage(
            JSON.stringify({
              validation: {
                chatlynAccessToken: validation.validation['access-token'],
                tokenType: validation.validation['token-type'],
                chatlynClient: validation.validation.client,
                expiry: validation.validation.expiry,
                uid: validation.validation.uid,
              },
              chatlynAccountId: this.currentChat.account_id,
              email: this.dashboardAppContext.contact.email,
              phoneNumber: this.dashboardAppContext.contact.phone_number,
              name: this.dashboardAppContext.contact.name,
              isAdmin: this.isAdmin,
            }),
            '*'
          );
        }

        this.iframeLoading = false;
      } else {
        const eventData = {
          event: 'appContext',
          data: this.dashboardAppContext,
        };
        frameElement.contentWindow.postMessage(JSON.stringify(eventData), '*');
        this.iframeLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.dashboard-app--container,
.dashboard-app--list,
.dashboard-app--list iframe {
  height: 100%;
  width: 100%;
}

.dashboard-app--list iframe {
  border: 0;
}
.dashboard-app_loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>
