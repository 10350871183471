<template>
  <formulate-form v-slot="{ hasErrors }" class="whats-app-select-send-modal">
    <chatlyn-message-templates-piker
      v-if="!isMessageTemplateSelected"
      :contact-preferred-language="contactPreferredLanguage"
      :inbox-id="inboxId"
      :selected-message-template-id="tempMessageTemplateId"
      :message-templates="availableMessageTemplates"
      :is-continue-button-disabled="isContinueButtonDisabled || hasErrors"
      @on-change="changeTempMessageTemplateId"
      @pick-template="pickTemplate"
      @on-go-back="onGoBack"
    />

    <chatlyn-message-template-send-form
      v-else
      :message-template="selectedMessageTemplate"
      :contact-preferred-language="contactPreferredLanguage"
      :has-errors="hasErrors"
      @reset-template="resetTemplate"
      @on-send-message="onSendMessage"
    />
  </formulate-form>
</template>

<script>
import { mapGetters } from 'vuex';
// import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker.vue';
// import TemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplateParser.vue';
import ChatlynMessageTemplatesPiker from '../../../../components/widgets/conversation/WhatsappTemplates/ChatlynMessageTemplatesPiker.vue';
import ChatlynMessageTemplateSendForm from '../../../../components/widgets/conversation/WhatsappTemplates/ChatlynMessageTemplateSendForm.vue';

export default {
  components: {
    // TemplatesPicker,
    // TemplateParser,
    ChatlynMessageTemplatesPiker,
    ChatlynMessageTemplateSendForm,
  },
  props: {
    inboxId: {
      type: Number,
      default: undefined,
    },

    show: {
      type: Boolean,
      default: true,
    },

    contactPreferredLanguage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tempMessageTemplateId: '',
      selectedMessageTemplateId: '',
    };
  },
  computed: {
    ...mapGetters({
      approvedMessageTemplates: 'messageTemplates/getApprovedMessageTemplates',
      currentAccountId: 'getCurrentAccountId',
      whatsAppInboxes: 'inboxes/getWhatsAppInboxes',
    }),

    sortedWhatsAppInboxesById() {
      const whatsAppInboxes = structuredClone(this.whatsAppInboxes);

      return whatsAppInboxes.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }

        if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
    },

    isContinueButtonDisabled() {
      return !this.tempMessageTemplateId;
    },

    isMessageTemplateSelected() {
      return Boolean(this.selectedMessageTemplateId);
    },

    selectedMessageTemplate() {
      return (
        this.availableMessageTemplates.find(
          (messageTemplate) =>
            // eslint-disable-next-line no-underscore-dangle
            messageTemplate._id === this.selectedMessageTemplateId
        ) || null
      );
    },

    currentWhatsAppInbox() {
      return this.sortedWhatsAppInboxesById.find(
        (inbox) => inbox.id === this.inboxId
      );
    },

    currentWhatsAppInboxPhoneNumber() {
      return (
        this.currentWhatsAppInbox.phone_number ||
        `+${this.currentWhatsAppInbox.additional_attributes?.number}`
      );
    },

    availableMessageTemplates() {
      return this.approvedMessageTemplates.filter((messageTemplate) => {
        if (messageTemplate.category === 'CONSENT') {
          return false;
        }

        if (this.whatsAppInboxes.length === 1) {
          return true;
        }

        if (
          messageTemplate.phoneNumber ===
          this.currentWhatsAppInboxPhoneNumber?.replace(/\+/g, '')
        ) {
          return true;
        }

        const inboxIndex = this.sortedWhatsAppInboxesById.findIndex(
          (inbox) => inbox.id === this.inboxId
        );

        return !messageTemplate.phoneNumber && inboxIndex === 0;
      });
    },
  },
  watch: {
    inboxId: {
      handler() {
        this.resetTemplate();
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch('messageTemplates/getAllMessageTemplates', {
      accountId: this.currentAccountId,
    });
  },
  methods: {
    changeTempMessageTemplateId(newId) {
      this.tempMessageTemplateId =
        this.tempMessageTemplateId === newId ? '' : newId;
    },

    pickTemplate() {
      this.selectedMessageTemplateId = this.tempMessageTemplateId;
      this.$emit('pickTemplate', true);
    },

    resetTemplate() {
      this.$emit('pickTemplate', false);
      this.tempMessageTemplateId = '';
      this.selectedMessageTemplateId = '';
    },

    onSendMessage(language, newLinks, templateId, date = new Date()) {
      this.$emit(
        'on-send',
        language,
        newLinks,
        templateId,
        this.currentWhatsAppInbox.phone_number ||
          `+${this.currentWhatsAppInbox?.additional_attributes?.number}`,
        date
      );
    },

    onClose() {
      this.$emit('cancel');
    },

    onGoBack() {
      this.$emit('on-go-back');
    },
  },
};
</script>

<style lang="scss">
/*
TODO: This destroys the view in the conversation modal when sending a template. Therefore removed for now
.whats-app-select-send-modal .message-preview-container {
  @apply grid grid-cols-2 p-0;
}
*/
</style>
