<template>
  <div
    class="chatlyn-container gap-8 flex-col my-8 grid md:grid-cols-3"
    :class="{
      'py-4 border-b dark:border-gray-700':
        showBorder,
    }"
  >
    <div class="col-span-1">
      <p
        v-if="title"
        class="text-base text-woot-500 dark:text-woot-500 mb-0 font-medium"
      >
        {{ title }}
      </p>
      <p class="text-sm mb-2">
        <slot v-if="subTitle" name="subTitle">
          {{ subTitle }}
        </slot>
      </p>
      <p v-if="note">
        <span class="font-semibold">{{ $t('INBOX_MGMT.NOTE') }}</span>
        {{ note }}
      </p>
    </div>
    <div class="col-span-2">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    showBorder: {
      type: Boolean,
      default: true,
    },
    note: {
      type: String,
      default: '',
    },
  },
};
</script>
