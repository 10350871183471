var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.priority)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content: _vm.tooltipText,
    delay: { show: 1500, hide: 0 },
    hideOnClick: true,
  }),expression:"{\n    content: tooltipText,\n    delay: { show: 1500, hide: 0 },\n    hideOnClick: true,\n  }"}],staticClass:"shrink-0 rounded-sm inline-flex w-3.5 h-3.5",class:{
    'bg-red-50 dark:bg-red-700 dark:bg-opacity-30 text-red-500 dark:text-red-600':
      _vm.isUrgent,
    'bg-gray-50 dark:bg-gray-700 text-gray-600 dark:text-gray-200':
      !_vm.isUrgent,
  }},[_c('fluent-icon',{attrs:{"icon":`priority-${_vm.priority.toLowerCase()}`,"size":"14","view-box":"0 0 14 14"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }