<template>
  <formulate-form
    v-slot="{ hasErrors }"
    class="relative min-h-[10rem]"
    name="360-profile-form"
    @submit="handle360ProfileFormSubmit"
  >
    <div v-if="profile" class="grid gap-8 md:grid-cols-2 xl:grid-cols-3">
      <div class="w-full md:col-span-1 xl:col-span-2">
        <settings-section
          :title="
            $t('CHATLYN_BUSINESS_PROFILE.LABEL.WHATSAPP_BUSINESS_PROFILE')
          "
        >
          <div class="flex flex-col gap-3">
            <woot-avatar-uploader
              :label="$t('CHATLYN_BUSINESS_PROFILE.PROFILE_PICTURE')"
              :src="picture"
              class="settings-item"
              :accept="'image/jpeg, image/png'"
              @change="handleProfilePictureUpload"
            />

            <input-counter
              v-if="!isApiWhatsAppChannel"
              :value="aboutUs"
              :max-length="139"
            >
              <formulate-input
                v-model="aboutUs"
                :label="$t('CHATLYN_BUSINESS_PROFILE.ABOUT_TEXT')"
                type="text"
                validation="required"
                :maxlength="139"
              />
            </input-counter>
          </div>
        </settings-section>

        <settings-section
          :title="$t('CHATLYN_BUSINESS_PROFILE.LABEL.BUSINESS_PROFILE')"
        >
          <div class="flex flex-col gap-3">
            <input-counter :value="address" :max-length="256">
              <formulate-input
                v-model="address"
                :label="$t('CHATLYN_BUSINESS_PROFILE.ADDRESS')"
                type="text"
                :maxlength="256"
              />
            </input-counter>

            <input-counter
              :value="description"
              :max-length="isApiWhatsAppChannel ? 512 : 256"
            >
              <formulate-input
                v-model="description"
                :label="$t('CHATLYN_BUSINESS_PROFILE.DESCRIPTION')"
                type="text"
                :maxlength="isApiWhatsAppChannel ? 512 : 256"
              />
            </input-counter>

            <input-counter :value="email" :max-length="128">
              <formulate-input
                v-model="email"
                :label="$t('CHATLYN_BUSINESS_PROFILE.EMAIL')"
                type="email"
                :validation="email ? 'email' : ''"
                :maxlength="128"
              />
            </input-counter>

            <input-counter :value="websitePrimary" :max-length="256">
              <formulate-input
                v-model="websitePrimary"
                :label="$t('CHATLYN_BUSINESS_PROFILE.WEBSITE_PRIMARY')"
                type="text"
                :validation="websitePrimary ? 'url' : ''"
                :validation-messages="{
                  url: $t('CHATLYN_GENERAL.MESSAGES.URL_INVALID'),
                }"
                :maxlength="256"
              />
            </input-counter>

            <input-counter :value="websiteSecondary" :max-length="256">
              <formulate-input
                v-model="websiteSecondary"
                :label="$t('CHATLYN_BUSINESS_PROFILE.WEBSITE_SECONDARY')"
                type="text"
                :validation="websiteSecondary ? 'url' : ''"
                :validation-messages="{
                  url: $t('CHATLYN_GENERAL.MESSAGES.URL_INVALID'),
                }"
                :maxlength="256"
              />
            </input-counter>

            <formulate-input
              v-model="businessVertical"
              :label="$t('CHATLYN_BUSINESS_PROFILE.BUSINESS_VERTICAL')"
              type="select"
              :validation="isApiWhatsAppChannel ? 'required' : ''"
              :options="businessVerticalKeyValues"
            />
          </div>

          <div class="mt-4 flex items-center justify-start">
            <woot-submit-button
              :disabled="hasErrors"
              :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
            />
          </div>
        </settings-section>
      </div>

      <div class="relative col-span-1 pt-10">
        <div class="inbox-360-profile-preview-container sticky top-1">
          <div class="inbox-360-profile-preview-title text-base">
            {{ $t('CHATLYN_BUSINESS_PROFILE.LABEL.PROFILE_PREVIEW') }}
          </div>
          <div class="inbox-360-profile-preview">
            <div class="inbox-360-profile-preview-image">
              <div
                class="absolute left-0 top-0 h-full w-full"
                :style="{
                  backgroundColor: 'rgb(41, 212, 185)',
                }"
              >
                <img
                  v-if="picture"
                  class="h-full w-full object-contain"
                  :src="picture"
                />
              </div>

              <div class="inbox-360-profile-preview-image-content">
                <div class="inbox-360-profile-preview-image-content-header">
                  <fluent-icon
                    icon="arrow-left"
                    color="var(--white)"
                    size="24"
                  />

                  <fluent-icon
                    icon="more-vertical"
                    color="var(--white)"
                    size="24"
                  />
                </div>

                <div
                  v-if="aboutUs"
                  class="inbox-360-profile-preview-image-content-footer"
                >
                  {{ aboutUs }}
                </div>
              </div>

              <div class="inbox-360-profile-preview-image-gradient" />
            </div>

            <div class="inbox-360-profile-preview-body">
              <div :style="{ height: '0.1px' }" />

              <div
                class="inbox-360-profile-preview-body-text inbox-360-profile-preview-body-label"
              >
                {{ $t('CHATLYN_BUSINESS_PROFILE.LABEL.BUSINESS_ACCOUNT_INFO') }}
              </div>

              <div
                v-if="description"
                class="inbox-360-profile-preview-body-text"
              >
                <fluent-icon
                  icon="building-shop"
                  class="flex-shrink-0"
                  size="16"
                />

                <span>
                  {{ description }}
                </span>
              </div>

              <div
                v-if="businessVerticalKeyValues && businessVertical"
                class="inbox-360-profile-preview-body-text"
              >
                <fluent-icon
                  class="flex-shrink-0"
                  icon="tag"
                  color="var(--s-300)"
                  size="16"
                />

                <span>
                  {{ businessVerticalKeyValues[businessVertical] }}
                </span>
              </div>

              <div v-if="address" class="inbox-360-profile-preview-body-text">
                <fluent-icon icon="location" color="var(--s-300)" size="16" />

                <span
                  :style="{
                    color: 'rgb(18, 140, 126)',
                  }"
                >
                  {{ address }}
                </span>
              </div>

              <div v-if="email" class="inbox-360-profile-preview-body-text">
                <fluent-icon icon="mail" color="var(--s-300)" size="16" />

                <span
                  :style="{
                    color: 'rgb(18, 140, 126)',
                  }"
                >
                  {{ email }}
                </span>
              </div>

              <div
                v-if="websitePrimary"
                class="inbox-360-profile-preview-body-text"
              >
                <fluent-icon
                  icon="link-square"
                  color="var(--s-300)"
                  size="16"
                />

                <span
                  :style="{
                    color: 'rgb(18, 140, 126)',
                  }"
                >
                  {{ websitePrimary }}
                </span>
              </div>

              <div
                v-if="websiteSecondary"
                class="inbox-360-profile-preview-body-text"
              >
                <fluent-icon
                  icon="link-square"
                  color="var(--s-300)"
                  size="16"
                />

                <span
                  :style="{
                    color: 'rgb(18, 140, 126)',
                  }"
                >
                  {{ websiteSecondary }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <chatlyn-spinner :is-visible="isLoading || !profile" />
  </formulate-form>
</template>

<script>
import ProfileAPI from '../../../../../api/profile';
import SettingsSection from '../../../../../components/SettingsSection';
import InputCounter from '../../../../../components/ui/InputCounter';
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import ChatlynSpinner from '../../../../../../shared/components/ChatlynSpinner.vue';

export default {
  name: 'ProfilePage',
  components: { SettingsSection, InputCounter, ChatlynSpinner },
  mixins: [alertMixin, inboxMixin],
  props: {
    inbox: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      profile: null,
      profilePicture: {
        file: null,
        url: '',
      },
    };
  },
  computed: {
    businessVerticalKeyValues() {
      const result = {};

      // TODO: Translations for new keys
      const keys = (
        this.isApiWhatsAppChannel
          ? [
              'UNDEFINED',
              'OTHER',
              'AUTO',
              'BEAUTY',
              'APPAREL',
              'EDU',
              'ENTERTAIN',
              'EVENT_PLAN',
              'FINANCE',
              'GROCERY',
              'GOVT',
              'HOTEL',
              'HEALTH',
              'NONPROFIT',
              'PROF_SERVICES',
              'RETAIL',
              'TRAVEL',
              'RESTAURANT',
              'NOT_A_BIZ',
            ]
          : [
              'Automotive',
              'Beauty, Spa and Salon',
              'Clothing and Apparel',
              'Education',
              'Entertainment',
              'Event Planning and Service',
              'Finance and Banking',
              'Food and Grocery',
              'Public Service',
              'Hotel and Lodging',
              'Medical and Health',
              'Non-profit',
              'Professional Services',
              'Shopping and Retail',
              'Travel and Transportation',
              'Restaurant',
              'Other',
            ]
      ).sort();

      const MATCHING_KEYS = {
        OTHER: 'Other',
        AUTO: 'Automotive',
        APPAREL: 'Clothing and Apparel',
        EDU: 'Education',
        ENTERTAIN: 'Entertainment',
        EVENT_PLAN: 'Event Planning and Service',
        FINANCE: 'Finance and Banking',
        GROCERY: 'Food and Grocery',
        HOTEL: 'Hotel and Lodging',
        HEALTH: 'Medical and Health',
        NONPROFIT: 'Non-profit',
        PROF_SERVICES: 'Professional Services',
        RETAIL: 'Shopping and Retail',
        TRAVEL: 'Travel and Transportation',
        RESTAURANT: 'Restaurant',
      };

      keys.forEach((key) => {
        result[key] = this.$t(
          `CHATLYN_BUSINESS_PROFILE.WHATSAPP_BUSINESS_VERTICAL.${
            MATCHING_KEYS[key] || key
          }`
        );
      });

      return result;
    },

    // Profile fields
    picture() {
      return this.profilePicture?.url;
    },

    aboutUs: {
      get() {
        return (
          (this.isApiWhatsAppChannel
            ? ''
            : this.profile?.profile?.about?.text) || ''
        );
      },
      set(newValue) {
        const oldProfile = structuredClone(this.profile);

        if (this.isApiWhatsAppChannel) {
          return;
        }

        oldProfile.profile.about.text = newValue;

        this.profile = oldProfile;
      },
    },

    address: {
      get() {
        return (
          (this.isApiWhatsAppChannel
            ? this.profile?.address
            : this.profile?.business?.profile?.address) || ''
        );
      },
      set(newValue) {
        const oldProfile = structuredClone(this.profile);

        if (this.isApiWhatsAppChannel) {
          oldProfile.address = newValue;
        } else {
          oldProfile.business.profile.address = newValue;
        }

        this.profile = oldProfile;
      },
    },

    description: {
      get() {
        return (
          (this.isApiWhatsAppChannel
            ? this.profile?.description
            : this.profile?.business?.profile?.description) || ''
        );
      },
      set(newValue) {
        const oldProfile = structuredClone(this.profile);

        if (this.isApiWhatsAppChannel) {
          oldProfile.description = newValue;
        } else {
          oldProfile.business.profile.description = newValue;
        }

        this.profile = oldProfile;
      },
    },

    email: {
      get() {
        return (
          (this.isApiWhatsAppChannel
            ? this.profile?.email
            : this.profile?.business?.profile?.email) || ''
        );
      },
      set(newValue) {
        const oldProfile = structuredClone(this.profile);

        if (this.isApiWhatsAppChannel) {
          oldProfile.email = newValue;
        } else {
          oldProfile.business.profile.email = newValue;
        }

        this.profile = oldProfile;
      },
    },

    websitePrimary: {
      get() {
        return (
          (this.isApiWhatsAppChannel
            ? this.profile?.websites[0]
            : this.profile?.business?.profile?.websites[0]) || ''
        );
      },
      set(newValue) {
        const oldProfile = structuredClone(this.profile);

        if (this.isApiWhatsAppChannel) {
          oldProfile.websites[0] = newValue;
        } else {
          oldProfile.business.profile.websites[0] = newValue;
        }

        this.profile = oldProfile;
      },
    },

    websiteSecondary: {
      get() {
        return (
          (this.isApiWhatsAppChannel
            ? this.profile?.websites[1]
            : this.profile?.business?.profile?.websites[1]) || ''
        );
      },
      set(newValue) {
        const oldProfile = structuredClone(this.profile);

        if (this.isApiWhatsAppChannel) {
          oldProfile.websites[1] = newValue;
        } else {
          oldProfile.business.profile.websites[1] = newValue;
        }

        this.profile = oldProfile;
      },
    },

    businessVertical: {
      get() {
        return (
          (this.isApiWhatsAppChannel
            ? this.profile?.vertical
            : this.profile?.business?.profile?.vertical) || ''
        );
      },
      set(newValue) {
        const oldProfile = structuredClone(this.profile);

        if (this.isApiWhatsAppChannel) {
          oldProfile.vertical = newValue;
        } else {
          oldProfile.business.profile.vertical = newValue;
        }

        this.profile = oldProfile;
      },
    },
  },
  mounted() {
    this.isLoading = true;
    const promises = [this.get360DialogProfile()];

    if (!this.isApiWhatsAppChannel) {
      promises.push(this.get360DialogProfilePhoto());
    }

    Promise.all(promises).finally(() => {
      this.isLoading = false;
    });
  },
  methods: {
    handleProfilePictureUpload({ file, url }) {
      if (!file || !url) {
        return;
      }

      this.profilePicture = { file, url };
    },

    async get360DialogProfile() {
      try {
        const response = await ProfileAPI.get360DialogProfile(
          this.inboxPhoneNumber
        );

        if (!response?.data) {
          throw new Error();
        }

        if (this.isApiWhatsAppChannel) {
          this.profile = structuredClone(response.data);
          this.profilePicture = {
            file: null,
            url: this.profile?.profile_picture_url,
          };
          return;
        }

        if (!response?.data?.profile || !response?.data?.business) {
          throw new Error();
        }

        this.profile = {
          business: response.data.business,
          profile: response.data.profile,
        };
      } catch (error) {
        this.profile = this.isApiWhatsAppChannel
          ? {
              websites: [],
            }
          : {
              business: {
                profile: {
                  websites: [],
                },
              },
              profile: {
                about: {
                  text: '',
                },
              },
            };
        // TODO
      }
    },

    async get360DialogProfilePhoto() {
      try {
        const response = await ProfileAPI.get360DialogProfilePhoto(
          this.inboxPhoneNumber
        );

        if (!response?.data?.settings?.profile?.photo?.link) {
          return;
        }

        this.profilePicture.url = response.data.settings.profile.photo.link;
      } catch (error) {
        // TODO
      }
    },

    async handleProfilePictureDelete() {
      this.profilePicture = { file: null, url: '' };
    },

    async handle360ProfileFormSubmit() {
      this.isLoading = true;

      let newPictureSrc = null;

      if (this.profilePicture.file) {
        try {
          const pictureResponse = await ProfileAPI.update360DialogProfilePhoto(
            this.inboxPhoneNumber,
            this.profilePicture.file
          );

          if (!pictureResponse?.data?.h) {
            throw new Error();
          }

          newPictureSrc = pictureResponse.data.h;

          this.profilePicture = { file: null, url: this.profilePicture.url };
          this.showAlert(this.$t('CHATLYN_GENERAL.MESSAGES.SUCCESS'));
        } catch (error) {
          if (error) {
            this.showAlert(this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN'));
          }
        }
      }

      try {
        if (this.isApiWhatsAppChannel) {
          const newProfile = structuredClone(this.profile);

          delete newProfile.profile_picture_url;

          if (newPictureSrc) {
            newProfile.profile_picture_handle = newPictureSrc;
          }

          await ProfileAPI.update360DialogProfileV2(
            this.inboxPhoneNumber,
            newProfile
          );
        } else {
          await ProfileAPI.update360DialogProfile(this.inboxPhoneNumber, {
            ...this.profile.business.profile,
            about: this.profile.profile.about.text,
          });
        }

        this.showAlert(this.$t('CHATLYN_GENERAL.MESSAGES.SUCCESS'));

        this.profilePicture.file = null;
      } catch (error) {
        this.showAlert(this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN'));
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.inbox-360-profile-preview-container {
  .inbox-360-profile-preview {
    @apply rounded-b border bg-white pb-2 shadow-lg dark:border-gray-700 dark:bg-gray-900;

    .inbox-360-profile-preview-image {
      @apply relative aspect-video;

      .inbox-360-profile-preview-image-content {
        @apply absolute h-full w-full px-4 py-4;

        .inbox-360-profile-preview-image-content-header {
          @apply relative flex items-start justify-between;

          height: 50%;
          z-index: 1;
        }

        .inbox-360-profile-preview-image-content-footer {
          @apply relative flex items-end justify-start text-base text-white;

          height: 50%;
          z-index: 1;
        }
      }

      .inbox-360-profile-preview-image-gradient {
        @apply absolute bottom-0 w-full;

        height: 8rem;
        background: linear-gradient(
          rgba(62, 62, 62, 0) 0%,
          rgba(0, 0, 0, 0.4) 100%
        );
      }
    }

    .inbox-360-profile-preview-body {
      @apply w-full bg-[#f0f2f5] dark:bg-gray-800;

      .inbox-360-profile-preview-body-text {
        @apply flex w-full flex-row flex-nowrap items-center gap-6 bg-white px-6 py-3 leading-none dark:bg-gray-900;

        span {
          @apply overflow-hidden text-ellipsis whitespace-nowrap;
        }
      }

      .inbox-360-profile-preview-body-label {
        margin: 0.8rem 0;
      }
    }
  }
}
</style>
