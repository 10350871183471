<template>
  <div>
    <woot-modal-header :header-title="$t('CONDITION.CREATE_BY.TITLE')" />

    <formulate-form
      v-slot="{ hasErrors }"
      name="create-list-by-condition"
      class="modal-body flex flex-col gap-3"
      @submit="handleSubmit"
    >
      <div
        class="flex flex-col gap-1 rounded-md bg-woot-50 px-4 py-2.5 text-woot-500 dark:bg-woot-700 dark:text-woot-25"
      >
        <span class="text-base font-bold">
          {{ modalTitle }}
        </span>

        <span class="text-sm">
          {{ modalText }}
        </span>
      </div>

      <formulate-input
        v-model="name"
        :label="$t('CHATLYN_GENERAL.LABEL.NAME')"
        :placeholder="$t('CHATLYN_CONTACT_LISTS.PLACEHOLDERS.NAME')"
        :validation-messages="{
          required: $t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
          min: $t('CHATLYN_GENERAL.MESSAGES.MIN_LENGTH_2'),
        }"
        :minlength="2"
        validation="required|min:2"
      />

      <div
        v-if="!isContactListsPage"
        class="rounded-md p-2.5 transition-all duration-300"
        :class="{
          'bg-gray-50 dark:bg-gray-900': !isDynamicList,
          'bg-woot-50 dark:bg-woot-700': isDynamicList,
        }"
      >
        <woot-switch
          v-model="isDynamicList"
          :label="$t('CHATLYN_CONTACT_LISTS.DYNAMIC_LABEL')"
        />
      </div>

      <slide-animation :is-shown="isDynamicList">
        <div class="flex flex-col gap-1">
          <label>{{ $t('CONDITION.CREATE_BY.CONDITION_LABEL') }}</label>

          <list-condition
            :list-condition="appliedContactCondition"
            :is-editable="false"
            :has-add-condition-button="false"
          />
        </div>
      </slide-animation>

      <div class="flex flex-row flex-nowrap items-center justify-end">
        <woot-button
          class="transition-all duration-300"
          :is-disabled="hasErrors"
          color-scheme="primary"
        >
          {{ $t('CHATLYN_GENERAL.BUTTON.CREATE') }}
        </woot-button>
      </div>
    </formulate-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';
import listActions from '../../../contactLists/mixins/listActions';

import WootSwitch from 'shared/components/ui/Switch.vue';
import ListCondition from '../../../contactLists/components/ListCondition.vue';
import WootButton from '../../../../../components/ui/WootButton.vue';
import SlideAnimation from '../../../../../components/animations/SlideAnimation.vue';

export default {
  name: 'CreateListByConditionModal',

  components: {
    WootSwitch,
    ListCondition,
    WootButton,
    SlideAnimation,
  },

  mixins: [listActions, alertMixin],

  props: {
    isContactListsPage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      name: '',
      isDynamicList: false,
    };
  },

  computed: {
    ...mapGetters({
      appliedContactCondition: 'contacts/getAppliedContactCondition',
      contactsMeta: 'contacts/getMeta',
      contactListMeta: 'contactLists/getMeta',
      contactList: 'contactLists/getList',
    }),

    modalTitle() {
      return this.isDynamicList
        ? this.$t('CONDITION.CREATE_BY.MESSAGE.TITLE_DYNAMIC', {
            COUNT: this.contactsCount,
          })
        : this.$t('CONDITION.CREATE_BY.MESSAGE.TITLE', {
            COUNT: this.contactsCount,
          });
    },

    modalText() {
      if (this.isContactListsPage) {
        return this.$t('CONDITION.CREATE_BY.MESSAGE.TEXT_LIST');
      }

      return this.isDynamicList
        ? this.$t('CONDITION.CREATE_BY.MESSAGE.TEXT_DYNAMIC')
        : this.$t('CONDITION.CREATE_BY.MESSAGE.TEXT');
    },

    contactListId() {
      // eslint-disable-next-line no-underscore-dangle
      return this.contactList?._id;
    },

    appliedListCondition() {
      return this.$store.getters['contactLists/getAppliedListCondition'](
        this.contactListId
      );
    },

    contactsCount() {
      return this.isContactListsPage
        ? this.contactListMeta.itemCount
        : this.contactsMeta.count;
    },
  },

  methods: {
    async handleSubmit() {
      const formElement = this.$formulate.registry.get(
        'create-list-by-condition'
      );

      if (!formElement) {
        return;
      }

      const hasErrors = await formElement.hasValidationErrors();

      if (hasErrors) {
        return;
      }

      const newRecord = {
        name: this.name,
        clientId: this.currentAccountId,
        static: !this.isDynamicList,
        condition: this.appliedContactCondition,
        contacts: [],
      };

      if (this.isContactListsPage) {
        newRecord.listId = this.contactListId;
        newRecord.condition = this.appliedListCondition;
      }

      await this.onListAction(newRecord);

      this.$emit('on-close');
    },
  },
};
</script>

<style></style>
