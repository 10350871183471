<template>
  <div
    class="bg-gray-50 dark:bg-gray-900 pt-4 pb-0 px-8 border-b dark:border-gray-700"
  >
    <h2 class="text-2xl text-gray-800 dark:text-gray-100 mb-2">
      {{ headerTitle }}
    </h2>
    <p
      v-if="headerContent"
      class="w-full text-gray-600 dark:text-gray-300 text-sm mb-2"
    >
      {{ headerContent }}
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    headerContent: {
      type: String,
      default: '',
    },
  },
};
</script>
