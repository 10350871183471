import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      contactList: 'contactLists/getList',
      listsUiFlags: 'contactLists/getUiFlags',
    }),
    listId() {
      // eslint-disable-next-line no-underscore-dangle
      return this.contactList?._id || null;
    },
    isLoading() {
      return Object.values(this.listsUiFlags).some((val) => val);
    },
  },
  methods: {
    showError(error) {
      const errorMessage =
        error.message || this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN');
      this.showAlert(errorMessage);
    },
    async onListAction(newRecord, actionType = 'createList') {
      let result;
      const isCreated = actionType === 'createList';
      try {
        result = await this.$store.dispatch(`contactLists/${actionType}`, {
          newRecord,
          ...(!isCreated && { listId: this.listId }),
        });
      } catch (error) {
        this.showError(error);
      }
      if (result) {
        const message = isCreated
          ? this.$t('CHATLYN_CONTACT_LISTS.NEW_MODAL.LIST_CREATED')
          : this.$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.LIST_UPDATED');
        this.showAlert(message);
        await this.refetchLists(isCreated);
      }
      this.disabled = false;
      this.$emit('close');
    },
    async onListDelete() {
      try {
        await this.$store.dispatch('contactLists/deleteList', {
          listId: this.listId,
        });
        this.$emit('delete');
        this.showAlert(
          this.$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.LIST_DELETED')
        );
      } catch (error) {
        this.showError(error);
      }
    },
    async refetchLists(isCreated) {
      try {
        await this.$store.dispatch('contactLists/getLists', {
          accountId: this.currentAccountId,
        });
        if (!isCreated && this.listId) {
          await this.$store.dispatch('contactLists/getList', {
            listId: this.listId,
            page: 1,
          });
        }
      } catch (error) {
        this.showError(error);
      }
    },
  },
};
