import {
  format,
  formatDistanceToNow,
  fromUnixTime,
  isSameYear,
} from 'date-fns';

export default {
  methods: {
    messageStamp(time, dateFormat = 'HH:mm') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    messageTimestamp(time, dateFormat = 'MMM d, yyyy') {
      const messageTime = fromUnixTime(time);
      const now = new Date();
      const messageDate = format(messageTime, dateFormat);
      if (!isSameYear(messageTime, now)) {
        return format(messageTime, 'LLL d y, h:mm a');
      }
      return messageDate;
    },
    dynamicTime(time) {
      const unixTime = fromUnixTime(time);
      return formatDistanceToNow(unixTime, { addSuffix: true });
    },
    dateFormat(time, dateFormat = 'MMM d, yyyy') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    shortTimestamp(time) {
      return time
        .replace(/about/g, this.$t('TIME.ABOUT'))
        .replace(/over/g, this.$t('TIME.OVER'))
        .replace(/almost/g, this.$t('TIME.ALMOST'))
        .replace('less than a minute ago', this.$t('TIME.LESS_THAN_MINUTE'))
        .replace('a minute ago', this.$t('TIME.MINUTE_AGO'))
        .replace('minute ago', this.$t('TIME.MINUTES_AGO'))
        .replace('minutes ago', this.$t('TIME.A_MINUTE_AGO'))
        .replace('an hour ago', this.$t('TIME.AN_HOUR_AGO'))
        .replace('hour ago', this.$t('TIME.HOUR_AGO'))
        .replace('hours ago', this.$t('TIME.HOURS_AGO'))
        .replace('a day ago', this.$t('TIME.DAY_AGO'))
        .replace('day ago', this.$t('TIME.A_DAY_AGO'))
        .replace('days ago', this.$t('TIME.DAYS_AGO'))
        .replace('a month ago', this.$t('TIME.A_MONTH_AGO'))
        .replace('month ago', this.$t('TIME.MONTHS_AGO'))
        .replace('months ago', this.$t('TIME.MONTH_AGO'))
        .replace('a year ago', this.$t('TIME.A_YEAR_AGO'))
        .replace('year ago', this.$t('TIME.YEAR_AGO'))
        .replace('years ago', this.$t('TIME.YEARS_AGO'));
    },
  },
};
