<template>
  <div class="card">
    <div class="card-header">
      <slot name="header">
        <div class="card-header--title-area">
          <div>{{ header }}</div>
          <span class="live">
            <span class="ellipse" /><span>{{
              $t('OVERVIEW_REPORTS.LIVE')
            }}</span>
          </span>
        </div>
        <div class="card-header--control-area">
          <slot name="control" />
        </div>
      </slot>
    </div>
    <div v-if="!isLoading" class="card-body flex gap-4 justify-between">
      <slot />
    </div>
    <div v-else-if="isLoading" class="conversation-metric-loader">
      <spinner />
    </div>
  </div>
</template>
<script>
import Spinner from 'shared/components/Spinner.vue';

export default {
  name: 'MetricCard',
  components: {
    Spinner,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  @apply bg-white dark:bg-gray-800 border dark:border-gray-700 rounded p-4 h-full;
}

.card-header {
  grid-template-columns: repeat(auto-fit, minmax(max-content, 50%));
  @apply grid flex-grow w-full mb-6;

  .card-header--title-area {
    @apply flex items-center flex-row;
    .live {
      @apply flex flex-row items-center px-1.5 m-1 text-green-600 dark:text-green-400 bg-green-500 bg-opacity-20 text-xs rounded;
      .ellipse {
        @apply bg-green-400 dark:bg-green-400 h-1 w-1 rounded-full mr-1 rtl:mr-0 rtl:ml-0;
      }
    }
  }

  .card-header--control-area {
    @apply flex flex-row items-center justify-end gap-2;
  }
}

.card-body {
  .metric-content {
    @apply pb-2;
    .heading {
      @apply text-gray-800 dark:text-gray-100 text-sm flex items-center m-0 gap-1;
    }
    .metric {
      @apply text-gray-700 dark:text-gray-100 text-3xl font-light m-0 font-medium;
    }
  }
}

.conversation-metric-loader {
  @apply items-center flex justify-center flex-grow py-4
}
</style>
