var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message-preview-container",class:_vm.messageTemplateClass},[(_vm.hasLabel)?_c('label',{staticClass:"block"},[_vm._v("\n    "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.MESSAGE_PREVIEW'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.isPreviewEmpty)?_c('div',{staticClass:"message-preview-background pointer-events-none flex items-end justify-start break-words rounded py-4 pe-12 ps-6"},[_c('div',{staticClass:"message-preview-message relative block rounded-lg p-3",style:({
        direction:
          _vm.currentTranslation && _vm.currentTranslation.language === 'ar'
            ? 'rtl'
            : '',
      })},[(_vm.headerComponent)?_c('div',{staticClass:"message-preview-message-header text-sm font-semibold"},[(_vm.headerComponent.format === 'TEXT')?_c('div',[_vm._v("\n          "+_vm._s(_vm.headerComponent.text)+"\n        ")]):_vm._e(),_vm._v(" "),(
            (_vm.headerComponent.example &&
              _vm.headerComponent.example.header_handle) ||
            _vm.customHeaderAttachment
          )?[(_vm.headerComponent.format === 'IMAGE')?_c('div',{staticClass:"message-preview-message-header-media-container",class:{
              'message-header--image':
                !_vm.headerComponent.example.header_handle[0],
            }},[(_vm.headerAttachment)?_c('img',{attrs:{"src":_vm.headerAttachment}}):_c('div',{staticClass:"message-header--preview"})]):_vm._e(),_vm._v(" "),(_vm.headerComponent.format === 'VIDEO')?_c('div',{staticClass:"message-preview-message-header-media-container message-header--video",class:{
              'message-header--video':
                !_vm.headerComponent.example.header_handle[0],
            }},[(_vm.headerAttachment)?_c('video',{attrs:{"src":_vm.headerAttachment,"controls":""}}):_c('div',{staticClass:"message-header--preview"})]):_vm._e()]:_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.bodyComponent && _vm.bodyComponent.text)?_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.formatTextWithLinks(_vm.bodyComponent.text)),expression:"formatTextWithLinks(bodyComponent.text)"}],staticClass:"message-preview-message-body"}):_vm._e(),_vm._v(" "),(_vm.footerComponent)?_c('div',{staticClass:"message-preview-message-footer"},[_vm._v("\n        "+_vm._s(_vm.footerComponent.text)+"\n      ")]):_vm._e()]),_vm._v(" "),(_vm.buttonsComponent)?_c('div',{staticClass:"message-preview-message-buttons"},_vm._l((_vm.buttonsComponent.buttons),function(button,buttonIndex){return _c('button',{key:buttonIndex,staticClass:"break-words",style:({
          direction:
            _vm.currentTranslation && _vm.currentTranslation.language === 'ar'
              ? 'rtl'
              : '',
        })},[_vm._v("\n        "+_vm._s(button.text)+"\n      ")])}),0):_vm._e()]):_c('div',{staticClass:"message-preview-empty-state"},[_vm._v("\n    "+_vm._s(_vm.emptyText)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }