<template>
  <div>
    <language-select
      v-model="selectedLanguage"
      :label="languageSelectLabel"
      :languages="deeplLanguagesOptions"
      :color="color"
      :is-loading="isLoading"
      @button-action="languageSelectButtonClick"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';
import DeeplAPI from '../../../../api/deepl';
import {
  CH_I18N_TO_DEEPL_LANGUAGES_KEYS,
  insertSpacesBetweenEmoji,
} from './constants';

import LanguageSelect from '../textTranslator/LanguageSelect.vue';

export default {
  name: 'TextTranslator',
  components: { LanguageSelect },
  mixins: [alertMixin],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    color: {
      type: String,
      default: 'var(--w-500)',
    },

    preferredLanguage: {
      type: String,
      default: '',
    },

    translateTagHandling: {
      type: [undefined, String],
      default: undefined,
    },
  },
  data() {
    return {
      selectedLanguage: '',
      isLoading: false,
      originalText: '',
      translatedText: '',
    };
  },
  computed: {
    ...mapGetters({
      deeplLanguages: 'deepl/getDeeplLanguages',
      currentUserLanguage: 'management/getUserLanguage',
    }),

    selectedDeeplLanguage() {
      return (
        this.deeplLanguages?.find(
          (deeplLanguage) => deeplLanguage.code === this.selectedLanguage
        ) || {}
      );
    },

    deeplLanguagesOptions() {
      if (!this.deeplLanguages?.length) {
        return [];
      }

      return this.deeplLanguages.map((deeplLanguage) => {
        return {
          label: deeplLanguage.name,
          value: deeplLanguage.code,
        };
      });
    },

    isTranslated() {
      if (!this.originalText) {
        return false;
      }

      return this.originalText !== this.value;
    },

    languageSelectLabel() {
      return this.isTranslated
        ? this.$t('CHATLYN_TRANSLATOR.SHOW_ORIGINAL')
        : this.label || this.$t('CHATLYN_TRANSLATOR.TRANSLATE');
    },

    languageSelectButtonClick() {
      return this.isTranslated
        ? this.handleShowOriginalButtonClick
        : this.handleTranslateButtonClick;
    },
  },
  watch: {
    selectedLanguage: {
      handler(newValue, prevValue) {
        if (!newValue || newValue === prevValue || !this.value.trim()) {
          return;
        }

        this.handleTranslateText(newValue);
      },
    },
  },
  mounted() {
    this.originalText = this.value;
    this.$store.dispatch('deepl/getDeeplLanguages');
  },
  methods: {
    handleShowOriginalButtonClick() {
      this.selectedLanguage = '';
      this.$emit('change', this.originalText);
    },

    findLanguage(value) {
      return (
        this.deeplLanguagesOptions.find((language) => language.value === value)
          ?.value || CH_I18N_TO_DEEPL_LANGUAGES_KEYS[value]
      );
    },

    getUserInterfaceLanguage() {
      const interfaceLocale = this.$root.$i18n.locale.replace(/_/g, '-');

      return (
        this.findLanguage(this.currentUserLanguage) ||
        this.findLanguage(this.preferredLanguage) ||
        this.findLanguage(interfaceLocale)
      );
    },

    handleTranslateButtonClick() {
      let targetLanguage =
        this.selectedLanguage || this.getUserInterfaceLanguage();

      if (!targetLanguage) {
        this.showAlert(
          this.$t('CHATLYN_TRANSLATOR.ERROR.MUST_SELECT_LANGUAGE')
        );
        return;
      }

      this.selectedLanguage = targetLanguage;
    },

    async handleTranslateText(targetLanguage) {
      try {
        this.isLoading = true;

        const text = insertSpacesBetweenEmoji(this.originalText);

        const response = await DeeplAPI.translateText(
          text,
          targetLanguage,
          this.translateTagHandling
        );

        this.$emit('change', response.data.text);
      } catch (error) {
        this.selectedLanguage = '';
        this.showAlert(this.$t('CHATLYN_TRANSLATOR.ERROR.TRANSLATION'));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
