<template>
  <woot-modal
    :show="show"
    :on-close="onReject"
    :size="size"
    :close-on-backdrop-click="!isLoading"
  >
    <unarchive-form
      :archive-type="archiveType"
      :is-loading="isLoading"
      @on-reject="onReject"
      @on-confirm="onConfirm"
    />
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import UnarchiveForm from './UnarchiveForm.vue';

export default {
  name: 'UnarchiveModal',
  components: { UnarchiveForm },
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    archiveType: {
      type: String,
      default: '',
    },

    size: {
      type: String,
      default: 'md',
    },
  },
  computed: {
    ...mapGetters({
      contactListsUiFlags: 'contactLists/getUiFlags',
    }),

    isLoading() {
      return this.contactListsUiFlags.isUpdating;
    },
  },
  methods: {
    onReject() {
      this.$emit('on-reject');
    },

    onConfirm(folderId = '') {
      this.$emit('on-confirm', folderId);
    },
  },
};
</script>

<style></style>
