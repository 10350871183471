<template>
  <form @submit.prevent="changePassword()">
    <div class="grid gap-8 md:grid-cols-3">
      <div class="col-span-1">
        <div class="block-title">
          {{ $t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.TITLE') }}
        </div>

        <p>{{ $t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.NOTE') }}</p>
      </div>

      <div class="col-span-2 flex flex-col gap-2">
        <woot-input
          v-model="currentPassword"
          type="password"
          :class="{ error: $v.currentPassword.$error }"
          :label="$t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.LABEL')"
          :placeholder="
            $t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.PLACEHOLDER')
          "
          :error="
            $v.currentPassword.$error
              ? $t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.ERROR')
              : ''
          "
          @blur="$v.currentPassword.$touch"
        />

        <woot-input
          v-model="password"
          type="password"
          :class="{ error: $v.password.$error }"
          :label="$t('PROFILE_SETTINGS.FORM.PASSWORD.LABEL')"
          :placeholder="$t('PROFILE_SETTINGS.FORM.PASSWORD.PLACEHOLDER')"
          :error="
            $v.password.$error ? $t('PROFILE_SETTINGS.FORM.PASSWORD.ERROR') : ''
          "
          @blur="$v.password.$touch"
        />

        <woot-input
          v-model="passwordConfirmation"
          type="password"
          :class="{ error: $v.passwordConfirmation.$error }"
          :label="$t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.LABEL')"
          :placeholder="
            $t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.PLACEHOLDER')
          "
          :error="
            $v.passwordConfirmation.$error
              ? $t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.ERROR')
              : ''
          "
          @blur="$v.passwordConfirmation.$touch"
        />

        <div>
          <woot-button
            :is-loading="isPasswordChanging"
            type="submit"
            :disabled="isSubmitButtonDisabled"
          >
            {{ $t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.BTN_TEXT') }}
          </woot-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { passwordValidator } from '../../../../helper/vuelidateValidation';

export default {
  mixins: [alertMixin],
  data() {
    return {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
      isPasswordChanging: false,
      errorMessage: '',
    };
  },
  validations: {
    currentPassword: {
      required,
    },
    password: {
      passwordValidator,
    },
    passwordConfirmation: {
      passwordValidator,
      isEqPassword(value) {
        if (value !== this.password) {
          return false;
        }
        return true;
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserId: 'getCurrentUserID',
    }),

    isSubmitButtonDisabled() {
      return (
        this.$v.currentPassword.$error ||
        this.$v.password.$error ||
        this.$v.passwordConfirmation.$error
      );
    },
  },
  methods: {
    async changePassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.ERROR'));
        return;
      }

      try {
        await this.$store.dispatch('updateProfile', {
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          current_password: this.currentPassword,
        });
        this.errorMessage = this.$t('PROFILE_SETTINGS.PASSWORD_UPDATE_SUCCESS');
      } catch (error) {
        this.errorMessage = this.$t('RESET_PASSWORD.API.ERROR_MESSAGE');
        if (error?.response?.data?.message) {
          this.errorMessage = error.response.data.message;
        }
      } finally {
        this.isPasswordChanging = false;
        this.showAlert(this.errorMessage);
      }
    },
  },
};
</script>
