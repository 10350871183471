<template>
  <div class="chatlyn-popover relative">
    <div
      :id="triggerId"
      :ref="triggerId"
      class="chatlyn-popover__trigger"
      @click="toggleIsPopoverActivated"
    >
      <slot name="trigger" :toggle-popover="toggleIsPopoverActivated" />
    </div>

    <transition name="fade">
      <div
        v-show="isPopoverActivated"
        :id="popoverId"
        :ref="popoverId"
        v-on-clickaway="isPopoverActivated ? handleClickAway : () => {}"
        :data-placement="placement"
        role="tooltip"
        class="chatlyn-popover__trigger absolute z-50 inline-block w-auto rounded-md bg-white p-1.5 shadow-md dark:bg-gray-900"
      >
        <slot name="content" :toggle-popover="toggleIsPopoverActivated" />
      </div>
    </transition>
  </div>
</template>

<script>
import getUuid from 'widget/helpers/uuid';
import { mixin as clickaway } from 'vue-clickaway';

import { CHATLYN_POPOVER_PLACEMENT } from './constants';

export default {
  name: 'ChatlynPopover',

  mixins: [clickaway],

  props: {
    placement: {
      type: String,
      default: CHATLYN_POPOVER_PLACEMENT.BOTTOM_END,
    },

    offset: {
      type: Number,
      default: 4,
    },
  },

  data() {
    const id = getUuid();

    return {
      triggerId: `trigger-${id}`,
      popoverId: `popover-${id}`,
      CHATLYN_POPOVER_PLACEMENT,
      isPopoverActivated: false,
      popoverSize: {
        height: 0,
        width: 0,
      },
      triggerSize: {
        height: 0,
        width: 0,
      },
    };
  },

  watch: {
    isPopoverActivated: {
      handler(newValue) {
        if (!newValue) {
          return;
        }

        this.$nextTick(() => {
          const popoverElement = this.$refs[this.popoverId];
          const triggerElement = this.$refs[this.triggerId];

          if (!popoverElement || !triggerElement) {
            return;
          }

          const popoverBoundingClientRect =
            popoverElement.getBoundingClientRect();
          const triggerBoundingClientRect =
            triggerElement.getBoundingClientRect();

          this.popoverSize = {
            height: popoverBoundingClientRect.height,
            width: popoverBoundingClientRect.width,
          };

          this.triggerSize = {
            height: triggerBoundingClientRect.height,
            width: triggerBoundingClientRect.width,
          };

          const styles = this.getPlacementsStyle();

          Object.entries(styles).forEach(([key, value]) => {
            popoverElement.style[key] = value;
          });
        });
      },
    },
  },

  methods: {
    toggleIsPopoverActivated() {
      this.isPopoverActivated = !this.isPopoverActivated;
    },

    getPlacementsStyle() {
      const value = {};

      const mainValue = `calc(100% + ${this.offset}px)`;
      const additionalHorizontalValue = `${-(
        (this.popoverSize.width - this.triggerSize.width) /
        2
      )}px`;
      const additionalVerticalValue = `${-(
        (this.popoverSize.height - this.triggerSize.height) /
        2
      )}px`;

      switch (this.placement) {
        case CHATLYN_POPOVER_PLACEMENT.TOP:
          value.bottom = mainValue;
          value.right = additionalHorizontalValue;
          break;

        case CHATLYN_POPOVER_PLACEMENT.BOTTOM:
          value.top = mainValue;
          value.right = additionalHorizontalValue;
          break;

        case CHATLYN_POPOVER_PLACEMENT.LEFT:
          value.right = mainValue;
          value.top = additionalVerticalValue;
          break;

        case CHATLYN_POPOVER_PLACEMENT.RIGHT:
          value.left = mainValue;
          value.top = additionalVerticalValue;
          break;
        // -------------------------------------- //
        case CHATLYN_POPOVER_PLACEMENT.TOP_START:
          value.bottom = mainValue;
          value.left = 0;
          break;

        case CHATLYN_POPOVER_PLACEMENT.BOTTOM_START:
          value.top = mainValue;
          value.left = 0;
          break;

        case CHATLYN_POPOVER_PLACEMENT.LEFT_START:
          value.right = mainValue;
          value.top = 0;
          break;

        case CHATLYN_POPOVER_PLACEMENT.RIGHT_START:
          value.left = mainValue;
          value.top = 0;
          break;
        // -------------------------------------- //
        case CHATLYN_POPOVER_PLACEMENT.TOP_END:
          value.bottom = mainValue;
          value.right = 0;
          break;

        case CHATLYN_POPOVER_PLACEMENT.BOTTOM_END:
          value.top = mainValue;
          value.right = 0;
          break;

        case CHATLYN_POPOVER_PLACEMENT.LEFT_END:
          value.right = mainValue;
          value.bottom = 0;
          break;

        case CHATLYN_POPOVER_PLACEMENT.RIGHT_END:
          value.left = mainValue;
          value.bottom = 0;
          break;

        default:
          break;
      }

      return value;
    },

    handleClickAway() {
      this.toggleIsPopoverActivated();
    },
  },
};
</script>

<style lang="scss" scoped>
.chatlyn-popover {
}
</style>
