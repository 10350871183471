<template>
  <div v-if="messageTemplate" class="w-full">
    <div class="grid w-full grid-cols-2 gap-4">
      <div class="flex flex-1 flex-col flex-nowrap gap-3">
        <div
          class="message-template-scheduled-container flex flex-col flex-nowrap !gap-0 gap-3 rounded-md border p-3 dark:border-gray-700"
        >
          <div class="message-template-scheduled-switcher">
            <woot-switch
              id="message-template-scheduled-switcher"
              v-model="showSchedule"
              :label="$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.SCHEDULE')"
            />
          </div>

          <slide-animation :is-shown="showSchedule">
            <div
              v-if="showSchedule"
              class="schedule mt-3 flex w-full flex-wrap gap-x-4 gap-y-2"
            >
              <FormulateInput
                v-model="scheduleDate"
                class="flex-1"
                type="date"
                :label="$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.DATE')"
                placeholder="$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.DATE')"
                :min="minDate"
                :validation="
                  minDate ? `required|after:${afterDate}` : 'required'
                "
              />

              <FormulateInput
                v-model="scheduleTime"
                class="flex-1"
                type="time"
                :label="$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TIME')"
                placeholder="$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TIME')"
                :validation="
                  minTime ? `required|timeAfter:${minTime}` : 'required'
                "
                :validation-messages="{
                  timeAfter: $t('CHATLYN_GENERAL.MESSAGES.AFTER_TIME_INVALID', {
                    time: minTime,
                  }),
                }"
                :validation-rules="{
                  timeAfter: (event) => timeAfter(event, minTime),
                }"
                :min="minTime"
              />

              <div class="text-muted w-full text-xs">{{ timezoneTitle }}</div>
            </div>
          </slide-animation>
        </div>

        <formulate-input
          v-model="selectedLanguage"
          type="select"
          :options="messageTemplateLanguagesOptions"
          :label="
            $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SELECT_SENDING_LANGUAGE')
          "
        />

        <links-before-send-editor
          v-if="newLinks && newLinks.length"
          :new-links="newLinks"
          :language-links="getTranslationLinks(currentTranslation)"
          :buttons-component="buttonsComponent"
          :is-translations-attributes-synced="true"
          :current-language="selectedLanguage"
          @change-new-links="changeNewLinks"
          @change-language-new-links="changeLanguageNewLinks"
        />

        <div v-else class="callout p-4 text-center">
          <div>
            <img
              class="mx-auto mb-1"
              src="~dashboard/assets/images/custom-send.svg"
              alt="send"
            />
          </div>

          <div class="text-base">
            {{ $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.EMPTY_LINKS.TITLE') }}
          </div>

          <div class="text-sm">
            {{ $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.EMPTY_LINKS.TEXT') }}
          </div>
        </div>
      </div>

      <div>
        <message-preview :current-translation="currentTranslation" />
      </div>
    </div>

    <div class="mt-6 flex w-full flex-row flex-nowrap justify-between gap-2">
      <woot-button
        color-scheme="secondary"
        variant="smooth"
        type="button"
        @click="$emit('reset-template')"
      >
        <div class="flex flex-row items-center" style="gap: 0.8rem">
          <fluent-icon icon="arrow-left" />

          {{
            $t('CHATLYN_MESSAGE_TEMPLATE.SEND_TO_CONTACT_FORM.SELECT_DIFFERENT')
          }}
        </div>
      </woot-button>

      <woot-button
        color-scheme="success"
        type="button"
        :disabled="hasErrors"
        @click="sendMessage"
      >
        <div class="flex flex-row items-center" style="gap: 0.8rem">
          <fluent-icon icon="send" />

          {{ $t('CHATLYN_MESSAGE_TEMPLATE.SEND_TO_CONTACT_FORM.SEND') }}
        </div>
      </woot-button>
    </div>
  </div>
</template>

<script>
import getUuid from 'widget/helpers/uuid';
import { format, getDate, getMinutes, setDate, setMinutes } from 'date-fns';

import { timeAfter } from '../../../../helper/formulateValidation';

import LinksBeforeSendEditor from '../../../../routes/dashboard/messageTemplates/components/LinksBeforeSendEditor.vue';
import MessagePreview from '../../../../routes/dashboard/messageTemplates/components/MessagePreview.vue';
import WootSwitch from 'shared/components/ui/Switch';
import SlideAnimation from '../../../animations/SlideAnimation.vue';
import { timeZoneOptions } from '../../../../routes/dashboard/settings/inbox/helpers/businessHour';

let updateTineInterval;

export default {
  name: 'ChatlynMessageTemplateSendForm',
  components: {
    LinksBeforeSendEditor,
    MessagePreview,
    SlideAnimation,
    WootSwitch,
  },

  props: {
    messageTemplate: {
      type: [Object, null],
      default: null,
    },

    hasErrors: {
      type: Boolean,
      default: false,
    },

    contactPreferredLanguage: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      showSchedule: false,
      scheduleDate: null,
      scheduleTime: null,
      currentDate: Date.now(),
      selectedLanguage: '',
      newLinks: [],
    };
  },

  computed: {
    linksValueTypeOptions() {
      return ['ATTRIBUTES', 'STATIC_TEXT'].map((key) => ({
        label: this.$t(`CHATLYN_MESSAGE_TEMPLATE.ATTRIBUTES.${key}`),
        value: key.toLowerCase(),
      }));
    },

    messageTemplateLanguages() {
      if (!this.messageTemplate) {
        return [];
      }

      const translations = this.messageTemplate.translations.filter(
        (translation) => ['approved', 'APPROVED'].includes(translation.status)
      );

      return translations.map((translation) => translation.language);
    },

    messageTemplateLanguagesOptions() {
      return this.messageTemplateLanguages.map((messageTemplateLanguage) => ({
        label: messageTemplateLanguage.toUpperCase(),
        value: messageTemplateLanguage,
      }));
    },

    currentTranslation() {
      if (!this.messageTemplate) {
        return null;
      }

      return (
        this.messageTemplate.translations.find(
          (translation) => translation.language === this.selectedLanguage
        ) || null
      );
    },

    headerComponent() {
      return this.currentTranslation?.components?.find(
        (component) => component.type === 'HEADER'
      );
    },

    buttonsComponent() {
      return this.currentTranslation?.components?.find(
        (component) => component.type === 'BUTTONS'
      );
    },

    minDate() {
      return format(this.currentDate, 'yyyy-MM-dd');
    },

    afterDate() {
      const date = new Date(this.currentDate);

      return format(setDate(date, getDate(date) - 1), 'yyyy-MM-dd');
    },

    minTime() {
      if (!this.scheduleDate) {
        return undefined;
      }

      if (format(this.currentDate, 'yyyy-MM-dd') !== this.scheduleDate) {
        return undefined;
      }

      const now = Date.now();

      return format(setMinutes(now, getMinutes(now) + 1), 'HH:mm');
    },

    timezoneTitle() {
      const matchedTimeZone =
        timeZoneOptions()?.find(
          (timeZone) => timeZone.value === this.managementAccount?.timezone
        )?.label ||
        `${format(new Date(), 'z')}
        (${Intl.DateTimeFormat().resolvedOptions().timeZone})`;

      return `${this.$t(
        'CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TIMEZONE'
      )} ${matchedTimeZone}`;
    },
  },
  watch: {
    messageTemplate: {
      handler(newValue) {
        if (!newValue || !Object.keys(newValue).length) {
          return;
        }

        this.selectedLanguage = (
          newValue.translations.find(
            (translation) =>
              translation.language === this.contactPreferredLanguage
          ) || newValue.translations[0]
        ).language;
      },
      deep: true,
      immediate: true,
    },

    'currentTranslation._id': {
      handler(newValue) {
        if (newValue) {
          this.setupSelectedLinksValueTypes();
        }
      },
      immediate: true,
    },

    selectedLanguage: {
      handler(newValue) {
        if (!newValue) {
          this.selectedLanguage = this.messageTemplateLanguagesOptions[0].value;
          return;
        }

        if (
          this.messageTemplateLanguagesOptions.some(
            (option) => option.value === newValue
          )
        ) {
          return;
        }

        this.selectedLanguage = this.messageTemplateLanguagesOptions[0].value;
      },
    },

    currentLanguageLinks: {
      get() {
        return this.newLinks.find(
          (newLinks) => newLinks.language === this.currentLanguage
        );
      },

      set(newValue) {
        let newLinks = structuredClone(this.newLinks);

        const index = newLinks.findIndex(
          (newLink) => newLink.language === newValue.language
        );

        if (index !== -1) {
          newLinks[index] = newValue;
        }

        this.newLinks = newLinks;
      },
    },

    showSchedule: {
      handler(newValue) {
        if (newValue) {
          clearInterval(updateTineInterval);

          updateTineInterval = setInterval(() => {
            this.currentDate = Date.now();
          }, 1000 * 60);
        } else {
          clearInterval(updateTineInterval);
        }
      },
    },
  },

  beforeDestroy() {
    clearInterval(updateTineInterval);
  },

  methods: {
    timeAfter,

    setupSelectedLinksValueTypes() {
      // Change it if we enable multi language templates

      const newLinks = this.messageTemplate.translations.map((translation) => {
        const newLanguageLinks = [];

        const links = translation.links
          ? JSON.parse(JSON.stringify(translation.links))
          : {
              header: [],
              body: [],
              buttons: [],
            };

        const editableHeaderFormats = ['image', 'video'];

        const headerComponent = translation.components?.find(
          (component) => component.type === 'HEADER'
        );

        if (
          headerComponent &&
          editableHeaderFormats.includes(headerComponent.format.toLowerCase())
        ) {
          links.header.push({
            example: headerComponent.example.header_handle[0],
            number: 1,
            value: '',
            valueType: headerComponent.format.toLowerCase(),
          });
        }

        ['header', 'body', 'buttons'].forEach((key) => {
          links[key].forEach((link) => {
            const newLink = {
              ...link,
              linkType: key,
              id: getUuid(),
            };

            if (!newLink.valueType) {
              newLink.valueType = link.value
                ? this.linksValueTypeOptions[0].value
                : this.linksValueTypeOptions[1].value;
            }

            newLanguageLinks.push(newLink);
          });
        });

        return {
          language: translation.language,
          links: newLanguageLinks,
        };
      });

      this.newLinks = newLinks;
    },

    getTranslationLinks(translation) {
      return this.newLinks.find(
        (newLinks) => newLinks.language === translation.language
      );
    },

    sendMessage() {
      const linksForSend =
        this.newLinks?.find(
          (newLink) => newLink.language === this.selectedLanguage
        )?.links || [];

      this.$emit(
        'on-send-message',
        this.selectedLanguage,
        linksForSend,
        // eslint-disable-next-line no-underscore-dangle
        this.messageTemplate._id,
        this.showSchedule
          ? new Date(`${this.scheduleDate} ${this.scheduleTime}`)
          : new Date()
      );
    },

    changeNewLinks(newLinks) {
      this.newLinks = structuredClone(newLinks);
    },

    changeLanguageNewLinks(newValue) {
      this.currentLanguageLinks = structuredClone(newValue);
    },
  },
};
</script>
