<template>
  <formulate-form #default="{ hasErrors }">
    <div class="grid md:grid-cols-3 gap-8">
      <div class="col-span-1">
        <div class="block-title text-gray-900 dark:text-gray-200">
          {{ $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.TITLE') }}
        </div>
        <p>{{ $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.NOTE') }}</p>
      </div>

      <div class="col-span-2">
        <div class="flex flex-row flex-nowrap gap-4 w-full">
          <select
            v-model="selectedMessageSignatureId"
            class="w-full"
            :disabled="isSignatureSelectDisabled"
          >
            <option
              v-for="messageSignature in allMessageSignatures"
              :key="messageSignature.id"
              :value="messageSignature.id"
            >
              {{ messageSignature.name }}
            </option>
          </select>

          <woot-button
            class="add-another-signature-button"
            icon="add-circle"
            color-scheme="secondary"
            type="button"
            :disabled="isAddButtonDisabled"
            @click="addSignature"
          >
            {{
              $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.ADD_ANOTHER')
            }}
          </woot-button>
        </div>

        <div v-if="selectedMessageSignature" class="signature-editor-container mt-2">
          <div class="signature-editor-header">
            <div class="w-full mb-2">
              <formulate-input
                v-model="selectedMessageSignature.name"
                type="text"
                validation="required"
                :label="
                  $t(
                    'PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.INTERNAL_TITLE'
                  )
                "
              />
            </div>

            <div class="w-full mb-2">
              <multiselect-ch
                v-model="selectedMessageSignatureInboxes"
                :multiple="true"
                :options="availableInboxes"
                :max-height="140"
              >
                <template #title>
                  <label>
                    {{
                      $t(
                        'PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.DEFAULT_FOR_INBOX'
                      )
                    }}
                  </label>
                </template>
              </multiselect-ch>
            </div>
          </div>

          <div>
            <label for="message-signature-input">
              {{ $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE.LABEL') }}
            </label>

            <woot-message-editor
              id="message-signature-input"
              v-model="selectedMessageSignature.signature"
              class="message-editor"
              :is-format-mode="true"
              :enabled-menu-options="customEditorMenuList"
              :placeholder="
                $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE.PLACEHOLDER')
              "
            />
          </div>

          <div class="flex flex-row gap-2 w-full justify-between">
            <woot-button
              class="add-another-signature-button"
              color-scheme="primary"
              type="button"
              :disabled="hasErrors || isSaveButtonDisabled"
              @click="saveSignature"
            >
              {{ $t('CHATLYN_GENERAL.BUTTON.SAVE') }}
            </woot-button>

            <woot-button
              class="add-another-signature-button"
              icon="delete"
              color-scheme="alert"
              variant="clear"
              type="button"
              @click="toggleShowDeleteModal"
            >
              {{ $t('CHATLYN_GENERAL.BUTTON.DELETE') }}
            </woot-button>
          </div>
        </div>

        <div
          v-if="isLoading"
          class="absolute h-full w-full top-0 left-0 flex items-center justify-center z-10"
          style="background: rgba(0, 0, 0, 0.05)"
        >
          <span class="spinner h-8 w-8" />
        </div>
      </div>
    </div>

    <delete-modal
      :title="
        $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.DELETE_MODAL.TITLE')
      "
      :reject-text="
        $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.DELETE_MODAL.NO')
      "
      :confirm-text="
        $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.DELETE_MODAL.YES')
      "
      :show="showDeleteModal"
      :on-close="toggleShowDeleteModal"
      :on-confirm="deleteSignature"
    />
  </formulate-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import getUuid from 'widget/helpers/uuid';

import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { MESSAGE_SIGNATURE_EDITOR_MENU_OPTIONS } from 'dashboard/constants/editor';
import DeleteModal from '../../../../components/widgets/modal/DeleteModal.vue';
import MultiselectCh from 'shared/components/ui/Multiselect.vue';

export default {
  components: {
    WootMessageEditor,
    DeleteModal,
    MultiselectCh,
  },
  mixins: [alertMixin],
  data() {
    return {
      //
      newMessageSignatureId: '',
      allMessageSignatures: [],
      selectedMessageSignatureId: -1,
      showDeleteModal: false,
      customEditorMenuList: MESSAGE_SIGNATURE_EDITOR_MENU_OPTIONS,
    };
  },
  computed: {
    ...mapGetters({
      currentUserId: 'getCurrentUserID',
      messageSignatures: 'management/getSignatures',
      currentUserMessageSignature: 'getCurrentUserMessageSignature',
      currentAccountId: 'getCurrentAccountId',
      inboxes: 'inboxes/getEmailInboxes',
      isLoading: 'management/getIsLoading',
    }),

    availableInboxes() {
      let inboxIds = [];

      this.allMessageSignatures.forEach(messageSignature => {
        inboxIds = [...inboxIds, ...messageSignature.inboxIds];
      });

      return this.inboxes.filter(inbox => !inboxIds.includes(inbox.id));
    },

    selectedMessageSignature: {
      get() {
        return this.allMessageSignatures.find(
          messageSignature =>
            messageSignature.id === this.selectedMessageSignatureId
        );
      },

      set(newValue) {
        this.allMessageSignatures = this.allMessageSignatures.map(
          messageSignature => {
            if (messageSignature.id === this.selectedMessageSignatureId) {
              return newValue;
            }

            return messageSignature;
          }
        );
      },
    },

    selectedMessageSignatureInboxes: {
      get() {
        if (!this.selectedMessageSignature?.inboxIds) {
          return [];
        }

        return this.inboxes.filter(inbox =>
          this.selectedMessageSignature.inboxIds.includes(inbox.id)
        );
      },

      set(newValue) {
        const newSelectedMessageSignature = {
          ...this.selectedMessageSignature,
        };

        newSelectedMessageSignature.inboxIds = newValue.map(inbox => inbox.id);

        this.selectedMessageSignature = newSelectedMessageSignature;
      },
    },

    isSignatureSelectDisabled() {
      return (
        !this.allMessageSignatures?.length ||
        Boolean(this.newMessageSignatureId)
      );
    },

    isAddButtonDisabled() {
      return Boolean(this.newMessageSignatureId);
    },

    isSaveButtonDisabled() {
      return !this.selectedMessageSignature?.signature;
    },
  },
  watch: {
    messageSignatures: {
      handler(newValue) {
        this.selectedMessageSignatureId = -1;
        this.newMessageSignatureId = '';

        if (newValue && newValue.length) {
          this.allMessageSignatures = [...newValue];
        }

        this.selectFirstSignature();
      },
    },
  },
  mounted() {
    this.initValues();
  },
  methods: {
    async initValues() {
      // const { message_signature: messageSignature } = this.currentUser;
      // this.messageSignature = messageSignature || '';
      this.$store.dispatch('management/getSignatures', {
        userId: this.currentUserId,
      });
    },

    toggleShowDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },

    selectFirstSignature() {
      this.selectedMessageSignatureId = this.allMessageSignatures.length
        ? this.allMessageSignatures[0].id
        : -1;
    },

    addSignature() {
      const newSignature = {
        id: getUuid(),
        name: '',
        inboxIds: [],
        signature: '',
      };

      if (
        !this.allMessageSignatures.length &&
        this.currentUserMessageSignature
      ) {
        newSignature.signature = this.currentUserMessageSignature;
      }

      this.allMessageSignatures = [...this.allMessageSignatures, newSignature];

      this.newMessageSignatureId = newSignature.id;

      this.selectedMessageSignatureId = newSignature.id;
    },

    removeSignature() {
      this.allMessageSignatures = [
        ...this.allMessageSignatures.filter(
          messageSignature =>
            messageSignature.id !== this.selectedMessageSignatureId
        ),
      ];

      this.selectFirstSignature();
    },

    async deleteSignature() {
      let result = true;

      if (this.newMessageSignatureId !== this.selectedMessageSignatureId) {
        result = await this.$store.dispatch('management/deleteSignature', {
          userId: this.currentUserId,
          signatureId: this.selectedMessageSignatureId,
        });
      } else {
        this.newMessageSignatureId = '';
      }

      this.removeSignature();
      this.toggleShowDeleteModal();

      this.showAlert(
        this.$t(
          result
            ? 'CHATLYN_GENERAL.MESSAGES.SUCCESS'
            : 'CHATLYN_GENERAL.MESSAGES.TRY_AGAIN'
        )
      );
    },

    async saveSignature() {
      const signature = this.allMessageSignatures.find(
        messageSignature =>
          messageSignature.id === this.selectedMessageSignatureId
      );

      if (!signature) {
        return;
      }

      let result = true;

      if (signature.id !== this.newMessageSignatureId) {
        result = await this.$store.dispatch('management/updateSignature', {
          userId: this.currentUserId,
          accountId: this.currentAccountId,
          signature: [signature],
        });
      } else {
        delete signature.id;

        result = await this.$store.dispatch('management/createSignature', {
          userId: this.currentUserId,
          accountId: this.currentAccountId,
          signature: [signature],
        });

        if (result) {
          await this.initValues();
        }
      }

      this.showAlert(
        this.$t(
          result
            ? 'CHATLYN_GENERAL.MESSAGES.SUCCESS'
            : 'CHATLYN_GENERAL.MESSAGES.TRY_AGAIN'
        )
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.message-editor {
  @apply px-3 mb-4;

  ::v-deep {
    .ProseMirror-menubar {
      @apply left-2;
    }
  }

  .add-another-signature-button {
    height: 4rem;
    padding: 1rem 1.2rem;

    .button__content {
      @apply flex-nowrap leading-none;

      font-size: 1.2rem;
    }
  }

  .signature-editor-container {
    @apply border border-solid border-gray-100 rounded-lg p-4;

    .signature-editor-header {
      @apply flex flex-col flex-nowrap w-full;

      span {
        .multiselect {
          .multiselect__select {
            @apply h-full;
          }

          .multiselect__tags,
          .multiselect__select {
            min-height: 3.8rem;
          }
        }

        .ch-multiselect__title {
          margin: 0;
        }

        input {
          height: 3.8rem;
        }
      }
    }
  }
}
</style>
