<template>
  <div 
    class="flex flex-grow flex-col items-center justify-center"
    :class="{'bg-gray-50 dark:bg-gray-950' : !transparent}"  
  >
    <div>
      <div
        v-if="title"
        class="block text-center w-full text-3xl font-light"
        :class="transparent ? 'text-gray-500 dark:text-gray-300' : 'text-gray-400 dark:text-gray-600'"  
      >
        {{ title }}
      </div>
      <div
        v-if="message"
        class="block text-center text-gray-500 dark:text-gray-400 my-3 mx-auto w-[90%]"
      >
        {{ message }}
      </div>
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    transparent: { type: Boolean, default: false },
  },
};
</script>
