<template>
  <div class="rounded-lg bg-gray-50 p-4 dark:bg-gray-900">
    <div v-if="conditions.length > 1" class="relative mb-3">
      <hr class="absolute left-0 top-5 w-full" />

      <formulate-input
        v-model="operator"
        :options="operatorsList"
        type="select"
        class="operator-select relative z-10 mx-auto"
        :disabled="!isEditable"
      />
    </div>

    <list-condition-builder
      v-for="(condition, i) in conditions"
      :key="condition.id"
      :condition="condition"
      :disable-remove="conditions.length < 2"
      :invalid="invalidConditions.includes(condition.id)"
      :is-editable="isEditable"
      @condition-changed="(updated) => onConditionChanged(updated, i)"
      @condition-removed="onRemoveCondition(i)"
    />

    <div v-if="invalidConditions.length" class="error-message py-2">
      {{ $t('CHATLYN_CONTACT_LISTS.ERROR_MESSAGE.INVALID_CONDITIONS') }}
    </div>

    <div class="text-center">
      <woot-button
        v-if="hasAddConditionButton"
        :disabled="invalidConditions.length > 0 || !isEditable"
        color-scheme="secondary"
        size="small"
        type="button"
        icon="add"
        @click="onAddCondition"
      >
        {{ $t('CHATLYN_CONTACT_LISTS.ADD_CONDITION_BUTTON') }}
      </woot-button>

      <woot-button
        v-if="hasClearAllButton"
        color-scheme="alert"
        size="small"
        type="button"
        icon="subtract"
        @click="onClearAllConditions"
      >
        {{ $t('CHATLYN_CONTACT_LISTS.CLEAR_ALL_CONDITION_BUTTON') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
import ListConditionBuilder from './ListConditionBuilder.vue';
import { validateConditions } from '../utils/validateConditions';
import { COMPARATOR, AND, OR } from '../utils/conditionConstants';

export default {
  name: 'AddListCondition',
  components: {
    ListConditionBuilder,
  },
  props: {
    listCondition: {
      type: Object,
      default: null,
    },

    isEditable: {
      type: Boolean,
      default: true,
    },

    hasClearAllButton: {
      type: Boolean,
      default: false,
    },

    hasAddConditionButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      operator: AND,
      conditions: [],
    };
  },
  computed: {
    invalidConditions() {
      const filledConditions = this.conditions.filter((c) => c.attribute.key);
      const duplicateConditions = validateConditions(
        filledConditions,
        this.operator
      );
      return duplicateConditions.map((condition) => condition.id);
    },

    isValid() {
      const isFilled = this.conditions.every(
        ({ attribute, value }) => attribute.key && value
      );
      return isFilled && !this.invalidConditions.length;
    },

    isEditMode() {
      return !!this.listCondition;
    },

    localCondition() {
      return {
        conditions: this.conditions.map(({ id, ...condition }) => condition),
        operator: this.operator,
      };
    },

    operatorsList() {
      return [
        {
          value: AND,
          label: this.$t('CHATLYN_CONTACT_LISTS.OPERATORS.AND'),
        },
        {
          value: OR,
          label: this.$t('CHATLYN_CONTACT_LISTS.OPERATORS.OR'),
        },
      ];
    },
  },
  watch: {
    localCondition(val) {
      this.$emit('update:condition', { condition: val, isValid: this.isValid });
    },
  },
  mounted() {
    if (this.listCondition) {
      this.setLocalCondition();
    } else {
      this.onAddCondition();
    }
  },
  methods: {
    setLocalCondition() {
      this.operator = this.listCondition.operator;
      this.conditions = this.listCondition.conditions.map(
        ({ _id, ...rest }) => ({
          ...rest,
          id: _id || Math.random(),
        })
      );
    },

    onAddCondition() {
      const updatedConditions = [...this.conditions];
      updatedConditions.push({
        attribute: { key: '', type: '' },
        comparator: COMPARATOR.EQUAL,
        value: '',
        id: Math.random(),
      });
      this.conditions = updatedConditions;
    },

    onClearAllConditions() {
      this.conditions = [];
      this.operator = AND;

      this.$nextTick(() => {
        this.$emit('after-clearing-all');
      });
    },

    onConditionChanged(updated, index) {
      const updatedConditions = [...this.conditions];
      updatedConditions.splice(index, 1, updated);
      this.conditions = updatedConditions;
    },

    onRemoveCondition(index) {
      const updatedConditions = [...this.conditions];
      updatedConditions.splice(index, 1);
      this.conditions = updatedConditions;
    },
  },
};
</script>

<style lang="scss" scoped>
.operator-select {
  width: max-content;
}
</style>
