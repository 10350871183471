<template>
  <div
    class="note-wrap mb-2 flex flex-grow flex-col overflow-hidden rounded-md border border-solid border-gray-100 bg-white p-4 text-gray-700 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-100"
  >
    <div
      class="flex justify-between gap-1 text-xs"
      :class="{
        'items-center': isOnPanel,
        'items-end': !isOnPanel,
      }"
    >
      <div class="flex items-center">
        <thumbnail
          :title="noteAuthorName"
          :src="noteAuthor.thumbnail"
          :username="noteAuthorName"
          size="20px"
        />
        <div
          class="mx-1 my-0 flex gap-1 p-0.5"
          :class="{
            'flex-col': isOnPanel,
            'flex-row': !isOnPanel,
          }"
        >
          <span class="">
            {{ noteAuthorName }}
          </span>

          <span v-if="!isOnPanel" class="text-muted">
            {{ $t('NOTES.LIST.LABEL') }}
          </span>

          <span class="">
            {{ readableTime }}
          </span>
        </div>
      </div>
      <div class="actions invisible flex">
        <woot-button
          v-tooltip="$t('NOTES.CONTENT_HEADER.DELETE')"
          variant="smooth"
          size="tiny"
          icon="delete"
          color-scheme="alert"
          @click="toggleDeleteModal"
        />
      </div>
      <woot-delete-modal
        v-if="showDeleteModal"
        :show.sync="showDeleteModal"
        :on-close="closeDelete"
        :on-confirm="confirmDeletion"
        :title="$t('DELETE_NOTE.CONFIRM.TITLE')"
        :message="$t('DELETE_NOTE.CONFIRM.MESSAGE')"
        :confirm-text="$t('DELETE_NOTE.CONFIRM.YES')"
        :reject-text="$t('DELETE_NOTE.CONFIRM.NO')"
      />
    </div>
    <p
      v-dompurify-html="formatMessage(note || '')"
      class="note__content mb-0 mt-3"
    />
  </div>
</template>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import timeMixin from 'dashboard/mixins/time';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';

export default {
  components: {
    Thumbnail,
  },

  mixins: [timeMixin, messageFormatterMixin],

  props: {
    id: {
      type: Number,
      default: 0,
    },
    note: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      default: () => {},
    },
    createdAt: {
      type: Number,
      default: 0,
    },

    isOnPanel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDeleteModal: false,
    };
  },
  computed: {
    readableTime() {
      return this.dynamicTime(this.createdAt);
    },
    noteAuthor() {
      return this.user || {};
    },
    noteAuthorName() {
      return this.noteAuthor.name || this.$t('APP_GLOBAL.DELETED_USER');
    },
  },

  methods: {
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    onDelete() {
      this.$emit('delete', this.id);
    },
    confirmDeletion() {
      this.onDelete();
      this.closeDelete();
    },
    closeDelete() {
      this.showDeleteModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// For RTL direction view
.app-rtl--wrapper {
  .note__content {
    ::v-deep {
      p {
        unicode-bidi: plaintext;
      }
    }
  }
}

.note-wrap:hover {
  .actions {
    @apply visible;
  }
}
</style>
