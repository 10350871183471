<template>
  <div class="avatar-container flex leading-[100%] font-medium items-center justify-center text-center cursor-default text-white border border-white dark:border-white/20" :style="avatarStyle" aria-hidden="true">
    <slot>{{ userInitial }}</slot> 
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    username: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 40,
    },
  },
  computed: {
    userInitial() {
      const parts = this.username.split(/[ -]/);
      let initials = parts.reduce((acc, curr) => acc + curr.charAt(0), '');

      if (initials.length > 2 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '');
      }
      initials = initials.substring(0, 2).toUpperCase();

      return initials;
    },
    avatarStyle() {
      let backgroundColor = "#417156";
      
      if (this.username === 'Bot') {
        backgroundColor = "#B33075";
      } else if (this.username) {
        backgroundColor = this.stringToColor(this.username);
      }

      return {
        backgroundColor: backgroundColor,
        fontSize: `${Math.floor(this.size / 2.5)}px`,
      };
    },
  },
  methods: {
    stringToColor(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = '#';
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return this.ensureContrast(color);
    },
    ensureContrast(color) {
      // Convert hex color to RGB
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      
      // Calculate luminance
      const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

      // Ensure contrast (use white text if luminance is less than 128, otherwise use black text)
      return luminance < 110 ? color : this.darkenColor(color, 0.55);
    },
    darkenColor(color, factor) {
      const r = Math.floor(parseInt(color.slice(1, 3), 16) * factor);
      const g = Math.floor(parseInt(color.slice(3, 5), 16) * factor);
      const b = Math.floor(parseInt(color.slice(5, 7), 16) * factor);
      return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;
    },
    componentToHex(c) {
      const hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    }
  }
};
</script>