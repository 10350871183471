/* eslint-disable class-methods-use-this */
/* global axios */
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? 'https://staging.chatlyn.com/smswamw/wa/profile'
    : window.location.protocol +
      '//' +
      window.location.hostname +
      '/smswamw/wa/profile';

class ProfileAPI {
  get360DialogProfile = async (phoneNumber) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/${phoneNumber.replace(/\+/g, '')}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  get360DialogProfilePhoto = async (phoneNumber) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    // /wa/profile/{number}/photo
    return axios.get(`${url}/${phoneNumber.replace(/\+/g, '')}/photo`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  update360DialogProfile = async (phoneNumber, profile) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(`${url}/${phoneNumber.replace(/\+/g, '')}`, profile, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  update360DialogProfileV2 = async (phoneNumber, profile) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(`${url}v2/${phoneNumber.replace(/\+/g, '')}`, profile, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  update360DialogProfilePhoto = async (phoneNumber, file) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    let formData = new FormData();
    formData.append('image', file);

    return axios.post(
      `${url}/${phoneNumber.replace(/\+/g, '')}/photo`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };
}

export default new ProfileAPI();
