var render = function render(){var _vm=this,_c=_vm._self._c;return _c('formulate-form',{staticClass:"unarchive-form relative",attrs:{"name":_vm.formName},on:{"submit":_vm.onConfirm},scopedSlots:_vm._u([{key:"default",fn:function({ hasErrors }){return [_c('woot-modal-header',{attrs:{"header-title":_vm.$t('CHATLYN_SIDEBAR.ARCHIVE_MODAL.UNARCHIVE_TITLE'),"header-content":_vm.$t('CHATLYN_SIDEBAR.ARCHIVE_MODAL.UNARCHIVE_MESSAGE', {
        ARCHIVE_TYPE: _vm.$t(
          `CHATLYN_SIDEBAR.ARCHIVE_MODAL.ARCHIVE_TYPE.${_vm.archiveType}`
        ),
      })}}),_vm._v(" "),(_vm.isContactList)?_c('div',{staticClass:"modal-content"},[_c('input-radio-group',{attrs:{"label":_vm.$t(`CHATLYN_SIDEBAR.ARCHIVE_MODAL.WHERE_TO_UNARCHIVE`),"items":_vm.unarchiveOptions,"action":_vm.handleChangeSelectedUnarchiveOption},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [(
            item.id !== _vm.unarchiveOptions[0].id &&
            _vm.selectedUnarchiveOption.id === _vm.unarchiveOptions[1].id
          )?_c('div',{staticClass:"mt-1 w-full pl-5"},[_c('formulate-input',{attrs:{"options":_vm.allAvailableFoldersOptions,"placeholder":_vm.$t('CHATLYN_GENERAL.PLACEHOLDER.PLEASE_CHOOSE'),"type":"select","validation":"required"},model:{value:(_vm.selectedFolder),callback:function ($$v) {_vm.selectedFolder=$$v},expression:"selectedFolder"}})],1):_vm._e()]}}],null,true)})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex justify-end gap-2 px-8 pb-8 pt-0"},[_c('woot-button',{attrs:{"color-scheme":"secondary","variant":"smooth","type":"button"},on:{"click":_vm.onReject}},[_vm._v("\n      "+_vm._s(_vm.$t('CHATLYN_GENERAL.BUTTON.NO'))+"\n    ")]),_vm._v(" "),_c('woot-button',{attrs:{"type":"submit","is-disabled":hasErrors}},[_vm._v("\n      "+_vm._s(_vm.$t('CHATLYN_GENERAL.BUTTON.YES'))+"\n    ")])],1),_vm._v(" "),_c('chatlyn-spinner',{attrs:{"is-visible":_vm.isLoading}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }