<template>
  <div class="relative">
    <div class="settings--content">
      <settings-section
        class="border-none"
        :title="$t('CHATLYN_INBOXES.WE_CHAT.CONFIG.TITLE')"
        :sub-title="$t('CHATLYN_INBOXES.WE_CHAT.CONFIG.DESC')"
      >
        <we-chat-configuration
          edit-mode
          :inbox="weChatInbox"
          @handle-form-submit="updateInbox"
        />
      </settings-section>

      <div
        v-if="isLoading"
        class="absolute h-full w-full top-0 left-0 flex items-center justify-center z-10"
        style="background: rgba(0, 0, 0, 0.05)"
      >
        <span class="spinner h-8 w-8" />
      </div>
    </div>
  </div>
</template>

<script>
import SettingsSection from '../../../../../components/SettingsSection.vue';
import WeChatConfiguration from '../components/WeChatConfiguration.vue';
import WeChatAPI from '../../../../../api/weChat';

export default {
  name: 'WeChatConfigurationPage',
  components: {
    SettingsSection,
    WeChatConfiguration,
  },
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      weChatInbox: null,
    };
  },
  mounted() {
    this.fetchInbox();
  },
  methods: {
    async fetchInbox() {
      try {
        this.isLoading = true;

        const { data } = await WeChatAPI.getWeChatInbox(this.inbox.id);

        if (!data) {
          return;
        }

        this.weChatInbox = data;
      } catch (error) {
        bus.$emit(
          'newToastMessage',
          this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN')
        );
      } finally {
        this.isLoading = false;
      }
    },

    async updateInbox(newInbox) {
      try {
        this.isLoading = true;

        await WeChatAPI.updateWeChatInbox(this.inbox.id, newInbox);

        bus.$emit(
          'newToastMessage',
          this.$t('CHATLYN_INBOXES.WE_CHAT.CONFIG.SUCCESS_MESSAGE')
        );
      } catch (error) {
        bus.$emit(
          'newToastMessage',
          this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN')
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
