<template>
  <div class="select-with-placeholder-container">
    <select
      :id="id"
      v-model="computedValue"
      class="select-with-placeholder-select"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>

    <label :for="id" class="select-with-placeholder-content">
      <span v-if="icon">
        <fluent-icon :icon="icon" :type="iconType" :size="iconSize" />
      </span>

      <span>
        {{ selectText }}
      </span>

      <span>
        <fluent-icon icon="chevron-down" :size="12" />
      </span>
    </label>
  </div>
</template>

<script>
import getUuid from 'widget/helpers/uuid';

export default {
  name: 'SelectWithPlaceholder',
  props: {
    id: {
      type: String,
      default: () => getUuid(),
    },

    value: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      default: () => [],
    },

    placeholder: {
      type: String,
      default: '',
    },

    icon: {
      type: String,
      default: '',
    },

    iconType: {
      type: String,
      default: 'outline',
    },

    iconSize: {
      type: Number,
      default: 15,
    },
  },
  computed: {
    selectText() {
      if (!this.value) {
        return this.placeholder;
      }

      const matchedValue = this.options.find(
        (option) => option.value === this.value
      );

      return matchedValue.label;
    },

    computedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>

<style lang="scss">
.select-with-placeholder-container {
  @apply relative rounded transition-all duration-200;

  .select-with-placeholder-content {
    @apply pointer-events-none relative z-10 flex h-10 w-full flex-row flex-nowrap items-center justify-center gap-2 px-4 transition-all duration-200 dark:text-gray-100;
  }

  .select-with-placeholder-select {
    @apply absolute left-0 top-0 m-0 h-full w-full cursor-pointer bg-none p-0;
  }
}
</style>
