<template>
  <div class="csat--table-container">
    <ve-table
      max-height="calc(100vh - 21.875rem)"
      :fixed-header="true"
      :border-around="true"
      :columns="columns"
      :table-data="tableData"
    />
    <div v-show="!tableData.length" class="csat--empty-records">
      {{ $t('CSAT_REPORTS.NO_RECORDS') }}
    </div>
    <div v-if="metrics.totalResponseCount" class="table-pagination">
      <ve-pagination
        :total="metrics.totalResponseCount"
        :page-index="pageIndex"
        :page-size="25"
        :page-size-option="[25]"
        @on-page-number-change="onPageNumberChange"
      />
    </div>
  </div>
</template>
<script>
import { VeTable, VePagination } from 'vue-easytable';
import UserAvatarWithName from 'dashboard/components/widgets/UserAvatarWithName.vue';
import { CSAT_RATINGS } from 'shared/constants/messages';
import { mapGetters } from 'vuex';
import timeMixin from 'dashboard/mixins/time';
import rtlMixin from 'shared/mixins/rtlMixin';

export default {
  components: {
    VeTable,
    VePagination,
  },
  mixins: [timeMixin, rtlMixin],
  props: {
    pageIndex: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'csat/getUIFlags',
      csatResponses: 'csat/getCSATResponses',
      metrics: 'csat/getMetrics',
    }),
    columns() {
      return [
        {
          field: 'contact',
          key: 'contact',
          title: this.$t('CSAT_REPORTS.TABLE.HEADER.CONTACT_NAME'),
          align: this.isRTLView ? 'right' : 'left',
          width: 200,
          renderBodyCell: ({ row }) => {
            if (row.contact) {
              return (
                <UserAvatarWithName
                  textClass="text-sm text-gray-800"
                  size="24px"
                  user={row.contact}
                />
              );
            }
            return '---';
          },
        },
        {
          field: 'assignedAgent',
          key: 'assignedAgent',
          title: this.$t('CSAT_REPORTS.TABLE.HEADER.AGENT_NAME'),
          align: this.isRTLView ? 'right' : 'left',
          width: 200,
          renderBodyCell: ({ row }) => {
            if (row.assignedAgent) {
              return (
                <UserAvatarWithName size="24px" user={row.assignedAgent} />
              );
            }
            return '---';
          },
        },
        {
          field: 'rating',
          key: 'rating',
          title: this.$t('CSAT_REPORTS.TABLE.HEADER.RATING'),
          align: 'center',
          width: 80,
          renderBodyCell: ({ row }) => {
            const [ratingObject = {}] = CSAT_RATINGS.filter(
              rating => rating.value === row.rating
            );
            return (
              <span class="emoji-response">{ratingObject.emoji || '---'}</span>
            );
          },
        },
        {
          field: 'feedbackText',
          key: 'feedbackText',
          title: this.$t('CSAT_REPORTS.TABLE.HEADER.FEEDBACK_TEXT'),
          align: this.isRTLView ? 'right' : 'left',
          width: 400,
        },
        {
          field: 'conversationId',
          key: 'conversationId',
          title: '',
          align: this.isRTLView ? 'right' : 'left',
          width: 100,
          renderBodyCell: ({ row }) => {
            const routerParams = {
              name: 'inbox_conversation',
              params: { conversation_id: row.conversationId },
            };
            return (
              <div class="text-right">
                <router-link to={routerParams}>
                  {`#${row.conversationId}`}
                </router-link>
                <div class="csat--timestamp" v-tooltip={row.createdAt}>
                  {row.createdAgo}
                </div>
              </div>
            );
          },
        },
      ];
    },
    tableData() {
      return this.csatResponses.map(response => ({
        contact: response.contact,
        assignedAgent: response.assigned_agent,
        rating: response.rating,
        feedbackText: response.feedback_message || '---',
        conversationId: response.conversation_id,
        createdAgo: this.dynamicTime(response.created_at),
        createdAt: this.messageStamp(response.created_at, 'LLL d yyyy, HH:mm'),
      }));
    },
  },
  methods: {
    onPageNumberChange(pageIndex) {
      this.$emit('page-change', pageIndex);
    },
  },
};
</script>
<style lang="scss" scoped>
.csat--table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ve-table {
    @apply bg-white dark:bg-gray-900;
  }

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }

  .emoji-response {
    @apply text-lg
  }

  .table-pagination {
    @apply mt-auto
  }
}

.csat--empty-records {
  @apply text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-900 border border-t-0 dark:border-gray-700
  text-sm py-16 text-center w-full;
}

.csat--timestamp {
  @apply text-gray-600 dark:text-gray-200 text-sm;
}
</style>
