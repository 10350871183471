<template>
  <div class="flex h-[2.375rem] min-w-0 items-center px-1 py-0">
    <span
      class="mr-1 inline-flex h-6 w-6 flex-shrink-0 items-center justify-center rounded bg-gray-100 p-0.5 rtl:ml-1 rtl:mr-0 dark:bg-gray-700"
    >
      <fluent-icon
        :icon="computedInboxIcon"
        size="14"
        class="text-gray-800 dark:text-gray-200"
      />
    </span>
    <div class="ml-1 mr-1 flex w-full min-w-0 flex-col">
      <h5 class="option__title">
        {{ name }}
      </h5>
      <p
        class="option__body overflow-hidden text-ellipsis whitespace-nowrap"
        :title="inboxIdentifier"
      >
        {{ inboxIdentifier || computedInboxType }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  getInboxClassByType,
  getReadableInboxByType,
} from 'dashboard/helper/inbox';

export default {
  components: {},
  props: {
    name: {
      type: String,
      default: '',
    },
    inboxIdentifier: {
      type: String,
      default: '',
    },
    channelType: {
      type: String,
      default: '',
    },
    apiInboxType: {
      type: [String || undefined],
      default: '',
    },
  },
  computed: {
    computedInboxIcon() {
      if (!this.channelType) return 'chat';
      const classByType = getInboxClassByType(
        this.channelType,
        this.inboxIdentifier
      );
      return classByType;
    },
    computedInboxType() {
      if (!this.channelType) return 'chat';
      const classByType = getReadableInboxByType(
        this.channelType,
        this.inboxIdentifier,
        this.apiInboxType
      );

      return classByType;
    },
  },
};
</script>

<style lang="scss" scoped>
.option__body {
  @apply m-0 inline-block min-w-0 text-xxs leading-[1.3] text-gray-500;
}
.option__title {
  @apply m-0 text-xs leading-[1.1] text-gray-800 dark:text-gray-100;
}
</style>
