<template>
  <div
    class="conversation-ai-toggler !z-20 flex cursor-pointer select-none flex-row flex-nowrap items-center justify-center gap-1 rounded-2xl border border-solid px-3 py-1.5 text-xs leading-none transition-all duration-300"
    :class="{
      'border-violet-300 bg-violet-400 text-white dark:border-violet-400 dark:bg-violet-500 dark:text-violet-100':
        isAIEnabled,
      'border-violet-200 bg-violet-100 text-violet-600 dark:border-violet-800 dark:bg-violet-900 dark:text-violet-200':
        !isAIEnabled,
    }"
    @click.prevent.stop="toggleIsAIEnabled"
  >
    <span
      class="pointer-events-none inline-flex flex-row flex-nowrap items-center gap-1"
    >
      <fluent-icon icon="wand" :size="16" class="-mt-0.5" />

      <span>
        {{
          isAIEnabled
            ? $t('CONVERSATION.AI_TOGGLER.LABEL.ACTIVE')
            : $t('CONVERSATION.AI_TOGGLER.LABEL.INACTIVE')
        }}
      </span>
    </span>

    <span class="pointer-events-none">
      <woot-switch
        id="conversation-ai-toggler"
        v-model="isAIEnabled"
        class="conversation-ai-toggler__switch"
        label=" "
      />
    </span>

    <chatlyn-spinner :is-visible="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import WootSwitch from 'shared/components/ui/Switch';
import ChatlynSpinner from '../../../../shared/components/ChatlynSpinner.vue';

export default {
  name: 'ConversationAiToggler',

  components: {
    WootSwitch,
    ChatlynSpinner,
  },

  props: {
    conversationId: {
      type: Number,
      required: true,
    },

    messages: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      conversationStatus: 'chatbot/getConversationStatus',
      chatbotUIFlags: 'chatbot/getUIFlags',
    }),

    isLoading() {
      return (
        this.chatbotUIFlags.isConversationStatusFetching ||
        this.chatbotUIFlags.isConversationStatusUpdating
      );
    },

    isAIEnabled: {
      get() {
        return typeof this.isAIEnabledOutside === 'boolean'
          ? this.isAIEnabledOutside
          : this.conversationStatus?.conversation_state === 'ai';
      },

      async set(newValue) {
        await this.updateConversationStatus(newValue);
      },
    },

    isAIEnabledOutside() {
      if (!this.latestMessage?.content) {
        return null;
      }

      switch (this.latestMessage.content) {
        case 'Handover requested, AI deactivated':
          return false;

        case 'AI activated':
          return true;

        default:
          return null;
      }
    },

    latestMessage() {
      if (!this.messages.length) {
        return null;
      }

      return this.messages[this.messages.length - 1];
    },
  },

  methods: {
    toggleIsAIEnabled() {
      this.isAIEnabled = !this.isAIEnabled;
    },

    async updateConversationStatus(newValue) {
      if (this.isLoading) {
        return;
      }

      await this.$store.dispatch('chatbot/updateConversationStatus', {
        accountId: this.accountId,
        conversationId: this.conversationId,
        state: newValue ? 'ai' : 'agent',
      });
    },
  },
};
</script>

<style lang="scss">
.conversation-ai-toggler {
  .conversation-ai-toggler__switch {
    .checkbox-switch-label::after {
      @apply border border-solid border-violet-200 bg-violet-400 dark:border-violet-500 dark:bg-violet-200;
    }

    .checkbox-switch:checked + .checkbox-switch-label::after {
      @apply border border-solid border-violet-300 bg-violet-500 dark:border-violet-300 dark:bg-violet-100;
    }

    .checkbox-switch-label::before {
      @apply border border-solid border-violet-200 bg-white dark:border-violet-500 dark:bg-violet-700;
    }

    .checkbox-switch:checked + .checkbox-switch-label::before {
      @apply border-white bg-white dark:border-violet-800 dark:bg-violet-800;
    }
  }

  .chatlyn-spinner {
    @apply h-full w-full;

    .chatlyn-spinner__circle {
      @apply h-6 w-6;
    }
  }
}
</style>
