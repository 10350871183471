<template>
  <woot-button
    variant="smooth"
    color-scheme="secondary"
    class="label--add"
    icon="add"
    size="tiny"
    @click="addLabel"
  >
    {{ $t('CONTACT_PANEL.LABELS.CONVERSATION.ADD_BUTTON') }}
  </woot-button>
</template>

<script>
export default {
  methods: {
    addLabel() {
      this.$emit('add');
    },
  },
};
</script>
