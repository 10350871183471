<template>
  <woot-modal
    :show="show"
    :on-close="onCancel"
    modal-type="right-aligned"
    :style="{
      '--contact-panel-width': '100%',
    }"
  >
    <woot-modal-header
      class="list--header pb-1 pt-4"
      :header-title="headerTitle"
    />

    <div class="flex h-full min-h-0 max-w-3xl flex-grow flex-col px-0 py-3">
      <add-contacts-to-list-form
        :in-progress="uiFlags.isCreating"
        :name="currentList.name"
        @submit="onSubmit"
        @show-create-modal="showCreateModal"
      />
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import AddContactsToListForm from './AddContactsToListForm.vue';

export default {
  name: 'AddContactsToList',
  components: {
    AddContactsToListForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      currentList: 'contactLists/getList',
    }),
    headerTitle() {
      return (
        this.$t('CHATLYN_CONTACT_LISTS.ADD_TO_LIST.TITLE', {
          name: this.currentList.name,
        }) || ''
      );
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    showCreateModal() {
      this.$emit('show-create-modal');
    },
    onSubmit() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &--header {
    flex: none;
  }
}
</style>
