<template>
  <formulate-form
    v-slot="{ hasErrors }"
    class="list-merge-form flex flex-col gap-3"
    name="list-merge-form"
    @submit="handleFormSubmit"
  >
    <div
      class="block"
      :class="{ error: $v.selectedList && $v.selectedList.$error }"
    >
      <label>
        {{ $t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.MERGE.LIST') }}
      </label>

      <multiselect
        v-model="selectedList"
        track-by="value"
        label="label"
        :allow-empty="false"
        :options="listsOptions"
        :multiple="false"
        :max-height="150"
        :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.PLEASE_CHOOSE')"
      />

      <span v-if="$v.selectedList && $v.selectedList.$error" class="message">
        {{ $t('CHATLYN_GENERAL.MESSAGES.ERROR') }}
      </span>
    </div>

    <formulate-input
      v-model="mergeName"
      :label="$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.DUPLICATE.NAME')"
      :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.NAME')"
      validation="required|min:2"
      :validation-messages="{
        required: $t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
      }"
      :minlength="2"
    />

    <div class="callout text-xs">{{ helpText }}</div>

    <div class="modal-actions mt-1 flex w-full items-center justify-between">
      <woot-button
        color-scheme="alert"
        variant="smooth"
        type="button"
        @click="$emit('handle-delete-list')"
      >
        {{ $t('CHATLYN_GENERAL.BUTTON.DELETE') }}
      </woot-button>

      <woot-button :disabled="hasErrors">
        {{ $t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.TABS.MERGE') }}
      </woot-button>
    </div>
  </formulate-form>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'ListMerge',
  mixins: [alertMixin],
  props: {
    contactList: {
      type: Object,
      default: () => ({}),
    },
  },
  validations: {
    selectedList: {
      required,
    },
  },
  data() {
    return {
      selectedList: {},
      mergeName: '',
    };
  },
  computed: {
    ...mapGetters({
      lists: 'contactLists/getAvailableLists',
      currentAccountId: 'getCurrentAccountId',
    }),
    helpText() {
      const base = this.$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.MERGE.HELP_TEXT');
      const dynamic = this.$t(
        'CHATLYN_CONTACT_LISTS.EDIT_MODAL.MERGE.HELP_TEXT_DYNAMIC'
      );
      return this.contactList.static ? base : base + ' ' + dynamic;
    },
    listsOptions() {
      return (
        this.lists
          // eslint-disable-next-line no-underscore-dangle
          .filter((list) => list._id !== this.contactList.id)
          .map((list) => ({
            label: list.name,
            // eslint-disable-next-line no-underscore-dangle
            value: list._id,
          }))
      );
    },
  },
  methods: {
    async handleFormSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          throw new Error();
        }

        const isSuccess = await this.$store.dispatch('contactLists/mergeList', {
          // eslint-disable-next-line no-underscore-dangle
          listAId: this.contactList._id,
          listBId: this.selectedList.value,
          name: this.mergeName,
        });

        if (!isSuccess) {
          throw new Error();
        }

        await this.$store.dispatch('contactLists/getLists', {
          accountId: this.currentAccountId,
        });

        this.showAlert(
          this.$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.MERGE.SUCCESS_MESSAGE')
        );
        this.$emit('on-close');
      } catch (error) {
        const errorMessage =
          error.message || this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN');
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>
