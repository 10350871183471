<template>
  <div
    :class="{
      'border-red-200 dark:border-red-900': invalid,
      'border-gray-200  dark:border-gray-700/50': !invalid,
    }"
    class="mb-2 flex gap-1 rounded border bg-gray-50 p-2 dark:bg-gray-800"
  >
    <formulate-input
      :value="localCondition.attribute.key"
      :options="attributesList"
      :placeholder="$t('CHATLYN_CONTACT_LISTS.PLACEHOLDERS.ATTRIBUTE')"
      :disabled="!isEditable"
      class="custom-formulate-error-tooltip tooltip__top w-1/3"
      type="select"
      validation="required"
      @change="({ target }) => onAttributeUpdate(target.value)"
    />

    <formulate-input
      :value="localCondition.comparator"
      :options="filteredComparators"
      :disabled="!isEditable"
      class="w-1/4"
      type="select"
      @change="onComparatorUpdate"
    />

    <formulate-input
      v-model="localCondition.value"
      :options="valueInput.options"
      :placeholder="$t('CHATLYN_CONTACT_LISTS.PLACEHOLDERS.ATTRIBUTE_VALUE')"
      :type="valueInput.component === SELECTOR ? 'select' : valueInput.type"
      :disabled="!isEditable"
      class="custom-formulate-error-tooltip tooltip__top flex-1"
      validation="required"
    />

    <woot-button
      :disabled="disableRemove || !isEditable"
      color-scheme="secondary"
      icon="dismiss"
      type="button"
      variant="clear"
      @click="$emit('condition-removed')"
    />
  </div>
</template>

<script>
import attributeMixin from 'dashboard/mixins/attributeMixin';
import { ATTRIBUTE_TYPE } from 'dashboard/constants/common';
import {
  ALL_COMPARATORS,
  BASE_COMPARATORS,
  BOOLEAN_LIST,
  COMPARATOR,
  SELECTOR,
  INPUT,
  TRUE,
  FALSE,
} from '../utils/conditionConstants';

const { TEXT, LINK, LIST, CHECKBOX, NUMBER, DATE } = ATTRIBUTE_TYPE;
const {
  EMPTY,
  NOT_EMPTY,
  EQUAL,
  NOT_EQUAL,
  CONTAIN,
  NOT_CONTAIN,
  LESS,
  GREATER,
} = COMPARATOR;

export default {
  name: 'ListConditionBuilder',
  components: {},
  mixins: [attributeMixin],
  props: {
    condition: {
      type: Object,
      required: true,
    },

    disableRemove: {
      type: Boolean,
      default: false,
    },

    invalid: {
      type: Boolean,
      default: false,
    },

    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      attributeType: 'contact_attribute',
      localCondition: { ...this.condition },
      valueInput: {
        component: INPUT,
        type: TEXT,
        options: [],
      },
      SELECTOR,
    };
  },
  computed: {
    attributesList() {
      return this.getAttributesList();
    },
    booleanList() {
      const translations = {
        [TRUE]: this.$t('CHATLYN_CONTACT_LISTS.BOOLEANS.TRUE'),
        [FALSE]: this.$t('CHATLYN_CONTACT_LISTS.BOOLEANS.FALSE'),
      };
      return BOOLEAN_LIST.map((value) => ({
        value,
        label: translations[value],
      }));
    },
    comparatorTranslations() {
      return {
        [EMPTY]: this.$t('CHATLYN_CONTACT_LISTS.COMPARATORS.EMPTY'),
        [NOT_EMPTY]: this.$t('CHATLYN_CONTACT_LISTS.COMPARATORS.NOT_EMPTY'),
        [EQUAL]: this.$t('CHATLYN_CONTACT_LISTS.COMPARATORS.EQUAL'),
        [NOT_EQUAL]: this.$t('CHATLYN_CONTACT_LISTS.COMPARATORS.NOT_EQUAL'),
        [CONTAIN]: this.$t('CHATLYN_CONTACT_LISTS.COMPARATORS.CONTAIN'),
        [NOT_CONTAIN]: this.$t('CHATLYN_CONTACT_LISTS.COMPARATORS.NOT_CONTAIN'),
        [LESS]: this.$t('CHATLYN_CONTACT_LISTS.COMPARATORS.LESS'),
        [GREATER]: this.$t('CHATLYN_CONTACT_LISTS.COMPARATORS.GREATER'),
      };
    },
    filteredComparators() {
      let comparators = ALL_COMPARATORS;
      if (this.localCondition.attribute) {
        comparators = [...BASE_COMPARATORS];
        const attrType = this.localCondition.attribute.type || TEXT;
        if (attrType === TEXT || attrType === LINK) {
          comparators.push(CONTAIN, NOT_CONTAIN);
        }
        if (attrType === NUMBER || attrType === DATE) {
          comparators.push(LESS, GREATER);
        }
      }
      return comparators.map((value) => ({
        value,
        label: this.comparatorTranslations[value],
      }));
    },
  },
  watch: {
    'localCondition.attribute.key': {
      handler(_newValue, prevValue) {
        this.clearValueByAttribute(prevValue);
      },
    },

    'localCondition.comparator': {
      handler(_newValue, prevValue) {
        this.clearValueByComparator(prevValue);
      },
    },

    localCondition: {
      handler(val) {
        const { options, ...attribute } = val.attribute;
        this.$emit('condition-changed', { ...val, attribute });
      },
      deep: true,
    },
  },
  mounted() {
    if (this.condition.attribute?.key) {
      this.onAttributeUpdate(this.condition.attribute.key, true);
    }
  },
  methods: {
    onAttributeUpdate(attrValue, initial = false) {
      const selectedAttribute =
        this.attributesList.find((attr) => attr.value === attrValue) || null;

      if (selectedAttribute?.value) {
        this.localCondition.attribute = {
          key: selectedAttribute.value,
          type: selectedAttribute.type,
          options: selectedAttribute.options,
        };

        this.setInputValue(initial);
      }
    },
    onComparatorUpdate({ target }) {
      this.localCondition.comparator = target.value;
      this.setInputValue();
    },
    setInputValue(initial = false) {
      if (this.localCondition.attribute) {
        const { type = TEXT, options } = this.localCondition.attribute;

        const selectorTypes = [CHECKBOX, LIST];
        const selectorComparators = [EMPTY, NOT_EMPTY];

        const booleanComparator = selectorComparators.includes(
          this.localCondition.comparator
        );

        const isSelector = selectorTypes.includes(type) || booleanComparator;

        const optionsList =
          booleanComparator || !options ? this.booleanList : options;

        this.valueInput = {
          component: isSelector ? SELECTOR : INPUT,
          type: isSelector ? 'select' : type,
          options: isSelector ? optionsList : [],
        };

        const firstOption = optionsList[0].value || optionsList[0];

        const defaultValue = isSelector
          ? firstOption
          : this.localCondition.value;

        this.localCondition.value = initial
          ? this.localCondition.value
          : defaultValue;
      }
    },

    clearValueByAttribute(attrKey) {
      const selectedAttribute =
        this.attributesList.find((attr) => {
          return attr.value === attrKey;
        }) || null;

      if (!selectedAttribute || selectedAttribute.type === 'text') {
        return;
      }

      this.localCondition.value = '';
    },

    clearValueByComparator(comparator) {
      if (!['empty', 'notEmpty'].includes(comparator)) {
        return;
      }

      this.localCondition.value = '';
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .formulate-input {
  input,
  select {
    margin-bottom: 0;
  }
}
</style>
