<template>
  <div
    class="selected-control flex w-full items-center justify-center gap-2 border-t border-woot-100 bg-woot-50 p-3 dark:border-woot-700 dark:bg-woot-800"
  >
    <span class="me-3 text-sm font-semibold text-woot-600 dark:text-woot-100">
      {{ selectedQuantity }} {{ $t('CHATLYN_CONTACT_LISTS.FOOTER.SELECTED') }}
    </span>

    <woot-button size="small" @click="showAddToList">
      {{ $t('CHATLYN_CONTACT_LISTS.FOOTER.ADD_TO_OTHER_LIST') }}
    </woot-button>

    <woot-button
      v-if="showDeleteFromList"
      :is-disabled="!isContactListsPage || selectedQuantity < 1"
      color-scheme="alert"
      size="small"
      data-testid="toggle-show-remove-contact-from-list-modal"
      @click="showDeleteSelectedModal"
    >
      {{ $t('CHATLYN_CONTACT_LISTS.FOOTER.REMOVE_FROM_LIST') }}
    </woot-button>

    <woot-button
      v-else-if="!isContactListsPage"
      color-scheme="alert"
      size="small"
      data-testid="toggle-show-delete-contact-modal"
      @click="showDeleteSelectedContactsModal"
    >
      {{ $t('CHATLYN_CONTACT_LISTS.FOOTER.DELETE_SELECTED_CONTACTS') }}
    </woot-button>

    <woot-button
      color-scheme="primary"
      variant="clear"
      size="small"
      data-testid="deselect-all-contacts"
      @click="deselectAll"
    >
      {{ $t('CHATLYN_CONTACT_LISTS.FOOTER.DESELECT_ALL') }}
    </woot-button>
  </div>
</template>

<script>
import WootButton from '../../../../components/ui/WootButton.vue';

export default {
  name: 'FooterActions',
  components: { WootButton },
  props: {
    showDeleteFromList: {
      type: Boolean,
      default: false,
    },
    selectedQuantity: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isContactListsPage() {
      return this.$route.name === 'contacts_lists_dashboard';
    },
  },
  methods: {
    showDeleteSelectedModal() {
      this.$emit('show-delete-selected-modal');
    },

    showDeleteSelectedContactsModal() {
      this.$emit('show-delete-selected-contacts-modal');
    },

    showAddToList() {
      this.$emit('show-add-to-list');
    },

    deselectAll() {
      this.$emit('deselect-all');
    },
  },
};
</script>
