<template>
  <div v-if="isEditorVisible" class="w-full">
    <div
      v-if="selectedTranslation.lang !== mainLanguage"
      class="callout primary mb-5 flex flex-row flex-nowrap items-center justify-between gap-2"
    >
      <span class="flex flex-row flex-nowrap items-center gap-2.5">
        <fluent-icon icon="document-copy" :size="16" type="solid" />

        <span class="text-xs">
          {{
            $t(
              'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.COPY_FROM_PRIMARY_LANGUAGE.MESSAGE',
              {
                LANGUAGE: mainLanguage,
              }
            )
          }}
        </span>
      </span>

      <span class="flex flex-row flex-nowrap items-center gap-2.5">
        <woot-button
          class="!h-auto !px-3 !py-2.5 !leading-none"
          type="button"
          size="tiny"
          variant="smooth"
          color-scheme="secondary"
          @click="copyFromMainLanguage(false)"
        >
          {{
            $t(
              'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.COPY_FROM_PRIMARY_LANGUAGE.INSERT'
            )
          }}
        </woot-button>

        <woot-button
          class="!h-auto !px-3 !py-2.5 !leading-none"
          type="button"
          size="tiny"
          @click="copyFromMainLanguage(true)"
        >
          {{
            $t(
              'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.COPY_FROM_PRIMARY_LANGUAGE.INSERT_TRANSLATE'
            )
          }}
        </woot-button>
      </span>
    </div>

    <div class="flex w-full flex-row flex-nowrap gap-2">
      <span
        class="flex flex-1 flex-col flex-nowrap gap-2 rounded border p-2.5 dark:border-gray-700"
      >
        <div>
          <label
            :for="`ai-handover-${selectedTranslationLanguage}-body-text`"
            class="mb-1"
          >
            {{ $t('AGENT_BOTS.AI_HANGOVER_CONFIGURATION.MESSAGE.BODY.LABEL') }}
          </label>

          <div
            v-on-clickaway="hideBodyEmojiPicker"
            class="ai-handover__body-text__container relative rounded border p-1 pt-2 dark:border-gray-700 dark:bg-gray-900"
          >
            <div class="px-2">
              <resizable-text-area
                :id="`ai-handover-${selectedTranslationLanguage}-body-text`"
                v-model="bodyText"
                class="border-none"
                is-formulate-form-component
                :placeholder="
                  $t(
                    'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.MESSAGE.BODY.PLACEHOLDER'
                  )
                "
                :min-height="3"
                :max-length="1024"
                validation="required|noMultipleNewLines|maxLength"
                :validation-messages="{
                  required: $t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
                  noMultipleNewLines: $t(
                    'CHATLYN_GENERAL.MESSAGES.NO_DOUBLE_NEW_LINES'
                  ),
                  maxLength: $t('CHATLYN_GENERAL.MESSAGES.TOO_LONG'),
                }"
                :validation-rules="{
                  noMultipleNewLines,
                  maxLength: () => maxLength(bodyText, 1024),
                }"
                :show-emoji-picker="showBodyEmojiPicker"
                @hide-emoji-picker="hideBodyEmojiPicker"
              />

              <transition name="fade">
                <emoji-input
                  v-if="showBodyEmojiPicker"
                  :on-click="handleOnClickEmoji"
                  class="emoji-dialog--expanded"
                />
              </transition>
            </div>

            <div class="mt-1 px-2">
              <input-translator
                v-model="bodyText"
                is-extended
                :is-hidden="!showBodyTranslationBox"
                :max-length="1024"
                @action-after-translate="toggleShowBodyTranslationBox"
              />
            </div>

            <reply-bottom-panel
              class="!px-2"
              hide-ai-suggestion-button
              hide-ai-button
              hide-send-button
              hide-ai-assist-button
              hide-signature-button
              disable-drag-drop
              show-immediate-translation
              show-text-counter
              portal-slug=""
              :message="selectedTranslation.body"
              :is-immediate-translation-disabled="!selectedTranslation.body"
              :show-emoji-picker="false"
              :translate-text-immediate-label="
                $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.TRANSLATE_TO') +
                ' ' +
                `${selectedTranslation.lang.toUpperCase()}`
              "
              :conversation-id="-1"
              :current-length="
                selectedTranslation.body ? selectedTranslation.body.length : 0
              "
              :max-length="1024"
              :preferred-language="selectedTranslation.lang"
              :show-translate-box="showBodyTranslationBox"
              @change-message="handleChangeBodyText"
              @toggle-translate-box="() => toggleShowBodyTranslationBox()"
              @toggle-emoji-picker="toggleShowBodyEmojiPicker"
              @change-is-translating="changeIsTranslating"
            />
          </div>
        </div>

        <input-with-translation
          v-model="buttonText"
          is-formulate-form-component
          type="text"
          :label="
            $t('AGENT_BOTS.AI_HANGOVER_CONFIGURATION.MESSAGE.BUTTON.LABEL')
          "
          :placeholder="
            $t(
              'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.MESSAGE.BUTTON.PLACEHOLDER'
            )
          "
          :max-length="20"
          :preferred-language="selectedTranslationLanguage"
          validation="required|maxLength"
          :validation-messages="{
            required: $t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
            maxLength: $t('CHATLYN_GENERAL.MESSAGES.TOO_LONG'),
          }"
          :validation-rules="{
            maxLength: () => maxLength(buttonText, 20),
          }"
        />
      </span>

      <span class="max-w-[17.875rem] flex-1">
        <message-preview
          :current-translation="previewObject"
          :has-label="false"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

import EmojiInput from '../../../../../../../../shared/components/emoji/EmojiInput.vue';
import ResizableTextArea from '../../../../../../../../shared/components/ResizableTextArea.vue';
import ReplyBottomPanel from '../../../../../../../components/widgets/WootWriter/ReplyBottomPanel.vue';
import { mapGetters } from 'vuex';
import InputTranslator from '../../../../../../../components/widgets/conversation/translator/InputTranslator.vue';
import InputWithTranslation from '../../../../../../../components/widgets/conversation/translator/InputWithTranslation.vue';
import {
  noMultipleNewLines,
  maxLength,
} from '../../../../../../../helper/formulateValidation';
import MessagePreview from '../../../../../messageTemplates/components/MessagePreview.vue';

export default {
  name: 'AiHandoverMessageTranslationEditor',
  components: {
    ResizableTextArea,
    EmojiInput,
    ReplyBottomPanel,
    InputTranslator,
    InputWithTranslation,
    MessagePreview,
  },
  mixins: [clickaway],
  props: {
    newHandover: {
      type: Object,
      required: true,
    },

    languages: {
      type: Array,
      default: () => [],
    },

    selectedTranslation: {
      type: Object,
      default: () => ({}),
    },

    mainLanguage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showBodyTranslationBox: false,
      isBodyTranslating: false,
      showBodyEmojiPicker: false,
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
    }),

    selectedTranslationLanguage() {
      return this.selectedTranslation.lang;
    },

    isEditorVisible() {
      return !!Object.keys(this.selectedTranslation).length;
    },

    bodyText: {
      get() {
        return this.selectedTranslation.body;
      },

      set(newValue) {
        this.$emit('handle-change-selected-translation', {
          ...this.selectedTranslation,
          body: newValue,
        });
      },
    },

    buttonText: {
      get() {
        return this.selectedTranslation.button;
      },

      set(newValue) {
        this.$emit('handle-change-selected-translation', {
          ...this.selectedTranslation,
          button: newValue,
        });
      },
    },

    previewObject() {
      return {
        language: this.selectedTranslationLanguage,
        components: [
          {
            text: this.bodyText,
            type: 'BODY',
          },
          {
            type: 'BUTTONS',
            buttons: [
              {
                type: 'QUICK_REPLY',
                text: this.buttonText,
              },
            ],
          },
        ],
      };
    },
  },
  methods: {
    noMultipleNewLines,
    maxLength,

    handleChangeSelectedLanguage(newLanguage) {
      this.$emit('handle-change-selected-language', newLanguage);
    },

    handleChangeBodyText(newValue) {
      this.bodyText = newValue;
    },

    toggleShowBodyTranslationBox() {
      this.showBodyTranslationBox = !this.showBodyTranslationBox;
    },

    changeIsTranslating(newValue) {
      this.isBodyTranslating = newValue;
    },

    toggleShowBodyEmojiPicker() {
      this.showBodyEmojiPicker = !this.showBodyEmojiPicker;
    },

    hideBodyEmojiPicker() {
      if (!this.showBodyEmojiPicker) {
        return;
      }

      this.toggleShowBodyEmojiPicker();
    },

    handleOnClickEmoji(emoji) {
      const element = document.querySelector(
        `#ai-handover-${this.selectedTranslationLanguage}-body-text`
      );

      if (!element) {
        return;
      }

      this.insertSymbol(
        emoji,
        element.selectionStart,
        element.selectionEnd,
        element
      );
    },

    insertSymbol(symbol, selectionStart, selectionEnd, element) {
      const newText =
        this.bodyText.slice(0, selectionStart) +
        symbol +
        this.bodyText.slice(selectionEnd, this.bodyText.length);

      this.bodyText = newText;

      if (element) {
        this.$nextTick(() => {
          element.focus();
          element.setSelectionRange(selectionStart, selectionEnd);
        });
      }
    },

    copyFromMainLanguage(isTranslate = false) {
      this.$emit(
        'copy-from-language',
        this.mainLanguage,
        this.selectedTranslationLanguage,
        isTranslate
      );
    },
  },
};
</script>

<style lang="scss">
.ai-handover__body-text__container {
  .formulate-resizable-text-area {
    textarea {
      @apply border-none bg-transparent p-0;
    }

    .formulate-input-errors {
      @apply mt-1;
    }
  }

  .emoji-dialog--expanded {
    left: -320px;
    top: unset;
    bottom: 12px;

    &::before {
      transform: rotate(-90deg);
      bottom: 14px;
      right: -19px;
      left: unset;
    }
  }
}
</style>
