<template>
  <formulate-form
    v-slot="{ hasErrors }"
    name="chat-bubbles-form"
    class="chat-bubbles-page-container"
    @submit="handleChatBubblesFormSubmit"
  >
    <div
      v-if="uiFlags.isFetching"
      class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
      style="background: rgba(0, 0, 0, 0.05)"
    >
      <span class="spinner h-8 w-8" />
    </div>
    <template v-if="!allChatBubbles.length && !uiFlags.isFetching">
      <div class="empty-languages-message-container">
        <div class="empty-languages-message-image" />

        <div class="empty-languages-message">
          <div class="empty-languages-message-title text-woot-500">
            {{ $t('CHATLYN_CHAT_BUBBLES.EMPTY_MESSAGE.TITLE') }}
          </div>

          <div class="empty-languages-message-body text-sm">
            {{ $t('CHATLYN_CHAT_BUBBLES.EMPTY_MESSAGE.BODY') }}
          </div>

          <div class="empty-languages-message-footer">
            <select-with-placeholder
              v-model="newLanguage"
              :placeholder="$t('CHATLYN_CHAT_BUBBLES.EMPTY_MESSAGE.FOOTER')"
              :options="availableLanguages"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-if="!uiFlags.isFetching && allChatBubbles.length">
      <div class="languages-chat-buttons-container chatlyn-container m-0 p-0">
        <language-bar
          has-right-side
          :current-language="selectedLanguage"
          :languages="languages"
          :has-additional-text="false"
          @change="handleChangeSelectedLanguage"
        >
          <template #right-side>
            <select-with-placeholder
              v-model="newLanguage"
              :placeholder="$t('CHATLYN_CHAT_BUBBLES.ADD_LANGUAGE')"
              :options="availableLanguages"
            />
          </template>
        </language-bar>

        <div
          v-if="selectedLanguageBubbles && selectedLanguageBubbles.length"
          class="mt-6 flex w-full flex-col flex-nowrap gap-4 rounded-md border p-4 dark:border-gray-700 dark:bg-gray-900"
        >
          <draggable
            v-model="selectedLanguageBubbles"
            class="flex flex-col flex-nowrap gap-4"
            handle=".draggable-handle"
          >
            <template v-for="chatBubble in selectedLanguageBubbles">
              <div
                :key="chatBubble.id"
                class="flex flex-row flex-nowrap items-center gap-4 rounded-md border bg-gray-50 px-4 py-2 dark:border-gray-700 dark:bg-gray-800"
              >
                <span class="draggable-handle cursor-pointer">
                  <fluent-icon
                    icon="re-order-dots-vertical"
                    :size="20"
                    color="var(--gray-500)"
                  />
                </span>

                <formulate-input
                  v-model="chatBubble.text"
                  class="flex-1 self-start"
                  label="Button-Text"
                  type="text"
                  validation="required"
                />

                <formulate-input
                  v-model="chatBubble.url"
                  class="flex-1 self-start"
                  label="URL"
                  type="url"
                  :validation="
                    chatBubble.url.startsWith('mailto:')
                      ? 'required|starts_with:mailto:'
                      : 'required|url'
                  "
                />

                <woot-button
                  icon="dismiss"
                  variant="clear"
                  color-scheme="secondary"
                  type="button"
                  @click="handleShowDeleteChatBubbleModal(chatBubble.id)"
                />
              </div>
            </template>
          </draggable>

          <template v-for="otherChatBubble in otherLanguagesBubbles">
            <div v-show="false" :key="otherChatBubble.id">
              <formulate-input
                v-model="otherChatBubble.text"
                class="flex-1 self-start"
                label="Button-Text"
                type="text"
                validation="required"
              />

              <formulate-input
                v-model="otherChatBubble.url"
                class="flex-1 self-start"
                label="URL"
                type="url"
                validation="required|url"
              />
            </div>
          </template>

          <div class="flex w-full flex-row flex-nowrap justify-center gap-4">
            <woot-button
              icon="add-circle"
              icon-type="solid"
              variant="smooth"
              color-scheme="secondary"
              type="button"
              @click="handleAddNewButtonClick"
            >
              {{ $t('CHATLYN_CHAT_BUBBLES.FORM.ADD_BUTTON') }}
            </woot-button>
          </div>
        </div>

        <div class="languages-chat-buttons-container-footer">
          <span>
            <woot-button
              icon="delete"
              variant="clear"
              color-scheme="alert"
              type="button"
              @click="handleShowDeleteAllLanguageChatBubblesModal"
            >
              {{ $t('CHATLYN_CHAT_BUBBLES.FORM.REMOVE_LANGUAGE') }}
            </woot-button>
          </span>

          <span class="flex flex-row gap-4">
            <woot-button icon="save" :is-disabled="hasErrors">
              {{ $t('CHATLYN_GENERAL.BUTTON.SAVE') }}
            </woot-button>
          </span>
        </div>
      </div>
    </template>

    <woot-modal
      :show.sync="showDeleteChatBubbleModal"
      :on-close="handleCloseDeleteChatBubbleModal"
    >
      <delete-chat-bubble-modal
        v-if="showDeleteChatBubbleModal"
        :is-language-deleting="isDeleteAllLanguageChatBubbles"
        @on-confirm="
          isDeleteAllLanguageChatBubbles
            ? deleteAllLanguageButtons()
            : deleteButton()
        "
        @close-modal="
          isDeleteAllLanguageChatBubbles
            ? handleCoseDeleteAllLanguageChatBubblesModal()
            : handleCloseDeleteChatBubbleModal()
        "
      />
    </woot-modal>
  </formulate-form>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectWithPlaceholder from '../../../../../components/ui/SelectWithPlaceholder.vue';
import LanguageBar from '../../../messageTemplates/components/LanguageBar.vue';
import configMixin from 'shared/mixins/configMixin';
import getUuid from 'widget/helpers/uuid';
import draggable from 'vuedraggable';
import alertMixin from 'shared/mixins/alertMixin';
import DeleteChatBubbleModal from './components/DeleteChatBubbleModal.vue';

export default {
  name: 'ChatBubblesPage',
  components: {
    LanguageBar,
    SelectWithPlaceholder,
    draggable,
    DeleteChatBubbleModal,
  },
  mixins: [configMixin, alertMixin],
  props: {
    inboxId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedLanguage: '',
      newChatBubbles: [],
      selectedChatBubble: '',
      allChatBubbles: [],
      showDeleteChatBubbleModal: false,
      isDeleteAllLanguageChatBubbles: false,
    };
  },
  computed: {
    ...mapGetters({
      chatBubbles: 'management/getChatBubbles',
      currentAccountId: 'getCurrentAccountId',
      isLoading: 'management/getIsLoading',
      uiFlags: 'management/getUiFlags',
    }),

    availableLanguages() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages
        .sort((l1, l2) => l1.iso_639_1_code.localeCompare(l2.iso_639_1_code))
        .filter((lang) => {
          return (
            this.languages.findIndex(
              (language) => language.name === lang.iso_639_1_code
            ) < 0
          );
        })
        .map((lang) => ({
          label: lang.name,
          value: lang.iso_639_1_code,
        }));
    },

    languages() {
      const uniqLang = [];

      this.allChatBubbles.forEach((chatBubble) => {
        if (!uniqLang.includes(chatBubble.lang)) {
          uniqLang.push(chatBubble.lang);
        }
      });

      return uniqLang
        .map((lang) => ({
          name: lang,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },

    newLanguage: {
      get() {
        return '';
      },

      set(newValue) {
        const newChatBubble = {
          id: getUuid(),
          lang: newValue,
          text: '',
          url: '',
          position: 0,
        };

        this.selectedLanguage = newValue;

        this.addNewButton(newChatBubble);
      },
    },

    selectedLanguageBubbles: {
      get() {
        return this.allChatBubbles
          .filter((chatBubble) => chatBubble.lang === this.selectedLanguage)
          .sort((a, b) => {
            if (a.position < b.position) {
              return -1;
            }

            if (a.position > b.position) {
              return 1;
            }
            return 0;
          });
      },

      set(newValue) {
        newValue.forEach((chatBubble, index) => {
          chatBubble.position = index;
        });

        // Just a different way if mutability stop working
        // newValue.for((chatBubble, index) => ({
        //   ...chatBubble,
        //   position: index,
        // }));

        // const oldButtons = JSON.parse(JSON.stringify(this.allChatBubbles));

        // this.allChatBubbles = oldButtons.map(oldButton => {
        //   const matchedValue = newButtons.find(
        //     newButton => newButton.id === oldButton.id
        //   );

        //   return matchedValue || oldButton;
        // });
      },
    },

    otherLanguagesBubbles() {
      return this.allChatBubbles.filter(
        (chatBubble) => chatBubble.lang !== this.selectedLanguage
      );
    },
  },
  watch: {
    chatBubbles: {
      handler(newValue) {
        this.allChatBubbles = JSON.parse(JSON.stringify(newValue));
      },
      deep: true,
    },

    allChatBubbles: {
      handler(newValue) {
        if (!newValue?.length) {
          return;
        }

        if (!newValue.some((bubble) => bubble.lang === this.selectedLanguage)) {
          this.$nextTick(() => {
            this.selectedLanguage = this.languages[0]?.name || '';
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch('management/getChatBubbles', {
      inboxId: this.inboxId,
    });
  },
  methods: {
    toggleShowDeleteChatBubbleModal() {
      this.showDeleteChatBubbleModal = !this.showDeleteChatBubbleModal;
    },

    handleChangeSelectedLanguage(newValue) {
      this.selectedLanguage = newValue;
    },

    addNewButton(newChatBubble) {
      this.newChatBubbles = [...this.newChatBubbles, newChatBubble.id];

      this.allChatBubbles = [...this.allChatBubbles, newChatBubble];
    },

    handleAddNewButtonClick() {
      const position = Math.max(
        ...this.selectedLanguageBubbles.map((chatBubble) => chatBubble.position)
      );

      const newChatBubble = {
        id: getUuid(),
        lang: this.selectedLanguage,
        text: '',
        url: '',
        position: position + 1,
      };

      this.addNewButton(newChatBubble);
    },

    handleShowDeleteChatBubbleModal(id) {
      this.selectedChatBubble = id;
      this.toggleShowDeleteChatBubbleModal();
    },

    handleShowDeleteAllLanguageChatBubblesModal() {
      this.isDeleteAllLanguageChatBubbles = true;
      this.toggleShowDeleteChatBubbleModal();
    },

    handleCloseDeleteChatBubbleModal() {
      this.selectedChatBubble = '';
      this.toggleShowDeleteChatBubbleModal();
    },

    handleCoseDeleteAllLanguageChatBubblesModal() {
      this.isDeleteAllLanguageChatBubbles = false;
      this.toggleShowDeleteChatBubbleModal();
    },

    async deleteButton() {
      try {
        if (!this.newChatBubbles.includes(this.selectedChatBubble)) {
          await this.$store.dispatch('management/deleteChatBubble', {
            inboxId: this.inboxId,
            accountId: this.currentAccountId,
            id: this.selectedChatBubble,
          });
        }

        this.removeButtonFromArrayById(this.selectedChatBubble);
      } catch (error) {
        //
      } finally {
        this.handleCloseDeleteChatBubbleModal();
      }
    },

    removeButtonFromArrayById(id) {
      this.newChatBubbles = [
        ...this.newChatBubbles.filter((newId) => newId !== id),
      ];

      this.allChatBubbles = JSON.parse(
        JSON.stringify(
          this.allChatBubbles.filter((chatBubble) => chatBubble.id !== id)
        )
      );
    },

    removeButtonsFromArrayByLanguage(language) {
      const buttonsToRemove = this.allChatBubbles.filter(
        (chatBubble) => chatBubble.lang === language
      );

      this.newChatBubbles = [
        ...this.newChatBubbles.filter((id) => {
          return !buttonsToRemove.some((button) => button.id === id);
        }),
      ];

      this.allChatBubbles = JSON.parse(
        JSON.stringify(
          this.allChatBubbles.filter(
            (chatBubble) => chatBubble.lang !== language
          )
        )
      );
    },

    async deleteAllLanguageButtons() {
      try {
        await this.$store.dispatch('management/deleteLanguageChatBubbles', {
          inboxId: this.inboxId,
          accountId: this.currentAccountId,
          language: this.selectedLanguage,
        });

        this.removeButtonsFromArrayByLanguage(this.selectedLanguage);
      } catch (error) {
        //
      } finally {
        this.handleCoseDeleteAllLanguageChatBubblesModal();
      }
    },

    async handleChatBubblesFormSubmit() {
      try {
        const formComponent = this.$formulate.registry.get('chat-bubbles-form');

        if (formComponent.hasErrors) {
          return;
        }

        const bubblesToCreate = [];
        const bubblesToUpdate = [];

        this.allChatBubbles.forEach((chatBubble) => {
          if (this.newChatBubbles.includes(chatBubble.id)) {
            delete chatBubble.id;
            bubblesToCreate.push(chatBubble);
            return;
          }

          bubblesToUpdate.push(chatBubble);
        });

        if (bubblesToCreate.length) {
          const createResult = await this.$store.dispatch(
            'management/createChatBubbles',
            {
              inboxId: this.inboxId,
              accountId: this.currentAccountId,
              chatBubbles: bubblesToCreate,
            }
          );

          if (!createResult) {
            throw new Error();
          }

          this.newChatBubbles = [];
        }

        if (bubblesToUpdate.length) {
          const updateResult = await this.$store.dispatch(
            'management/updateChatBubbles',
            {
              inboxId: this.inboxId,
              accountId: this.currentAccountId,
              chatBubbles: bubblesToUpdate,
            }
          );

          if (!updateResult) {
            throw new Error();
          }
        }

        this.showAlert(this.$t('CHATLYN_GENERAL.MESSAGES.SUCCESS'));

        await this.$store.dispatch('management/getChatBubbles', {
          inboxId: this.inboxId,
        });
      } catch (error) {
        this.showAlert(this.$t('CHATLYN_GENERAL.MESSAGES.ERROR'));
      }
    },
  },
};
</script>

<style lang="scss">
.chat-bubbles-page-container {
  @apply relative;

  .empty-languages-message-container {
    @apply max-w-4xl;

    .empty-languages-message-image {
      @apply relative m-auto aspect-video max-w-sm bg-contain bg-bottom bg-no-repeat;
      background-image: url('~assets/images/chat-bubbles-empty-state.svg');
    }

    .empty-languages-message {
      @apply -mt-4 flex flex-col flex-nowrap gap-4 rounded-md bg-gray-50 p-8 text-center dark:bg-gray-900 dark:text-gray-100;

      .empty-languages-message-footer {
        @apply relative flex flex-row justify-center text-center;

        .select-with-placeholder-container {
          @apply h-full cursor-pointer;

          .select-with-placeholder-select {
            @apply bg-woot-500  dark:bg-woot-700;
          }

          .select-with-placeholder-select,
          .select-with-placeholder-content {
            @apply text-white dark:text-gray-100;
          }
        }
      }
    }
  }

  .languages-chat-buttons-container {
    .language-bar {
      .select-with-placeholder-container {
        @apply h-full cursor-pointer;

        .select-with-placeholder-select {
          @apply border-0 bg-white dark:bg-gray-800;
        }

        .select-with-placeholder-content {
          @apply flex-nowrap border-none p-0 leading-none;
        }
      }
    }

    .languages-chat-buttons-container-footer {
      @apply mt-4 flex w-full flex-row flex-nowrap justify-between gap-4;
    }
  }
}
</style>
