<template>
  <div
    v-if="!authUIFlags.isFetching && !accountUIFlags.isFetchingItem"
    id="app"
    class="app-wrapper h-full min-h-0 w-full flex-grow-0"
    :class="{ 'app-rtl--wrapper': isRTLView }"
    :dir="isRTLView ? 'rtl' : 'ltr'"
  >
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <add-account-modal
      :show="showAddAccountModal"
      :has-accounts="hasAccounts"
    />
    <woot-snackbar-box />
    <network-notification />
  </div>
  <loading-state v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import AddAccountModal from '../dashboard/components/layout/sidebarComponents/AddAccountModal.vue';
import LoadingState from './components/widgets/LoadingState.vue';
import NetworkNotification from './components/NetworkNotification.vue';
import UpgradeBanner from './components/app/UpgradeBanner.vue';
import PaymentPendingBanner from './components/app/PaymentPendingBanner.vue';
import vueActionCable from './helper/actionCable';
import WootSnackbarBox from './components/SnackbarContainer.vue';
import rtlMixin from 'shared/mixins/rtlMixin';
import { setColorTheme } from './helper/themeHelper';
import {
  registerSubscription,
  verifyServiceWorkerExistence,
} from './helper/pushHelper';

export default {
  name: 'App',

  components: {
    AddAccountModal,
    LoadingState,
    NetworkNotification,
    PaymentPendingBanner,
    WootSnackbarBox,
    UpgradeBanner,
  },

  mixins: [rtlMixin],

  data() {
    return {
      showAddAccountModal: false,
      latestChatwootVersion: null,
    };
  },

  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      authUIFlags: 'getAuthUIFlags',
      accountUIFlags: 'accounts/getUIFlags',
      currentAccountId: 'getCurrentAccountId',
      currentUserLanguage: 'management/getUserLanguage',
      isDark: 'theme/isDark',
      colorTheme: 'theme/colorTheme',
    }),
    hasAccounts() {
      const { accounts = [] } = this.currentUser || {};
      return accounts.length > 0;
    },
  },

  watch: {
    currentUser() {
      if (!this.hasAccounts) {
        this.showAddAccountModal = true;
      }
    },
    currentAccountId() {
      if (this.currentAccountId) {
        this.initializeAccount();
        this.fetchAINests();
      }
    },
  },
  mounted() {
    this.initializeColorTheme();
    this.listenToThemeChanges();
    this.setLocale(window.chatwootConfig.selectedLocale);
  },
  methods: {
    initializeColorTheme() {
      this.$store.dispatch('theme/getColorTheme');
      setColorTheme(this.isDark);
    },

    listenToThemeChanges() {
      const mql = window.matchMedia('(prefers-color-scheme: dark)');
      mql.onchange = (e) => {
        if (this.colorTheme === 'auto') {
          this.$store.commit('theme/updateIsDark');
          setColorTheme(e.matches);
        }
      };
    },

    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },

    async initializeAccount() {
      await this.$store.dispatch('accounts/get');

      this.$store.dispatch('setActiveAccount', {
        accountId: this.currentAccountId,
      });

      const {
        locale: accountLocale,
        latest_chatwoot_version: latestChatwootVersion,
      } = this.getAccount(this.currentAccountId);
      const { pubsub_token: pubsubToken } = this.currentUser || {};

      await this.$store.dispatch('management/getUserLanguage', {
        currentUserId: this.currentUser.id,
      });

      const locale = this.currentUserLanguage || accountLocale;

      this.setLocale(locale);
      this.updateRTLDirectionView(locale);
      this.latestChatwootVersion = latestChatwootVersion;
      vueActionCable.init(pubsubToken);

      verifyServiceWorkerExistence((registration) =>
        registration.pushManager.getSubscription().then((subscription) => {
          if (subscription) {
            registerSubscription();
          }
        })
      );
    },

    async fetchAINests() {
      await this.$store.dispatch('chatbot/getAINests', {
        accountId: this.currentAccountId,
      });
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/app';
</style>
