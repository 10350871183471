var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.errorStatusTooltip()),expression:"errorStatusTooltip()"}],staticClass:"first-letter-uppercase flex items-center gap-1"},[_c('span',{class:[
      _vm.checkStatuses
        ? 'status-indicator bg-green-500'
        : 'status-indicator bg-red-500',
    ]}),_vm._v(" "),_c('span',{staticClass:"menu-label inline-block text-xs",class:[
      _vm.checkStatuses
        ? 'text-green-700 dark:text-green-400'
        : 'text-red-700 dark:text-red-400',
    ]},[_vm._v("\n    "+_vm._s(_vm.checkStatuses
        ? _vm.$t('CHATLYN_SENT_TEMPLATES_REPORTS.STATUS.SENT')
        : _vm.$t('CHATLYN_SENT_TEMPLATES_REPORTS.STATUS.FAILED'))+"\n  ")]),_vm._v(" "),(_vm.data.statuses.length > 0)?_c('div',{staticClass:"ml-3 inline-flex items-center gap-0"},[(_vm.showIcon('sent'))?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.showIcon('sent')),expression:"showIcon('sent')"}],staticClass:"sent-indicator bg-gray-100 text-gray-600 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"},[_c('fluent-icon',{attrs:{"icon":"checkmark-single","type":"outline","class-name":"visibility-check","size":"12"}})],1):_vm._e(),_vm._v(" "),(_vm.showIcon('delivered'))?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.showIcon('delivered')),expression:"showIcon('delivered')"}],staticClass:"sent-indicator bg-woot-50 text-gray-900 hover:bg-woot-100 dark:bg-woot-700 dark:text-woot-100 dark:hover:bg-woot-600"},[_c('fluent-icon',{attrs:{"icon":"checkmark-double","type":"outline","class-name":"visibility-check","size":"12"}})],1):_vm._e(),_vm._v(" "),(_vm.showIcon('read'))?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.showIcon('read')),expression:"showIcon('read')"}],staticClass:"sent-indicator bg-green-500 text-white hover:bg-green-600 dark:bg-green-600 dark:hover:bg-woot-500"},[_c('fluent-icon',{attrs:{"icon":"checkmark-double","type":"outline","class-name":"visibility-check","size":"12"}})],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }