var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slide-animation-container grid grid-rows-[0fr]",class:{
    'grid-rows-[0fr]': !_vm.isShown,
    'grid-rows-[1fr]': _vm.isShown,
  },style:({
    '--slide-animation-duration': `${_vm.duration}ms`,
    overflow: _vm.overflow,
  })},[_c('div',{staticClass:"min-h-0 min-w-0"},[_c('transition',{attrs:{"name":"slide"}},[_vm._t("default")],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }