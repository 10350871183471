<template>
  <div class="app-wrapper flex h-full flex-grow dark:text-gray-300">
    <sidebar
      :route="currentRoute"
      :show-secondary-sidebar="isSidebarOpen"
      @open-notification-panel="openNotificationPanel"
      @toggle-account-modal="toggleAccountModal"
      @open-key-shortcut-modal="toggleKeyShortcutModal"
      @close-key-shortcut-modal="closeKeyShortcutModal"
      @show-add-label-popup="showAddLabelPopup"
      @show-add-list-popup="showAddListPopup"
      @show-add-entrypoint-popup="showAddEntrypointPopup"
      @show-add-folder-popup="showAddFolderPopup"
      @show-edit-list-popup="showEditListPopup"
      @show-delete-list-popup="showDeleteListPopup"
      @show-archive-popup="showArchivePopup"
      @show-edit-folder-popup="showEditFolderPopup"
      @show-delete-folder-popup="showDeleteFolderPopup"
    />

    <section class="flex h-full w-full min-w-0 flex-grow">
      <router-view />

      <command-bar />

      <account-selector
        :show-account-modal="showAccountModal"
        @close-account-modal="toggleAccountModal"
        @show-create-account-modal="openCreateAccountModal"
      />

      <add-account-modal
        :show="showCreateAccountModal"
        @close-account-create-modal="closeCreateAccountModal"
      />

      <woot-key-shortcut-modal
        :show.sync="showShortcutModal"
        @close="closeKeyShortcutModal"
        @clickaway="closeKeyShortcutModal"
      />

      <notification-panel
        v-if="isNotificationPanel"
        @close="closeNotificationPanel"
      />

      <woot-modal :show.sync="showAddLabelModal" :on-close="hideAddLabelPopup">
        <add-label-modal @close="hideAddLabelPopup" />
      </woot-modal>

      <woot-modal
        :show.sync="showAddListModal"
        :on-close="hideAddListPopup"
        size="md"
      >
        <add-list-modal :folder-id="folderId" @close="hideAddListPopup" />
      </woot-modal>

      <woot-modal
        :show="showAddFolderModal || showEditFolderModal"
        :on-close="
          showEditFolderModal ? hideEditFolderPopup : hideAddFolderPopup
        "
        size="sm"
      >
        <add-folder-modal
          :is-edit-mode="showEditFolderModal"
          :folder-id="showEditFolderModal ? folderId : undefined"
          @close="
            (newValue) => {
              return showEditFolderModal
                ? hideEditFolderPopup(newValue)
                : hideAddFolderPopup(newValue);
            }
          "
        />
      </woot-modal>

      <entrypoint-edit-modal
        v-if="showAddEntrypoint"
        :is-visible="showAddEntrypoint"
        @close="hideAddEntrypointPopup"
      />

      <woot-modal
        :show.sync="showEditListModal"
        :on-close="hideEditListPopup"
        size="md"
      >
        <list-settings
          v-if="showEditListModal"
          @delete="() => hideEditListPopup(true)"
          @close="hideEditListPopup"
        />
      </woot-modal>

      <delete-modal
        size="sm"
        :show="showDeleteListModal"
        :title="
          $t('CHATLYN_CONTACT_LISTS.REMOVE_LIST.TITLE', {
            list: contactList.name,
          })
        "
        :message="$t('CHATLYN_CONTACT_LISTS.REMOVE_LIST.MESSAGE')"
        :reject-text="$t('CHATLYN_CONTACT_LISTS.REMOVE_LIST.CANCEL')"
        :confirm-text="$t('CHATLYN_CONTACT_LISTS.REMOVE_LIST.CONFIRM')"
        :on-close="hideDeleteListPopup"
        :on-confirm="() => hideDeleteListPopup(true)"
        :has-buttons="!listEntrypoints.length"
      >
        <template v-if="listEntrypoints.length" #body>
          <div class="callout warning mb-4 flex flex-row gap-2 text-xs">
            <span>
              <fluent-icon icon="lock-closed" :size="12" />
            </span>

            <span>
              <div>
                {{ $t('CHATLYN_CONTACT_LISTS.REMOVE_LIST.ALERT.TITLE') }}

                <ul class="list-disc">
                  <li
                    v-for="entrypoint in listEntrypoints"
                    :key="entrypoint.entrypointId"
                    class="ml-6"
                  >
                    {{ entrypoint.name }}
                  </li>
                </ul>

                {{ $t('CHATLYN_CONTACT_LISTS.REMOVE_LIST.ALERT.TEXT') }}
              </div>
            </span>
          </div>
        </template>
      </delete-modal>

      <confirm-modal
        size="sm"
        buttons-alignment="end"
        :show="showArchiveModal && !unarchive"
        :on-close="() => hideArchivePopup(false)"
        :on-confirm="() => hideArchivePopup(true)"
        :title="$t('CHATLYN_SIDEBAR.ARCHIVE_MODAL.TITLE')"
        :message="
          $t('CHATLYN_SIDEBAR.ARCHIVE_MODAL.MESSAGE', {
            ARCHIVE_TYPE: $t(
              `CHATLYN_SIDEBAR.ARCHIVE_MODAL.ARCHIVE_TYPE.${archiveType}`
            ),
          })
        "
        :confirm-text="$t('CHATLYN_GENERAL.BUTTON.YES')"
        :reject-text="$t('CHATLYN_GENERAL.BUTTON.NO')"
      />

      <unarchive-modal
        :show="showArchiveModal && unarchive"
        :archive-type="archiveType"
        @on-reject="() => hideArchivePopup(false)"
        @on-confirm="(folderId) => hideArchivePopup(true, folderId)"
      />

      <delete-modal
        size="sm"
        :show="showDeleteFolderModal"
        :title="
          $t('FOLDERS.DELETE_MODAL.TITLE', {
            FOLDER: folder.name,
          })
        "
        :message="$t('FOLDERS.DELETE_MODAL.MESSAGE')"
        :reject-text="$t('FOLDERS.DELETE_MODAL.CANCEL')"
        :confirm-text="$t('FOLDERS.DELETE_MODAL.CONFIRM')"
        :on-close="hideDeleteFolderPopup"
        :on-confirm="() => hideDeleteFolderPopup(true)"
        :has-buttons="!folderEntrypoints.length"
      >
        <template v-if="folderEntrypoints.length" #body>
          <div class="callout warning mb-4 flex flex-row gap-2 text-xs">
            <span>
              <fluent-icon icon="lock-closed" :size="12" />
            </span>

            <div class="flex flex-col gap-0.5 font-semibold">
              <span>
                {{ $t('FOLDERS.DELETE_MODAL.ALERT.TITLE') }}
              </span>

              <ol class="ml-5 list-decimal font-medium">
                <li
                  v-for="folderEntrypoint in folderEntrypoints"
                  :key="folderEntrypoint.listId"
                  class="mt-1 first:mt-0"
                >
                  <span>
                    {{
                      $t('FOLDERS.DELETE_MODAL.ALERT.MESSAGE', {
                        LIST_NAME: folderEntrypoint.name,
                      })
                    }}
                  </span>

                  <ol class="ml-4 list-disc font-normal">
                    <li
                      v-for="entrypoint in folderEntrypoint.entrypoints"
                      :key="entrypoint.entrypointId"
                      class="mt-0.5 first:mt-0"
                    >
                      {{ entrypoint.name }}
                    </li>
                  </ol>
                </li>
              </ol>

              <span>
                {{ $t('FOLDERS.DELETE_MODAL.ALERT.TEXT') }}
              </span>
            </div>
          </div>
        </template>
      </delete-modal>

      <chatlyn-spinner :is-visible="isLoading" />
    </section>

    <div id="chatlyn-app-target" />
  </div>
</template>

<script>
import Sidebar from '../../components/layout/Sidebar.vue';
import CommandBar from './commands/commandbar.vue';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import WootKeyShortcutModal from 'dashboard/components/widgets/modal/WootKeyShortcutModal.vue';
import AddAccountModal from 'dashboard/components/layout/sidebarComponents/AddAccountModal.vue';
import AccountSelector from 'dashboard/components/layout/sidebarComponents/AccountSelector.vue';
import AddLabelModal from 'dashboard/routes/dashboard/settings/labels/AddLabel.vue';
import AddListModal from 'dashboard/routes/dashboard/contactLists/AddList';
import NotificationPanel from 'dashboard/routes/dashboard/notifications/components/NotificationPanel.vue';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import wootConstants from 'dashboard/constants/globals';
import EntrypointEditModal from './automations/components/EntrypointEditModal.vue';
import AddFolderModal from './folders/AddFolder.vue';
import ListSettings from './contactLists/ListSettings.vue';
import ChatlynSpinner from '../../../shared/components/ChatlynSpinner.vue';
import { mapGetters } from 'vuex';
import DeleteModal from '../../components/widgets/modal/DeleteModal.vue';
import ConfirmModal from '../../components/ConfirmModal.vue';
import { ARCHIVE_TYPE } from '../../../shared/constants/archiveType';
import UnarchiveModal from './contactLists/unarchive/UnarchiveModal.vue';

export default {
  components: {
    Sidebar,
    CommandBar,
    WootKeyShortcutModal,
    AddAccountModal,
    AccountSelector,
    AddLabelModal,
    AddListModal,
    NotificationPanel,
    EntrypointEditModal,
    AddFolderModal,
    ListSettings,
    ChatlynSpinner,
    DeleteModal,
    ConfirmModal,
    UnarchiveModal,
  },
  mixins: [uiSettingsMixin],
  data() {
    return {
      showAccountModal: false,
      showCreateAccountModal: false,
      showAddLabelModal: false,
      showAddListModal: false,
      showAddFolderModal: false,
      showShortcutModal: false,
      showAddEntrypoint: false,
      showAddFolder: false,
      showEditListModal: false,
      showDeleteListModal: false,
      showArchiveModal: false,
      showEditFolderModal: false,
      showDeleteFolderModal: false,
      unarchive: false,
      archiveType: '',
      idToArchive: '',
      folderId: '',
      isNotificationPanel: false,
      displayLayoutType: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      folder: 'folders/getFolder',
      contactList: 'contactLists/getList',
      currentAccountId: 'getCurrentAccountId',
      listEntrypoints: 'contactLists/getListEntrypoints',
      folderEntrypoints: 'folders/getFolderEntrypoints',
    }),

    currentRoute() {
      return ' ';
    },
    isSidebarOpen() {
      const { show_secondary_sidebar: showSecondarySidebar } = this.uiSettings;
      return showSecondarySidebar;
    },
    previouslyUsedDisplayType() {
      const {
        previously_used_conversation_display_type: conversationDisplayType,
      } = this.uiSettings;
      return conversationDisplayType;
    },
    previouslyUsedSidebarView() {
      const { previously_used_sidebar_view: showSecondarySidebar } =
        this.uiSettings;
      return showSecondarySidebar;
    },
  },
  watch: {
    displayLayoutType() {
      const { LAYOUT_TYPES } = wootConstants;
      this.updateUISettings({
        conversation_display_type:
          this.displayLayoutType === LAYOUT_TYPES.EXPANDED
            ? LAYOUT_TYPES.EXPANDED
            : this.previouslyUsedDisplayType,
        show_secondary_sidebar:
          this.displayLayoutType === LAYOUT_TYPES.EXPANDED
            ? false
            : this.previouslyUsedSidebarView,
      });
    },

    'contactList._id': {
      async handler(newValue) {
        if (!newValue) {
          return;
        }

        await this.getListEntrypoints();
      },
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    bus.$on(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    bus.$off(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
  },

  methods: {
    handleResize() {
      const { SMALL_SCREEN_BREAKPOINT, LAYOUT_TYPES } = wootConstants;
      let throttled = false;
      const delay = 150;

      if (throttled) {
        return;
      }
      throttled = true;

      setTimeout(() => {
        throttled = false;
        if (window.innerWidth <= SMALL_SCREEN_BREAKPOINT) {
          this.displayLayoutType = LAYOUT_TYPES.EXPANDED;
        } else {
          this.displayLayoutType = LAYOUT_TYPES.CONDENSED;
        }
      }, delay);
    },

    toggleSidebar() {
      this.updateUISettings({
        show_secondary_sidebar: !this.isSidebarOpen,
        previously_used_sidebar_view: !this.isSidebarOpen,
      });
    },

    openCreateAccountModal() {
      this.showAccountModal = false;
      this.showCreateAccountModal = true;
    },

    closeCreateAccountModal() {
      this.showCreateAccountModal = false;
    },

    toggleAccountModal() {
      this.showAccountModal = !this.showAccountModal;
    },

    toggleKeyShortcutModal() {
      this.showShortcutModal = true;
    },

    closeKeyShortcutModal() {
      this.showShortcutModal = false;
    },

    showAddLabelPopup() {
      this.showAddLabelModal = true;
    },

    showAddListPopup(folderId = '') {
      this.showAddListModal = true;
      this.folderId = folderId;
    },

    showAddFolderPopup() {
      this.showAddFolderModal = true;
    },

    async showEditListPopup(listId) {
      this.isLoading = true;
      await this.fetchContactList(listId);

      this.navigateToListPage(listId);

      this.isLoading = false;

      this.showEditListModal = true;
    },

    async showDeleteListPopup(listId) {
      this.isLoading = true;
      await this.fetchContactList(listId);

      this.navigateToListPage(listId);

      this.isLoading = false;

      this.showDeleteListModal = true;
    },

    showArchivePopup(archiveType, id, unarchive = false) {
      this.unarchive = unarchive;
      this.showArchiveModal = true;
      this.archiveType = archiveType;
      this.idToArchive = id;
    },

    showEditFolderPopup(folderId) {
      this.showEditFolderModal = true;
      this.folderId = folderId;
    },

    async showDeleteFolderPopup(folderId) {
      this.isLoading = true;

      await this.fetchFolder(folderId);

      await this.getFolderEntrypoints(folderId);

      this.isLoading = false;

      this.showDeleteFolderModal = true;
    },

    hideAddListPopup() {
      this.showAddListModal = false;
      this.folderId = '';
    },

    async hideAddFolderPopup(isCreated = false) {
      this.showAddFolderModal = false;

      if (isCreated) {
        await this.fetchFolders();
      }
    },

    hideAddLabelPopup() {
      this.showAddLabelModal = false;
    },

    async hideEditListPopup(isDeleted = false) {
      this.showEditListModal = false;

      if (isDeleted) {
        await this.fetchContactLists();
      }
    },

    async hideDeleteListPopup(isDeleted = false) {
      this.showDeleteListModal = false;

      if (isDeleted) {
        await this.$store.dispatch('contactLists/deleteList', {
          // eslint-disable-next-line no-underscore-dangle
          listId: this.contactList?._id,
        });

        if (this.$route.name !== 'contacts_dashboard') {
          this.$router.push({ name: 'contacts_dashboard' });
        }

        await this.fetchContactLists();
      }
    },

    async hideArchivePopup(isArchived = false, folderId = '') {
      if (isArchived) {
        switch (this.archiveType) {
          case ARCHIVE_TYPE.FOLDER:
            await this.$store.dispatch('folders/updateFolder', {
              clientId: this.currentAccountId,
              folder: {
                _id: this.idToArchive,
                isArchived: !this.unarchive,
              },
            });

            break;

          case ARCHIVE_TYPE.CONTACT_LIST:
            await this.$store.dispatch('contactLists/updateList', {
              listId: this.idToArchive,
              newRecord: {
                _id: this.idToArchive,
                isArchived: !this.unarchive,
                folderId,
              },
            });

            break;

          default:
            break;
        }
      }

      await this.fetchFolders();
      await this.fetchContactLists();

      this.unarchive = false;
      this.showArchiveModal = false;
      this.archiveType = '';
    },

    async hideEditFolderPopup(isUpdated = false) {
      this.showEditFolderModal = false;
      this.folderId = '';

      if (isUpdated) {
        await this.fetchContactLists();
        await this.fetchFolders();
      }
    },

    async hideDeleteFolderPopup(isDeleted = false) {
      this.showDeleteFolderModal = false;

      if (isDeleted) {
        await this.$store.dispatch('folders/deleteFolder', {
          clientId: this.currentAccountId,
          // eslint-disable-next-line no-underscore-dangle
          folderId: this.folder?._id,
        });

        if (this.$route.name !== 'contacts_dashboard') {
          this.$router.push({ name: 'contacts_dashboard' });
        }
        await this.fetchContactLists();

        await this.fetchFolders();
      }
    },

    openNotificationPanel() {
      this.isNotificationPanel = true;
    },

    closeNotificationPanel() {
      this.isNotificationPanel = false;
    },

    showAddEntrypointPopup() {
      this.showAddEntrypoint = true;
    },

    hideAddEntrypointPopup() {
      this.showAddEntrypoint = false;
    },

    navigateToListPage(listId) {
      const newRoute = this.$route;

      if (newRoute.params.listId === listId) {
        return;
      }

      if (newRoute.params.listId) {
        newRoute.params.listId = listId;
        this.$router.replace(newRoute);
      }
    },

    async fetchContactList(listId) {
      await this.$store.dispatch('contactLists/getList', {
        listId,
      });
    },

    async fetchContactLists() {
      await this.$store.dispatch('contactLists/getLists', {
        accountId: this.$route.params.accountId,
      });
    },

    async fetchFolder(folderId) {
      await this.$store.dispatch('folders/getFolder', {
        clientId: this.currentAccountId,
        folderId,
      });
    },

    async fetchFolders() {
      await this.$store.dispatch('folders/getAllFolders', {
        clientId: this.currentAccountId,
      });
    },

    async getListEntrypoints() {
      const result = await this.$store.dispatch(
        'contactLists/getListEntrypoints',
        {
          accountId: this.currentAccountId,
          // eslint-disable-next-line no-underscore-dangle
          listId: this.contactList._id,
        }
      );

      if (!result) {
        this.showAlert('CHATLYN_CONTACT_LISTS.ENTRYPOINTS_ERROR_MESSAGE');
      }
    },

    async getFolderEntrypoints(folderId) {
      const result = await this.$store.dispatch(
        'folders/getFolderEntrypoints',
        {
          accountId: this.currentAccountId,
          folderId,
        }
      );

      if (!result) {
        this.showAlert('FOLDERS.ENTRYPOINTS_ERROR_MESSAGE');
      }
    },
  },
};
</script>
