<template>
  <div>
    <slide-animation :is-shown="!isHidden">
      <div
        v-dompurify-html="
          hasError
            ? $t('CONVERSATION.AI_SUGGESTION.FAILED_MESSAGE')
            : aiSuggestion
        "
        class="break-words rounded-lg border border-solid border-violet-100 bg-violet-50 p-2 text-xs text-violet-600 shadow-sm shadow-violet-500 dark:border-violet-700 dark:bg-violet-800 dark:text-violet-100 dark:shadow-violet-400"
      />
    </slide-animation>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SlideAnimation from '../../../animations/SlideAnimation.vue';
export default {
  name: 'AiSuggestion',

  components: { SlideAnimation },

  props: {
    isHidden: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      aiSuggestions: 'chatbot/getAISuggestions',
      chatbotUIFlags: 'chatbot/getUIFlags',
    }),

    aiSuggestion() {
      return this.aiSuggestions[0] || '';
    },

    hasError() {
      return this.chatbotUIFlags.isAISuggestionFailed;
    },
  },
};
</script>

<style></style>
