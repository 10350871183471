<template>
  <div
    role="dialog"
    class="emoji-dialog absolute -top-[95px] right-0 z-20 box-content h-[300px] w-80 rounded-md border border-solid border-gray-100 bg-white shadow-lg dark:border-gray-800/50 dark:bg-gray-900"
  >
    <div class="flex flex-col">
      <div class="emoji-search--wrap flex gap-2">
        <input
          ref="searchbar"
          v-model="search"
          type="text"
          class="emoji-search--input focus:box-shadow-blue dark:focus:box-shadow-dark"
          :placeholder="$t('EMOJI.PLACEHOLDER')"
        />
        <woot-button
          v-if="showRemoveButton"
          size="small"
          variant="smooth"
          class="dark:!hover:bg-gray-700 dark:!bg-gray-800"
          color-scheme="secondary"
          type="button"
          @click="onClick('')"
        >
          {{ $t('EMOJI.REMOVE') }}
        </woot-button>
      </div>
      <div v-if="hasNoSearch" ref="emojiItem" class="emoji-item">
        <h5 class="emoji-category--title">
          {{ selectedKey }}
        </h5>
        <div class="emoji--row">
          <button
            v-for="item in filterEmojisByCategory"
            :key="item.slug"
            v-dompurify-html="item.emoji"
            class="emoji--item"
            track-by="$index"
            type="button"
            @click="onClick(item.emoji)"
          />
        </div>
      </div>
      <div v-else ref="emojiItem" class="emoji-item">
        <div v-for="category in filterAllEmojisBySearch" :key="category.slug">
          <h5 v-if="category.emojis.length > 0" class="emoji-category--title">
            {{ category.name }}
          </h5>
          <div v-if="category.emojis.length > 0" class="emoji--row">
            <button
              v-for="item in category.emojis"
              :key="item.slug"
              v-dompurify-html="item.emoji"
              class="emoji--item"
              track-by="$index"
              type="button"
              @click="onClick(item.emoji)"
            />
          </div>
        </div>
        <div v-if="hasEmptySearchResult" class="empty-message">
          <div class="emoji-icon">
            <fluent-icon icon="emoji" size="48" />
          </div>
          <span class="empty-message--text">
            {{ $t('EMOJI.NOT_FOUND') }}
          </span>
        </div>
      </div>

      <div class="emoji-dialog--footer" role="menu">
        <ul>
          <li>
            <button
              class="emoji--item"
              :class="{ active: selectedKey === 'Search' }"
              type="button"
              @click="changeCategory('Search')"
            >
              <fluent-icon
                icon="search"
                size="16"
                class="text-gray-700 dark:text-gray-100"
              />
            </button>
          </li>
          <li
            v-for="category in categories"
            :key="category.slug"
            @click="changeCategory(category.name)"
          >
            <button
              v-dompurify-html="getFirstEmojiByCategoryName(category.name)"
              class="emoji--item"
              :class="{ active: selectedKey === category.name }"
              type="button"
              @click="changeCategory(category.name)"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import emojis from './emojisGroup.json';
import FluentIcon from 'shared/components/FluentIcon/Index.vue';
const SEARCH_KEY = 'Search';

export default {
  components: { FluentIcon },
  props: {
    onClick: {
      type: Function,
      default: () => {},
    },
    showRemoveButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedKey: 'Search',
      emojis,
      search: '',
    };
  },
  computed: {
    categories() {
      return [...this.emojis];
    },
    filterEmojisByCategory() {
      const selectedCategoryName = this.emojis.find((category) =>
        category.name === this.selectedKey ? category.name : null
      );
      return selectedCategoryName?.emojis;
    },
    filterAllEmojisBySearch() {
      return this.emojis.map((category) => {
        const allEmojis = category.emojis.filter((emoji) =>
          emoji.slug.replaceAll('_', ' ').includes(this.search.toLowerCase())
        );
        return allEmojis.length > 0
          ? { ...category, emojis: allEmojis }
          : { ...category, emojis: [] };
      });
    },
    hasNoSearch() {
      return this.selectedKey !== SEARCH_KEY && this.search === '';
    },
    hasEmptySearchResult() {
      return this.filterAllEmojisBySearch.every(
        (category) => category.emojis.length === 0
      );
    },
  },
  watch: {
    search() {
      this.selectedKey = 'Search';
    },
    selectedKey() {
      return this.selectedKey === 'Search' ? this.focusSearchInput() : null;
    },
  },
  mounted() {
    this.focusSearchInput();
  },
  methods: {
    changeCategory(category) {
      this.search = '';
      this.$refs.emojiItem.scrollTo({ top: 0 });
      this.selectedKey = category;
    },
    getFirstEmojiByCategoryName(categoryName) {
      const categoryItem = this.emojis.find((category) =>
        category.name === categoryName ? category : null
      );
      return categoryItem ? categoryItem.emojis[0].emoji : '';
    },
    focusSearchInput() {
      this.$refs.searchbar.focus();
    },
  },
};
</script>
<style scoped>
@tailwind components;
@layer components {
  .box-shadow-blue {
    box-shadow:
      0 0 0 1px #1f93ff,
      0 0 1px 2px #c7e3ff;
  }

  .box-shadow-dark {
    box-shadow:
      0 0 0 1px #212222,
      0 0 1px 2px #4c5155;
  }
}
</style>
<style lang="scss">
@import '~dashboard/assets/scss/mixins';

.emoji-dialog {
  &::before {
    $space-slab: 12px;

    @media (prefers-color-scheme: dark) {
      $color-bg-dark: #26292b;
      @include arrow(bottom, $color-bg-dark, $space-slab);
    }
    @media (prefers-color-scheme: light) {
      $color-bg: #ebf0f5;
      @include arrow(bottom, $color-bg, $space-slab);
    }

    @apply absolute -bottom-3 right-5;
  }
}

.emoji--item {
  @apply m-0 h-6 cursor-pointer rounded border-0 bg-transparent px-1 py-0 text-lg hover:bg-gray-100 dark:hover:bg-gray-800;
}

.emoji--row {
  @apply box-border p-1;

  .emoji--item {
    @apply m-1 h-[26px] w-[26px] leading-normal;
  }
}

.emoji-search--wrap {
  @apply sticky top-2 m-2;

  .emoji-search--input {
    @apply m-0 h-8 w-full rounded-md border border-solid border-transparent bg-gray-50 p-2 text-sm text-gray-800 focus-visible:border-transparent dark:border-gray-800/50 dark:bg-gray-800 dark:text-gray-100;
  }
}

.empty-message {
  @apply flex h-[212px] flex-col items-center justify-center;

  .emoji-icon {
    @apply mb-2 text-gray-200 dark:text-gray-200;
  }
  .empty-message--text {
    @apply text-sm font-medium text-gray-200 dark:text-gray-200;
  }
}

.emoji-item {
  @apply h-[212px] overflow-y-auto;
}

.emoji-category--title {
  @apply m-0 px-2 py-1 text-sm font-medium capitalize leading-normal text-gray-800 dark:text-gray-100;
}

.emoji-dialog--footer {
  @apply relative -left-px bottom-0 w-[322px] rounded-b-md border-b border-solid border-gray-100 bg-gray-100 px-1 py-0 rtl:-right-px rtl:left-[unset] dark:border-gray-800/50 dark:bg-gray-800;

  ul {
    @apply relative left-[2px] m-0 flex list-none overflow-auto px-0 py-1 rtl:left-[unset] rtl:right-[2px];

    > li {
      @apply flex cursor-pointer items-center justify-center p-1;
    }

    li .active {
      @apply bg-white dark:bg-gray-900;
    }
    .emoji--item {
      @apply flex items-center text-sm;

      &:hover {
        @apply bg-gray-100 dark:bg-gray-900;
      }
    }
  }
}
</style>
