<template>
  <div class="flex h-full w-full min-w-0 flex-col bg-gray-50 dark:bg-gray-800">
    <conversation-header
      v-if="currentChat.id"
      :chat="currentChat"
      :is-contact-panel-open="isContactPanelOpen"
      :show-back-button="isOnExpandedLayout"
      @contact-panel-toggle="onToggleContactPanel"
    />
    <div
      class="relative flex h-full min-h-0 flex-grow bg-gray-50 dark:bg-gray-950"
    >
      <div
        v-if="currentChat.id && !showContactPanel"
        class="sidebar-toggle__wrap"
        :class="dashboardApps.length && currentChat.id ? 'with-tabs' : ''"
      >
        <button @click="onToggleContactPanel">
          <fluent-icon
            class="m-1 select-none"
            size="16"
            :icon="isRightOrLeftIcon"
          />
        </button>
      </div>

      <messages-view
        v-if="currentChat.id"
        :inbox-id="inboxId"
        :is-contact-panel-open="isContactPanelOpen"
        @contact-panel-toggle="onToggleContactPanel"
      />
      <empty-state v-else :is-on-expanded-layout="isOnExpandedLayout" />
      <div
        v-show="showContactPanel"
        class="sidebar relative right-0 z-50 flex h-full min-w-[var(--contact-panel-width)] max-w-[var(--contact-panel-width)] flex-grow flex-col border-s bg-white align-top shadow-xl dark:border-gray-800 dark:bg-gray-900 lg:max-h-[100%] xl:shadow-none 2xl:relative"
      >
        <div
          class="sidebar-toggle__wrap inner"
          :class="dashboardApps.length && currentChat.id ? 'with-tabs' : ''"
        >
          <button @click="onToggleContactPanel">
            <fluent-icon
              class="m-1 select-none"
              size="16"
              :icon="isRightOrLeftIcon"
            />
          </button>
        </div>
        <woot-tabs
          v-if="dashboardApps.length && currentChat.id"
          :index="activeIndex"
          class="dashboard-app--tabs flex-shrink-0 bg-white dark:bg-gray-900"
          @change="onDashboardAppTabChange"
        >
          <woot-tabs-item
            v-for="tab in dashboardAppTabs"
            :key="tab.key"
            :name="tab.name"
            :show-badge="false"
          />
        </woot-tabs>
        <contact-panel
          v-if="showContactPanel && !activeIndex"
          :conversation-id="currentChat.id"
          :inbox-id="currentChat.inbox_id"
          :on-toggle="onToggleContactPanel"
        />
        <dashboard-app-frame
          v-for="(dashboardApp, index) in dashboardApps"
          v-show="activeIndex - 1 === index"
          :key="currentChat.id + '-' + dashboardApp.id"
          :is-visible="activeIndex - 1 === index"
          :config="dashboardApps[index].content"
          :current-chat="currentChat"
          :position="index"
          class="relative"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import types from '../../../store/mutation-types';

import ContactPanel from 'dashboard/routes/dashboard/conversation/ContactPanel.vue';
import ConversationHeader from './ConversationHeader.vue';
import DashboardAppFrame from '../DashboardApp/Frame.vue';
import EmptyState from './EmptyState/EmptyState.vue';
import MessagesView from './MessagesView.vue';

export default {
  components: {
    ContactPanel,
    ConversationHeader,
    DashboardAppFrame,
    EmptyState,
    MessagesView,
  },

  props: {
    inboxId: {
      type: [Number, String],
      default: '',
      required: false,
    },
    isContactPanelOpen: {
      type: Boolean,
      default: true,
    },
    isOnExpandedLayout: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return { activeIndex: 0 };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      dashboardApps: 'dashboardApps/getRecords',
    }),
    dashboardAppTabs() {
      return [
        {
          key: 'messages',
          name: this.$t('CONVERSATION.DASHBOARD_APP_TAB_MESSAGES'),
        },
        ...this.dashboardApps.map((dashboardApp) => ({
          key: `dashboard-${dashboardApp.id}`,
          name: dashboardApp.title,
        })),
      ];
    },
    showContactPanel() {
      return this.isContactPanelOpen && this.currentChat.id;
    },

    dashboardAppConfig() {
      if (!this.dashboardApps) {
        return undefined;
      }

      return this.dashboardApps[this.activeIndex - 1]?.content;
    },
    isRightOrLeftIcon() {
      if (this.isContactPanelOpen) {
        return 'chevron-right';
      }
      return 'chevron-left';
    },
  },
  watch: {
    'currentChat.inbox_id'(inboxId) {
      if (inboxId) {
        this.$store.dispatch('inboxAssignableAgents/fetch', [inboxId]);
      }
    },
    'currentChat.id'() {
      this.fetchLabels();
      this.activeIndex = 0;
      this.$store.commit(`chatbot/${types.CLEAR_CHATBOT_AI_SUGGESTION}`);
    },
  },
  mounted() {
    this.fetchLabels();
    this.$store.dispatch('dashboardApps/get');
  },
  methods: {
    fetchLabels() {
      if (!this.currentChat.id) {
        return;
      }
      this.$store.dispatch('conversationLabels/get', this.currentChat.id);
    },
    onToggleContactPanel() {
      this.$emit('contact-panel-toggle');
    },
    onDashboardAppTabChange(index) {
      this.activeIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar {
  @media screen and (max-width: 1200px) {
    @apply absolute;
  }
}
.sidebar-toggle__wrap {
  @apply absolute left-auto right-0 top-4 z-30 rounded-s border border-e-0 bg-white p-1 shadow-xl transition-all duration-200 hover:shadow-lg dark:border-gray-700 dark:bg-gray-600;

  // position change when contact panel is open
  &.inner {
    @apply -left-9 right-auto -z-0 dark:border-gray-800 dark:bg-gray-900;
  }

  // increase margin top if dashboard apps are present
  &.with-tabs {
    @apply top-12;
  }

  button {
    @apply inline-flex h-12 w-7 items-center justify-center rounded-sm bg-transparent transition-colors duration-200 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700;
  }
}

.dashboard-app--tabs {
  ::v-deep .tabs-title a {
    @apply py-2;
  }
}
/*
.conversation-sidebar-wrap {
  &::v-deep .contact--panel {
    @apply w-full h-full max-w-full flex-grow;
  }
}
*/
</style>
