<template>
  <div
    class="conversation group relative flex min-h-[5rem] flex-shrink-0 cursor-pointer items-center rounded-lg px-3 py-0 transition-colors duration-200 odd:bg-gray-50 hover:bg-gray-100 dark:odd:bg-gray-800/50 dark:hover:bg-gray-700"
    :class="{
      active: isActiveChat,
      'unread-chat': hasUnread,
      'has-inbox-name': showInboxName,
      selected: selected,
    }"
    @mouseenter="onCardHover"
    @mouseleave="onCardLeave"
    @click="onCardClick"
    @contextmenu="openContextMenu($event)"
  >
    <label v-if="hovered || selected" class="checkbox-wrapper" @click.stop>
      <input
        :value="selected"
        :checked="selected"
        class="checkbox"
        type="checkbox"
        @change="onSelectConversation($event.target.checked)"
      />
    </label>
    <thumbnail
      v-if="bulkActionCheck"
      :src="currentContact.thumbnail"
      :badge="inboxBadge"
      class="columns"
      :username="currentContact.name"
      :status="currentContact.availability_status"
      size="40px"
    />
    <div class="flex-grow px-0 py-3">
      <aside
        class="text-muted relative flex flex-shrink flex-grow-0 pe-14 ps-2"
      >
        <div
          class="flex w-[13rem] flex-nowrap items-center gap-2 text-ellipsis"
        >
          <priority-mark :priority="chat.priority" />

          <inbox-name
            v-if="showInboxName"
            :inbox="inbox"
            class="whitespace-nowrap"
          />

          <div
            v-if="showAssignee && assignee.name"
            class="flex flex-shrink flex-grow-0 items-center text-xs"
          >
            <fluent-icon icon="person" size="12" class="me-1 flex-shrink-0" />
            <div class="overflow-hidden text-ellipsis whitespace-nowrap">
              {{ assignee.name }}
            </div>
          </div>
        </div>

        <span class="text-muted absolute right-0 top-0 text-xxs leading-4">
          <time-ago
            :last-activity-timestamp="chat.timestamp"
            :created-at-timestamp="chat.created_at"
          />
        </span>

        <span
          v-if="hasUnread"
          class="unread absolute right-0 top-5 ml-auto mt-1 h-4 min-w-[1rem] rounded-full bg-green-400 px-1 py-0 text-center text-xxs font-medium leading-4 text-white shadow-lg dark:bg-green-600"
        >
          {{ unreadCount > 9 ? '9+' : unreadCount }}
        </span>
      </aside>
      <div
        class="conversation--user mx-2 my-0 w-[60%] overflow-hidden text-ellipsis whitespace-nowrap pt-0.5 text-sm capitalize text-gray-900 dark:text-gray-100"
      >
        {{ currentContact.name }}
      </div>
      <message-preview
        v-if="lastMessageInChat"
        :message="lastMessageInChat"
        class="conversation--message mx-2 my-0 h-6 w-[16.5rem] max-w-[96%] text-sm leading-6 text-gray-700 dark:text-gray-200"
      />
      <p
        v-else
        class="conversation--message mx-2 my-0 h-6 w-[16.5rem] max-w-[96%] text-ellipsis text-sm leading-6 text-gray-700 dark:text-gray-200"
      >
        <fluent-icon
          size="16"
          class="-mt-0.5 inline-block align-middle text-gray-600 dark:text-gray-300"
          icon="info"
        />
        <span>
          {{ $t(`CHAT_LIST.NO_MESSAGES`) }}
        </span>
      </p>
      <card-labels :conversation-id="chat.id" />
    </div>
    <woot-context-menu
      v-if="showContextMenu"
      ref="menu"
      :x="contextMenu.x"
      :y="contextMenu.y"
      @close="closeContextMenu"
    >
      <conversation-context-menu
        :status="chat.status"
        :inbox-id="inbox.id"
        :priority="chat.priority"
        :has-unread-messages="hasUnread"
        @update-conversation="onUpdateConversation"
        @assign-agent="onAssignAgent"
        @assign-label="onAssignLabel"
        @assign-team="onAssignTeam"
        @mark-as-unread="markAsUnread"
        @assign-priority="assignPriority"
      />
    </woot-context-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Thumbnail from '../Thumbnail.vue';
import MessagePreview from './MessagePreview.vue';
import conversationMixin from '../../../mixins/conversations';
import timeMixin from '../../../mixins/time';
import router from '../../../routes';
import { frontendURL, conversationUrl } from '../../../helper/URLHelper';
import InboxName from '../InboxName.vue';
import inboxMixin from 'shared/mixins/inboxMixin';
import ConversationContextMenu from './contextMenu/Index.vue';
import alertMixin from 'shared/mixins/alertMixin';
import TimeAgo from 'dashboard/components/ui/TimeAgo.vue';
import CardLabels from './conversationCardComponents/CardLabels.vue';
import PriorityMark from './PriorityMark.vue';

export default {
  components: {
    CardLabels,
    InboxName,
    Thumbnail,
    ConversationContextMenu,
    TimeAgo,
    MessagePreview,
    PriorityMark,
  },

  mixins: [inboxMixin, timeMixin, conversationMixin, alertMixin],
  props: {
    activeLabel: {
      type: String,
      default: '',
    },
    chat: {
      type: Object,
      default: () => {},
    },
    hideInboxName: {
      type: Boolean,
      default: false,
    },
    hideThumbnail: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: [String, Number],
      default: 0,
    },
    foldersId: {
      type: [String, Number],
      default: 0,
    },
    showAssignee: {
      type: Boolean,
      default: false,
    },
    conversationType: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovered: false,
      showContextMenu: false,
      contextMenu: {
        x: null,
        y: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      inboxesList: 'inboxes/getInboxes',
      activeInbox: 'getSelectedInbox',
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
    }),
    bulkActionCheck() {
      return !this.hideThumbnail && !this.hovered && !this.selected;
    },
    chatMetadata() {
      return this.chat.meta || {};
    },

    assignee() {
      return this.chatMetadata.assignee || {};
    },

    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.chatMetadata.sender.id
      );
    },

    isActiveChat() {
      return this.currentChat.id === this.chat.id;
    },

    unreadCount() {
      return this.chat.unread_count;
    },

    hasUnread() {
      return this.unreadCount > 0;
    },

    isInboxNameVisible() {
      return !this.activeInbox;
    },

    lastMessageInChat() {
      return this.lastMessage(this.chat);
    },

    inbox() {
      const { inbox_id: inboxId } = this.chat;
      const stateInbox = this.$store.getters['inboxes/getInbox'](inboxId);
      return stateInbox;
    },

    showInboxName() {
      return (
        !this.hideInboxName &&
        this.isInboxNameVisible &&
        this.inboxesList.length > 1
      );
    },
    inboxName() {
      const stateInbox = this.inbox;
      return stateInbox.name || '';
    },
  },
  methods: {
    onCardClick(e) {
      const { activeInbox, chat } = this;
      const path = frontendURL(
        conversationUrl({
          accountId: this.accountId,
          activeInbox,
          id: chat.id,
          label: this.activeLabel,
          teamId: this.teamId,
          foldersId: this.foldersId,
          conversationType: this.conversationType,
        })
      );

      if (e.metaKey || e.ctrlKey) {
        window.open(
          window.chatwootConfig.hostURL + path,
          '_blank',
          'noopener noreferrer nofollow'
        );
        return;
      }
      if (this.isActiveChat) {
        return;
      }

      router.push({ path });
    },
    onCardHover() {
      this.hovered = !this.hideThumbnail;
    },
    onCardLeave() {
      this.hovered = false;
    },
    onSelectConversation(checked) {
      const action = checked ? 'select-conversation' : 'de-select-conversation';
      this.$emit(action, this.chat.id, this.inbox.id);
    },
    openContextMenu(e) {
      e.preventDefault();
      this.$emit('context-menu-toggle', true);
      this.contextMenu.x = e.pageX || e.clientX;
      this.contextMenu.y = e.pageY || e.clientY;
      this.showContextMenu = true;
    },
    closeContextMenu() {
      this.$emit('context-menu-toggle', false);
      this.showContextMenu = false;
      this.contextMenu.x = null;
      this.contextMenu.y = null;
    },
    onUpdateConversation(status, snoozedUntil) {
      this.closeContextMenu();
      this.$emit(
        'update-conversation-status',
        this.chat.id,
        status,
        snoozedUntil
      );
    },
    async onAssignAgent(agent) {
      this.$emit('assign-agent', agent, [this.chat.id]);
      this.closeContextMenu();
    },
    async onAssignLabel(label) {
      this.$emit('assign-label', [label.title], [this.chat.id]);
      this.closeContextMenu();
    },
    async onAssignTeam(team) {
      this.$emit('assign-team', team, this.chat.id);
      this.closeContextMenu();
    },
    async markAsUnread() {
      this.$emit('mark-as-unread', this.chat.id);
      this.closeContextMenu();
    },
    async assignPriority(priority) {
      this.$emit('assign-priority', priority, this.chat.id);
      this.closeContextMenu();
    },
  },
};
</script>
<style lang="scss" scoped>
.conversation {
  &.selected {
    @apply bg-gray-100 outline -outline-offset-1 outline-gray-200 dark:bg-gray-800 dark:outline-gray-700 dark:hover:bg-gray-800/50;

    &.active {
      @apply outline-woot-100 dark:outline-woot-500;
    }
  }

  &.active {
    @apply bg-woot-50/70 hover:bg-woot-50/90 dark:bg-woot-800 hover:dark:bg-woot-800;

    .checkbox-wrapper {
      @apply hover:bg-woot-100 dark:hover:bg-woot-700;
    }
  }

  &.unread-chat {
    .conversation--message,
    .conversation--user {
      @apply font-semibold;
    }
  }

  &.compact {
    @apply rounded pl-0 pr-3;

    .conversation--message {
      @apply w-[12rem];
    }
  }

  .checkbox-wrapper {
    @apply flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full transition-colors duration-200 hover:bg-gray-200 dark:hover:bg-gray-600/50;

    input[type='checkbox'] {
      @apply m-0 cursor-pointer;
    }
  }
}
</style>
