<template>
  <settings-section
    :title="$t('CHATLYN_INBOXES.SHARED_INBOX.CONFIG.TITLE')"
    :sub-title="$t('CHATLYN_INBOXES.SHARED_INBOX.CONFIG.DESC')"
  >
    <form @submit.prevent="updateSharedInbox">
      <label for="enable-shared-inbox" class="mt-2 flex items-center gap-2">
        <input
          v-model="sharedInboxEnabled"
          type="checkbox"
          name="enable-shared-inbox"
        />
        {{ $t('CHATLYN_INBOXES.SHARED_INBOX.CONFIG.TOGGLE') }}
      </label>

      <woot-input
        v-if="sharedInboxEnabled"
        v-model="sharedInboxMail"
        :class="{ error: $v.sharedInboxMail.$error }"
        class="medium-9 columns"
        :label="$t('CHATLYN_INBOXES.SHARED_INBOX.CONFIG.INPUT.LABEL')"
        :placeholder="
          $t('CHATLYN_INBOXES.SHARED_INBOX.CONFIG.INPUT.PLACEHOLDER')
        "
        @blur="$v.sharedInboxMail.$touch"
      />

      <woot-submit-button
        class="mt-3"
        :button-text="'Update settings'"
        :loading="uiFlags.isUpdatingSharedInbox"
        :disabled="$v.$invalid || uiFlags.isUpdatingSharedInbox"
      />
    </form>
  </settings-section>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import SettingsSection from '../../../../../components/SettingsSection.vue';

export default {
  name: 'MicrosoftSharedInbox',

  components: { SettingsSection },

  mixins: [alertMixin],

  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      sharedInboxEnabled: false,
      sharedInboxMail: '',
    };
  },

  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags' }),
  },

  validations() {
    const validations = {};

    if (this.sharedInboxEnabled) {
      validations.sharedInboxMail = { required, email };
    }

    return validations;
  },

  watch: {
    inbox: {
      handler(newValue) {
        if (!newValue || !Object.keys(newValue)?.length) {
          return;
        }

        this.setDefaults();
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    setDefaults() {
      const { smtp_login } = this.inbox;
      this.sharedInboxEnabled = Boolean((smtp_login || '')?.trim()?.length > 0);
      this.sharedInboxMail = smtp_login;
    },

    async updateSharedInbox() {
      try {
        const payload = {
          id: this.inbox.id,
          channel: {
            smtp_login: this.sharedInboxEnabled ? this.sharedInboxMail : '',
          },
        };
        await this.$store.dispatch('inboxes/updateSharedInbox', payload);
        this.showAlert(this.$t('CHATLYN_INBOXES.SHARED_INBOX.SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('CHATLYN.MESSAGES.TRY_AGAIN'));
      }
    },
  },
};
</script>

<style></style>
