var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full min-h-0 flex-grow overflow-auto",class:_vm.isMessageTemplateRoute
      ? 'flex flex-col bg-white p-0 dark:bg-gray-900'
      : 'p-4'},[_c('woot-button',{attrs:{"color-scheme":"success","class-names":"button--fixed-top","icon":"arrow-download"},on:{"click":_vm.downloadReports}},[_vm._v("\n    "+_vm._s(_vm.downloadButtonLabel)+"\n  ")]),_vm._v(" "),(_vm.filterItemsList && _vm.type !== 'sent-templates')?_c('report-filters',{attrs:{"type":_vm.type,"filter-items-list":_vm.filterItemsList,"group-by-filter-items-list":_vm.groupByfilterItemsList,"selected-group-by-filter":_vm.selectedGroupByFilter},on:{"date-range-change":_vm.onDateRangeChange,"filter-change":_vm.onFilterChange,"group-by-filter-change":_vm.onGroupByFilterChange,"business-hours-toggle":_vm.onBusinessHoursToggle}}):_vm._e(),_vm._v(" "),(_vm.type !== 'sent-templates')?_c('div',[(_vm.filterItemsList.length)?_c('div',{staticClass:"mt-4 flex flex-wrap gap-1 xl:flex-nowrap"},_vm._l((_vm.metrics),function(metric,index){return _c('woot-report-stats-card',{key:metric.NAME,attrs:{"desc":metric.DESC,"heading":metric.NAME,"info-text":_vm.displayInfoText(metric.KEY),"index":index,"point":_vm.displayMetric(metric.KEY),"trend":_vm.calculateTrend(metric.KEY),"selected":index === _vm.currentSelection},on:{"click":_vm.changeSelection}})}),1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"report-bar rounded bg-white py-4 dark:bg-gray-800"},[(_vm.isAccountReportFetched)?_c('woot-loading-state',{attrs:{"message":_vm.$t('REPORT.LOADING_CHART')}}):_c('div',{staticClass:"chart-container"},[(
            _vm.currentSelectionKey &&
            _vm.accountReport.data[_vm.currentSelectionKey].length &&
            _vm.filterItemsList.length &&
            _vm.isCurrentMetric
          )?_c('woot-bar',{attrs:{"collection":_vm.collection,"chart-options":_vm.chartOptions}}):_c('div',{staticClass:"empty-state px-4 text-sm"},[_vm._v("\n          "+_vm._s(_vm.$t('REPORT.NO_ENOUGH_DATA'))+"\n        ")])],1)],1)]):(_vm.templates)?_c('div',{staticClass:"sent-templates flex h-full w-full flex-1 flex-col"},[_c('filter-bar',{class:{ 'px-4 pt-3': _vm.isMessageTemplateRoute },attrs:{"filters":_vm.sentTemplatesFilters,"templates":_vm.messageTemplateNames,"whats-app-inboxes":_vm.whatsAppInboxes},on:{"filter-by-failed":_vm.filterByFailed,"filter-by-template":_vm.filterByTemplate,"filter-by-number":_vm.filterByNumber,"filter-by-recipient":_vm.filterByRecipient,"filter-by-date":_vm.filterByDate,"clear-filters":_vm.clearFilters}}),_vm._v(" "),_c('sent-templates-table',{attrs:{"templates":_vm.templates.data,"status-counts":_vm.statusCounts,"all-templates":_vm.allTemplates.data,"whats-app-inboxes":_vm.whatsAppInboxes,"page-index":_vm.templates.meta.page,"total":_vm.templates.meta.itemCount},on:{"sort-change":_vm.changeSort,"page-change":_vm.pageChange}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }