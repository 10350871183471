<template>
  <div class="flex h-full w-full flex-col">
    <contacts-header
      :search-query="searchQuery"
      :header-title="pageTitle"
      :segments-id="segmentsId"
      :static-list="isStaticList"
      this-selected-contact-id=""
      @on-input-search="onInputSearch"
      @on-toggle-filter="toggleShowContactConditionModal"
      @on-search-submit="onSearchSubmit"
      @on-toggle-import="onShowImporter"
      @on-export-submit="onExportSubmit"
      @on-toggle-delete-filter="onToggleDeleteFilters"
      @on-toggle-edit-filter="toggleShowContactConditionModal"
      @toggle-show-create-contact-modal="toggleShowCreateContactModal"
      @toggle-show-add-to-contact-list-modal="toggleShowAddToContactListModal"
      @on-toggle-send-message="onToggleSendMessage"
      @on-toggle-edit-list="showEditList"
      @on-toggle-export-contacts="onToggleShowExportConfirmationModal"
      @on-toggle-export-contacts-list="onToggleExportContactsListModal"
      @on-toggle-show-create-list-by-condition-modal="
        toggleShowCreateListByConditionModal
      "
      @on-toggle-show-add-to-list-by-condition-modal="
        toggleShowAddToListByConditionModal
      "
      @on-clear-condition="onClearCondition"
    />

    <div class="relative flex flex-grow overflow-hidden">
      <contacts-table-new
        :key="tableKey"
        :records="isContactListsPage ? listsRecords : records"
        :show-search-empty-state="showEmptySearchResult"
        :is-loading="isLoadingState"
        :sort-config="sortConfig"
        :deselected="deselected"
        @on-sort-change="onSortChange"
        @select-row="changeSelectedQuantity"
        @open-contact-info-panel="openContactInfoPanel"
        @select-all="selectAll"
        @deselect-all="deselectAll"
      />

      <contact-info-panel
        v-if="showContactViewPane"
        :contact="selectedContact"
        :on-close="closeContactInfoPanel"
      />
    </div>

    <table-footer
      class="mt-auto"
      :current-page="
        isContactListsPage
          ? contactListMeta.currentPage
          : Number(meta.currentPage)
      "
      :total-count="isContactListsPage ? contactListMeta.itemCount : meta.count"
      :page-size="15"
      @page-change="onPageChange"
    >
      <footer-actions
        v-if="showFooterActions"
        :selected-quantity="selectedQuantity"
        :show-delete-from-list="isContactListsPage && isStaticList"
        @show-delete-selected-modal="showDeleteSelectedModal"
        @show-delete-selected-contacts-modal="toggleDeleteSelectedContacts"
        @show-add-to-list="onToggleAddToList"
        @deselect-all="deselectAll"
      />
    </table-footer>

    <delete-custom-views
      v-if="showDeleteSegmentsModal"
      :show-delete-popup.sync="showDeleteSegmentsModal"
      :active-custom-view="activeSegment"
      :custom-views-id="segmentsId"
      :active-filter-type="filterType"
      :open-last-item-after-delete="openLastItemAfterDeleteInSegment"
      @close="onCloseDeleteSegmentsModal"
    />

    <woot-delete-modal
      :show.sync="deleteSelected"
      :on-close="closeDeleteSelectedPopup"
      :on-confirm="deleteSelectedItems"
      :title="$t('CHATLYN_CONTACT_LISTS.REMOVE_MODAL.TITLE')"
      :message="$t('CHATLYN_CONTACT_LISTS.REMOVE_MODAL.MESSAGE')"
      :confirm-text="$t('CHATLYN_GENERAL.BUTTON.REMOVE')"
      :reject-text="$t('CHATLYN_GENERAL.BUTTON.CANCEL')"
    />

    <woot-delete-modal
      :show.sync="deleteSelectedContacts"
      :on-close="toggleDeleteSelectedContacts"
      :on-confirm="handleDeleteSelectedContacts"
      :title="$t('CONTACTS_PAGE.DELETE_SELECTED_CONTACTS_MODAL.TITLE')"
      :message="
        $t('CONTACTS_PAGE.DELETE_SELECTED_CONTACTS_MODAL.MESSAGE', {
          NUMBER_OF_CONTACTS: selectedQuantity,
        })
      "
      :confirm-text="$t('CHATLYN_GENERAL.BUTTON.REMOVE')"
      :reject-text="$t('CHATLYN_GENERAL.BUTTON.CANCEL')"
    />

    <confirm-modal
      hide-reject
      success-icon="arrow-download"
      buttons-alignment="end"
      :show="showExportConfirmationModal"
      :on-close="onCloseShowExportConfirmationModal"
      :on-confirm="onExportSubmit"
      :title="$t('CONTACTS_PAGE.EXPORT_CONTACTS_MODAL.TITLE')"
      :message="$t('CONTACTS_PAGE.EXPORT_CONTACTS_MODAL.MESSAGE')"
      :confirm-text="$t('CONTACTS_PAGE.EXPORT_CONTACTS_LIST_MODAL.SUBMIT')"
    />

    <confirm-modal
      hide-reject
      success-icon="arrow-download"
      buttons-alignment="end"
      :show="showExportListConfirmationModal"
      :on-close="onCloseExportContactsListModal"
      :on-confirm="onExportListSubmit"
      :title="$t('CONTACTS_PAGE.EXPORT_CONTACTS_LIST_MODAL.TITLE')"
      :message="
        $t('CONTACTS_PAGE.EXPORT_CONTACTS_LIST_MODAL.MESSAGE', {
          LIST_NAME: contactList ? contactList.name : '',
        })
      "
      :confirm-text="$t('CONTACTS_PAGE.EXPORT_CONTACTS_LIST_MODAL.SUBMIT')"
    />

    <create-contact
      :show="showCreateModal"
      @cancel="toggleShowCreateContactModal"
    />

    <add-contacts-to-list
      :show="showCreateListModal"
      @show-create-modal="toggleShowCreateContactModal"
      @cancel="toggleShowAddToContactListModal"
    />

    <!-- <woot-modal :show.sync="showImportModal" :on-close="onCloseImport">
      <import-contacts
        :on-close="onCloseImport"
        @fetch-contacts="fetchContacts(DEFAULT_PAGE)"
      />
    </woot-modal> -->

    <woot-modal
      :show.sync="showSendMessageModal"
      :on-close="onCloseSendMessage"
      size="lg"
      overflow="scrollable"
    >
      <send-message-template-modal is-contacts @close="onCloseSendMessage" />
    </woot-modal>

    <woot-modal
      :show.sync="showEditListModal"
      :on-close="closeEditList"
      size="md"
    >
      <list-settings
        v-if="showEditListModal"
        @delete="onToggleEditList"
        @close="closeEditList"
      />
    </woot-modal>

    <woot-modal
      :show="showContactConditionModal"
      :on-close="toggleShowContactConditionModal"
      size="md"
    >
      <contacts-condition
        :is-contact-lists-page="isContactListsPage"
        @on-close="toggleShowContactConditionModal"
        @on-clear-condition="onClearCondition"
      />
    </woot-modal>

    <!-- TODO -->

    <!-- <woot-modal
      :show.sync="showFiltersModal"
      :on-close="closeAdvanceFiltersModal"
      size="md"
    >
      <contacts-advanced-filters
        v-if="showFiltersModal"
        :on-close="closeAdvanceFiltersModal"
        :initial-filter-types="contactFilterItems"
        :initial-applied-filters="appliedFilter"
        :active-segment-name="activeSegmentName"
        :is-segments-view="hasActiveSegments"
        @applyFilter="onApplyFilter"
        @updateSegment="onUpdateSegment"
        @clearFilters="clearFilters"
      />
    </woot-modal> -->

    <woot-modal
      :show.sync="showAddToListModal"
      overflow="visible"
      :on-close="onCloseAddToList"
    >
      <add-to-list-modal
        :contacts="selectedIds"
        @close="onCloseAddToList"
        @deleted="onToggleAddToList"
      />
    </woot-modal>

    <woot-modal
      :show="showCreateListByConditionModal"
      :on-close="toggleShowCreateListByConditionModal"
      size="md"
    >
      <create-list-by-condition-modal
        :is-contact-lists-page="isContactListsPage"
        @on-close="toggleShowCreateListByConditionModal"
      />
    </woot-modal>

    <woot-modal
      :show="showAddToListByConditionModal"
      :on-close="toggleShowAddToListByConditionModal"
      size="md"
      overflow="visible"
    >
      <add-to-list-by-condition-modal
        :is-contact-lists-page="isContactListsPage"
        @on-close="toggleShowAddToListByConditionModal"
      />
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';
import countries from 'shared/constants/countries.js';
import { generateValuesForEditCustomViews } from 'dashboard/helper/customViewsHelper';
import { CONTACTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import contactFilterItems from '../contactFilterItems';

import ContactsHeader from './Header.vue';
import ContactsTableNew from './ContactsTableNew.vue';
import ContactInfoPanel from './ContactInfoPanel.vue';
import CreateContact from 'dashboard/routes/dashboard/conversation/contact/CreateContact.vue';
import AddContactsToList from 'dashboard/routes/dashboard/contactLists/AddContactsToList.vue';
import TableFooter from 'dashboard/components/widgets/TableFooter.vue';
// import ImportContacts from './ImportContacts.vue';
import DeleteCustomViews from 'dashboard/routes/dashboard/customviews/DeleteCustomViews.vue';
import FooterActions from './FooterActions.vue';
import AddToListModal from 'dashboard/routes/dashboard/contactLists/AddToList.vue';
import ListSettings from 'dashboard/routes/dashboard/contactLists/ListSettings.vue';
import SendMessageTemplateModal from '../../messageTemplates/components/SendMessageTemplateModal.vue';
import ConfirmModal from '../../../../components/ConfirmModal.vue';
import ContactsCondition from './condition/ContactsCondition.vue';
import CreateListByConditionModal from './condition/CreateListByConditionModal.vue';
import AddToListByConditionModal from './condition/AddToListByConditionModal.vue';

const DEFAULT_PAGE = 1;
const FILTER_TYPE_CONTACT = 1;

export default {
  components: {
    FooterActions,
    ContactsHeader,
    ContactsTableNew,
    TableFooter,
    ContactInfoPanel,
    CreateContact,
    AddContactsToList,
    // ImportContacts,
    DeleteCustomViews,
    AddToListModal,
    ListSettings,
    SendMessageTemplateModal,
    ConfirmModal,
    ContactsCondition,
    CreateListByConditionModal,
    AddToListByConditionModal,
  },
  mixins: [alertMixin],
  props: {
    label: { type: String, default: '' },
    segmentsId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      DEFAULT_PAGE,
      tableKey: 0,
      deselected: false,
      isLoadingContactList: false,
      selectedQuantity: 0,
      selectedIds: [],
      selectedClean: false,
      deleteSelected: false,
      deleteSelectedContacts: false,
      searchQuery: '',
      showCreateModal: false,
      showAddToListModal: false,
      showCreateListModal: false,
      showEditListModal: false,
      // showImportModal: false,
      showSendMessageModal: false,
      showExportConfirmationModal: false,
      showExportListConfirmationModal: false,
      selectedContactId: '',
      sortConfig: { name: 'asc' },
      contactFilterItems: contactFilterItems.map((filter) => ({
        ...filter,
        attributeName: this.$t(
          `CONTACTS_FILTER.ATTRIBUTES.${filter.attributeI18nKey}`
        ),
      })),
      segmentsQuery: {},
      filterType: FILTER_TYPE_CONTACT,
      showDeleteSegmentsModal: false,

      showContactConditionModal: false,
      showCreateListByConditionModal: false,
      showAddToListByConditionModal: false,
      // TODO
      // showFiltersModal: false,
      appliedFilter: [],
    };
  },
  computed: {
    ...mapGetters({
      records: 'contacts/getContacts',
      listsRecords: 'contactLists/getContacts',
      uiFlags: 'contacts/getUIFlags',
      meta: 'contacts/getMeta',
      contactListMeta: 'contactLists/getMeta',
      segments: 'customViews/getCustomViews',
      appliedContactFilters: 'contacts/getAppliedContactFilters',
      appliedContactCondition: 'contacts/getAppliedContactCondition',
      accountId: 'getCurrentAccountId',
      contactList: 'contactLists/getList',
    }),

    appliedListCondition() {
      return this.$store.getters['contactLists/getAppliedListCondition'](
        this.$route.params.listId
      );
    },

    showEmptySearchResult() {
      return this.isContactListsPage
        ? !!this.searchQuery && this.listsRecords.length === 0
        : !!this.searchQuery && this.records.length === 0;
    },

    hasAppliedFilters() {
      return this.appliedContactFilters.length;
    },

    hasAppliedCondition() {
      return (
        !this.isContactListsPage &&
        !!this.appliedContactCondition?.conditions?.length
      );
    },

    hasAppliedListCondition() {
      return (
        this.isContactListsPage &&
        !!this.appliedListCondition?.conditions?.length
      );
    },

    hasActiveSegments() {
      return this.activeSegment && this.segmentsId !== 0;
    },

    isContactDashboard() {
      return (
        this.$route.name === 'contacts_dashboard' ||
        this.$route.name === 'contacts_labels_dashboard' ||
        this.$route.name === 'contacts_lists_dashboard'
      );
    },
    isContactListsPage() {
      return this.$route.name === 'contacts_lists_dashboard';
    },
    isLoadingState() {
      return this.isContactListsPage
        ? this.isLoadingContactList
        : this.uiFlags.isFetching;
    },
    isStaticList() {
      return this.contactList?.static;
    },
    pageTitle() {
      if (this.hasActiveSegments) {
        return this.activeSegment.name;
      }
      if (this.label) {
        return `#${this.label}`;
      }
      return this.$t('CONTACTS_PAGE.HEADER');
    },
    selectedContact() {
      if (!this.selectedContactId) {
        return undefined;
      }
      if (this.isContactListsPage) {
        return this.listsRecords.find(
          (item) => this.selectedContactId === item.id
        );
      }
      return this.records.find((item) => this.selectedContactId === item.id);
    },
    showContactViewPane() {
      return this.selectedContactId !== '';
    },
    wrapClass() {
      return this.showContactViewPane ? 'me-[var(--contact-panel-width)]' : '';
    },
    pageParameter() {
      const selectedPageNumber = Number(this.$route.query?.page);
      return !Number.isNaN(selectedPageNumber) &&
        selectedPageNumber >= DEFAULT_PAGE
        ? selectedPageNumber
        : DEFAULT_PAGE;
    },
    activeSegment() {
      if (this.segmentsId) {
        const [firstValue] = this.segments.filter(
          (view) => view.id === Number(this.segmentsId)
        );
        return firstValue;
      }
      return undefined;
    },
    activeSegmentName() {
      return this.activeSegment?.name;
    },
    showFooterActions() {
      return this.selectedQuantity > 0;
    },
  },
  watch: {
    label() {
      this.fetchContacts(DEFAULT_PAGE);
      // if (this.hasAppliedFilters) {
      //   this.clearFilters();
      // }
    },

    activeSegment() {
      if (this.hasActiveSegments) {
        const payload = this.activeSegment.query;
        this.fetchSavedFilteredContact(payload, DEFAULT_PAGE);
      }
      if (this.hasAppliedFilters && this.$route.name === 'contacts_dashboard') {
        this.fetchFilteredContacts(DEFAULT_PAGE);
      } else {
        this.fetchContacts(DEFAULT_PAGE);
      }
    },

    $route() {
      this.searchQuery = '';
      this.tableKey += 1;
      this.deselectAll();

      if (this.showContactInfoPanelPane) {
        this.closeContactInfoPanel();
      }

      if (this.isContactListsPage) {
        this.fetchContactList(this.pageParameter);
      }

      if (this.isContactDashboard) {
        if (this.hasAppliedCondition) {
          this.fetchContactsByCondition(this.pageParameter);
        } else {
          this.fetchContacts(this.pageParameter);
        }
      }
    },
  },

  mounted() {
    this.fetchContactLists();
    this.fetchFolders();

    if (this.hasAppliedCondition) {
      this.fetchContactsByCondition(this.meta.currentPage);
    } else {
      this.fetchContacts(this.meta.currentPage);
    }

    if (this.isContactListsPage) {
      this.fetchContactList(this.pageParameter);
    }
  },

  methods: {
    async fetchFolders() {
      await this.$store.dispatch('folders/getAllFolders', {
        clientId: this.accountId,
      });
    },

    async fetchContactList(page) {
      let timeoutId = null;

      this.updatePageParam(page || this.pageParameter);

      timeoutId = setTimeout(() => {
        this.isLoadingContactList = true;
      }, 200);

      let value = '';
      if (this.searchQuery.charAt(0) === '+') {
        value = this.searchQuery.substring(1);
      } else {
        value = this.searchQuery;
      }

      try {
        const payload = {
          listId: this.$route.params.listId,
          page: page || this.pageParameter,
          pageParams: this.getSortAttribute(),
          search: encodeURIComponent(value),
          // payload: Object.keys(this.segmentsQuery).length
          //   ? this.segmentsQuery.payload
          //   : []
        };

        if (this.hasAppliedListCondition) {
          payload.condition = this.appliedListCondition;
        }

        await this.$store.dispatch('contactLists/getList', payload);
        clearTimeout(timeoutId);
        this.isLoadingContactList = false;
      } catch (error) {
        clearTimeout(timeoutId);
        this.isLoadingContactList = false;
      }
    },

    async fetchContactLists() {
      await this.$store.dispatch('contactLists/getLists', {
        accountId: this.$route.params.accountId,
      });
    },

    changeSelectedQuantity(keys) {
      this.selectedQuantity = keys.length;
      this.selectedIds = keys;
    },

    selectAll(keys) {
      this.selectedIds = keys;
      this.selectedQuantity = keys.length;
    },

    deselectAll() {
      // this.selectedClean = true;
      this.selectedIds = [];
      this.selectedQuantity = 0;
      this.deselected = true;
      setTimeout(() => {
        this.deselected = false;
      }, 100);
    },

    updatePageParam(page) {
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
    },

    getSortAttribute() {
      let sortAttr = Object.keys(this.sortConfig).reduce((acc, sortKey) => {
        const sortOrder = this.sortConfig[sortKey];
        if (sortOrder) {
          const sortOrderSign = sortOrder === 'asc' ? '' : '-';
          return `${sortOrderSign}${sortKey}`;
        }
        return acc;
      }, '');
      if (!sortAttr) {
        this.sortConfig = { name: 'asc' };
        sortAttr = 'name';
      }
      return sortAttr;
    },

    fetchContacts(page) {
      if (this.isContactDashboard) {
        this.updatePageParam(page);
        let value = '';
        if (this.searchQuery.charAt(0) === '+') {
          value = this.searchQuery.substring(1);
        } else {
          value = this.searchQuery;
        }
        const requestParams = {
          page,
          sortAttr: this.getSortAttribute(),
          label: this.label,
        };
        if (!value) {
          this.$store.dispatch('contacts/get', requestParams);
        } else {
          this.$store.dispatch('contacts/search', {
            search: encodeURIComponent(value),
            ...requestParams,
          });
        }
      }
    },

    fetchSavedFilteredContact(payload, page) {
      if (this.hasActiveSegments) {
        this.updatePageParam(page);
        this.$store.dispatch('contacts/filter', {
          queryPayload: payload,
          page,
        });
      }
    },

    fetchFilteredContacts(page) {
      if (this.hasAppliedFilters) {
        const payload = this.segmentsQuery;
        this.updatePageParam(page);
        this.$store.dispatch('contacts/filter', {
          queryPayload: payload,
          page,
        });
      }
    },

    onInputSearch(event) {
      const newQuery = event.target.value;
      const refetchAllContacts = !!this.searchQuery && newQuery === '';
      this.searchQuery = newQuery;
      if (refetchAllContacts) {
        if (this.isContactListsPage) {
          this.fetchContactList(DEFAULT_PAGE);
        } else {
          this.fetchContacts(DEFAULT_PAGE);
        }
      }
    },

    onSearchSubmit() {
      this.selectedContactId = '';
      if (this.searchQuery) {
        if (this.isContactListsPage) {
          this.fetchContactList(DEFAULT_PAGE);
        } else {
          this.fetchContacts(DEFAULT_PAGE);
        }
      }
    },

    onPageChange(page) {
      this.selectedContactId = '';

      if (this.isContactListsPage) {
        this.fetchContactList(page);
      } else {
        if (this.segmentsId !== 0) {
          const payload = this.activeSegment.query;
          this.fetchSavedFilteredContact(payload, page);
        }
        if (this.hasAppliedCondition) {
          this.fetchContactsByCondition(page);
        } else {
          this.fetchContacts(page);
        }
      }
    },

    openContactInfoPanel(contactId) {
      this.selectedContactId = contactId;
      this.showContactInfoPanelPane = true;
    },

    closeContactInfoPanel() {
      this.selectedContactId = '';
      this.showContactInfoPanelPane = false;
      this.fetchContactLists();
      // if (this.isContactListsPage && !fetchContactsList) {
      //   this.fetchContactList();
      // }
      // this.deselectAll();
    },

    toggleShowCreateContactModal() {
      this.showCreateModal = !this.showCreateModal;
    },

    toggleShowAddToContactListModal() {
      this.showCreateListModal = !this.showCreateListModal;
    },

    onToggleEditList() {
      this.showEditListModal = !this.showEditListModal;
      this.$router.push({ name: 'contacts_dashboard' });
      this.fetchContactLists();
    },

    onToggleAddToList() {
      this.showAddToListModal = !this.showAddToListModal;
    },

    onCloseAddToList() {
      this.showAddToListModal = false;
    },

    showDeleteSelectedModal() {
      this.deleteSelected = true;
    },

    onToggleDeleteFilters() {
      this.showDeleteSegmentsModal = true;
    },

    onCloseDeleteSegmentsModal() {
      this.showDeleteSegmentsModal = false;
    },

    closeDeleteSelectedPopup() {
      this.deleteSelected = false;
    },

    onToggleShowExportConfirmationModal() {
      this.showExportConfirmationModal = !this.showExportConfirmationModal;
    },

    onCloseShowExportConfirmationModal() {
      this.showExportConfirmationModal = false;
    },

    onToggleExportContactsListModal() {
      this.showExportListConfirmationModal =
        !this.showExportListConfirmationModal;
    },

    onCloseExportContactsListModal() {
      this.showExportListConfirmationModal = false;
    },

    showEditList() {
      this.fetchContactList(DEFAULT_PAGE);
      this.showEditListModal = true;
    },

    closeEditList() {
      this.showEditListModal = false;
    },

    async deleteSelectedItems() {
      try {
        await this.$store.dispatch('contactLists/deleteContacts', {
          // eslint-disable-next-line no-underscore-dangle
          listId: this.$route.params.listId,
          contacts: this.selectedIds,
        });

        await this.fetchContactLists();
        await this.fetchContactList(this.contactList.meta.currentPage);

        const successMessage =
          this.selectedIds.length > 1
            ? this.$t('CHATLYN_CONTACT_LISTS.REMOVE_MODAL.SUCCESS_MESSAGE')
            : this.$t('CHATLYN_CONTACT_LISTS.REMOVE_MODAL.SUCCESS_MESSAGE_ONE');
        this.showAlert(successMessage);
        this.deleteSelected = false;
        this.deselectAll();
      } catch (error) {
        const errorMessage =
          error.message || this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN');
        this.showAlert(errorMessage);
      }
    },

    async handleDeleteSelectedContacts() {
      try {
        await this.$store.dispatch('contactLists/deleteContactsFromLists', {
          // eslint-disable-next-line no-underscore-dangle
          contacts: this.selectedIds,
        });

        const promises = this.selectedIds.map((id) =>
          this.$store.dispatch('contacts/delete', id)
        );

        await Promise.all(promises);

        await this.fetchContactLists();

        this.showAlert(
          this.$t('CHATLYN_CONTACT_LISTS.REMOVE_MODAL.SUCCESS_MESSAGE')
        );
        this.deleteSelected = false;
        this.deselectAll();
      } catch (error) {
        const errorMessage =
          error.message || this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN');
        this.showAlert(errorMessage);
      } finally {
        this.deleteSelectedContacts = false;
      }
    },

    onShowImporter() {
      this.$router.push({
        name: 'contacts_importer_dashboard',
      });
    },

    // onCloseImport() {
    //   this.showImportModal = false;
    // },

    onToggleSendMessage() {
      this.showSendMessageModal = !this.showSendMessageModal;
    },
    onCloseSendMessage() {
      this.showSendMessageModal = false;
    },
    onSortChange(params) {
      this.sortConfig = params;
      if (this.isContactListsPage) {
        this.fetchContactList(params);
      } else if (this.hasAppliedCondition) {
        this.fetchContactsByCondition(this.meta.currentPage);
      } else {
        this.fetchContacts(this.meta.currentPage);
      }

      const sortBy =
        Object.entries(params).find((pair) => Boolean(pair[1])) || [];

      this.$track(CONTACTS_EVENTS.APPLY_SORT, {
        appliedOn: sortBy[0],
        order: sortBy[1],
      });
    },

    async toggleShowContactConditionModal(isSubmitted = false) {
      this.showContactConditionModal = !this.showContactConditionModal;

      if (isSubmitted) {
        if (this.isContactListsPage) {
          await this.fetchContactList(DEFAULT_PAGE);
        } else {
          await this.fetchContactsByCondition(DEFAULT_PAGE);
        }
      }
    },

    async fetchContactsByCondition(page) {
      const params = {
        page,
        accountId: this.accountId,
        limit: 15,
        sortAttr: this.getSortAttribute(),
        label: this.label,
        condition: this.appliedContactCondition,
      };

      await this.$store.dispatch('contacts/getAll', params);
    },

    async onClearCondition(isOpened = true) {
      if (isOpened) {
        this.toggleShowContactConditionModal();
      }

      if (this.isContactListsPage) {
        await this.fetchContactList(DEFAULT_PAGE);
      } else {
        await this.fetchContacts(DEFAULT_PAGE);
      }
    },

    toggleShowCreateListByConditionModal() {
      this.showCreateListByConditionModal =
        !this.showCreateListByConditionModal;
    },

    toggleShowAddToListByConditionModal() {
      this.showAddToListByConditionModal = !this.showAddToListByConditionModal;
    },

    // onToggleFilters() {
    //   if (this.hasActiveSegments) {
    //     this.initializeSegmentToFilterModal(this.activeSegment);
    //   }
    //   this.showFiltersModal = true;
    // },
    // closeAdvanceFiltersModal() {
    //   this.showFiltersModal = false;
    //   this.appliedFilter = [];
    // },
    // onApplyFilter(payload) {
    //   this.closeContactInfoPanel();
    //   this.segmentsQuery = filterQueryGenerator(payload);
    //   if (this.isContactListsPage) {
    //     this.fetchContactList(DEFAULT_PAGE);
    //   } else {
    //     this.$store.dispatch('contacts/filter', {
    //       queryPayload: filterQueryGenerator(payload),
    //     });
    //   }
    //   this.showFiltersModal = false;
    // },
    // onUpdateSegment(payload, segmentName) {
    //   const payloadData = {
    //     ...this.activeSegment,
    //     name: segmentName,
    //     query: filterQueryGenerator(payload),
    //   };
    //   this.$store.dispatch('customViews/update', payloadData);
    //   this.closeAdvanceFiltersModal();
    // },
    // clearFilters() {
    //   this.segmentsQuery = {};
    //   this.$store.dispatch('contacts/clearContactFilters');
    //   if (this.isContactListsPage) {
    //     this.fetchContactList(this.pageParameterx);
    //   } else {
    //     this.fetchContacts(this.pageParameter);
    //   }
    // },

    onExportSubmit() {
      try {
        this.$store.dispatch('contacts/export');
        this.showExportConfirmationModal = false;
        this.showAlert(this.$t('EXPORT_CONTACTS.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message || this.$t('EXPORT_CONTACTS.ERROR_MESSAGE')
        );
      }
    },
    async onExportListSubmit() {
      try {
        const isSuccess = await this.$store.dispatch(
          'contactLists/exportList',
          {
            listId: this.$route.params.listId,
            listName: this.contactList.name,
          }
        );
        this.showExportListConfirmationModal = false;

        if (!isSuccess) {
          throw new Error('');
        }

        this.showAlert(
          this.$t('CONTACTS_PAGE.EXPORT_CONTACTS_LIST_MODAL.SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(
          error.message || this.$t('EXPORT_CONTACTS.ERROR_MESSAGE')
        );
      }
    },
    setParamsForEditSegmentModal() {
      // Here we are setting the params for edit segment modal to show the existing values.

      // For custom attributes we get only attribute key.
      // So we are mapping it to find the input type of the attribute to show in the edit segment modal.
      const params = {
        countries: countries,
        filterTypes: contactFilterItems,
        allCustomAttributes:
          this.$store.getters['attributes/getAttributesByModel'](
            'contact_attribute'
          ),
      };
      return params;
    },
    initializeSegmentToFilterModal(activeSegment) {
      // Here we are setting the params for edit segment modal.
      //  To show the existing values. when we click on edit segment button.

      // Here we get the query from the active segment.
      // And we are mapping the query to the actual values.
      // To show in the edit segment modal by the help of generateValuesForEditCustomViews helper.
      const query = activeSegment?.query?.payload;
      if (!Array.isArray(query)) return;

      this.appliedFilter.push(
        ...query.map((filter) => ({
          attribute_key: filter.attribute_key,
          attribute_model: filter.attribute_model,
          filter_operator: filter.filter_operator,
          values: Array.isArray(filter.values)
            ? generateValuesForEditCustomViews(
                filter,
                this.setParamsForEditSegmentModal()
              )
            : [],
          query_operator: filter.query_operator,
          custom_attribute_type: filter.custom_attribute_type,
        }))
      );
    },
    openSavedItemInSegment() {
      const lastItemInSegments = this.segments[this.segments.length - 1];
      const lastItemId = lastItemInSegments.id;
      this.$router.push({
        name: 'contacts_segments_dashboard',
        params: { id: lastItemId },
      });
    },
    openLastItemAfterDeleteInSegment() {
      if (this.segments.length > 0) {
        this.openSavedItemInSegment();
      } else {
        this.$router.push({ name: 'contacts_dashboard' });
        this.fetchContacts(DEFAULT_PAGE);
      }
    },

    toggleDeleteSelectedContacts() {
      this.deleteSelectedContacts = !this.deleteSelectedContacts;
    },
  },
};
</script>
