<template>
  <div
    class="message-template__panel absolute right-0 z-10 h-full w-[var(--contact-panel-width)] overflow-y-auto border-l bg-white text-sm shadow-xl dark:border-gray-800/50 dark:bg-gray-900"
  >
    <div class="flex flex-row flex-nowrap items-center justify-end px-2 pt-2">
      <woot-button
        class="close-button"
        variant="clear"
        color-scheme="secondary"
        icon="dismiss"
        @click="close"
      />
    </div>

    <div
      v-if="messageTemplate"
      class="message-template__panel__body -mt-2 flex flex-col gap-4 px-4"
    >
      <message-template-info-actions
        :message-template="messageTemplate"
        :message-template-type="messageTemplateType"
        @open-message-template-modal="editMessageTemplate"
        @delete-template="deleteTemplate"
        @fetch-message-templates="fetchMessageTemplates"
      />

      <div class="flex flex-col flex-nowrap gap-3">
        <language-bar
          :languages="languages"
          :current-language="currentLanguage"
          :translations="messageTemplate.translations"
          :has-additional-text="false"
          :custom-mark-color="currentTranslationStatusOptionColor"
          :has-color-mark="hasLanguageBarColorMark"
          has-add-button
          :has-popover="false"
          @change="selectCurrentLanguage"
          @handle-add-button-click="toggleShowEditModal"
        />

        <message-preview
          class="message-template__panel__body__preview"
          :has-label="false"
          :modal-mode="false"
          :current-translation="currentTranslation"
        />

        <div class="flex flex-row flex-nowrap gap-2">
          <woot-button
            class="message-template__action-button"
            variant="smooth"
            color-scheme="secondary"
            icon="edit"
            size="small"
            :is-disabled="isEditButtonDisabled"
            @click="editMessageTemplate"
          >
            {{
              $t('CHATLYN_MESSAGE_TEMPLATE.BUTTON.EDIT_LANGUAGE', {
                LANGUAGE: currentLanguage.toUpperCase(),
              })
            }}
          </woot-button>

          <woot-button
            v-if="hasDeleteTranslationLanguageButton"
            class="message-template__action-button"
            variant="smooth"
            color-scheme="alert"
            icon="delete"
            size="small"
            @click="toggleShowDeleteLanguageModal"
          >
            {{
              $t('CHATLYN_MESSAGE_TEMPLATE.BUTTON.DELETE_LANGUAGE', {
                LANGUAGE: currentLanguage.toUpperCase(),
              })
            }}
          </woot-button>
        </div>

        <div class="message-template__info flex flex-col gap-1 py-4">
          <div>
            <span>
              <fluent-icon icon="whatsapp" :size="16" />
            </span>

            <span>
              {{
                $t(
                  `CHATLYN_MESSAGE_TEMPLATE.LIST.INBOX_TYPE.${messageTemplateType.toUpperCase()}`
                )
              }}
            </span>
          </div>

          <div v-if="messageTemplateStatus">
            <span>
              <fluent-icon icon="stack" :size="16" />
            </span>

            <span>
              {{
                `${messageTemplateStatus} ${$t(
                  'CHATLYN_MESSAGE_TEMPLATE.TEMPLATE'
                )}`
              }}
            </span>
          </div>

          <div>
            <span>
              <fluent-icon icon="calendar-empty" :size="16" />
            </span>

            <span>
              {{ currentTranslationCreatedAt }}
            </span>
          </div>

          <div class="flex items-center gap-2">
            <span
              class="status-indicator mx-0.5"
              :style="{
                'background-color': currentTranslationStatusOptionColor,
              }"
            />
            <span>
              {{ currentTranslationStatusOptionText }}
            </span>
          </div>

          <div v-if="statusReason" class="-mt-1 ps-6">
            {{
              $t('CHATLYN_MESSAGE_TEMPLATE.REJECT_MESSAGE', {
                REASON: statusReason,
              })
            }}
          </div>
        </div>
      </div>
    </div>

    <teleport to="#chatlyn-app-target">
      <div>
        <woot-delete-modal
          :show.sync="showDeleteLanguageModal"
          :title="
            $t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.TITLE', {
              LANGUAGE: currentLanguage.toUpperCase(),
            })
          "
          :message="
            $t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.MESSAGE')
          "
          :confirm-text="
            $t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.ACCEPT')
          "
          :reject-text="
            $t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.DECLINE')
          "
          :on-confirm="deleteLanguage"
          :on-close="toggleShowDeleteLanguageModal"
          :is-loading="isDeleting"
          :close-on-backdrop-click="false"
        />
      </div>
    </teleport>

    <chatlyn-spinner :is-visible="isLoading" />
  </div>
</template>

<script>
import Teleport from 'vue2-teleport';

import MessagePreview from './MessagePreview.vue';
import MessageTemplateInfoActions from './MessageTemplateInfoActions.vue';
import types from '../../../../store/mutation-types';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import LanguageBar from './LanguageBar.vue';
import WootButton from '../../../../components/ui/WootButton.vue';
import { format } from 'date-fns';
import {
  MESSAGE_TEMPLATES_CHANNEL_TYPE,
  MESSAGE_TEMPLATES_STATUSES_OPTION,
} from '../constants';
import ChatlynSpinner from '../../../../../shared/components/ChatlynSpinner.vue';

export default {
  name: 'MessageTemplateInfoPanel',
  components: {
    Teleport,
    MessagePreview,
    MessageTemplateInfoActions,
    LanguageBar,
    WootButton,
    ChatlynSpinner,
  },
  mixins: [alertMixin],
  props: {
    selectedTranslationLanguage: {
      type: String,
      default: '',
    },

    messageTemplateType: {
      type: String,
      default: '',
    },

    isCreateModalShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDeleteLanguageModal: false,
    };
  },
  computed: {
    ...mapGetters({
      messageTemplate: 'messageTemplates/getMessageTemplate',
      messageTemplatesUIFlags: 'messageTemplates/getUIFlags',
      accountId: 'getCurrentAccountId',
      uiFlags: 'messageTemplates/getUIFlags',
    }),

    currentLanguage: {
      get() {
        return this.selectedTranslationLanguage;
      },

      set(newValue) {
        this.$emit('change-selected-translation-language', newValue);
      },
    },

    languages() {
      return this.messageTemplate.translations.map((translation) => ({
        name: translation.language,
      }));
    },

    messageTemplateStatus() {
      if (!this.messageTemplate.category) {
        return '';
      }

      return this.$t(
        `CHATLYN_MESSAGE_TEMPLATE.CATEGORY.${this.messageTemplate.category}`
      );
    },

    currentTranslation() {
      return this.messageTemplate.translations.find(
        (translation) => translation.language === this.currentLanguage
      );
    },

    currentTranslationCreatedAt() {
      if (!this.currentTranslation?.createdAt) {
        return '';
      }

      return `${this.$t('CHATLYN_MESSAGE_TEMPLATE.PANEL.CREATED_ON')} ${format(
        new Date(this.currentTranslation.createdAt),
        `MMM dd, HH:mm`
      )}`;
    },

    currentTranslationStatus() {
      return (
        this.currentTranslation?.status ||
        this.messageTemplate?.status ||
        ''
      ).toUpperCase();
    },

    currentTranslationStatusOption() {
      return MESSAGE_TEMPLATES_STATUSES_OPTION[this.currentTranslationStatus];
    },

    currentTranslationStatusOptionColor() {
      if (
        !this.currentTranslationStatusOption ||
        !this.currentTranslationStatusOption.color
      ) {
        return '';
      }

      return this.currentTranslationStatusOption.color;
    },

    currentTranslationStatusOptionText() {
      if (
        !this.currentTranslationStatusOption ||
        !this.currentTranslationStatusOption.title
      ) {
        return '';
      }

      return this.$t(this.currentTranslationStatusOption.title);
    },

    isEditButtonDisabled() {
      if (
        [MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS].includes(this.messageTemplateType)
      ) {
        return false;
      }

      return !['DRAFT', 'REJECTED'].includes(this.currentTranslationStatus);
    },

    hasDeleteTranslationLanguageButton() {
      if (
        !this.currentTranslation ||
        !Object.keys(this.currentTranslation)?.length
      ) {
        return false;
      }

      if (
        [MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS].includes(this.messageTemplateType)
      ) {
        return true;
      }

      return this.currentTranslation.status === 'draft';
    },

    isDeleting() {
      return this.messageTemplatesUIFlags.isDeleting;
    },

    statusReason() {
      return this.currentTranslationStatus === 'REJECTED'
        ? this.currentTranslation?.rejectedReason ||
            this.messageTemplate?.rejectedReason ||
            ''
        : '';
    },

    showEditModal: {
      get() {
        return this.isCreateModalShown;
      },

      set(newValue) {
        if (newValue) {
          this.editMessageTemplate();
          return;
        }

        this.closeEditMessageTemplate();
      },
    },

    hasLanguageBarColorMark() {
      return [MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP].includes(
        this.messageTemplateType
      );
    },

    isLoading() {
      return this.uiFlags.isFetching || this.uiFlags.isTemplateFetching;
    },
  },
  watch: {
    messageTemplate: {
      handler(newValue) {
        if (!newValue) {
          return;
        }

        if (
          this.currentLanguage &&
          newValue.translations.some(
            (translation) => translation.language === this.currentLanguage
          )
        ) {
          return;
        }

        this.currentLanguage = newValue.translations[0].language;
      },
      deep: true,
    },
  },

  methods: {
    toggleShowDeleteLanguageModal() {
      this.showDeleteLanguageModal = !this.showDeleteLanguageModal;
    },

    toggleShowEditModal() {
      this.showEditModal = !this.showEditModal;
    },

    close() {
      this.$emit('close');
      this.$store.commit(`messageTemplates/${types.CLEAR_MESSAGE_TEMPLATE}`);
    },

    deleteTemplate(status) {
      if (!status) {
        this.showAlert(
          this.$t('CHATLYN_MESSAGE_TEMPLATE.DELETE_MODAL.ERROR_MESSAGE', {
            TEMPLATE_NAME: this.messageTemplate.title,
          })
        );

        return;
      }
      this.$emit('delete-template', status);

      this.showAlert(
        this.$t('CHATLYN_MESSAGE_TEMPLATE.DELETE_MODAL.SUCCESS_MESSAGE', {
          TEMPLATE_NAME: this.messageTemplate.title,
        })
      );

      this.$store.commit(`messageTemplates/${types.CLEAR_MESSAGE_TEMPLATE}`);
    },

    selectCurrentLanguage(newValue) {
      this.currentLanguage = newValue;
    },

    editMessageTemplate() {
      this.$emit('open-message-template-modal', this.currentLanguage);
    },

    closeEditMessageTemplate() {
      this.$emit('close-message-template-modal');
    },

    fetchMessageTemplates() {
      this.$emit('fetch-message-templates');
    },

    async deleteLanguage() {
      if (!this.currentLanguage) {
        return;
      }

      const matchedTranslation = this.messageTemplate.translations.find(
        (transition) => transition.language === this.currentLanguage
      );

      try {
        let deleteResponse;

        switch (this.messageTemplateType) {
          case MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS:
            deleteResponse = await this.$store.dispatch(
              'messageTemplates/deleteSmsMessageTemplateTranslation',
              {
                // eslint-disable-next-line no-underscore-dangle
                templateId: this.messageTemplate._id,
                language: this.currentLanguage,
              }
            );
            break;

          case MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP:
            if (matchedTranslation.status) {
              deleteResponse = await this.$store.dispatch(
                'messageTemplates/deleteMessageTemplateTranslation',
                {
                  // eslint-disable-next-line no-underscore-dangle
                  templateId: this.messageTemplate._id,
                  language: this.currentLanguage,
                }
              );
            }
            break;

          default:
            break;
        }

        if (!deleteResponse) {
          throw new Error();
        }

        const newTranslations = structuredClone(
          this.messageTemplate.translations.filter(
            (transition) => transition.language !== this.currentLanguage
          )
        );

        this.messageTemplate.translations = newTranslations;

        this.toggleShowDeleteLanguageModal();
      } catch (error) {
        //
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message-template__panel {
  .message-template__panel__body {
    .message-template__action-button {
      @apply flex flex-1 items-center justify-center;

      ::v-deep .button__content {
        @apply w-auto;
      }
    }

    .message-template__info {
      > div {
        @apply flex items-center gap-2;
      }
    }
  }
}
</style>
