<template>
  <formulate-form v-slot="{ hasErrors }" class="p-0" @submit="formSubmit">
    <div clasS="modal-header">
      <div class="modal-header-title">
        {{ headerTitle }}
      </div>
    </div>

    <div class="modal-body flex flex-col gap-2">
      <formulate-input
        v-model.trim="newFolder.name"
        :label="$t('CHATLYN_GENERAL.LABEL.NAME')"
        :placeholder="$t('FOLDERS.ADD_MODAL.NAME_FIELD.PLACEHOLDER')"
        :validation-messages="{
          min: $t('CHATLYN_GENERAL.MESSAGES.MIN_LENGTH_2'),
        }"
        :minlength="2"
        validation="required|min:2"
      />
    </div>

    <div class="modal-footer">
      <woot-button :is-disabled="hasErrors" data-testid="label-submit">
        {{
          isEditMode
            ? $t('CHATLYN_GENERAL.BUTTON.UPDATE')
            : $t('CHATLYN_GENERAL.BUTTON.CREATE')
        }}
      </woot-button>
    </div>

    <chatlyn-spinner :is-visible="isLoading" />
  </formulate-form>
</template>

<script>
import { mapGetters } from 'vuex';
import ChatlynSpinner from '../../../../shared/components/ChatlynSpinner.vue';

export default {
  name: 'AddFolder',
  components: { ChatlynSpinner },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },

    folderId: {
      type: [String, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      newFolder: {
        name: '',
        isArchived: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      folder: 'folders/getFolder',
      foldersUiFlags: 'folders/getFoldersUiFlags',
      currentAccountId: 'getCurrentAccountId',
    }),

    isLoading() {
      return Object.values(this.foldersUiFlags).some((val) => val);
    },

    hasEditMode() {
      return this.folderId && this.isEditMode;
    },

    formSubmit() {
      return this.hasEditMode ? this.updateFolder : this.addFolder;
    },

    headerTitle() {
      return this.$t(
        this.hasEditMode
          ? 'FOLDERS.EDIT_MODAL.TITLE'
          : 'FOLDERS.ADD_MODAL.TITLE'
      );
    },
  },
  mounted() {
    this.initialSetup();
  },
  methods: {
    async initialSetup() {
      if (!this.hasEditMode) {
        return;
      }

      await this.fetchFolder(this.folderId);

      // eslint-disable-next-line no-underscore-dangle
      if (this.folder._id !== this.folderId) {
        return;
      }

      this.newFolder = structuredClone(this.folder);
    },

    async fetchFolder(id) {
      await this.$store.dispatch('folders/getFolder', {
        clientId: this.currentAccountId,
        folderId: id,
      });
    },

    async addFolder() {
      await this.$store.dispatch('folders/createFolder', {
        clientId: this.currentAccountId,
        folder: {
          ...this.newFolder,
          clientId: this.currentAccountId,
        },
      });

      this.$emit('close', true);
    },

    async updateFolder() {
      // eslint-disable-next-line no-underscore-dangle
      if (!this.hasEditMode || this.newFolder?._id !== this.folderId) {
        return;
      }

      await this.$store.dispatch('folders/updateFolder', {
        clientId: this.currentAccountId,
        folder: {
          ...this.newFolder,
        },
      });

      this.$emit('close', true);
    },
  },
};
</script>

<style></style>
