<template>
  <footer
    v-if="isFooterVisible"
    class="flex flex-wrap items-center justify-between border-t border-solid border-gray-100 bg-white dark:border-gray-700/50 dark:bg-gray-800"
  >
    <div
      class="footer__wrapper flex w-full items-center justify-between px-8 py-4"
    >
      <div v-if="!hidePageMeta" class="left-aligned-wrap">
        <div class="text-xs text-gray-600 dark:text-gray-200">
          <strong>{{ firstIndex }}</strong>
          - <strong>{{ lastIndex }}</strong> of
          <strong>{{ totalCount }}</strong> items
        </div>
      </div>
      <div class="right-aligned-wrap">
        <div
          v-if="totalCount"
          class="primary button-group pagination-button-group"
        >
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            class-names="goto-first"
            :is-disabled="hasFirstPage"
            @click="onFirstPage"
          >
            <fluent-icon
              icon="chevron-first"
              size="18"
              :class="pageFooterIconClass"
            />
          </woot-button>
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="hasPrevPage"
            @click="onPrevPage"
          >
            <fluent-icon icon="chevron-left" size="18" />
          </woot-button>
          <div class="page-input__wrapper">
            <div class="page-input">
              <input
                ref="page"
                v-model="page"
                class="page-input__field"
                :style="'max-width: ' + inputWidth + 'px'"
                type="text"
                @keydown.enter="onPageChange(page)"
                @focus="inputFocused"
                @blur="inputBlured"
              />
              <woot-button
                size="tiny"
                variant="clear"
                color-scheme="secondary"
                :is-disabled="!focused"
                @click="onFirstPage"
              >
                <fluent-icon icon="dismiss" size="16" />
              </woot-button>
            </div>
          </div>
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="hasNextPage"
            @click="onNextPage"
          >
            <fluent-icon icon="chevron-right" size="18" />
          </woot-button>
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            class-names="goto-last"
            :is-disabled="hasLastPage"
            @click="onLastPage"
          >
            <fluent-icon
              icon="chevron-last"
              size="18"
              :class="pageFooterIconClass"
            />
          </woot-button>
        </div>
      </div>
    </div>
    <slot />
  </footer>
</template>

<script>
import rtlMixin from 'shared/mixins/rtlMixin';

export default {
  components: {},
  mixins: [rtlMixin],
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },

    pageSize: {
      type: Number,
      default: 25,
    },

    totalCount: {
      type: Number,
      default: 0,
    },

    hidePageMeta: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      focused: false,
      inputWidth: 20,
    };
  },
  computed: {
    pageFooterIconClass() {
      return this.isRTLView
        ? 'margin-right-minus-slab'
        : 'margin-left-minus-slab';
    },
    isFooterVisible() {
      return this.totalCount && !(this.firstIndex > this.totalCount);
    },
    firstIndex() {
      return this.pageSize * (this.currentPage - 1) + 1;
    },
    lastIndex() {
      return Math.min(this.totalCount, this.pageSize * this.currentPage);
    },
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
    hasLastPage() {
      const lastPageNumber = Math.ceil(this.totalCount / this.pageSize);
      return this.currentPage >= lastPageNumber;
    },
    hasFirstPage() {
      return this.currentPage === 1;
    },
    hasNextPage() {
      return this.currentPage === Math.ceil(this.totalCount / this.pageSize);
    },
    hasPrevPage() {
      return this.currentPage === 1;
    },
  },
  watch: {
    currentPage: {
      handler(newValue) {
        this.$nextTick(() => {
          if (newValue === this.page) {
            return;
          }

          this.page = newValue;
        });
      },
    },

    page(newValue) {
      this.$nextTick(() => {
        if (newValue <= 9) {
          this.inputWidth = 20;
        } else if (newValue >= 10 && newValue < 100) {
          this.inputWidth = 32;
        } else {
          this.inputWidth = 40;
        }
      });
    },
  },

  mounted() {
    if (!this.currentPage) {
      this.page = 1;
    } else {
      this.page = this.currentPage;
    }
  },

  methods: {
    inputFocused() {
      this.$nextTick(() => {
        this.$refs.page.select();
      });
      setTimeout(() => {
        this.focused = true;
      }, 250);
    },

    inputBlured() {
      setTimeout(() => {
        this.focused = false;
      }, 250);
    },

    onNextPage() {
      if (this.hasNextPage) {
        return;
      }
      const newPage = this.currentPage + 1;
      this.page = newPage;
      this.onPageChange(newPage);
    },

    onPrevPage() {
      if (this.hasPrevPage) {
        return;
      }
      const newPage = this.currentPage - 1;
      this.page = newPage;
      this.onPageChange(newPage);
    },

    onFirstPage() {
      if (this.hasFirstPage) {
        return;
      }
      const newPage = 1;
      this.page = newPage;
      this.onPageChange(newPage);
    },

    onLastPage() {
      if (this.hasLastPage) {
        return;
      }
      const newPage = Math.ceil(this.totalCount / this.pageSize);
      this.page = newPage;
      this.onPageChange(newPage);
    },

    onPageChange(page) {
      let currentPage = Number(page);
      const lastPage = Math.ceil(this.totalCount / this.pageSize);
      if (currentPage < 1) {
        currentPage = 1;
      } else if (currentPage > lastPage) {
        currentPage = lastPage;
      }
      this.page = currentPage;
      this.$emit('page-change', currentPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.goto-first,
.goto-last {
  // these buttons apparently dont work with our pagination
  //@apply hidden;

  i:last-child {
    @apply -ml-1;
  }
}
.page-input {
  @apply relative m-0.5 flex h-7 items-center rounded border border-gray-200 bg-white px-1 focus:border-gray-400/60 focus:outline-none dark:border-gray-700 dark:bg-gray-800;

  &__wrapper {
    @apply bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-100;
  }

  &__field {
    min-width: 1.5rem;
    max-width: 2rem;
    background: transparent !important;
    @apply h-full border-none px-1 outline-none;
  }
}
</style>
