import { validationData } from '../api/automation.stub';
import Cookies from 'js-cookie';

export const formatValidation = (accountId) => {
  let validation = null;
  if (process.env.FRONTEND_ENV === 'develop') {
    validation = validationData;
  } else {
    const validationCookies = Cookies.getJSON('cw_d_session_info');
    validation = {
      validation: {
        clientId: accountId,
        'access-token': validationCookies['access-token'],
        'token-type': 'Bearer',
        client: validationCookies.client,
        expiry: validationCookies.expiry,
        uid: validationCookies.uid,
      },
    };
  }
  return validation;
};
