<template>
  <div
    class="relative bg-white dark:bg-gray-800"
    :style="{
      minHeight: '2rem',
      minWidth: '2rem',
    }"
  >
    <div
      v-if="isLoading"
      class="absolute h-full w-full top-0 left-0 flex items-center justify-center z-10"
      style="background: rgba(0, 0, 0, 0.05)"
    >
      <span class="spinner h-8 w-8" />
    </div>
    <message-preview
      v-else-if="messageTemplate && messageTemplate.translations"
      :modal-mode="false"
      :current-translation="messageTemplate.translations[0]"
    />
    <div class="text-center w -40" v-else>
      <span class="text-xs block">{{ $t('CHATLYN_MESSAGE_TEMPLATE.NO_MESSAGE_PREVIEW') }}</span>
      <span class="text-xxs text-muted">{{ $t('CHATLYN_MESSAGE_TEMPLATE.NO_MESSAGE_PREVIEW_DESCRIPTION') }}</span>
    </div>
  </div>
</template>

<script>
import MessageTemplateAPI from '../../../../api/messageTemplates';
import MessagePreview from '../../../../routes/dashboard/messageTemplates/components/MessagePreview.vue';

export default {
  name: 'TemplatePreview',
  components: { MessagePreview },
  props: {
    messageTemplateName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      messageTemplate: null,
    };
  },
  mounted() {
    this.fetchMessageTemplate();
  },
  methods: {
    async fetchMessageTemplate() {
      try {
        this.isLoading = true;
        const { data } = await MessageTemplateAPI.getTemplateByName(
          this.messageTemplateName
        );

        if (!data) {
          throw new Error();
        }

        this.messageTemplate = data;
      } catch (error) {
        //
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .message-preview-container {
  .message-preview-background {
    @apply p-4;

    & > * {
      width: 100% !important;
    }
  }
}
</style>
