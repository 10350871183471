import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state = {
  userLanguage: '',
  account: null,
  chatBubbles: [],
  signatures: [],
  inboxOutOut: null,
  sms: {
    isEnabled: false,
    shortNames: [],
  },
  uiFlags: {
    isCreating: false,
    isFetching: false,
    isSmsFetching: false,
    isUpdating: false,
    isSmsUpdating: false,
    isMerging: false,
    isDeleting: false,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
