<template>
  <div class="flex-grow overflow-auto">
    <form
      v-if="!uiFlags.isFetchingItem"
      class="chatlyn-container my-8 flex flex-col gap-8"
      @submit.prevent="updateAccount"
    >
      <div class="grid gap-8 md:grid-cols-3">
        <div class="col-span-1">
          <div class="block-title text-gray-900 dark:text-gray-200">
            {{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.TITLE') }}
          </div>
          <p>{{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.NOTE') }}</p>
        </div>
        <div class="col-span-2">
          <label :class="{ error: $v.name.$error }">
            {{ $t('GENERAL_SETTINGS.FORM.NAME.LABEL') }}
            <input
              v-model="name"
              type="text"
              :placeholder="$t('GENERAL_SETTINGS.FORM.NAME.PLACEHOLDER')"
              @blur="$v.name.$touch"
            />
            <span v-if="$v.name.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.NAME.ERROR') }}
            </span>
          </label>

          <div
            v-if="featureInboundEmailEnabled"
            class="mb-6 mt-6 rounded-lg bg-gray-200 p-4 text-gray-500 dark:bg-gray-800 dark:text-gray-200"
          >
            {{ $t('GENERAL_SETTINGS.FORM.FEATURES.INBOUND_EMAIL_ENABLED') }}
          </div>

          <div
            v-if="featureCustomReplyDomainEnabled"
            class="mb-6 mt-6 rounded-lg bg-gray-50 p-4 text-gray-500"
          >
            {{
              $t('GENERAL_SETTINGS.FORM.FEATURES.CUSTOM_EMAIL_DOMAIN_ENABLED')
            }}
          </div>
          <div v-if="featureCustomReplyDomainEnabled" class="mt-4">
            {{ $t('GENERAL_SETTINGS.FORM.DOMAIN.LABEL') }}
            <input
              v-model="domain"
              type="text"
              :placeholder="$t('GENERAL_SETTINGS.FORM.DOMAIN.PLACEHOLDER')"
            />
          </div>
          <div v-if="featureCustomReplyEmailEnabled" class="mt-4">
            {{ $t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.LABEL') }}
            <input
              v-model="supportEmail"
              type="text"
              :placeholder="
                $t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.PLACEHOLDER')
              "
            />
          </div>
          <div
            v-if="showAutoResolutionConfig"
            :class="{ error: $v.autoResolveDuration.$error }"
          >
            <label>
              {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.LABEL') }}
              <input
                v-model="autoResolveDuration"
                type="number"
                :placeholder="
                  $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.PLACEHOLDER')
                "
                @blur="$v.autoResolveDuration.$touch"
              />
            </label>
            <div v-if="$v.autoResolveDuration.$error" class="error">
              <div class="message">
                {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.ERROR') }}
              </div>
            </div>
          </div>

          <div class="mt-4">
            <multiselect-ch
              v-model="timeZone"
              :options="timeZonesSearchOptions"
              :multiple="false"
              label="title"
              :max-height="140"
            >
              <template #title>
                <label>
                  {{ $t('CHATLYN_ACCOUNT_SETTINGS.TIME_ZONE') }}
                </label>
              </template>
            </multiselect-ch>
          </div>
        </div>
      </div>

      <hr />

      <div class="grid gap-8 md:grid-cols-3">
        <div class="col-span-1">
          <div class="block-title text-gray-900 dark:text-gray-200">
            {{ $t('CHATLYN_LANGUAGES.SETTINGS.TITLE') }}
          </div>
          <p>{{ $t('CHATLYN_LANGUAGES.SETTINGS.NOTE') }}</p>
        </div>
        <div class="col-span-2">
          <label class="block" :class="{ error: $v.locale.$error }">
            {{ $t('CHATLYN_LANGUAGES.MAIN_LANGUAGE.LABEL') }}
            <select v-model="locale">
              <option
                v-for="lang in mainLanguageDropdownValues"
                :key="lang.iso_639_1_code"
                :value="lang.iso_639_1_code"
              >
                {{ lang.name }}
              </option>
            </select>
            <span v-if="$v.locale.$error" class="message">
              {{ $t('CHATLYN_GENERAL.MESSAGES.ERROR') }}
            </span>
          </label>

          <label
            class="mt-4 block"
            :class="{ error: $v.additionalLocales.$error }"
          >
            {{ $t('CHATLYN_LANGUAGES.ADDITIONAL_LANGUAGE.LABEL') }}

            <multiselect-ch
              v-model="additionalLocales"
              :options="additionalLanguageDropdownValues"
              :placeholder="
                $t('CHATLYN_LANGUAGES.ADDITIONAL_LANGUAGE.PLACEHOLDER')
              "
              :multiple="true"
              :max-height="200"
            />

            <span v-if="$v.additionalLocales.$error" class="message">
              {{ $t('CHATLYN_GENERAL.MESSAGES.ERROR') }}
            </span>
          </label>
        </div>
      </div>

      <hr />

      <div class="grid gap-8 md:grid-cols-3">
        <div class="col-span-1">
          <h4 class="block-title text-gray-900 dark:text-gray-200">
            {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.TITLE') }}
          </h4>
          <p>
            {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.NOTE') }}
          </p>
        </div>
        <div class="col-span-2">
          <woot-code :script="getAccountId" />
        </div>
      </div>

      <woot-submit-button
        class="button success button--fixed-top"
        :button-text="$t('GENERAL_SETTINGS.SUBMIT')"
        :loading="isUpdating"
      />
    </form>

    <woot-loading-state v-if="uiFlags.isFetchingItem" />
  </div>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import accountMixin from '../../../../mixins/account';
import { FEATURE_FLAGS } from '../../../../featureFlags';
import semver from 'semver';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import MultiselectCh from 'shared/components/ui/Multiselect.vue';
import rtlMixin from 'shared/mixins/rtlMixin';
import { MESSAGE_TEMPLATE_AVAILABLE_TRANSLATION_LANGUAGE } from '../../messageTemplates/constants';
import { timeZoneOptions } from '../inbox/helpers/businessHour';

export default {
  components: {
    MultiselectCh,
  },
  mixins: [accountMixin, alertMixin, configMixin, uiSettingsMixin, rtlMixin],
  data() {
    return {
      id: '',
      name: '',
      locale: 'en',
      additionalLocales: [],
      domain: '',
      supportEmail: '',
      features: {},
      autoResolveDuration: null,
      latestChatwootVersion: null,
      timeZone: { name: '', title: '' },
    };
  },
  validations: {
    name: {
      required,
    },
    locale: {
      required,
    },
    autoResolveDuration: {
      minValue: minValue(1),
      maxValue: maxValue(999),
    },
    additionalLocales: {},
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      getAccount: 'accounts/getAccount',
      uiFlags: 'accounts/getUIFlags',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      currentUserId: 'getCurrentUserID',
      currentUserLanguage: 'management/getUserLanguage',
      managementAccount: 'management/getAccount',
    }),
    showAutoResolutionConfig() {
      return this.isFeatureEnabledonAccount(
        this.accountId,
        FEATURE_FLAGS.AUTO_RESOLVE_CONVERSATIONS
      );
    },
    hasAnUpdateAvailable() {
      if (!semver.valid(this.latestChatwootVersion)) {
        return false;
      }

      return semver.lt(
        this.globalConfig.appVersion,
        this.latestChatwootVersion
      );
    },
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
    mainLanguageDropdownValues() {
      return this.languagesSortedByCode.filter(
        (language) =>
          !this.additionalLocales.some(
            (additionalLang) => additionalLang.id === language.iso_639_1_code
          )
      );
    },
    isUpdating() {
      return this.uiFlags.isUpdating;
    },

    featureInboundEmailEnabled() {
      return !!this.features.inbound_emails;
    },

    featureCustomReplyDomainEnabled() {
      return (
        this.featureInboundEmailEnabled && !!this.features.custom_reply_domain
      );
    },

    featureCustomReplyEmailEnabled() {
      return (
        this.featureInboundEmailEnabled && !!this.features.custom_reply_email
      );
    },

    getAccountId() {
      return this.id.toString();
    },

    additionalLanguageDropdownValues() {
      return this.languagesSortedByCode
        .map((lang) => ({
          id: lang.iso_639_1_code,
          name: lang.name,
        }))
        .filter(
          (language) =>
            MESSAGE_TEMPLATE_AVAILABLE_TRANSLATION_LANGUAGE.includes(
              language.id
            ) && language.id !== this.locale
        );
    },

    timeZonesSearchOptions() {
      return timeZoneOptions().map((timeZoneOption) => ({
        title: timeZoneOption.label,
        name: timeZoneOption.value,
      }));
    },
  },
  mounted() {
    this.initializeAccount();
  },
  methods: {
    async initializeAccount() {
      try {
        const {
          name,
          locale: accountLocale,
          id,
          domain,
          support_email,
          features,
          auto_resolve_duration,
          latest_chatwoot_version: latestChatwootVersion,
        } = this.getAccount(this.accountId);

        await this.$store.dispatch('management/getAccount', {
          accountId: this.accountId,
        });

        await this.$store.dispatch('management/getUserLanguage', {
          currentUserId: this.currentUserId,
        });

        const interfaceLocale = this.currentUserLanguage || accountLocale;

        const mainLocale = this.managementAccount?.mainLang || accountLocale;

        this.$root.$i18n.locale = interfaceLocale;
        this.name = name;
        this.locale = mainLocale;
        this.id = id;
        this.domain = domain;
        this.supportEmail = support_email;
        this.features = features;
        this.autoResolveDuration = auto_resolve_duration;
        this.latestChatwootVersion = latestChatwootVersion;
        this.additionalLocales = this.additionalLanguageDropdownValues.filter(
          (additionalLanguageDropdownValue) =>
            this.managementAccount.additionalLang.includes(
              additionalLanguageDropdownValue.id
            )
        );

        this.timeZone = this.timeZonesSearchOptions.find(
          (timeZoneOption) =>
            timeZoneOption.name === this.managementAccount.timezone
        );
      } catch (error) {
        // Ignore error
      }
    },

    async updateAccount() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('GENERAL_SETTINGS.FORM.ERROR'));
        return;
      }
      try {
        await this.$store.dispatch('accounts/update', {
          locale: this.locale,
          name: this.name,
          domain: this.domain,
          support_email: this.supportEmail,
          auto_resolve_duration: this.autoResolveDuration,
        });

        await this.$store.dispatch('management/updateAccount', {
          accountId: this.accountId,
          account: {
            mainLang: this.locale,
            additionalLang: this.additionalLocales.map((locale) => locale.id),
            timezone: this.timeZone.name,
          },
        });

        this.$root.$i18n.locale = this.currentUserLanguage || this.locale;
        this.getAccount(this.id).locale = this.locale;
        this.updateRTLDirectionView(this.currentUserLanguage || this.locale);
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.ERROR'));
      }
    },
  },
};
</script>
