<template>
  <div
    class="h-full min-h-0 flex-grow overflow-auto"
    :class="
      isMessageTemplateRoute
        ? 'flex flex-col bg-white p-0 dark:bg-gray-900'
        : 'p-4'
    "
  >
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="arrow-download"
      @click="downloadReports"
    >
      {{ downloadButtonLabel }}
    </woot-button>
    <report-filters
      v-if="filterItemsList && type !== 'sent-templates'"
      :type="type"
      :filter-items-list="filterItemsList"
      :group-by-filter-items-list="groupByfilterItemsList"
      :selected-group-by-filter="selectedGroupByFilter"
      @date-range-change="onDateRangeChange"
      @filter-change="onFilterChange"
      @group-by-filter-change="onGroupByFilterChange"
      @business-hours-toggle="onBusinessHoursToggle"
    />
    <div v-if="type !== 'sent-templates'">
      <div
        v-if="filterItemsList.length"
        class="mt-4 flex flex-wrap gap-1 xl:flex-nowrap"
      >
        <woot-report-stats-card
          v-for="(metric, index) in metrics"
          :key="metric.NAME"
          :desc="metric.DESC"
          :heading="metric.NAME"
          :info-text="displayInfoText(metric.KEY)"
          :index="index"
          :point="displayMetric(metric.KEY)"
          :trend="calculateTrend(metric.KEY)"
          :selected="index === currentSelection"
          @click="changeSelection"
        />
      </div>
      <div class="report-bar rounded bg-white py-4 dark:bg-gray-800">
        <woot-loading-state
          v-if="isAccountReportFetched"
          :message="$t('REPORT.LOADING_CHART')"
        />
        <div v-else class="chart-container">
          <!-- TODO: if some data into selected metric found -->
          <woot-bar
            v-if="
              currentSelectionKey &&
              accountReport.data[currentSelectionKey].length &&
              filterItemsList.length &&
              isCurrentMetric
            "
            :collection="collection"
            :chart-options="chartOptions"
          />
          <!-- TODO: if no data in metrics -->
          <div v-else class="empty-state px-4 text-sm">
            {{ $t('REPORT.NO_ENOUGH_DATA') }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="templates"
      class="sent-templates flex h-full w-full flex-1 flex-col"
    >
      <filter-bar
        :filters="sentTemplatesFilters"
        :templates="messageTemplateNames"
        :whats-app-inboxes="whatsAppInboxes"
        :class="{ 'px-4 pt-3': isMessageTemplateRoute }"
        @filter-by-failed="filterByFailed"
        @filter-by-template="filterByTemplate"
        @filter-by-number="filterByNumber"
        @filter-by-recipient="filterByRecipient"
        @filter-by-date="filterByDate"
        @clear-filters="clearFilters"
      />
      <sent-templates-table
        :templates="templates.data"
        :status-counts="statusCounts"
        :all-templates="allTemplates.data"
        :whats-app-inboxes="whatsAppInboxes"
        :page-index="templates.meta.page"
        :total="templates.meta.itemCount"
        @sort-change="changeSort"
        @page-change="pageChange"
      />
    </div>
    <!-- TODO: huge card with charts -->
    <!--    <report-container v-if="filterItemsList.length" :group-by="groupBy" />-->
  </div>
</template>

<script>
import SentTemplatesTable from './sent-templates/SentTemplatesTable.vue';
import ReportFilters from './ReportFilters.vue';
import ReportContainer from '../ReportContainer.vue';
import { GROUP_BY_FILTER, METRIC_CHART } from '../constants';
import reportMixin from '../../../../../mixins/reportMixin';
import {
  generateFileName,
  generateSentTemplatesFileName,
} from '../../../../../helper/downloadHelper';
import { REPORTS_EVENTS } from '../../../../../helper/AnalyticsHelper/events';
import FilterBar from './sent-templates/FilterBar.vue';
import SentTemplatesApi from '../../../../../api/sentTemplates';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { formatTime } from '@chatwoot/utils';
import fromUnixTime from 'date-fns/fromUnixTime';
import { format } from 'date-fns';

const REPORTS_KEYS = {
  CONVERSATIONS: 'conversations_count',
  INCOMING_MESSAGES: 'incoming_messages_count',
  OUTGOING_MESSAGES: 'outgoing_messages_count',
  FIRST_RESPONSE_TIME: 'avg_first_response_time',
  RESOLUTION_TIME: 'avg_resolution_time',
  RESOLUTION_COUNT: 'resolutions_count',
  REPLY_TIME: 'reply_time',
};

export default {
  components: {
    SentTemplatesTable,
    FilterBar,
    ReportFilters,
    ReportContainer,
  },
  mixins: [reportMixin],
  props: {
    type: {
      type: String,
      default: 'account',
    },
    getterKey: {
      type: String,
      default: '',
    },
    actionKey: {
      type: String,
      default: '',
    },
    downloadButtonLabel: {
      type: String,
      default: 'Download Reports',
    },
  },
  data() {
    return {
      currentSelection: 0,
      currentSelectionKey: '',
      sentTemplatesFilters: {
        order: 'DESC',
        page: 1,
        template: null,
        number: null,
        recipient: '',
        from: moment().add(-7, 'days').toDate(),
        to: moment().toDate(),
        failed: false,
        csv: false,
      },
      from: 0,
      to: 0,
      selectedFilter: null,
      groupBy: GROUP_BY_FILTER[1],
      groupByfilterItemsList: this.$t('REPORT.GROUP_BY_DAY_OPTIONS'),
      selectedGroupByFilter: null,
      businessHours: false,
      templates: {
        data: [],
        meta: {},
      },
      allTemplates: {
        data: [],
        meta: {},
      },
      statusCounts: { failed: 0, sent: 0, delivered: 0, read: 0 },
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      whatsAppInboxes: 'inboxes/getWhatsAppInboxes',
      messageTemplateNames: 'messageTemplates/getMessageTemplateNames',
    }),
    isMessageTemplateRoute() {
      return this.$route.name === 'sent_templates_reports';
    },
    isCurrentMetric() {
      const displayCurrentMetric = this.displayMetric(this.currentSelectionKey);
      const numericPart = displayCurrentMetric.replace(/\D/g, ''); // Видаляємо всі нечислові символи
      const number = parseInt(numericPart, 10);
      return !!number;
    },
    isAccountReportFetched() {
      return !Object.values(this.accountReport.isFetching).every(
        (value) => value === false
      );
    },
    filterItemsList() {
      return this.$store.getters[this.getterKey] || [];
    },
    collection() {
      if (this.accountReport.isFetching[this.currentSelectionKey]) {
        return {};
      }
      if (!this.accountReport.data[this.currentSelectionKey].length) return {};
      const labels = this.accountReport.data[this.currentSelectionKey].map(
        (element) => {
          if (this.groupBy.period === GROUP_BY_FILTER[2].period) {
            let week_date = new Date(fromUnixTime(element.timestamp));
            const first_day = week_date.getDate() - week_date.getDay();
            const last_day = first_day + 6;

            const week_first_date = new Date(week_date.setDate(first_day));
            const week_last_date = new Date(week_date.setDate(last_day));

            return `${format(week_first_date, 'dd/MM/yy')} - ${format(
              week_last_date,
              'dd/MM/yy'
            )}`;
          }
          if (this.groupBy.period === GROUP_BY_FILTER[3].period) {
            return format(fromUnixTime(element.timestamp), 'MMM-yyyy');
          }
          if (this.groupBy.period === GROUP_BY_FILTER[4].period) {
            return format(fromUnixTime(element.timestamp), 'yyyy');
          }
          return format(fromUnixTime(element.timestamp), 'dd-MMM-yyyy');
        }
      );

      const datasets = METRIC_CHART[
        this.metrics[this.currentSelection].KEY
      ].datasets.map((dataset) => {
        switch (dataset.type) {
          case 'bar':
            return {
              ...dataset,
              yAxisID: 'y-left',
              label: this.metrics[this.currentSelection].NAME,
              data: this.accountReport.data[this.currentSelectionKey].map(
                (element) => element.value
              ),
            };
          case 'line':
            return {
              ...dataset,
              yAxisID: 'y-right',
              label: this.metrics[0].NAME,
              data: this.accountReport.data[this.currentSelectionKey].map(
                (element) => element.count
              ),
            };
          default:
            return dataset;
        }
      });

      return {
        labels,
        datasets,
      };
    },
    chartOptions() {
      let tooltips = {};
      if (this.isAverageMetricType(this.metrics[this.currentSelection].KEY)) {
        tooltips.callbacks = {
          label: (tooltipItem) => {
            return this.$t(this.metrics[this.currentSelection].TOOLTIP_TEXT, {
              metricValue: formatTime(tooltipItem.yLabel),
              conversationCount:
                this.accountReport.data[tooltipItem.index].count,
            });
          },
        };
      }
      return {
        scales: METRIC_CHART[this.metrics[this.currentSelection].KEY].scales,
        tooltips: tooltips,
      };
    },
    metrics() {
      let reportKeys = ['CONVERSATIONS'];
      // If report type is agent, we don't need to show
      // incoming messages count, as there will not be any message
      // sent by an agent which is incoming.
      if (this.type !== 'agent') {
        reportKeys.push('INCOMING_MESSAGES');
      }
      reportKeys = [
        ...reportKeys,
        'OUTGOING_MESSAGES',
        'FIRST_RESPONSE_TIME',
        'RESOLUTION_TIME',
        'RESOLUTION_COUNT',
      ];
      const infoText = {
        FIRST_RESPONSE_TIME: this.$t(
          `REPORT.METRICS.FIRST_RESPONSE_TIME.INFO_TEXT`
        ),
        RESOLUTION_TIME: this.$t(`REPORT.METRICS.RESOLUTION_TIME.INFO_TEXT`),
      };
      return reportKeys.map((key) => ({
        NAME: this.$t(`REPORT.METRICS.${key}.NAME`),
        KEY: REPORTS_KEYS[key],
        DESC: this.$t(`REPORT.METRICS.${key}.DESC`),
        INFO_TEXT: infoText[key],
        TOOLTIP_TEXT: `REPORT.METRICS.${key}.TOOLTIP_TEXT`,
      }));
    },
  },
  mounted() {
    if (this.type === 'sent-templates') {
      // Commented for avoid doing same request twice
      // this.fetchSentTemplates();
      this.fetchAllSentTemplates();
      this.fetchTemplates();
    } else {
      this.$store.dispatch(this.actionKey);
    }
    if (this.metrics.length) {
      this.currentSelectionKey = this.metrics[0].KEY;
    }
  },
  methods: {
    changeSort(data) {
      this.sentTemplatesFilters.order = data.templateName;
      this.fetchSentTemplates();
    },
    async fetchSentTemplates() {
      try {
        const { data } = await SentTemplatesApi.getSentTemplates({
          order: this.sentTemplatesFilters.order.toUpperCase(),
          page: this.sentTemplatesFilters.page || 1,
          take: 50,
          accountId: this.accountId,
          number: this.sentTemplatesFilters.number
            ? this.sentTemplatesFilters.number.phone_number.replace('+', '') ||
              this.sentTemplatesFilters.number?.additional_attributes?.number
            : '',
          recipient: this.sentTemplatesFilters.recipient.replace('+', ''),
          show_only_failed: this.sentTemplatesFilters.failed,
          template: true,
          template_name: this.sentTemplatesFilters.template
            ? this.sentTemplatesFilters.template.name
            : '',
          from: this.sentTemplatesFilters.from,
          to: this.sentTemplatesFilters.to,
          csv: this.sentTemplatesFilters.csv,
        });
        this.templates = data;
        this.sentTemplatesFilters.csv = false;
        this.formattingTemplates(data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAllSentTemplates() {
      try {
        const { data } = await SentTemplatesApi.getSentTemplates({
          order: this.sentTemplatesFilters.order.toUpperCase(),
          page: 1,
          take: 100000,
          accountId: this.accountId,
          number: this.sentTemplatesFilters.number
            ? this.sentTemplatesFilters.number.phone_number.replace('+', '') ||
              this.sentTemplatesFilters.number?.additional_attributes?.number
            : '',
          recipient: this.sentTemplatesFilters.recipient.replace('+', ''),
          show_only_failed: this.sentTemplatesFilters.failed,
          template: true,
          template_name: this.sentTemplatesFilters.template
            ? this.sentTemplatesFilters.template.name
            : '',
          from: this.sentTemplatesFilters.from,
          to: this.sentTemplatesFilters.to,
          csv: this.sentTemplatesFilters.csv,
        });
        this.allTemplates = data;
        this.templates = {
          data: data.data.slice(0, 50),
          meta: data.meta,
        };
        this.sentTemplatesFilters.csv = false;
        this.formattingTemplates(data.data.slice(0, 50));
        this.formattingAllTemplates(data.data);
      } catch (error) {
        console.log(error);
      }
    },
    formattingAllTemplates(templates) {
      const statusCounts = { failed: 0, sent: 0, delivered: 0, read: 0 };

      let mappedTemplates = [];

      mappedTemplates = templates.map((item) => ({
        statusCode: item.statusCode,
        statuses: item.statuses
          .map((status) => status.value)
          .filter((value) =>
            ['read', 'delivered', 'sent', 'failed'].includes(value)
          ),
      }));

      mappedTemplates.forEach((template) => {
        let finalStatus = 'failed';

        if (
          template.statuses.length > 0 &&
          !template.statuses.includes('failed')
        ) {
          finalStatus = 'sent';
          if (template.statuses.includes('read')) {
            finalStatus = 'read';
          } else if (template.statuses.includes('delivered')) {
            finalStatus = 'delivered';
          }
        }

        statusCounts[finalStatus] += 1;
      });

      this.statusCounts = statusCounts;
    },
    formattingTemplates(templates) {
      let dictionary = {};

      this.messageTemplateNames.forEach((item) => {
        dictionary[item.name] = item.title;
      });

      this.templates.data = templates.map((item) => ({
        ...item,
        templateName: dictionary[item.templateName] || item.templateName,
      }));
    },
    async fetchTemplates() {
      await this.$store.dispatch(
        'messageTemplates/getAllMessageTemplatesName',
        {
          accountId: this.accountId,
        }
      );
    },
    filterByFailed(failed) {
      this.sentTemplatesFilters.failed = failed;
      this.sentTemplatesFilters.page = 1;
      this.fetchSentTemplates();
      this.fetchAllSentTemplates();
    },
    filterByTemplate(template) {
      this.sentTemplatesFilters.template = template;
      this.sentTemplatesFilters.page = 1;
      this.fetchSentTemplates();
      this.fetchAllSentTemplates();
    },
    filterByNumber(number) {
      this.sentTemplatesFilters.number = number;
      this.sentTemplatesFilters.page = 1;
      this.fetchSentTemplates();
      this.fetchAllSentTemplates();
    },
    filterByRecipient(recipient) {
      this.sentTemplatesFilters.recipient = recipient;
      this.sentTemplatesFilters.page = 1;
      this.fetchSentTemplates();
      this.fetchAllSentTemplates();
    },
    filterByDate(date) {
      this.sentTemplatesFilters.from = date[0];
      this.sentTemplatesFilters.to = date[1];
      this.fetchSentTemplates();
      this.fetchAllSentTemplates();
    },
    clearFilters() {
      this.sentTemplatesFilters = {
        order: 'DESC',
        page: 1,
        template: null,
        number: null,
        recipient: '',
        from: moment().add(-7, 'days').toDate(),
        to: moment().toDate(),
        failed: false,
        csv: false,
      };
      this.fetchSentTemplates();
      this.fetchAllSentTemplates();
    },
    pageChange(pageIndex) {
      this.sentTemplatesFilters.page = pageIndex;
      this.fetchSentTemplates();
    },
    fetchAllData() {
      if (this.selectedFilter) {
        const { from, to, groupBy, businessHours } = this;
        this.$store.dispatch('fetchAccountSummary', {
          from,
          to,
          type: this.type,
          id: this.selectedFilter.id,
          groupBy: groupBy.period,
          businessHours,
        });
        this.fetchChartData();
      }
    },
    fetchChartData() {
      [
        'CONVERSATIONS',
        'INCOMING_MESSAGES',
        'OUTGOING_MESSAGES',
        'FIRST_RESPONSE_TIME',
        'RESOLUTION_TIME',
        'RESOLUTION_COUNT',
        'REPLY_TIME',
      ].forEach(async (key) => {
        try {
          const { from, to, groupBy, businessHours } = this;
          this.$store.dispatch('fetchAccountReport', {
            metric: REPORTS_KEYS[key],
            from,
            to,
            type: this.type,
            id: this.selectedFilter.id,
            groupBy: groupBy.period,
            businessHours,
          });
        } catch {
          this.showAlert(this.$t('REPORT.DATA_FETCHING_FAILED'));
        }
      });
    },
    async downloadReports() {
      const { from, to, type, businessHours } = this;
      const dispatchMethods = {
        agent: 'downloadAgentReports',
        label: 'downloadLabelReports',
        inbox: 'downloadInboxReports',
        team: 'downloadTeamReports',
        'sent-templates': 'downloadSentTemplatesReports',
      };
      if (dispatchMethods[type]) {
        if (type !== 'sent-templates') {
          const fileName = generateFileName({ type, to, businessHours });
          const params = { from, to, fileName, businessHours };
          await this.$store.dispatch(dispatchMethods[type], params);
        } else {
          const fileName = generateSentTemplatesFileName({
            type,
            from: this.sentTemplatesFilters.from,
            to: this.sentTemplatesFilters.to,
          });
          const { data } = await SentTemplatesApi.getSentTemplates({
            order: this.sentTemplatesFilters.order.toUpperCase(),
            page: this.sentTemplatesFilters.page || 1,
            take: 50,
            accountId: this.accountId,
            number: this.sentTemplatesFilters.number
              ? this.sentTemplatesFilters.number?.phone_number?.replace(
                  '+',
                  ''
                ) ||
                this.sentTemplatesFilters.number?.additional_attributes?.number
              : '',
            recipient: this.sentTemplatesFilters.recipient.replace('+', ''),
            show_only_failed: this.sentTemplatesFilters.failed,
            template: true,
            template_name: this.sentTemplatesFilters.template
              ? this.sentTemplatesFilters.template.name
              : '',
            from: this.sentTemplatesFilters.from,
            to: this.sentTemplatesFilters.to,
            csv: true,
          });
          const params = { fileName, data };
          await this.$store.dispatch(dispatchMethods[type], params);
        }
      }
    },
    onDateRangeChange({ from, to, groupBy }) {
      // do not track filter change on inital load
      if (this.from !== 0 && this.to !== 0) {
        this.$track(REPORTS_EVENTS.FILTER_REPORT, {
          filterType: 'date',
          reportType: this.type,
        });
      }

      this.from = from;
      this.to = to;
      this.groupByfilterItemsList = this.fetchFilterItems(groupBy);
      const filterItems = this.groupByfilterItemsList.filter(
        (item) => item.id === this.groupBy.id
      );
      if (filterItems.length > 0) {
        this.selectedGroupByFilter = filterItems[0];
      } else {
        this.selectedGroupByFilter = this.groupByfilterItemsList[0];
        this.groupBy = GROUP_BY_FILTER[this.selectedGroupByFilter.id];
      }
      this.fetchAllData();
    },
    onFilterChange(payload) {
      if (payload) {
        this.selectedFilter = payload;
        this.fetchAllData();
      }
    },
    onGroupByFilterChange(payload) {
      this.groupBy = GROUP_BY_FILTER[payload.id];
      this.fetchAllData();

      this.$track(REPORTS_EVENTS.FILTER_REPORT, {
        filterType: 'groupBy',
        filterValue: this.groupBy?.period,
        reportType: this.type,
      });
    },
    fetchFilterItems(groupBy) {
      switch (groupBy) {
        case GROUP_BY_FILTER[2].period:
          return this.$t('REPORT.GROUP_BY_WEEK_OPTIONS');
        case GROUP_BY_FILTER[3].period:
          return this.$t('REPORT.GROUP_BY_MONTH_OPTIONS');
        case GROUP_BY_FILTER[4].period:
          return this.$t('REPORT.GROUP_BY_YEAR_OPTIONS');
        default:
          return this.$t('REPORT.GROUP_BY_DAY_OPTIONS');
      }
    },
    onBusinessHoursToggle(value) {
      this.businessHours = value;
      this.fetchAllData();

      this.$track(REPORTS_EVENTS.FILTER_REPORT, {
        filterType: 'businessHours',
        filterValue: value,
        reportType: this.type,
      });
    },
    changeSelection(index) {
      this.currentSelection = index;
      this.currentSelectionKey = this.metrics[index].KEY;
      this.fetchChartData();
    },
  },
};
</script>

<style lang="scss" scoped>
.sent-templates {
  height: calc(100vh - 90px);
}
</style>
