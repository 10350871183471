<template>
  <div
    class="slide-animation-container grid grid-rows-[0fr]"
    :class="{
      'grid-rows-[0fr]': !isShown,
      'grid-rows-[1fr]': isShown,
    }"
    :style="{
      '--slide-animation-duration': `${duration}ms`,
      overflow,
    }"
  >
    <div class="min-h-0 min-w-0">
      <transition name="slide">
        <slot />
      </transition>
    </div>
  </div>
</template>

<script>
let changeOverflowTimeout;

export default {
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },

    duration: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      overflow: '',
    };
  },
  watch: {
    isShown: {
      handler(newValue) {
        clearTimeout(changeOverflowTimeout);

        changeOverflowTimeout = setTimeout(
          () => {
            this.overflow = newValue ? 'visible' : 'hidden';
          },
          newValue ? this.duration : 0
        );
      },
    },
  },
  mounted() {
    this.overflow = this.isShown ? 'visible' : 'hidden';
  },
};
</script>

<style lang="scss">
.slide-animation-container {
  transition: grid-template-rows var(--slide-animation-duration) ease-in-out;

  .slide-enter-active {
    animation: slide-in var(--slide-animation-duration) ease-in-out;
  }

  .slide-leave-active {
    animation: slide-in var(--slide-animation-duration) ease-in-out reverse;
  }

  @keyframes slide-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100;
    }
  }
}
</style>
