<template>
  <formulate-form
    v-slot="{ hasErrors }"
    class="unarchive-form relative"
    :name="formName"
    @submit="onConfirm"
  >
    <woot-modal-header
      :header-title="$t('CHATLYN_SIDEBAR.ARCHIVE_MODAL.UNARCHIVE_TITLE')"
      :header-content="
        $t('CHATLYN_SIDEBAR.ARCHIVE_MODAL.UNARCHIVE_MESSAGE', {
          ARCHIVE_TYPE: $t(
            `CHATLYN_SIDEBAR.ARCHIVE_MODAL.ARCHIVE_TYPE.${archiveType}`
          ),
        })
      "
    />

    <div v-if="isContactList" class="modal-content">
      <input-radio-group
        :label="$t(`CHATLYN_SIDEBAR.ARCHIVE_MODAL.WHERE_TO_UNARCHIVE`)"
        :items="unarchiveOptions"
        :action="handleChangeSelectedUnarchiveOption"
      >
        <template #default="{ item }">
          <div
            v-if="
              item.id !== unarchiveOptions[0].id &&
              selectedUnarchiveOption.id === unarchiveOptions[1].id
            "
            class="mt-1 w-full pl-5"
          >
            <formulate-input
              v-model="selectedFolder"
              :options="allAvailableFoldersOptions"
              :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.PLEASE_CHOOSE')"
              type="select"
              validation="required"
            />
          </div>
        </template>
      </input-radio-group>
    </div>

    <div class="flex justify-end gap-2 px-8 pb-8 pt-0">
      <woot-button
        color-scheme="secondary"
        variant="smooth"
        type="button"
        @click="onReject"
      >
        {{ $t('CHATLYN_GENERAL.BUTTON.NO') }}
      </woot-button>

      <woot-button type="submit" :is-disabled="hasErrors">
        {{ $t('CHATLYN_GENERAL.BUTTON.YES') }}
      </woot-button>
    </div>

    <chatlyn-spinner :is-visible="isLoading" />
  </formulate-form>
</template>

<script>
import { mapGetters } from 'vuex';
import ChatlynSpinner from '../../../../../shared/components/ChatlynSpinner.vue';
import InputRadioGroup from '../../settings/inbox/components/InputRadioGroup.vue';

export default {
  name: 'UnarchiveList',
  components: { InputRadioGroup, ChatlynSpinner },
  props: {
    archiveType: {
      type: String,
      default: '',
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formName: 'unarchive-form',
      //
      selectedUnarchiveOption: {},
      selectedFolder: '',
    };
  },
  computed: {
    ...mapGetters({
      allAvailableFolders: 'folders/getAllAvailableFolders',
    }),

    isContactList() {
      return this.archiveType === 'CONTACT_LIST';
    },

    allAvailableFoldersOptions() {
      return this.allAvailableFolders.map((folder) => ({
        label: folder.name,
        // eslint-disable-next-line no-underscore-dangle
        value: folder._id,
      }));
    },

    unarchiveOptions() {
      return [
        {
          id: 'no-folder',
          title: this.$t(`CHATLYN_SIDEBAR.ARCHIVE_MODAL.NO_FOLDER`),
          checked: true,
          disabled: false,
        },
        {
          id: 'to-folder',
          title: this.$t(`CHATLYN_SIDEBAR.ARCHIVE_MODAL.CHOOSE_FOLDER`),
          checked: false,
          disabled: !this.allAvailableFolders?.length,
        },
      ];
    },
  },
  methods: {
    onReject() {
      this.$emit('on-reject');
    },

    async onConfirm() {
      const form = this.$formulate.registry.get(this.formName);

      if (!form) {
        return;
      }

      const hasErrors = await form.hasValidationErrors();

      if (hasErrors) {
        return;
      }

      this.$emit('on-confirm', this.selectedFolder);
    },

    handleChangeSelectedUnarchiveOption(newValue) {
      this.selectedFolder = '';
      this.selectedUnarchiveOption = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.unarchive-form {
  .modal-content {
    ::v-deep {
      .radio-group {
        .radio-group__items {
          @apply flex flex-col gap-2;
        }
      }
    }
  }
}
</style>
