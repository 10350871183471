const CH_I18N_TO_DEEPL_LANGUAGES_KEYS = {
  en: 'en-US',
  pt: 'pt-PT',
  pt_PT: 'pt-PT',
};

const insertSpacesBetweenEmoji = (text) => {
  return text
    .split(/([\uD800-\uDBFF][\uDC00-\uDFFF])/)
    .map((value) => {
      const newValue = value.trim();

      if (/\P{Emoji}/u.test(newValue) && JSON.stringify(newValue) !== '"‍"') {
        return ` ${newValue} `;
      }

      return newValue;
    })
    .join('');
};

export { CH_I18N_TO_DEEPL_LANGUAGES_KEYS, insertSpacesBetweenEmoji };
