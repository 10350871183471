<!-- eslint-disable vue/no-mutating-props -->
<template>
  <woot-modal :show.sync="show" :on-close="onClose" size="lg">
    <woot-modal-header :header-title="$t('WHATSAPP_TEMPLATES.MODAL.TITLE')" />
    <div class="modal-content">
      <whatsapp-templates
        class="w-full"
        :contact-preferred-language="contactPreferredLanguage"
        :inbox-id="inboxId"
        @on-send="onSendMessage"
        @cancel="onClose"
      />
    </div>
  </woot-modal>
</template>

<script>
import WhatsappTemplates from '../../../../routes/dashboard/conversation/contact/WhatsappTemplates.vue';

export default {
  components: {
    WhatsappTemplates,
  },
  props: {
    inboxId: {
      type: Number,
      default: undefined,
    },
    show: {
      type: Boolean,
      default: true,
    },
    contactPreferredLanguage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedWaTemplate: null,
    };
  },
  computed: {
    modalHeaderContent() {
      return this.selectedWaTemplate
        ? this.$t(
            'CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.TEMPLATE_SELECTED_SUBTITLE',
            {
              templateName: this.selectedWaTemplate.name,
            }
          )
        : this.$t('WHATSAPP_TEMPLATES.MODAL.SUBTITLE');
    },
  },
  methods: {
    onSendMessage(
      language,
      newLinks,
      templateId,
      phoneNumber,
      date = new Date()
    ) {
      this.$emit('on-send', language, newLinks, templateId, phoneNumber, date);
    },
    onClose() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped>
.modal-content {
  padding: 1.5625rem 2rem;
}
</style>
