<template>
  <formulate-form
    v-slot="{ hasErrors }"
    class="opt-out-form"
    name="opt-out-form"
    @submit="handleOptOutFormSubmit"
  >
    <settings-section
      :title="$t('CHATLYN_OPT_OUT.PAGE_TITLE')"
      :sub-title="$t('CHATLYN_OPT_OUT.PAGE_DESC')"
    >
      <div v-if="newInboxOutOut" class="flex flex-col flex-nowrap gap-4">
        <label class="flex items-center gap-2">
          <input
            id="opt-out-form-switch"
            v-model="newInboxOutOut.optOut"
            type="checkbox"
            class="opt-out-form__switch"
          />
          {{ $t('CHATLYN_OPT_OUT.SWITCH_LABEL') }}
        </label>
        <div
          v-dompurify-html="$t('CHATLYN_OPT_OUT.PAGE_HELP_TEXT')"
          class="text-muted text-xs"
        />

        <template v-if="newInboxOutOut.optOut">
          <div class="flex flex-row flex-nowrap gap-4">
            <multiselect-ch
              v-model="optOutTemplate"
              :options="consentMessageTemplatesSearchOptions"
              :multiple="false"
              label="title"
              :max-height="140"
            >
              <template #title>
                <label class="opt-out-field-label">
                  {{ $t('CHATLYN_OPT_OUT.OPT_OUT_SELECT_LABEL') }}

                  <fluent-icon
                    v-tooltip.right-start="
                      $t('CHATLYN_OPT_OUT.OPT_OUT_SELECT_TOOLTIP')
                    "
                    icon="info"
                    size="14"
                    class="info-icon my-auto"
                  />
                </label>
              </template>
            </multiselect-ch>

            <!-- Invisible -->
            <formulate-input
              v-model="newInboxOutOut.optOutTemplate"
              class="hidden w-full"
              type="select"
              validation="required"
              :label="$t('CHATLYN_OPT_OUT.OPT_OUT_SELECT_LABEL')"
              :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.PLEASE_CHOOSE')"
              :options="consentMessageTemplatesOptions"
            >
              <template #label="{ label, id }">
                <label class="opt-out-field-label" :for="id">
                  {{ label }}

                  <fluent-icon
                    v-tooltip.right-start="
                      $t('CHATLYN_OPT_OUT.OPT_OUT_SELECT_TOOLTIP')
                    "
                    icon="info"
                    size="14"
                    class="info-icon my-auto"
                  />
                </label>
              </template>
            </formulate-input>

            <div class="opt-out-interval-container">
              <formulate-input
                v-model="newInboxOutOut.days"
                type="number"
                validation="required"
                :label="$t('CHATLYN_OPT_OUT.INTERVAL_LABEL')"
                :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.PLEASE_CHOOSE')"
                :min="0"
              >
                <template #label="{ label, id }">
                  <label class="opt-out-field-label" :for="id">
                    {{ label }}

                    <fluent-icon
                      v-tooltip.right-start="
                        $t('CHATLYN_OPT_OUT.INTERVAL_TOOLTIP')
                      "
                      icon="info"
                      size="14"
                      class="info-icon my-auto"
                    />
                  </label>
                </template>

                <template #prefix>
                  <span class="opt-out-interval__prefix">
                    <fluent-icon
                      icon="hourglass-half"
                      size="14"
                      class="info-icon my-auto"
                    />
                  </span>
                </template>

                <template #suffix>
                  <span class="opt-out-interval__suffix">
                    {{ $t('CHATLYN_OPT_OUT.DAYS') }}
                  </span>
                </template>
              </formulate-input>
            </div>
          </div>

          <div>
            <multiselect-ch
              v-model="goodByeTemplate"
              class="opt-out-goodbye-template"
              :options="consentMessageTemplatesSearchOptions"
              :multiple="false"
              label="title"
              :max-height="140"
            >
              <template #title>
                <label class="opt-out-field-label">
                  {{ $t('CHATLYN_OPT_OUT.GOODBYE_SELECT_LABEL') }}

                  <fluent-icon
                    v-tooltip.right-start="
                      $t('CHATLYN_OPT_OUT.GOODBYE_SELECT_TOOLTIP')
                    "
                    icon="info"
                    size="14"
                    class="info-icon my-auto"
                  />

                  <span class="opt-out-field-label--optional">
                    {{ $t('CHATLYN_GENERAL.LABEL.OPTIONAL') }}
                  </span>
                </label>
              </template>

              <template #help>
                <div
                  class="opt-out-goodbye-template__help text-muted my-1 text-xs"
                >
                  {{ $t('CHATLYN_OPT_OUT.GOODBY_SELECT_HELP_TEXT') }}
                </div>
              </template>
            </multiselect-ch>

            <!-- Invisible -->
            <formulate-input
              v-model="newInboxOutOut.goodByeTemplate"
              class="hidden"
              type="select"
              :label="$t('CHATLYN_OPT_OUT.GOODBYE_SELECT_LABEL')"
              :help="$t('CHATLYN_OPT_OUT.GOODBY_SELECT_HELP_TEXT')"
              :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.PLEASE_CHOOSE')"
              :options="consentMessageTemplatesOptions"
            >
              <template #label="{ label, id }">
                <label class="opt-out-field-label" :for="id">
                  {{ label }}

                  <fluent-icon
                    v-tooltip.right-start="
                      $t('CHATLYN_OPT_OUT.INTERVAL_TOOLTIP')
                    "
                    icon="info"
                    size="14"
                    class="info-icon my-auto"
                  />

                  <span class="opt-out-field-label--optional">
                    {{ $t('CHATLYN_GENERAL.LABEL.OPTIONAL') }}
                  </span>
                </label>
              </template>
            </formulate-input>
          </div>
        </template>

        <woot-button
          style="width: max-content; margintop: 1rem"
          :disabled="hasErrors"
        >
          {{ $t('CHATLYN_OPT_OUT.SUBMIT') }}
        </woot-button>
      </div>
    </settings-section>

    <div v-if="isLoading" class="absolute left-0 top-0 h-full w-full">
      <div class="relative left-0 top-0 flex h-full w-full">
        <div
          class="absolute left-0 top-0 h-full w-full rounded-lg bg-gray-200 opacity-25 dark:bg-gray-900"
        />
        <span
          class="spinner -translate-x-1/2 -translate-y-1/2 transform"
          :style="{
            left: '50%',
            top: '50%',
          }"
        />
      </div>
    </div>
  </formulate-form>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import SettingsSection from '../../../../../components/SettingsSection.vue';
import MultiselectCh from 'shared/components/ui/Multiselect.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'OptOutPage',
  components: { SettingsSection, MultiselectCh },
  mixins: [alertMixin],
  props: {
    inboxId: {
      type: Number,
      required: true,
    },

    phoneNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newInboxOutOut: null,
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      messageTemplates: 'messageTemplates/getApprovedMessageTemplates',
      inboxOutOut: 'management/getInboxOutOut',
      uiFlags: 'management/getUiFlags',
    }),

    consentMessageTemplates() {
      return this.messageTemplates.filter(
        (messageTemplate) =>
          messageTemplate.category === 'CONSENT' &&
          messageTemplate.phoneNumber === this.phoneNumber.replace('+', '')
      );
    },

    optOutTemplate: {
      get() {
        return this.consentMessageTemplatesSearchOptions.find(
          (searchOption) =>
            searchOption.name === this.newInboxOutOut?.optOutTemplate
        );
      },

      set(newValue) {
        this.newInboxOutOut.optOutTemplate = newValue.name;
      },
    },

    goodByeTemplate: {
      get() {
        return this.consentMessageTemplatesSearchOptions.find(
          (searchOption) =>
            searchOption.name === this.newInboxOutOut?.goodByeTemplate
        );
      },

      set(newValue) {
        this.newInboxOutOut.goodByeTemplate = newValue.name;
      },
    },

    consentMessageTemplatesOptions() {
      return [
        { label: '', value: '' },
        ...this.consentMessageTemplates.map((messageTemplate) => ({
          label: messageTemplate.title,
          value: messageTemplate.name,
          disabled: [
            this.newInboxOutOut?.optOutTemplate,
            this.newInboxOutOut?.goodByeTemplate,
          ].includes(messageTemplate.name),
        })),
      ];
    },

    consentMessageTemplatesSearchOptions() {
      return [
        { title: '', name: '' },
        ...this.consentMessageTemplates.map((messageTemplate) => ({
          title: messageTemplate.title,
          name: messageTemplate.name,
          $isDisabled: [
            this.newInboxOutOut?.optOutTemplate,
            this.newInboxOutOut?.goodByeTemplate,
          ].includes(messageTemplate.name),
        })),
      ];
    },

    isFromHasErrors() {
      const component = this.$formulate.registry.get('opt-out-form');

      return component ? component.hasErrors : true;
    },

    isLoading() {
      return this.uiFlags.isFetching;
    },
  },
  watch: {
    inboxOutOut: {
      handler(newValue) {
        if (!newValue) {
          this.newInboxOutOut = {
            optOut: false,
            days: 7,
            optOutTemplate: '',
            goodByeTemplate: '',
          };

          return;
        }

        this.newInboxOutOut = JSON.parse(JSON.stringify(newValue));
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.initialSetup();
  },
  methods: {
    async fetchInboxOutOut() {
      await this.$store.dispatch('management/getInboxOutOut', {
        id: this.inboxId,
      });
    },

    async fetchTemplates() {
      await this.$store.dispatch('messageTemplates/getAllMessageTemplates', {
        accountId: this.currentAccountId,
      });
    },

    async initialSetup() {
      await this.fetchInboxOutOut();

      await this.fetchTemplates();
    },

    toggleShowOptOut() {
      this.newInboxOutOut.optOut = !this.newInboxOutOut.optOut;
    },

    async handleOptOutFormSubmit() {
      if (this.isFromHasErrors) {
        return;
      }

      const result = await this.$store.dispatch(
        'management/updateInboxOptOut',
        {
          inboxOptOut: {
            id: this.inboxId,
            ...this.newInboxOutOut,
          },
        }
      );

      this.showAlert(
        result
          ? this.$t('CHATLYN_OPT_OUT.SUCCESS_MESSAGE')
          : this.$t('CHATLYN_OPT_OUT.ERROR_MESSAGE')
      );
    },
  },
};
</script>

<style lang="scss">
.opt-out-form {
  @apply relative;

  .opt-out-interval-container {
    .formulate-input-element {
      @apply flex flex-row flex-nowrap;

      .opt-out-interval__suffix,
      .opt-out-interval__prefix {
        @apply flex items-center px-2 text-gray-600 transition-all duration-200 dark:text-gray-400;
      }

      .opt-out-interval__prefix {
        @apply rounded-l border-b border-l border-t border-gray-200 bg-gray-100 dark:border-gray-700 dark:bg-gray-800;
      }

      input {
        @apply rounded-none;
      }

      .opt-out-interval__suffix {
        @apply rounded-r border-b border-r border-t border-solid border-gray-200 bg-gray-100 text-sm dark:border-gray-700 dark:bg-gray-800;
      }

      &:focus-within {
        & > * {
          border-color: #79b292;
        }
      }
    }
  }

  .opt-out-field-label {
    @apply flex flex-row flex-nowrap gap-2;

    .opt-out-field-label--optional {
      @apply text-gray-500;
    }
  }
}
</style>
