<template>
  <div
    class="message-text__wrap"
    :class="{
      ...attachmentTypeClasses,
      '!cursor-default !pb-6': isVideo,
    }"
  >
    <img
      v-if="isImage && !isImageError"
      :src="attachment.data_url"
      @click="onClick"
      @error="onImgError"
    />

    <media-player
      v-if="isVideo"
      :src="attachment.data_url"
      :type="CHATLYN_MEDIA_PLAYER_TYPE.VIDEO"
      @error="onImgError"
    />

    <media-player
      v-else-if="isAudio"
      class="mb-2"
      :src="`${attachment.data_url}?t=${Date.now()}`"
      @error="onImgError"
    />

    <gallery-view
      v-if="show"
      :show.sync="show"
      :attachment="attachment"
      :all-attachments="filteredCurrentChatAttachments"
      @error="onImgError"
      @close="onClose"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { hasPressedCommand } from 'shared/helpers/KeyboardHelpers';
import { CHATLYN_MEDIA_PLAYER_TYPE } from '../../../ui/MediaPlayer/constants';

import GalleryView from '../components/GalleryView.vue';
import MediaPlayer from '../../../ui/MediaPlayer/MediaPlayer.vue';

const ALLOWED_FILE_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
};

export default {
  components: {
    GalleryView,
    MediaPlayer,
  },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      CHATLYN_MEDIA_PLAYER_TYPE,
      show: false,
      isImageError: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChatAttachments: 'getSelectedChatAttachments',
    }),
    isImage() {
      return this.attachment.file_type === ALLOWED_FILE_TYPES.IMAGE;
    },
    isVideo() {
      return this.attachment.file_type === ALLOWED_FILE_TYPES.VIDEO;
    },
    isAudio() {
      return this.attachment.file_type === ALLOWED_FILE_TYPES.AUDIO;
    },
    attachmentTypeClasses() {
      return {
        image: this.isImage,
        video: this.isVideo,
      };
    },
    filteredCurrentChatAttachments() {
      const attachments = this.currentChatAttachments.filter((attachment) =>
        ['image', 'video', 'audio'].includes(attachment.file_type)
      );
      return attachments;
    },
  },
  watch: {
    attachment() {
      this.isImageError = false;
    },
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick(e) {
      if (hasPressedCommand(e)) {
        window.open(this.attachment.data_url, '_blank');
        return;
      }
      this.show = true;
    },
    onImgError() {
      this.isImageError = true;
      this.$emit('error');
    },
  },
};
</script>
