<template>
  <div class="footer-wrap">
    <custom-button
      v-if="config.isDefaultScreen"
      class="start-conversation"
      :style="{ background: config.color }"
    >
      {{
        $t('INBOX_MGMT.WIDGET_BUILDER.FOOTER.START_CONVERSATION_BUTTON_TEXT')
      }}
    </custom-button>
    <div v-else class="chat-message-input is-focused">
      <resizable-text-area
        id="chat-input"
        :rows="1"
        :placeholder="
          $t('INBOX_MGMT.WIDGET_BUILDER.FOOTER.CHAT_INPUT_PLACEHOLDER')
        "
        class="user-message-input is-focused"
      />
      <div class="button-wrap">
        <fluent-icon icon="emoji" />
        <fluent-icon class="icon-send" icon="send" />
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from 'dashboard/components/buttons/Button.vue';
import ResizableTextArea from 'shared/components/ResizableTextArea.vue';
export default {
  name: 'WidgetFooter',
  components: {
    CustomButton,
    ResizableTextArea,
  },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="scss">
.footer-wrap {
  @apply relative flex w-full flex-col px-4;

  .start-conversation {
    @apply rounded text-sm;
    justify-content: center;
  }

  .chat-message-input {
    @apply flex items-center rounded border bg-white px-3 pb-0 pt-0 text-sm;

    &::v-deep textarea {
      @apply text-gray-800 placeholder-gray-400;
    }

    &.is-focused {
      box-shadow:
        0 0 0 1px var(--gray-500),
        0 0 2px 2px var(--gray-100);
    }
  }

  .button-wrap {
    display: flex;
    align-items: center;
  }

  .user-message-input {
    border: 0;
    height: var(--space-medium);
    min-height: var(--space-medium);
    max-height: var(--space-giga);
    line-height: 1;
    font-size: var(--font-size-small);
    resize: none;
    padding: var(--space-zero);
    padding-top: var(--space-smaller);
    padding-bottom: var(--space-smaller);
    margin-top: var(--space-small);
    margin-bottom: var(--space-small);
    flex: 1;

    ::v-deep {
      textarea {
        outline: none;
        padding: 0;
        display: flex;
        width: 100% !important;
      }
    }
  }

  .icon-send {
    margin-left: var(--space-one);
  }
}
</style>
