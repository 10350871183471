<template>
  <transition name="network-notification-fade" tag="div">
    <div v-show="showNotification" class="right-4 top-4 absolute z-50">
      <div class="bg-yellow-100 text-yellow-600 p-4 border-4 border-white gap-2 flex justify-between items-center rounded shadow-xl">
        <fluent-icon icon="wifi-off" />
        <div class="text-sm mx-2">
          {{
            useInstallationName(
              $t('NETWORK.NOTIFICATION.TEXT'),
              globalConfig.installationName
            )
          }}
        </div>
        <woot-button color-scheme="warning" size="small" @click="refreshPage">
          {{ $t('NETWORK.BUTTON.REFRESH') }}
        </woot-button>
        <woot-button
          variant="smooth"
          size="small"
          color-scheme="warning"
          icon="dismiss-circle"
          @click="closeNotification"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import { mapGetters } from 'vuex';
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  mixins: [globalConfigMixin],

  data() {
    return {
      showNotification: !navigator.onLine,
    };
  },

  computed: {
    ...mapGetters({ globalConfig: 'globalConfig/get' }),
  },

  mounted() {
    window.addEventListener('offline', this.updateOnlineStatus);
    window.bus.$on(BUS_EVENTS.WEBSOCKET_DISCONNECT, () => {
      this.updateOnlineStatus({ type: 'offline' });
    });
  },

  beforeDestroy() {
    window.removeEventListener('offline', this.updateOnlineStatus);
  },

  methods: {
    refreshPage() {
      window.location.reload();
    },

    closeNotification() {
      this.showNotification = false;
    },

    updateOnlineStatus(event) {
      if (event.type === 'offline') {
        this.showNotification = true;
      }
    },
  },
};
</script>
