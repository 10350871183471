<template>
  <div class="relative">
    <div class="flex flex-row gap-1 border-b border-transparent px-4 pb-1 pt-3">
      <woot-sidemenu-icon
        size="tiny"
        class="relative top-0 ltr:-ml-1.5 rtl:-mr-1.5"
      />
      <router-link
        :to="searchUrl"
        class="search-link inline-flex h-6 flex-1 items-center gap-1 rounded-md bg-gray-50 px-2 py-0 text-left text-gray-500 rtl:mr-3 rtl:text-right dark:bg-gray-800 dark:text-gray-200"
      >
        <div class="flex">
          <fluent-icon icon="search" class="search--icon" size="16" />
        </div>
        <p
          class="search--label mb-0 overflow-hidden text-ellipsis whitespace-nowrap text-sm"
        >
          {{ $t('CONVERSATION.SEARCH_MESSAGES') }}
        </p>
      </router-link>
      <switch-layout
        :is-on-expanded-layout="isOnExpandedLayout"
        @toggle="$emit('toggle-conversation-layout')"
      />
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';
import timeMixin from '../../../../mixins/time';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import SwitchLayout from './SwitchLayout.vue';
import { frontendURL } from 'dashboard/helper/URLHelper';
export default {
  components: {
    SwitchLayout,
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  mixins: [timeMixin, messageFormatterMixin, clickaway],
  props: {
    isOnExpandedLayout: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    searchUrl() {
      return frontendURL(`accounts/${this.accountId}/search`);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-link {
  &:hover {
    .search--icon,
    .search--label {
      @apply hover:text-woot-500 dark:hover:text-woot-500;
    }
  }
}
</style>
