<template>
  <div
    class="message-template-links-container"
    :style="isOnlyHeaderLinks ? 'border: none; padding: 0;' : ''"
  >
    <div
      v-for="(link, index) in languageLinks.links"
      :key="link.id"
      class="flex flex-col flex-nowrap gap-2"
      :class="{
        'rounded-md bg-gray-50 p-2 dark:bg-gray-700/30':
          link.linkType === 'buttons',
      }"
    >
      <div
        v-if="link.linkType === 'buttons'"
        class="text-xs text-gray-600 dark:text-gray-400"
      >
        {{ urlButtonTextByLink(link) }}
      </div>

      <div class="message-template-link-container">
        <span class="message-template-link-number">
          <fluent-icon
            v-if="link.linkType === 'header'"
            icon="image"
            :size="20"
          />

          <template v-else-if="link.linkType === 'body'">
            {{ TAGS[isHasHeaderLink ? index - 1 || 0 : index] }}
          </template>

          <fluent-icon v-else icon="flash-on" :size="20" />
        </span>

        <div
          v-if="link.linkType === 'header'"
          class="message-template-link-header-uploader overflow-hidden"
        >
          <drag-drop-file-upload
            v-if="link.valueType === 'image'"
            class="w-full flex-1 px-4"
            is-small
            :label="$t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.UPLOAD_FILE')"
            :value="link.value ? { name: link.value } : null"
            :accept="ALLOWED_IMAGE_TYPES"
            :extensions="ALLOWED_IMAGE_EXTENSIONS"
            @input="(file) => changeSelectedLinkFileLink(link, file)"
          />

          <drag-drop-file-upload
            v-else
            class="w-full flex-1 px-4"
            is-small
            is-video
            :label="$t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.UPLOAD_FILE')"
            :value="link.value ? { name: link.value } : null"
            :accept="ALLOWED_VIDEO_TYPES"
            @input="(file) => changeSelectedLinkFileLink(link, file)"
          />

          <div
            v-if="isUploading"
            class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
            style="background: rgba(0, 0, 0, 0.05)"
          >
            <span class="spinner h-8 w-8" />
          </div>
        </div>

        <template v-else>
          <label class="message-template-link-example">
            <formulate-input
              v-model="link.valueType"
              class="h-full w-full"
              type="select"
              :options="linksValueTypeOptions"
              @change="changeSelectedLinksValueType(link.linkType, link.number)"
            />
          </label>

          <span class="message-template-link-link">
            <fluent-icon icon="link" size="16" />
          </span>

          <label class="message-template-link-attribute">
            <template v-if="linksValueTypeOptions[0].value === link.valueType">
              <formulate-input
                v-model="link.value"
                class="custom-formulate-error-tooltip tooltip__top h-full"
                type="select"
                validation="required"
                :placeholder="
                  $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SELECT_A_VALUE')
                "
                :options="attributesOptions"
                @change="handleSyncLinkValue(link)"
              />
            </template>

            <formulate-input
              v-else
              v-model="link.value"
              class="custom-formulate-error-tooltip tooltip__top h-full"
              type="text"
              validation="required"
              :placeholder="
                $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.ENTER_A_VALUE')
              "
              @change="handleSyncLinkValue(link)"
            />
          </label>
        </template>
      </div>

      <div
        v-if="
          linksValueTypeOptions[0].value === link.valueType &&
          getSelectedAttributeOption(link) &&
          getSelectedAttributeOption(link).type === 'date'
        "
        class="message-template-link-container"
      >
        <span class="message-template-link-link">
          <fluent-icon icon="paint-brush" />
        </span>

        <formulate-input
          v-model="link.format"
          class="formulate-input--full-width custom-formulate-error-tooltip tooltip__top h-full"
          type="select"
          validation="required"
          :validation-messages="{
            required: $t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
          }"
          :placeholder="
            $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SELECT_A_VALUE')
          "
          :options="dateFormatOptions"
          @change="handleSyncLinkFormat(link)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DragDropFileUpload from '../../../../components/ui/DragDropFileUpload.vue';
import { TAGS } from 'dashboard/constants/common';
import attributeMixin from 'dashboard/mixins/attributeMixin';
import alertMixin from 'shared/mixins/alertMixin';
import FileUpload from '../../../../api/fileUpload';
import getUuid from 'widget/helpers/uuid';
import { DATE_FORMAT, TIME_FORMAT } from '../constants';

const MAX_IMAGE_SIZE = 5; // mb
const MAX_VIDEO_SIZE = 16; // mb
const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png'];
const ALLOWED_VIDEO_TYPES = ['video/mp4', 'video/3gpp'];
const ALLOWED_IMAGE_EXTENSIONS = ['png', 'jpeg'];

export default {
  name: 'LinksBeforeSendEditor',
  components: { DragDropFileUpload },
  mixins: [alertMixin, attributeMixin],
  props: {
    languageLinks: {
      type: Object,
      default: () => ({}),
    },

    newLinks: {
      type: Array,
      default: () => [],
    },

    buttonsComponent: {
      type: Object,
      default: () => ({}),
    },

    isTranslationsAttributesSynced: {
      type: Boolean,
      default: false,
    },

    currentLanguage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      TAGS,
      MAX_IMAGE_SIZE,
      MAX_VIDEO_SIZE,
      ALLOWED_VIDEO_TYPES,
      ALLOWED_IMAGE_TYPES,
      ALLOWED_IMAGE_EXTENSIONS,
      attributeType: 'contact_attribute',
      isUploading: false,
    };
  },
  computed: {
    attributesOptions() {
      return this.getAttributesList({
        filterByKey: 'type',
        excludedValues: ['checkbox'],
      });
    },

    dateFormatOptions() {
      const options = [];

      options.push({
        label: this.$t('CHATLYN_MESSAGE_TEMPLATE.DATE_FORMAT.DATE'),
        value: '',
        disabled: true,
      });

      DATE_FORMAT.forEach((dateFormat) => {
        options.push({
          label: dateFormat,
          value: dateFormat,
        });
      });

      options.push({
        label: this.$t('CHATLYN_MESSAGE_TEMPLATE.DATE_FORMAT.TIME'),
        value: '',
        disabled: true,
      });

      TIME_FORMAT.forEach((timeFormat) => {
        options.push({
          label: timeFormat,
          value: timeFormat,
        });
      });

      return options;
    },

    linksValueTypeOptions() {
      return ['ATTRIBUTES', 'STATIC_TEXT'].map((key) => ({
        label: this.$t(`CHATLYN_MESSAGE_TEMPLATE.ATTRIBUTES.${key}`),
        value: key.toLowerCase(),
      }));
    },

    isHasHeaderLink() {
      return this.languageLinks?.links?.some(
        (link) => link.linkType === 'header'
      );
    },

    isOnlyHeaderLinks() {
      return this.languageLinks?.links?.every(
        (link) => link.linkType === 'header'
      );
    },

    urlButtons() {
      return (
        this.buttonsComponent?.buttons?.filter(
          (button) => button.type === 'URL'
        ) || []
      );
    },
  },
  mounted() {
    this.$store.dispatch('attributes/get');
  },
  methods: {
    formatUrl(url) {
      return url?.replace(/{{([1-9]{1}|10)}}/gm, '') || '';
    },

    urlButtonTextByLink(link) {
      try {
        const button = this.urlButtons[link.number - 1];

        return `“${button.text}”: ${this.formatUrl(button.url)}`;
      } catch (error) {
        return '';
      }
    },

    changeSelectedLinksValueType(linkType, number) {
      let oldLinks = structuredClone(
        this.isTranslationsAttributesSynced ? this.newLinks : this.languageLinks
      );

      if (this.isTranslationsAttributesSynced) {
        const currentLink = this.languageLinks.links.find(
          (link) => link.linkType === linkType && link.number === number
        );

        oldLinks = oldLinks.map((oldLink) => {
          oldLink.links = oldLink.links.map((link) => {
            if (link.linkType === linkType && link.number === number) {
              return { ...link, ...currentLink, value: '', id: getUuid() };
            }

            return link;
          });

          return oldLink;
        });

        this.$emit('change-new-links', oldLinks);
      } else {
        oldLinks.links = oldLinks.links.map((link) => {
          if (link.linkType === linkType && link.number === number) {
            return { ...link, value: '', id: getUuid() };
          }

          return link;
        });

        this.$emit('change-language-new-links', oldLinks);
      }
    },

    async uploadFile(file) {
      try {
        this.isUploading = true;
        return await FileUpload.uploadFile(file);
      } catch (error) {
        //
        return null;
      } finally {
        this.isUploading = false;
      }
    },

    async changeSelectedLinkFileLink(link, newValue) {
      if (!link || !link.valueType) {
        return;
      }

      if (!['string', 'object'].includes(typeof newValue)) {
        return;
      }

      if (typeof newValue === 'object' && !newValue.name) {
        return;
      }

      let response;

      switch (link.valueType) {
        case 'image':
          if ((newValue.size / 1024 / 1024).toFixed(4) > this.MAX_IMAGE_SIZE) {
            this.showAlert(
              this.$t(
                'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.ERROR.IMAGE_MAX_SIZE',
                {
                  MAX_SIZE: `${this.MAX_IMAGE_SIZE} mb`,
                }
              )
            );
            break;
          }

          // eslint-disable-next-line no-case-declarations
          response = await this.uploadFile(newValue);

          break;

        case 'video':
          if ((newValue.size / 1024 / 1024).toFixed(4) > this.MAX_VIDEO_SIZE) {
            this.showAlert(
              this.$t(
                'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.ERROR.VIDEO_MAX_SIZE',
                {
                  MAX_SIZE: `${this.MAX_VIDEO_SIZE} mb`,
                }
              )
            );
            break;
          }

          if (!this.ALLOWED_VIDEO_TYPES.includes(newValue.type)) {
            this.showAlert(
              this.$t(
                'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.ERROR.VIDEO_TYPE',
                {
                  FILE_TYPES: this.ALLOWED_VIDEO_TYPES.join(', '),
                }
              )
            );
            break;
          }

          response = await this.uploadFile(newValue);
          break;

        default:
          break;
      }

      if (!response?.data?.fileUrl) {
        return;
      }

      link.value = response.data ? response.data.fileUrl : null;

      if (this.isTranslationsAttributesSynced) {
        let oldLinks = JSON.parse(JSON.stringify(this.newLinks));

        oldLinks = oldLinks.map((oldLink) => {
          oldLink.links = oldLink.links.map((newLink) => {
            if (
              newLink.linkType === link.linkType &&
              newLink.number === link.number &&
              newLink.valueType === link.valueType &&
              newLink.id !== link.id
            ) {
              return { ...newLink, ...link, id: getUuid() };
            }

            return newLink;
          });

          return oldLink;
        });

        this.$emit('change-new-links', oldLinks);
      }
    },

    getSelectedAttributeOption(link) {
      const selectedAttribute = this.attributesOptions.find(
        (attribute) => attribute.value === link.value
      );

      if (selectedAttribute?.type !== 'date') {
        delete link.format;
      }

      return selectedAttribute;
    },

    handleSyncLinkValue(currentLink) {
      if (!this.isTranslationsAttributesSynced || !currentLink) {
        return;
      }

      this.$nextTick(() => {
        this.handleSyncAttributes(JSON.parse(JSON.stringify(currentLink)), [
          'value',
          'valueType',
        ]);
      });
    },

    handleSyncLinkFormat(currentLink) {
      if (!this.isTranslationsAttributesSynced && !currentLink) {
        return;
      }

      this.$nextTick(() => {
        this.handleSyncAttributes(currentLink, [
          'value',
          'valueType',
          'format',
        ]);
      });
    },

    handleSyncAttributes(currentLink, keys) {
      let oldLinks = JSON.parse(JSON.stringify(this.newLinks));

      oldLinks = oldLinks.map((oldLink) => {
        oldLink.links = oldLink.links.map((link) => {
          if (
            currentLink.linkType === link.linkType &&
            currentLink.number === link.number
          ) {
            keys.forEach((key) => {
              link[key] = currentLink[key];
            });
          }

          return link;
        });

        return oldLink;
      });

      this.$emit('change-new-links', oldLinks);
    },
  },
};
</script>

<style lang="scss">
.message-template-links-container {
  @apply flex flex-col flex-nowrap gap-4;

  .message-template-links-message {
    @apply flex flex-row items-center gap-4 bg-yellow-50 px-4 text-yellow-700;

    span:first-child {
      @apply flex-nowrap;
    }
  }

  .message-template-link-container {
    @apply flex flex-nowrap items-center rounded border transition-all duration-200 dark:border-gray-700;

    & > *:first-child {
      @apply rounded-s;
    }

    & > *:last-child {
      &,
      input,
      select {
        @apply rounded-e;
      }
    }

    &:hover {
      @apply border-gray-300 dark:border-gray-600;
    }

    &:focus-within {
      @apply border-gray-400 dark:border-gray-500;
    }

    input,
    select {
      @apply m-0 rounded-none border-none;
    }

    .message-template-link-example {
      @apply w-full self-stretch;
      max-width: 33%;
    }

    & > * {
      @apply flex items-center self-stretch;
    }

    .message-template-link-number,
    .message-template-link-link {
      @apply flex aspect-square h-10 items-center justify-center self-stretch bg-gray-100 text-xl leading-none text-gray-500 dark:bg-gray-700 dark:text-gray-200;
    }

    .message-template-link-header-uploader {
      @apply relative;

      .chatlyn-drag-drop-file-upload {
        @apply border-none;
      }
    }

    .message-template-link-attribute {
      &,
      & > * {
        @apply w-full;
      }
    }

    .message-template-link-example,
    .message-template-link-attribute {
      @apply h-full;
    }
  }
}
</style>
