const CHATLYN_POPOVER_PLACEMENT = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  // ----------------------- //
  TOP_START: 'TOP_START',
  BOTTOM_START: 'BOTTOM_STAR',
  LEFT_START: 'LEFT_START',
  RIGHT_START: 'RIGHT_START',
  // ----------------------- //
  TOP_END: 'TOP_END',
  BOTTOM_END: 'BOTTOM_END',
  LEFT_END: 'LEFT_END',
  RIGHT_END: 'RIGHT_END',
};

export { CHATLYN_POPOVER_PLACEMENT };
