<template>
  <div class="my-2 mx-8 text-base">
    <settings-section
      :title="$t('INBOX_MGMT.TABS.PRE_CHAT_FORM')"
      :sub-title="$t('INBOX_MGMT.PRE_CHAT_FORM.DESCRIPTION')"
    >
        <label class="toggle-input-wrap flex items-center gap-2 dark:text-gray-100">
          <input
          v-model="preChatFormEnabled"
            type="checkbox"
            name="toggle-business-hours"
          />
          {{ $t('INBOX_MGMT.PRE_CHAT_FORM.ENABLE.LABEL') }}
        </label>

        <form class="flex flex-col" @submit.prevent="updateInbox">
          <div v-if="preChatFormEnabled">
            <div class="mt-4">
              <label class="block mb-1">
                {{ $t('INBOX_MGMT.PRE_CHAT_FORM.PRE_CHAT_MESSAGE.LABEL') }}
              </label>
              <woot-message-editor
                v-model="preChatMessage"
                class="message-editor"
                :placeholder="
                  $t('INBOX_MGMT.PRE_CHAT_FORM.PRE_CHAT_MESSAGE.PLACEHOLDER')
                "
              />
            </div>
            <div class="mt-4">
              <label>{{ $t('INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS') }}</label>
              <table class="w-full table">
                <thead>
                  <tr>
                    <th scope="col" />
                    <th scope="col" />
                    <th scope="col" class="font-normal text-xs text-left text-muted py-2">
                      {{ $t('INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.KEY') }}
                    </th>
                    <th scope="col" class="font-normal text-xs text-left text-muted py-2">
                      {{ $t('INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.TYPE') }}
                    </th>
                    <th scope="col" class="font-normal text-xs text-left text-muted py-2">
                      {{ $t('INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.LABEL') }}
                    </th>
                    <th scope="col" class="font-normal text-xs text-left text-muted py-2">
                      {{
                        $t(
                          'INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.PLACE_HOLDER'
                        )
                      }}
                    </th>
                    <th scope="col" class="font-normal text-xs text-left text-muted py-2">
                      {{
                        $t('INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.REQUIRED')
                      }}
                    </th>
                  </tr>
                </thead>
                <pre-chat-fields
                  :pre-chat-fields="preChatFields"
                  @update="handlePreChatFieldOptions"
                  @drag-end="changePreChatFieldFieldsOrder"
                />
              </table>
            </div>
          </div>
          <div class="my-4 w-auto">
            <woot-submit-button
              :button-text="
                $t('INBOX_MGMT.SETTINGS_POPUP.UPDATE_PRE_CHAT_FORM_SETTINGS')
              "
              :loading="uiFlags.isUpdating"
            />
          </div>
        </form>
    </settings-section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import PreChatFields from './PreChatFields.vue';
import SettingsSection from 'dashboard/components/SettingsSection.vue';
import { getPreChatFields, standardFieldKeys } from 'dashboard/helper/preChat';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
export default {
  components: {
    PreChatFields,
    WootMessageEditor,
    SettingsSection
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      preChatFormEnabled: false,
      preChatMessage: '',
      preChatFields: [],
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      customAttributes: 'attributes/getAttributes',
    }),
    preChatFieldOptions() {
      const { pre_chat_form_options: preChatFormOptions } = this.inbox;
      return getPreChatFields({
        preChatFormOptions,
        customAttributes: this.customAttributes,
      });
    },
  },
  watch: {
    inbox() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      const { pre_chat_form_enabled: preChatFormEnabled } = this.inbox;
      this.preChatFormEnabled = preChatFormEnabled;
      const {
        pre_chat_message: preChatMessage,
        pre_chat_fields: preChatFields,
      } = this.preChatFieldOptions || {};
      this.preChatMessage = preChatMessage;
      this.preChatFields = preChatFields;
    },
    isFieldEditable(item) {
      return !!standardFieldKeys[item.name] || !item.enabled;
    },
    handlePreChatFieldOptions(event, type, item) {
      this.preChatFields.forEach((field, index) => {
        if (field.name === item.name) {
          this.preChatFields[index][type] = !item[type];
        }
      });
    },

    changePreChatFieldFieldsOrder(updatedPreChatFieldOptions) {
      this.preChatFields = updatedPreChatFieldOptions;
    },

    async updateInbox() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          channel: {
            pre_chat_form_enabled: this.preChatFormEnabled,
            pre_chat_form_options: {
              pre_chat_message: this.preChatMessage,
              pre_chat_fields: this.preChatFields,
            },
          },
        };
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      }
    },
  },
};
</script>
<style scoped lang="scss">
.message-editor {
  @apply px-3;

  ::v-deep {
    .ProseMirror-menubar {
      @apply rounded-tl-[4px];
    }
  }
}
</style>
