<template>
  <div class="relative">
    <formulate-form
      v-slot="{ hasErrors }"
      class="mx-0 flex flex-wrap"
      name="ai-handover-form"
    >
      <settings-section
        class="mt-0"
        :title="$t('AGENT_BOTS.AI_HANGOVER_CONFIGURATION.SETTINGS.TITLE')"
        :sub-title="$t('AGENT_BOTS.AI_HANGOVER_CONFIGURATION.SETTINGS.MESSAGE')"
      >
        <div class="flex w-[60%] flex-col gap-5">
          <woot-switch
            v-model="isEnabled"
            :label="
              $t('AGENT_BOTS.AI_HANGOVER_CONFIGURATION.SETTINGS.SWITCH_LABEL')
            "
          />

          <div v-if="isEnabled" class="flex flex-col gap-2.5">
            <select v-model="handoverSettingsValue">
              <option
                v-for="option in handoverSettingsOptions"
                :key="option.value"
                :value="option.value"
                :disabled="option.disabled"
              >
                {{ option.label }}
              </option>
            </select>

            <select
              v-if="handoverSettingsValue === 'another_inbox'"
              v-model="inboxIdToClone"
            >
              <option :value="''" disabled />

              <option
                v-for="option in availableInboxes"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </option>
            </select>

            <label v-else>
              <div class="flex flex-row items-center gap-1">
                <span>
                  {{
                    $t(
                      'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.SETTINGS.INTERVAL.LABEL'
                    )
                  }}
                </span>

                <span>
                  <fluent-icon
                    v-tooltip="
                      $t(
                        'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.SETTINGS.INTERVAL.TOOLTIP'
                      )
                    "
                    icon="info"
                    :size="12"
                  />
                </span>
              </div>

              <div
                class="flex w-max flex-row items-center overflow-visible rounded border dark:border-gray-700"
              >
                <span class="px-2 py-3">
                  <fluent-icon icon="chat" :size="16" />
                </span>

                <formulate-input
                  v-model="newHandover.interval"
                  class="ai-handover-interval-input custom-formulate-error-tooltip tooltip__top"
                  :validation="`required|max:${INTERVAL_MAX}|min:${INTERVAL_MIN}`"
                  :validation-messages="{
                    min: $t('CHATLYN_GENERAL.MESSAGES.MIN_NUMBER', {
                      MIN: INTERVAL_MIN,
                    }),
                    max: $t('CHATLYN_GENERAL.MESSAGES.MAX_NUMBER', {
                      MAX: INTERVAL_MAX,
                    }),
                  }"
                  type="number"
                  :min="INTERVAL_MIN"
                  :max="INTERVAL_MAX"
                />

                <span
                  class="self-stretch border-l bg-gray-50 p-2 dark:border-gray-700 dark:bg-gray-800"
                >
                  {{
                    $t(
                      'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.SETTINGS.INTERVAL.HELP_TEXT'
                    )
                  }}
                </span>
              </div>
            </label>

            <woot-button
              class="mt-2.5 w-max"
              type="button"
              :disabled="hasErrors"
              @click="saveSettings"
            >
              {{
                $t(
                  'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.SETTINGS.UPDATE_BUTTON'
                )
              }}
            </woot-button>
          </div>
        </div>
      </settings-section>
    </formulate-form>

    <div
      v-if="isEnabled && handoverSettingsValue === 'custom'"
      class="mx-0 flex flex-wrap"
    >
      <settings-section
        class="mt-0"
        :title="$t('AGENT_BOTS.AI_HANGOVER_CONFIGURATION.MESSAGE.TITLE')"
        :sub-title="$t('AGENT_BOTS.AI_HANGOVER_CONFIGURATION.MESSAGE.MESSAGE')"
      >
        <div class="relative flex flex-col flex-nowrap gap-5">
          <language-bar
            has-add-button
            has-right-side
            has-remove-button
            :has-minimum-one-language="true"
            :languages="languages"
            :current-language="selectedLanguage"
            :available-languages="availableLanguagesOptions"
            :has-additional-text="false"
            :translations="newHandover.translations"
            @change="handleChangeSelectedLanguage"
            @handle-add-button-click="handleAddTranslation"
            @handle-remove-button-click="toggleShowDeleteLanguageModal"
          />

          <template v-for="translation in newHandover.translations">
            <formulate-form
              v-show="selectedLanguage === translation.lang"
              :key="translation.lang"
              :name="`ai-handover-${translation.lang}-form`"
            >
              <ai-handover-message-translation-editor
                :languages="languages"
                :new-handover="newHandover"
                :selected-translation="selectedTranslation"
                :main-language="mainLanguage"
                @copy-from-language="copyFromLanguage"
                @handle-change-selected-translation="
                  handleChangeSelectedTranslation
                "
              />
            </formulate-form>
          </template>

          <div
            v-if="
              Array.isArray(newHandover.translations) &&
              newHandover.translations.length
            "
            class="flex flex-row"
          >
            <woot-button
              :is-disabled="formsErrors[selectedLanguage]"
              class="!h-10 rounded-r-none border-none !px-3.5 !py-2.5"
              size="small"
              type="button"
              @click="saveTranslation"
            >
              {{
                `${$t(
                  'CHATLYN_GENERAL.BUTTON.SAVE'
                )} ${selectedLanguage.toUpperCase()}`
              }}
            </woot-button>

            <span class="w-px bg-woot-200" />

            <popover-select v-model="selectedSaveOption" :options="saveOptions">
              <woot-button
                class="!h-10 rounded-l-none border-none !px-3.5 !py-2.5"
                icon="chevron-down"
                size="small"
                type="button"
              />
            </popover-select>
          </div>

          <chatlyn-spinner :is-visible="isTranslating" />
        </div>
      </settings-section>
    </div>

    <chatlyn-spinner :is-visible="false" />

    <woot-delete-modal
      :show.sync="showDeleteLanguageModal"
      :title="
        $t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.TITLE', {
          LANGUAGE: selectedLanguage.toUpperCase(),
        })
      "
      :message="$t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.MESSAGE')"
      :confirm-text="
        $t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.ACCEPT')
      "
      :reject-text="
        $t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.DECLINE')
      "
      :is-loading="handoverUiFlags.isTranslationsDeleting"
      :on-confirm="deleteSelectedLanguage"
      :on-close="toggleShowDeleteLanguageModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WootSwitch from 'shared/components/ui/Switch';
import ChatlynSpinner from '../../../../../../../shared/components/ChatlynSpinner.vue';
import SettingsSection from '../../../../../../components/SettingsSection.vue';
import { INBOX_TYPES } from '../../../../../../../shared/mixins/inboxMixin';
import AiHandoverMessageTranslationEditor from './components/AiHandoverMessageTranslationEditor.vue';
import LanguageBar from '../../../../messageTemplates/components/LanguageBar.vue';
import { MESSAGE_TEMPLATE_AVAILABLE_TRANSLATION_LANGUAGE } from '../../../../messageTemplates/constants';
import PopoverSelect from '../../../../../../../shared/components/ui/PopoverSelect.vue';
import Deepl from '../../../../../../api/deepl';
import alertMixin from 'shared/mixins/alertMixin';

const INTERVAL_MIN = 1;
const INTERVAL_MAX = 10;

export default {
  name: 'AiHandoverConfiguration',
  components: {
    SettingsSection,
    ChatlynSpinner,
    WootSwitch,
    AiHandoverMessageTranslationEditor,
    LanguageBar,
    PopoverSelect,
  },
  mixins: [alertMixin],
  props: {
    botId: {
      type: String,
      required: true,
    },

    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      INTERVAL_MIN,
      INTERVAL_MAX,
      handoverSettingsValue: 'custom',
      inboxIdToClone: '',
      newHandover: null,
      selectedLanguage: '',
      mainLanguage: '',
      showDeleteLanguageModal: false,
      formsErrors: {},
      formsErrorsPromises: {},
      isTranslating: false,
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      currentUserId: 'getCurrentUserID',
      inboxes: 'inboxes/getInboxes',
      handover: 'chatbot/getHandover',
      handoverUiFlags: 'chatbot/getUIFlags',
      getAccount: 'accounts/getAccount',
      managementAccount: 'management/getAccount',
    }),

    isEnabled: {
      get() {
        return this.newHandover?.enabled || false;
      },

      async set(newValue) {
        await this.updateHandover(
          structuredClone({
            id: this.newHandover.id,
            enabled: newValue,
          })
        );
      },
    },

    currentAccountLocale() {
      const { locale: accountLocale } = this.getAccount(this.currentAccountId);

      return accountLocale;
    },

    handoverSettingsOptions() {
      return [
        {
          label: 'Choose from another Inbox',
          value: 'another_inbox',
          disabled: !this.availableInboxes.length,
        },
        {
          label: 'Custom',
          value: 'custom',
        },
      ];
    },

    availableInboxes() {
      return this.inboxes.filter(
        (value) =>
          [INBOX_TYPES.WHATSAPP, INBOX_TYPES.FB].includes(value.channel_type) &&
          Object.keys(
            this.$store.getters['agentBots/getActiveAgentBot'](value.id) || {}
          ).length > 0
      );
    },

    inboxToCloneActiveAgentBot() {
      return this.$store.getters['agentBots/getActiveAgentBot'](
        this.inboxIdToClone
      );
    },

    languages() {
      return this.newHandover?.translations?.map((transition) => ({
        name: transition.lang,
      }));
    },

    selectedTranslation: {
      get() {
        return this.getTranslationByLanguage(this.selectedLanguage);
      },

      set(newValue) {
        this.replaceTranslationByLanguage({
          ...newValue,
          lang: this.selectedLanguage,
        });

        this.updateLanguageErrors(this.selectedLanguage);
      },
    },

    availableLanguages() {
      let result = [];

      if (!this.managementAccount) {
        return result;
      }

      const { additionalLang } = this.managementAccount;

      result = additionalLang.filter((language) =>
        MESSAGE_TEMPLATE_AVAILABLE_TRANSLATION_LANGUAGE.includes(language)
      );

      if (
        !this.languages?.some(
          (language) => language.name === this.currentAccountLocale
        ) &&
        !this.additionalLang?.some(
          (language) => language === this.currentAccountLocale
        )
      ) {
        result.push(this.currentAccountLocale);
      }

      return result;
    },

    availableLanguagesOptions() {
      return this.availableLanguages
        .filter((availableLanguage) => {
          return !this.languages.some(
            (language) => language.name === availableLanguage
          );
        })
        .map((availableLanguage) => ({
          value: availableLanguage,
          label: availableLanguage.toUpperCase(),
        }));
    },

    isSomeLanguageHasErrors() {
      return Object.values(this.formsErrors).some((value) => !!value);
    },

    saveOptions() {
      return [
        {
          label: this.$t('CHATLYN_GENERAL.BUTTON.SAVE_ALL'),
          value: 'save_all',
          disabled: this.isSomeLanguageHasErrors,
        },
      ];
    },

    selectedSaveOption: {
      get() {
        return '';
      },

      async set(newValue) {
        switch (newValue) {
          case 'save_all':
            await this.saveAllTranslations();
            break;

          default:
            break;
        }
      },
    },

    isSpinnerVisible() {
      return ['isFetching', 'isUpdating', 'isTranslationsAdding'].some(
        (value) => this.handoverUiFlags[value]
      );
    },
  },
  watch: {
    handover: {
      handler(newValue) {
        if (!newValue || Object.keys(newValue).length === 0) {
          return;
        }

        let handover = structuredClone(newValue);

        if (this.newHandover) {
          handover = structuredClone({
            ...newValue,
            translations: this.newHandover.translations.map(
              (oldTranslation) => {
                const newTranslation = newValue.translations.find(
                  (translation) => translation.lang === oldTranslation.lang
                );

                if (!newTranslation || !Object.keys(newTranslation).length) {
                  return oldTranslation;
                }

                return typeof oldTranslation.id === 'number'
                  ? { ...newTranslation, ...oldTranslation }
                  : { ...oldTranslation, ...newTranslation };
              }
            ),
          });
        }

        this.newHandover = handover;
      },
      deep: true,
      immediate: true,
    },

    languages: {
      handler(newValue) {
        if (newValue?.length !== Object.keys(this.formsErrors)?.length) {
          newValue.forEach((value) => {
            this.updateLanguageErrors(value.name);
          });
        }

        if (
          !this.mainLanguage ||
          !newValue.some((value) => value.name === this.mainLanguage)
        ) {
          this.mainLanguage = newValue[0]?.name || '';
        }

        if (
          this.selectedLanguage &&
          newValue.some((value) => value.name === this.selectedLanguage)
        ) {
          return;
        }

        this.selectedLanguage = newValue[0]?.name || '';
      },
      deep: true,
    },

    'newHandover.translations': {
      handler(newValue) {
        if ((newValue || []).length) {
          return;
        }

        this.$nextTick(() => {
          this.handleAddTranslation(this.currentAccountLocale);
        });
      },
      deep: true,
    },

    formsErrorsPromises: {
      async handler(newValue) {
        const oldErrors = structuredClone(this.formsErrors);

        await Promise.all(
          Object.entries(newValue).map(async ([key, value]) => {
            oldErrors[key] = await value;
          })
        );

        this.formsErrors = oldErrors;
      },
      deep: true,
    },
  },
  mounted() {
    this.initialSetup();
  },
  methods: {
    handleChangeSelectedLanguage(newLanguage) {
      this.selectedLanguage = newLanguage;
    },

    handleChangeSelectedTranslation(newValue) {
      this.selectedTranslation = newValue;
    },

    toggleShowDeleteLanguageModal() {
      this.showDeleteLanguageModal = !this.showDeleteLanguageModal;
    },

    getTranslationByLanguage(language) {
      return this.newHandover?.translations.find(
        (translation) => translation.lang === language
      );
    },

    replaceTranslationByLanguage(newTranslation) {
      const oldHandover = structuredClone(this.newHandover);

      oldHandover.translations = oldHandover.translations.map((translation) =>
        translation.lang === newTranslation.lang ? newTranslation : translation
      );

      this.newHandover = oldHandover;
    },

    async updateLanguageErrors(language) {
      this.formsErrors = structuredClone({
        ...this.formsErrors,
        [language]: true,
      });

      this.$nextTick(() => {
        try {
          const formComponent = this.$formulate.registry.get(
            `ai-handover-${language}-form`
          );

          if (!formComponent) {
            const oldErrors = structuredClone(this.formsErrors);
            const oldErrorsPromises = { ...this.formsErrorsPromises };

            delete oldErrors[language];
            delete oldErrorsPromises[language];

            this.formsErrors = oldErrors;
            this.formsErrorsPromises = oldErrorsPromises;

            return;
          }

          this.formsErrorsPromises = {
            ...this.formsErrorsPromises,
            [language]: formComponent.hasValidationErrors(),
          };
        } catch (error) {
          //
        }
      });
    },

    async copyFromLanguage(from, to, isTranslate = false) {
      const fromTranslation = structuredClone(
        this.getTranslationByLanguage(from)
      );

      const toTranslation = structuredClone(this.getTranslationByLanguage(to));

      delete fromTranslation.id;
      delete fromTranslation.lang;

      const newTranslation = {
        ...toTranslation,
        ...fromTranslation,
      };

      this.isTranslating = true;

      if (isTranslate) {
        try {
          const { data } = await Deepl.translateText(newTranslation.body, to);

          if (!data || !data.text) {
            throw new Error();
          }

          newTranslation.body = data.text;
        } catch (error) {
          this.showAlert(
            this.$t(
              'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.API_ERROR.TRANSLATE_TEXT_MESSAGE'
            )
          );
        }

        try {
          const { data } = await Deepl.translateText(newTranslation.button, to);

          if (!data || !data.text) {
            throw new Error();
          }

          newTranslation.button = data.text;
        } catch (error) {
          this.showAlert(
            this.$t(
              'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.API_ERROR.TRANSLATE_TEXT_MESSAGE'
            )
          );
        }
      }

      this.isTranslating = false;

      this.replaceTranslationByLanguage(newTranslation);
    },

    async initialSetup() {
      await this.fetchHandover();
      await this.fetchManagementAccount();
    },

    handleAddTranslation(newValue) {
      const oldHandover = structuredClone(this.newHandover);

      oldHandover.translations.push({
        lang: newValue,
        body: '',
        button: '',
      });

      this.newHandover = structuredClone(oldHandover);

      this.selectedLanguage = newValue;

      this.updateLanguageErrors(newValue);
    },

    async deleteSelectedLanguage() {
      try {
        const oldHandover = structuredClone(this.newHandover);
        const id = this.selectedTranslation.id;

        if (id) {
          const response = await this.$store.dispatch(
            'chatbot/deleteHandoverTranslation',
            {
              accountId: this.currentAccountId,
              translationId: this.selectedTranslation.id,
              updateStoreValue: true,
            }
          );

          if (!response) {
            throw new Error();
          }
        }

        oldHandover.translations = oldHandover.translations.filter(
          (transition) => transition.lang !== this.selectedLanguage
        );

        this.newHandover = oldHandover;

        this.updateLanguageErrors(this.selectedLanguage);

        if (!id) {
          return;
        }

        await this.fetchHandover();
      } catch (error) {
        this.showAlert(
          this.$t(
            'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.API_ERROR.DELETE_TRANSLATION_MESSAGE'
          )
        );
      } finally {
        this.toggleShowDeleteLanguageModal();
      }
    },

    async fetchHandover() {
      const result = await this.$store.dispatch('chatbot/getHandover', {
        accountId: this.currentAccountId,
        userId: this.currentUserId,
        botId: this.botId,
        inboxId: this.inbox.id,
      });

      if (!result) {
        this.$t(
          'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.API_ERROR.FETCH_HANDOVER_MESSAGE'
        );
      }
    },

    async fetchManagementAccount() {
      await this.$store.dispatch('management/getAccount', {
        accountId: this.currentAccountId,
      });
    },

    async updateHandover(newValue = null) {
      const result = await this.$store.dispatch('chatbot/updateHandover', {
        accountId: this.currentAccountId,
        handover: newValue || this.newHandover,
        updateStoreValue: true,
      });

      if (!result) {
        this.showAlert(
          this.$t(
            'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.API_ERROR.UPDATE_HANDOVER_MESSAGE'
          )
        );
      }
    },

    async saveSettings() {
      try {
        const formComponent = this.$formulate.registry.get('ai-handover-form');

        if (await formComponent.hasValidationErrors()) {
          return;
        }

        await this.updateHandover(
          structuredClone({
            id: this.newHandover.id,
            interval: Number.parseInt(this.newHandover.interval, 10),
          })
        );
      } catch (error) {
        this.showAlert(
          this.$t(
            'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.API_ERROR.UPDATE_HANDOVER_MESSAGE'
          )
        );
      }
    },

    async saveTranslation() {
      if (this.formsErrors[this.selectedLanguage]) {
        return;
      }

      if (typeof this.selectedTranslation.id !== 'number') {
        const result = await this.$store.dispatch(
          'chatbot/addHandoverTranslations',
          {
            accountId: this.currentAccountId,
            handoverId: this.newHandover.id,
            translations: [this.selectedTranslation],
            updateStoreValue: true,
          }
        );

        if (!result) {
          this.$t(
            'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.API_ERROR.SAVE_TRANSLATION_MESSAGE'
          );
        }

        return;
      }

      const newTranslations = structuredClone(this.handover.translations);

      await this.updateHandover({
        id: this.newHandover.id,
        translations: newTranslations.map((translation) =>
          translation.id === this.selectedTranslation.id
            ? this.selectedTranslation
            : translation
        ),
      });
    },

    async saveAllTranslations() {
      if (this.isSomeLanguageHasErrors) {
        return;
      }

      const translationsObject = {
        new: [],
        exist: [],
      };

      this.newHandover.translations.forEach((translation) => {
        if (typeof translation.id === 'number') {
          translationsObject.exist.push(translation);
          return;
        }

        translationsObject.new.push(translation);
      });

      if (translationsObject.exist.length) {
        await this.updateHandover({
          id: this.newHandover.id,
          translations: translationsObject.exist,
        });
      }

      if (translationsObject.new.length) {
        const result = await this.$store.dispatch(
          'chatbot/addHandoverTranslations',
          {
            accountId: this.currentAccountId,
            handoverId: this.newHandover.id,
            translations: translationsObject.new,
            updateStoreValue: true,
          }
        );

        if (!result) {
          this.$t(
            'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.API_ERROR.SAVE_ALL_TRANSLATIONS_MESSAGE'
          );
        }
      }
    },
  },
};
</script>

<style lang="scss">
.ai-handover-interval-input {
  input {
    @apply pr-6;

    border: none !important;
    border-radius: 0 !important;
  }
}
</style>
