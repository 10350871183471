<template>
  <div
    class="mb-2 rounded-md border bg-white px-5 py-4 dark:border-gray-700 dark:bg-gray-800"
  >
    <div class="flex flex-row items-start justify-between">
      <div class="flex flex-col">
        <div class="mb-1 text-base">
          {{ campaign.title }}
        </div>
        <div
          v-dompurify-html="formatMessage(campaign.message)"
          class="line-clamp-1 text-sm [&>p]:mb-0"
        />
      </div>
      <div class="flex flex-nowrap gap-2">
        <woot-button
          v-if="isOngoingType"
          variant="smooth"
          icon="edit"
          color-scheme="secondary"
          size="small"
          data-testid="toggle-show-edit-campaign-modal"
          @click="$emit('edit', campaign)"
        >
          {{ $t('CAMPAIGN.LIST.BUTTONS.EDIT') }}
        </woot-button>
        <woot-button
          variant="smooth"
          icon="delete"
          size="small"
          color-scheme="alert"
          data-testid="toggle-show-delete-campaign-modal"
          @click="$emit('delete', campaign)"
        />
      </div>
    </div>

    <div class="mt-4 flex flex-row items-center gap-6">
      <woot-label
        small
        :title="campaignStatus"
        :color-scheme="colorScheme"
        class="text-xs"
      />
      <inbox-name :inbox="campaign.inbox" />
      <user-avatar-with-name v-if="campaign.sender" :user="campaign.sender" />
      <div
        v-if="campaign.trigger_rules.url"
        class="text-truncate w-1/4 text-xs text-woot-600"
      >
        {{ campaign.trigger_rules.url }}
      </div>
      <div
        v-if="campaign.scheduled_at"
        class="text-xs text-gray-700 dark:text-gray-500"
      >
        {{ messageStamp(new Date(campaign.scheduled_at), 'LLL d, h:mm a') }}
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatarWithName from 'dashboard/components/widgets/UserAvatarWithName.vue';
import InboxName from 'dashboard/components/widgets/InboxName.vue';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import timeMixin from 'dashboard/mixins/time';

export default {
  components: {
    UserAvatarWithName,
    InboxName,
  },
  mixins: [messageFormatterMixin, timeMixin],
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    isOngoingType: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    campaignStatus() {
      if (this.isOngoingType) {
        return this.campaign.enabled
          ? this.$t('CAMPAIGN.LIST.STATUS.ENABLED')
          : this.$t('CAMPAIGN.LIST.STATUS.DISABLED');
      }

      return this.campaign.campaign_status === 'completed'
        ? this.$t('CAMPAIGN.LIST.STATUS.COMPLETED')
        : this.$t('CAMPAIGN.LIST.STATUS.ACTIVE');
    },
    colorScheme() {
      if (this.isOngoingType) {
        return this.campaign.enabled ? 'success' : 'secondary';
      }
      return this.campaign.campaign_status === 'completed'
        ? 'secondary'
        : 'success';
    },
  },
};
</script>
