<template>
  <section class="flex flex-1 flex-col">
    <div v-if="isLoading" class="flex flex-col items-center justify-center">
      <spinner />
      <span>{{ $t('CONTACTS_PAGE.LIST.LOADING_MESSAGE') }}</span>
    </div>

    <empty-state
      v-if="showSearchEmptyState"
      :title="$t('CONTACTS_PAGE.LIST.404')"
    />

    <empty-state
      v-else-if="!isLoading && !contacts.length"
      transparent
      :title="$t('CONTACTS_PAGE.LIST.NO_CONTACTS')"
      :message="$t('CONTACTS_PAGE.LIST.ALREADY_IN_LIST')"
    />

    <ve-table
      v-else
      class="header-hidden"
      :fixed-header="false"
      :table-data="tableData"
      :columns="columns"
      row-key-field-name="rowKey"
      :checkbox-option="checkboxOption"
      :event-custom-option="eventCustomOption"
      :cell-selection-option="cellSelectionOption"
      :border-around="false"
    />
  </section>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';

import Spinner from 'shared/components/Spinner.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import timeMixin from 'dashboard/mixins/time';

export default {
  name: 'ContactsShortTable',
  components: {
    EmptyState,
    Spinner,
    VeTable,
  },
  mixins: [clickaway, timeMixin],
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    showSearchEmptyState: {
      type: Boolean,
      default: false,
    },
    selectedClean: {
      type: Boolean,
      default: false,
    },
    onClickContact: {
      type: Function,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    activeContactId: {
      type: [String, Number],
      default: '',
    },
    sortParam: {
      type: String,
      default: 'name',
    },
    sortOrder: {
      type: String,
      default: 'asc',
    },
  },
  data() {
    return {
      sortConfig: {},
      sortOption: {
        sortAlways: true,
        sortChange: (params) => this.$emit('on-sort-change', params),
      },
      cellSelectionOption: {
        // disble cell selection
        enable: false,
      },
      eventCustomOption: {
        // eslint-disable-next-line no-unused-vars
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            click: () => {
              const currentRowKey = row.rowKey;
              this.changeSelectedRowKeysByRowClick(currentRowKey);
            },
          };
        },
      },
      checkboxOption: {
        selectedRowKeys: [],
        // eslint-disable-next-line no-unused-vars
        selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {},
        // eslint-disable-next-line no-unused-vars
        selectedAllChange: ({ isSelected, selectedRowKeys }) => {
          this.changeSelectedRowKeys(selectedRowKeys);
        },
      },
    };
  },
  computed: {
    tableData() {
      if (this.isLoading) {
        return [];
      }
      return this.contacts.map((item) => {
        return {
          ...item,
          rowKey: item.id,
          phone_number: item.phone_number || '---',
        };
      });
    },
    columns() {
      return [
        {
          field: '',
          key: 'check',
          fixed: 'left',
          type: 'checkbox',
          title: '',
          width: 40,
          align: 'center',
        },
        {
          field: 'name',
          key: 'name',
          rowKey: 'name',
          title: '',
          fixed: 'left',
          align: 'left',
          sortBy: '',
          width: 175,
          renderBodyCell: ({ row }) => (
            <div class="flex items-center gap-2">
              <Thumbnail
                src={row.thumbnail}
                size="24px"
                username={row.name}
                status={row.availability_status}
              />
              <div>
                <div class="flex items-center gap-1 whitespace-nowrap text-base">
                  {row.name}
                  <router-link
                    target="_blank"
                    class="text-woot-500 transition-all duration-200 hover:scale-150"
                    to={`/app/accounts/${this.$route.params.accountId}/contacts/${row.id}`}
                  >
                    <fluent-icon icon="open" size="12" />
                  </router-link>
                </div>
                <div
                  style="max-width: 175px"
                  class="text-muted w-full overflow-hidden text-ellipsis whitespace-nowrap text-xs"
                >
                  {row.email}
                </div>
              </div>
            </div>
          ),
        },
        {
          field: 'phone_number',
          rowKey: 'phone_number',
          key: 'phone_number',
          sortBy: '',
          title: '',
          align: 'left',
          width: 125,
        },
      ];
    },
  },
  watch: {
    sortOrder() {
      this.setSortConfig();
    },
    sortParam() {
      this.setSortConfig();
    },
  },
  mounted() {
    this.setSortConfig();
  },
  methods: {
    setSortConfig() {
      this.sortConfig = { [this.sortParam]: this.sortOrder };
    },
    changeSelectedRowKeys(keys) {
      this.checkboxOption.selectedRowKeys = keys;
      this.$emit('selected-row', keys);
    },
    changeSelectedRowKeysByRowClick(currentRowKey) {
      const { selectedRowKeys } = this.checkboxOption;

      if (selectedRowKeys.includes(currentRowKey)) {
        const rowKeyIndex = selectedRowKeys.indexOf(currentRowKey);
        selectedRowKeys.splice(rowKeyIndex, 1);
      } else {
        selectedRowKeys.push(currentRowKey);
      }
      this.$emit('selected-row', selectedRowKeys);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.user-details {
  margin-left: 0.5rem !important;
  .user-name {
    padding: 0 !important;
  }
}
.contacts-table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.contacts-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
  }

  .row--user-block {
    align-items: center;
    display: flex;
    text-align: left;

    .user-block {
      min-width: 0;
    }

    .user-thumbnail-box {
      margin-right: var(--space-small);
    }

    .user-name {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      margin: 0;
      text-transform: capitalize;
    }

    .view-details--button {
      color: var(--color-body);
    }

    .user-email {
      margin: 0;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }

  .ve-table-sort {
    top: -4px;
  }
}

.contacts--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.cell--social-profiles {
  a {
    color: var(--s-300);
    display: inline-block;
    font-size: var(--font-size-medium);
    min-width: var(--space-large);
    text-align: center;
  }
}

.contacts-table--empty-state__help-text {
  @apply text-center text-gray-500;

  font-size: 1.4rem;
  line-height: 1.2;
}
</style>
