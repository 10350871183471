<template>
  <div class="flex w-96 flex-col px-10 py-4">
    <h3 class="analytics__title">
      {{ $t('CHATLYN_SENT_TEMPLATES_REPORTS.ANALYTICS') }}
    </h3>
    <canvas
      ref="pie-chart"
      width="200"
      height="200"
      class="analytics__pie-chart mb-8"
    />
    <ul class="total">
      <li class="total__item-main mb-4">
        <span class="total__label-main">{{
          $t('CHATLYN_SENT_TEMPLATES_REPORTS.TOTAL')
        }}</span>
        <span class="total__count-main">{{ totalCount }}</span>
      </li>
    </ul>
    <ul class="total grid grid-cols-2 gap-x-4">
      <li v-for="(total, index) in totals" :key="index" class="total__item">
        <span class="total__label">{{ total.label }}</span>
        <span class="total__count">{{ total.count }}</span>
        <div class="rounded bg-gray-50 dark:bg-gray-700">
          <div
            class="total__line"
            :style="{ width: total.width + '%', background: total.color }"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'AnalyticsSidebar',
  props: {
    statusCounts: {
      type: Object,
      default: () => ({}),
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      myChart: null,
      config: {
        type: 'pie',
        data: {
          datasets: [
            {
              data: [],
              backgroundColor: ['#F43F5E', '#FFE4E6', '#6DAB89', '#518D6C'],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
        },
      },
      totals: null,
    };
  },
  watch: {
    statusCounts() {
      this.setChart();
    },
  },
  mounted() {
    this.setChart();
  },
  methods: {
    prepareChartData() {
      const labelTranslations = {
        sent: this.$t('CHATLYN_SENT_TEMPLATES_REPORTS.STATUS.SENT_ONLY'),
        failed: this.$t('CHATLYN_SENT_TEMPLATES_REPORTS.STATUS.FAILED'),
        delivered: this.$t('CHATLYN_SENT_TEMPLATES_REPORTS.STATUS.DELIVERED'),
        read: this.$t('CHATLYN_SENT_TEMPLATES_REPORTS.STATUS.READ'),
      };
      const colors = ['#F43F5E', '#FFE4E6', '#6DAB89', '#518D6C'];
      let colorIndex = 0;

      const labels = Object.keys(this.statusCounts).map((label) => {
        return labelTranslations[label];
      });
      const data = Object.values(this.statusCounts);
      this.totals = Object.entries(this.statusCounts).map(([label, count]) => {
        const width = (count / this.totalCount) * 100;
        const color = colors[colorIndex % colors.length];
        colorIndex += 1;
        label = labelTranslations[label] || label;
        return { label, count, width, color };
      });
      return { labels, data };
    },
    setChart() {
      const { labels, data } = this.prepareChartData();
      this.config.data.labels = labels;
      this.config.data.datasets[0].data = data;
      const ctx = this.$refs['pie-chart'];

      if (this.myChart) {
        this.myChart.destroy();
      }

      this.myChart = new Chart(ctx, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics {
  &__title {
    @apply mb-6 font-semibold;
  }
}
.total {
  &__item {
    @apply mb-2 flex flex-col;

    &-main {
      @apply mb-4 flex flex-col;
    }
  }

  &__label {
    &:first-letter {
      @apply uppercase;
    }

    @apply mb-1 text-sm text-gray-400;

    &-main {
      &:first-letter {
        @apply uppercase;
      }

      @apply mb-1 font-semibold;
    }
  }

  &__count {
    @apply mb-1 text-2xl font-light;

    &-main {
      @apply mb-1 text-4xl font-light;
    }
  }

  &__line {
    @apply h-1 rounded;
  }
}
</style>
