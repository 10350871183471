var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBannerVisible),expression:"isBannerVisible"}],staticClass:"!z-20 flex items-center justify-center gap-1 rounded-bl-lg rounded-br-lg px-4 py-3 text-center text-sm text-white",class:{
    'min-h-[3rem] min-w-[16rem] flex-row bg-green-500 dark:bg-green-600':
      !_vm.isExpiredComputed,
    'flex-col bg-red-500 dark:bg-red-700': _vm.isExpiredComputed,
  }},[(_vm.isExpiredComputed)?[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
        _vm.$t('CONVERSATION.CAN_REPLY.TITLE', {
          LINK: 'https://academy.chatlyn.com/en/articles/24-hour-conversation-window-on-whatsapp-business/',
        })
      ),expression:"\n        $t('CONVERSATION.CAN_REPLY.TITLE', {\n          LINK: 'https://academy.chatlyn.com/en/articles/24-hour-conversation-window-on-whatsapp-business/',\n        })\n      "}],staticClass:"text-sm font-semibold"}),_vm._v(" "),_c('span',{staticClass:"text-xs font-normal"},[_vm._v("\n      "+_vm._s(_vm.$t('CONVERSATION.CAN_REPLY.TEXT'))+"\n    ")])]:[_c('fluent-icon',{attrs:{"icon":"timer"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.timerTime))]),_vm._v(" "),_c('span',[_vm._v("\n      "+_vm._s(_vm.$t('CONVERSATION.CAN_REPLY.UNTIL_TEXT'))+"\n    ")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }