import { render, staticRenderFns } from "./DragDropListItem.vue?vue&type=template&id=18f511f2&scoped=true&"
import script from "./DragDropListItem.vue?vue&type=script&lang=js&"
export * from "./DragDropListItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f511f2",
  null
  
)

export default component.exports