<template>
  <div
    class="filter-bar grid w-full items-center gap-2 md:grid-cols-3 xl:flex xl:flex-nowrap"
  >
    <div class="filter-bar__check flex items-center gap-2">
      <input
        id="failed_only"
        v-model="computedFailed"
        class="notification--checkbox"
        type="checkbox"
      />
      <label for="failed_only">
        {{ $t('CHATLYN_SENT_TEMPLATES_REPORTS.FILTER_BAR.SHOW_FAILED_ONLY') }}
      </label>
    </div>
    <div class="filter-bar__select xl:flex-grow">
      <multiselect
        v-model="computedTemplateName"
        class="no-margin"
        :placeholder="
          $t('CHATLYN_SENT_TEMPLATES_REPORTS.FILTER_BAR.CHOOSE_TEMPLATE')
        "
        label="title"
        track-by="_id"
        :options="templates"
        :option-height="24"
        :show-labels="false"
      />
    </div>
    <div class="filter-bar__select xl:flex-grow">
      <multiselect
        v-model="computedNumber"
        class="no-margin"
        :placeholder="
          $t('CHATLYN_SENT_TEMPLATES_REPORTS.FILTER_BAR.CHOOSE_INBOX')
        "
        label="name"
        track-by="id"
        :options="whatsAppInboxes"
        :option-height="24"
        :show-labels="false"
      />
    </div>
    <input
      v-model="computedRecipient"
      class="xl:max-w-[12rem]"
      type="text"
      :placeholder="
        $t('CHATLYN_SENT_TEMPLATES_REPORTS.FILTER_BAR.ENTER_PHONE_NUBMER')
      "
      @keyup.enter="filterByRecipient"
    />
    <div class="filter-bar__range">
      <woot-date-range-picker
        show-range
        format="MM-DD-YYYY"
        class="auto-width xl:min-w-[16rem]"
        :value="computedDate"
        :confirm-text="$t('CHATLYN_GENERAL.BUTTON.APPLY')"
        :placeholder="
          $t('CHATLYN_SENT_TEMPLATES_REPORTS.FILTER_BAR.SELECT_DATE_RANGE')
        "
        @change="updateRange"
      />
    </div>
    <woot-button color-scheme="secondary" @click="clearFilters">
      {{ $t('CHATLYN_SENT_TEMPLATES_REPORTS.FILTER_BAR.CLEAR_FILTERS') }}
    </woot-button>
  </div>
</template>

<script>
import WootDateRangePicker from 'dashboard/components/ui/DateRangePicker.vue';
import { isPhoneNumberValid } from 'shared/helpers/Validators';
import parsePhoneNumber from 'libphonenumber-js';

export default {
  name: 'FilterBar',
  components: {
    WootDateRangePicker,
  },
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    templates: {
      type: Array,
      default: () => [],
    },
    whatsAppInboxes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeDialCode: '',
      onlyFailed: false,
      selectedSentTemplate: null,
      selectedInbox: null,
      recipient: '',
      range: [],
    };
  },
  validations: {
    phoneNumber: {},
  },
  computed: {
    computedFailed: {
      get() {
        return this.filters.failed;
      },
      set(value) {
        this.$emit('filter-by-failed', value);
      },
    },
    computedTemplateName: {
      get() {
        return this.filters.template;
      },
      set(value) {
        this.$emit('filter-by-template', value);
      },
    },
    computedNumber: {
      get() {
        return this.filters.number;
      },
      set(value) {
        this.$emit('filter-by-number', value);
      },
    },
    computedRecipient: {
      get() {
        return this.filters.recipient;
      },
      set(value) {
        this.recipient = value;
      },
    },
    computedDate: {
      get() {
        return [this.filters.from, this.filters.to];
      },
      set(value) {
        this.$emit('filter-by-date', value);
      },
    },
    parsePhoneNumber() {
      return parsePhoneNumber(this.phoneNumber);
    },
    isPhoneNumberNotValid() {
      if (this.phoneNumber !== '') {
        return (
          !isPhoneNumberValid(this.phoneNumber, this.activeDialCode) ||
          (this.phoneNumber !== '' ? this.activeDialCode === '' : false)
        );
      }
      return false;
    },
    phoneNumberError() {
      if (this.activeDialCode === '') {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.DIAL_CODE_ERROR');
      }
      if (!isPhoneNumberValid(this.phoneNumber, this.activeDialCode)) {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.ERROR');
      }
      return '';
    },
  },
  methods: {
    onPhoneNumberInputChange(value, code) {
      this.activeDialCode = code;
    },
    setPhoneCode(code) {
      if (this.phoneNumber !== '' && this.parsePhoneNumber) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        if (dialCode === code) {
          return;
        }
        this.activeDialCode = `+${dialCode}`;
        this.phoneNumber = this.phoneNumber.replace(`+${dialCode}`, `${code}`);
      } else {
        this.activeDialCode = code;
      }
    },
    clearFilters() {
      this.$emit('clear-filters');
    },
    updateRange(range) {
      this.range = range;
      this.$emit('filter-by-date', range);
    },
    filterByRecipient() {
      this.$emit('filter-by-recipient', this.recipient);
    },
  },
};
</script>

<style scoped lang="scss">
.filter-bar {
  ::v-deep .button__content {
    text-align: center;
  }
}
</style>
