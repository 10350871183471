<template>
  <button
    type="button"
    class="toggle-button"
    :class="{ active: value, small: size === 'small' }"
    role="switch"
    :aria-checked="value.toString()"
    @click="onClick"
  >
    <span aria-hidden="true" :class="{ active: value }" />
  </button>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    size: { type: String, default: '' },
  },
  methods: {
    onClick() {
      this.$emit('input', !this.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.toggle-button {
  @apply flex flex-shrink-0 relative inline-block bg-transparent border border-gray-300 dark:border-gray-600 w-8 h-4 rounded-full;

  span {
    @apply absolute top-0 left-0 ms-1 my-0.5 bg-gray-400 w-2.5 h-2.5 rounded-full transition-transform duration-200;
  }
  
  &.active {
    @apply bg-woot-500 border-woot-600;
    span {
      @apply bg-white translate-x-3;
    }
  }

  &.small {
    @apply w-7;

    span {
      @apply w-2.5 h-2.5 my-0.5 ms-0.5;
      &.active {
        @apply translate-x-3;
      }
    }
  }
}
</style>
