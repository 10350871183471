<template>
  <li
    :class="{
      'tabs-title': true,
      'is-active': active,
    }"
  >
    <a class="flex flex-row items-center cursor-pointer py-1.5" @click="onTabClick">
      <span v-if="icon" :style="{ marginRight: '0.4rem' }">
        <fluent-icon v-bind="iconProps" :icon="icon" />
      </span>
      <span> {{ name }} </span>
      <div v-if="showBadge" class="badge">
        <span> 
          {{ getItemCount }}
        </span>
      </div>
    </a>
  </li>
</template>
<script>
export default {
  name: 'WootTabsItem',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    iconProps: {
      type: Object,
      default: () => ({
        size: '14',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
    showBadge: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    active() {
      return this.index === this.$parent.index;
    },

    getItemCount() {
      return this.count;
    },
  },

  methods: {
    onTabClick(event) {
      event.preventDefault();
      if (!this.disabled) {
        this.$parent.$emit('change', this.index);
      }
    },
  },
};
</script>
