<template>
  <section
    class="contacts-table-wrap relative flex h-full w-full flex-wrap overflow-auto bg-white dark:bg-gray-900"
  >
    <progress-bar v-if="isLoading" />

    <ve-table
      v-if="records.length"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :cell-selection-option="cellSelectionOption"
      :sort-option="sortOption"
      :event-custom-option="eventCustomOption"
      :checkbox-option="checkboxOption"
      row-key-field-name="rowKey"
      scroll-width="160rem"
      fixed-header
    />

    <empty-state
      v-if="showSearchEmptyState"
      :title="$t('CONTACTS_PAGE.LIST.NO_CONTACTS_FOUND')"
    />
    <empty-state
      v-else-if="!isLoading && !records.length"
      :title="$t('CONTACTS_PAGE.LIST.404')"
    />
  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';
import ProgressBar from '../../../../components/widgets/ProgressBar.vue';
import EmptyState from '../../../../components/widgets/EmptyState.vue';
import Thumbnail from '../../../../components/widgets/Thumbnail.vue';
import FluentIcon from '../../../../../shared/components/FluentIcon/DashboardIcon.vue';
import rtlMixin from '../../../../../shared/mixins/rtlMixin';
import timeMixin from '../../../../mixins/time';
import { getCountryFlag } from '../../../../helper/flag';

export default {
  components: {
    ProgressBar,
    EmptyState,
    VeTable,
  },
  mixins: [timeMixin, rtlMixin],
  props: {
    records: { type: Array, default: () => [] },
    showSearchEmptyState: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    deselected: { type: Boolean, default: false },
    sortParam: {
      type: String,
      default: 'name',
    },
    sortConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showProgressBar: false,
      isLoadingTimeout: null,
      sortOption: {
        sortAlways: true,
        sortChange: (params) => {
          this.$emit('on-sort-change', params);
        },
      },
      eventCustomOption: {
        bodyRowEvents: ({ row }) => {
          return {
            click: (event) => {
              if (event.target.type !== 'checkbox') {
                const currentRowKey = row.rowKey;
                this.openContactInfoPanel(currentRowKey);
              }
            },
          };
        },
      },
      checkboxOption: {
        selectedRowKeys: [],
        selectedRowChange: ({ selectedRowKeys }) => {
          this.emitSelects(selectedRowKeys);
          this.changeSelectedRowKeys(selectedRowKeys);
        },
        selectedAllChange: ({ isSelected, selectedRowKeys }) => {
          if (isSelected) {
            this.selectedAll(selectedRowKeys);
          } else {
            this.deselectedAll();
          }
        },
      },
      cellSelectionOption: {
        enable: false,
      },
    };
  },
  computed: {
    tableData() {
      return this.records.map((item) => {
        const additional = item.additional_attributes || {};
        const { last_activity_at: lastActivityAt } = item;
        const { created_at: createdAt } = item;
        return {
          ...item,
          rowKey: item.id,
          phone_number: item.phone_number || '---',
          company: additional.company_name || '---',
          profiles: additional.social_profiles || {},
          city: additional.city || '---',
          country: additional.country,
          countryCode: additional.country_code,
          conversationsCount: item.conversations_count || '---',
          last_activity_at: lastActivityAt
            ? this.dynamicTime(lastActivityAt)
            : '---',
          created_at: createdAt ? this.dynamicTime(createdAt) : '---',
        };
      });
    },
    columns() {
      return [
        {
          field: '',
          key: 'check',
          fixed: 'left',
          type: 'checkbox',
          title: '',
          width: 50,
          align: 'center',
        },
        {
          field: 'name',
          key: 'name',
          rowKey: 'name',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.NAME'),
          fixed: 'left',
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.name || '',
          width: 300,
          renderBodyCell: ({ row }) => (
            <a class="stretched-link flex items-center gap-2">
              <Thumbnail
                src={row.thumbnail}
                size="32px"
                username={row.name}
                status={row.availability_status}
                class="flex-shrink-0"
              />
              <div class="overflow-hidden text-ellipsis whitespace-nowrap text-left text-base">
                {row.name}
              </div>
            </a>
          ),
        },
        {
          field: 'email',
          rowKey: 'email',
          key: 'email',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.EMAIL_ADDRESS'),
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.email || '',
          width: 240,
          renderBodyCell: ({ row }) => {
            if (row.email)
              return (
                <div class="overflow-hidden text-ellipsis whitespace-nowrap">
                  {row.email}
                </div>
              );
            return '---';
          },
        },
        {
          field: 'phone_number',
          rowKey: 'phone_number',
          key: 'phone_number',
          sortBy: this.sortConfig.phone_number || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.PHONE_NUMBER'),
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'company',
          rowKey: 'company',
          key: 'company',
          sortBy: this.sortConfig.company || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.COMPANY'),
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'city',
          rowKey: 'city',
          key: 'city',
          sortBy: this.sortConfig.city || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.CITY'),
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'country',
          rowKey: 'country',
          key: 'country',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.COUNTRY'),
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.country || '',
          renderBodyCell: ({ row }) => {
            if (row.country) {
              return (
                <div class="overflow-hidden text-ellipsis whitespace-nowrap">
                  {`${getCountryFlag(row.countryCode)} ${row.country}`}
                </div>
              );
            }
            return '---';
          },
        },
        {
          field: 'profiles',
          rowKey: 'profiles',
          key: 'profiles',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.SOCIAL_PROFILES'),
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            const { profiles } = row;

            const items = Object.keys(profiles);

            if (!items.length) return '---';

            return (
              <div class="cell--social-profiles flex items-center gap-0.5">
                {items.map(
                  (profile) =>
                    profiles[profile] && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        href={`https://${profile}.com/${profiles[profile]}`}
                      >
                        <FluentIcon icon={`brand-${profile}`} />
                      </a>
                    )
                )}
              </div>
            );
          },
        },
        {
          field: 'last_activity_at',
          rowKey: 'last_activity_at',
          key: 'last_activity_at',
          sortBy: this.sortConfig.last_activity_at || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.LAST_ACTIVITY'),
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'created_at',
          key: 'created_at',
          sortBy: this.sortConfig.created_at || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.CREATED_AT'),
          align: this.isRTLView ? 'right' : 'left',
        },
      ];
    },
  },
  watch: {
    deselected(newVal) {
      if (newVal) {
        this.checkboxOption.selectedRowKeys = [];
      }
    },
    selectedClean() {
      if (this.selectedClean) {
        this.checkboxOption.selectedRowKeys = [];
        this.$emit('disable-deselect');
      }
    },
  },
  methods: {
    openContactInfoPanel(id) {
      this.$emit('open-contact-info-panel', id);
    },
    emitSelects(keys) {
      this.$emit('select-row', keys);
    },
    selectedAll(keys) {
      this.checkboxOption.selectedRowKeys = keys;
      this.$emit('select-all', keys);
    },
    deselectedAll() {
      this.checkboxOption.selectedRowKeys = [];
      this.$emit('deselect-all', []);
    },
    changeSelectedRowKeys(keys) {
      this.checkboxOption.selectedRowKeys = keys;
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts-table-wrap::v-deep {
  /*
  .ve-table table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr  {
    @apply relative;
    td.ve-table-body-td.ve-table-fixed-right {

    }
  }
*/
}
/*
.contacts-table-wrap::v-deep {
  .ve-table {
    // @apply pb-8;
  }

  .row--user-block {
    @apply items-center flex text-left;

    .user-block {
      @apply items-start flex flex-col my-0 mx-2;
    }

    .user-name {
      @apply text-sm font-medium m-0 capitalize;
    }

    .view-details--button {
      @apply text-gray-600 dark:text-gray-200;
    }

    .user-email {
      @apply m-0;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }

  .ve-table-sort {
    @apply -top-1;
  }
}
*/

.cell--social-profiles {
  a {
    @apply min-w-[2rem] text-center text-lg text-gray-300 dark:text-gray-400;
  }
}
</style>
