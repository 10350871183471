<template>
  <div v-on-clickaway="onCloseAgentList" class="bulk-action__agents">
    <div class="dropdown-pane-header">
      <span>{{ $t('BULK_ACTION.AGENT_SELECT_LABEL') }}</span>
      <woot-button
        size="tiny"
        variant="clear"
        color-scheme="secondary"
        icon="dismiss"
        @click="onClose"
      />
    </div>
    <div class="container">
      <div
        v-if="assignableAgentsUiFlags.isFetching"
        class="agent__list-loading"
      >
        <spinner />
        <p>{{ $t('BULK_ACTION.AGENT_LIST_LOADING') }}</p>
      </div>
      <div v-else class="agent__list-container">
        <ul v-if="!selectedAgent">
          <li class="search-container mb-1">
              <input
                ref="search"
                v-model="query"
                type="search"
                placeholder="Search"
                class="small"
              />
          </li>
          <li v-for="agent in filteredAgents" :key="agent.id">
            <div class="agent-list-item" @click="assignAgent(agent)">
              <thumbnail
                :src="agent.thumbnail"
                :status="agent.availability_status"
                :username="agent.name"
                size="22px"
                class="flex-shrink-0"
              />
              <span class="reports-option__title">{{ agent.name }}</span>
            </div>
          </li>
        </ul>
        <div v-else class="agent-confirmation-container">
          <p class="px-1" v-if="selectedAgent.id">
            {{
              $t('BULK_ACTION.ASSIGN_CONFIRMATION_LABEL', {
                conversationCount,
                conversationLabel,
              })
            }}
            <strong>
              {{ selectedAgent.name }}
            </strong>
            <span>?</span>
          </p>
          <p class="px-1" v-else>
            {{
              $t('BULK_ACTION.UNASSIGN_CONFIRMATION_LABEL', {
                conversationCount,
                conversationLabel,
              })
            }}
          </p>
          <div class="agent-confirmation-actions">
            <woot-button
              color-scheme="secondary"
              variant="clear"
              size="small"
              @click="goBack"
            >
              {{ $t('BULK_ACTION.GO_BACK_LABEL') }}
            </woot-button>
            <woot-button
              color-scheme="primary"
              variant="flat"
              size="small"
              :is-loading="uiFlags.isUpdating"
              @click="submit"
            >
              {{ $t('BULK_ACTION.YES') }}
            </woot-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import Spinner from 'shared/components/Spinner.vue';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  components: {
    Thumbnail,
    Spinner,
  },
  mixins: [clickaway],
  props: {
    selectedInboxes: {
      type: Array,
      default: () => [],
    },
    conversationCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      query: '',
      selectedAgent: null,
      goBackToAgentList: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'bulkActions/getUIFlags',
      inboxes: 'inboxes/getInboxes',
      assignableAgentsUiFlags: 'inboxAssignableAgents/getUIFlags',
    }),
    filteredAgents() {
      if (this.query) {
        return this.assignableAgents.filter(agent =>
          agent.name.toLowerCase().includes(this.query.toLowerCase())
        );
      }
      return [
        {
          confirmed: true,
          name: 'None',
          id: null,
          role: 'agent',
          account_id: 0,
          email: 'None',
        },
        ...this.assignableAgents,
      ];
    },
    assignableAgents() {
      return this.$store.getters['inboxAssignableAgents/getAssignableAgents'](
        this.selectedInboxes.join(',')
      );
    },
    conversationLabel() {
      return this.conversationCount > 1 ? 'conversations' : 'conversation';
    },
  },
  mounted() {
    this.$store.dispatch('inboxAssignableAgents/fetch', this.selectedInboxes);
  },
  methods: {
    submit() {
      this.$emit('select', this.selectedAgent);
    },
    goBack() {
      this.goBackToAgentList = true;
      this.selectedAgent = null;
    },
    assignAgent(agent) {
      this.selectedAgent = agent;
    },
    onClose() {
      this.$emit('close');
    },
    onCloseAgentList() {
      if (this.selectedAgent === null && !this.goBackToAgentList) {
        this.onClose();
      }
      this.goBackToAgentList = false;
    },
  },
};
</script>

<style scoped lang="scss">
.bulk-action__agents {
  @apply max-w-[75%] absolute right-2 bottom-11 origin-top-right w-auto z-20 min-w-[15rem] bg-white dark:bg-gray-800 rounded-lg border dark:border-gray-700 shadow-lg;
  .container {
    @apply overflow-y-auto max-h-[15rem] px-1 pb-1;
  }
}

ul {
  @apply m-0 list-none flex flex-col gap-1;
  
  .agent-list-item {
    @apply flex items-center gap-1 text-sm p-1 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200 rounded cursor-pointer;
  }
}


.agent-confirmation-container {
  .agent-confirmation-actions {
    @apply w-full flex gap-2 justify-end;
  }
}

.agent__list-loading {
  @apply m-2.5 rounded-md bg-gray-50 dark:bg-gray-900 flex items-center justify-center flex-col p-5 h-[calc(95%-6.25rem)];
}
</style>
