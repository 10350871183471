var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chatlyn-media-player relative !z-10 flex h-full flex-col rounded-lg bg-gray-200 dark:bg-gray-500",attrs:{"id":_vm.mediaPlayerContainerId}},[_c('div',{staticClass:"chatlyn-media-player__media h-[calc(100%_-_2.8125rem)] flex-1"},[(_vm.type === _vm.CHATLYN_MEDIA_PLAYER_TYPE.AUDIO)?_c('audio',{attrs:{"id":_vm.mediaPlayerId,"preload":_vm.preload},on:{"playing":() => {
          _vm.handleIsPausedChange(false);
        },"pause":() => {
          _vm.handleIsPausedChange(true);
        },"timeupdate":_vm.handleTimeUpdate,"loadeddata":_vm.handleOnLoadedData,"volumechange":_vm.handleVolumeChange,"error":_vm.handleOnError}},[(_vm.mediaPlayerElement && _vm.mediaSrc)?_c('source',{attrs:{"src":_vm.mediaSrc}}):_vm._e()]):(_vm.type === _vm.CHATLYN_MEDIA_PLAYER_TYPE.VIDEO)?_c('video',{staticClass:"h-full w-full rounded-tl-lg rounded-tr-lg",class:{
        'cursor-not-allowed': !_vm.canPlay,
        'cursor-pointer': _vm.canPlay,
      },attrs:{"id":_vm.mediaPlayerId,"preload":_vm.preload},on:{"click":_vm.toggleIsPaused,"playing":() => {
          _vm.handleIsPausedChange(false);
        },"pause":() => {
          _vm.handleIsPausedChange(true);
        },"timeupdate":_vm.handleTimeUpdate,"loadeddata":_vm.handleOnLoadedData,"volumechange":_vm.handleVolumeChange,"error":_vm.handleOnError}},[(_vm.mediaPlayerElement && _vm.mediaSrc)?_c('source',{attrs:{"src":_vm.mediaSrc}}):_vm._e()]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"chatlyn-media-player__controls flex w-full flex-row flex-nowrap items-center gap-1 p-2 text-gray-800 dark:text-white",class:{
      'border-t border-solid border-gray-100 dark:border-gray-400':
        _vm.type === _vm.CHATLYN_MEDIA_PLAYER_TYPE.VIDEO,
    }},[_c('woot-button',{staticClass:"!h-auto !w-auto !bg-transparent !p-1.5 !text-gray-800 dark:!text-white",attrs:{"icon":_vm.playButtonIcon,"variant":"clear","size":"small","type":"button","disabled":!_vm.canPlay},on:{"click":_vm.toggleIsPaused}}),_vm._v(" "),_c('span',{staticClass:"inline-flex w-20 items-center justify-center overflow-hidden text-ellipsis whitespace-nowrap text-xs"},[_vm._v("\n      "+_vm._s(_vm.formattedProgress)+" / "+_vm._s(_vm.formattedDuration)+"\n    ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.progressComputed),expression:"progressComputed"}],staticClass:"progressbar-light dark:progressbar-dark flex-1",class:{
        'cursor-not-allowed': !_vm.canPlay,
        'cursor-pointer': _vm.canPlay,
      },style:({
        '--filledTrack': _vm.progressFilledPercent,
      }),attrs:{"min":0,"max":_vm.duration,"step":0.01,"type":"range","disabled":!_vm.canPlay},domProps:{"value":(_vm.progressComputed)},on:{"__r":function($event){_vm.progressComputed=$event.target.value}}}),_vm._v(" "),_c('span',{staticClass:"chatlyn-media-player__controls__volume relative inline-flex"},[_c('woot-button',{staticClass:"chatlyn-media-player__controls__volume__button !h-auto !w-auto !bg-transparent !p-1.5 !text-gray-800 dark:!text-white",attrs:{"icon":_vm.volumeButtonIcon,"variant":"clear","size":"small","type":"button","disabled":!_vm.canPlay},on:{"click":_vm.toggleIsMutedMedia}}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.canPlay),expression:"canPlay"}],staticClass:"chatlyn-media-player__controls__volume__range absolute right-0 z-50 hidden w-20 rounded-bl-lg rounded-br-lg bg-gray-200 px-2 shadow-md dark:bg-gray-500",class:{
          'top-full': !_vm.isFullscreen,
          'bottom-full': _vm.isFullscreen,
        }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.volume),expression:"volume"}],staticClass:"progressbar-light dark:progressbar-dark h-full w-full",style:({
            '--filledTrack': _vm.volumeFilledPercent,
          }),attrs:{"min":0,"max":100,"step":1,"disabled":!_vm.canPlay,"type":"range"},domProps:{"value":(_vm.volume)},on:{"__r":function($event){_vm.volume=$event.target.value}}})])],1),_vm._v(" "),(_vm.type === _vm.CHATLYN_MEDIA_PLAYER_TYPE.VIDEO)?_c('woot-button',{staticClass:"!h-auto !w-auto !bg-transparent !p-1.5 !text-gray-800 dark:!text-white",attrs:{"icon":_vm.fullscreenButtonIcon,"variant":"clear","size":"small","type":"button","disabled":!_vm.canPlay},on:{"click":_vm.toggleIsFullscreen}}):_vm._e(),_vm._v(" "),_c('woot-button',{staticClass:"!h-auto !w-auto !bg-transparent !p-1.5 !text-gray-800 dark:!text-white",attrs:{"icon":"arrow-download","variant":"clear","size":"small","type":"button","disabled":!_vm.canPlay},on:{"click":_vm.downloadMedia}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }