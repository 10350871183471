import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state = {
  record: null,
  conversationStatus: null,
  aiNests: [],
  aiSuggestion: [],
  uiFlags: {
    isFetching: false,
    isUpdating: false,
    isTranslationsAdding: false,
    isTranslationsDeleting: false,
    isConversationStatusFetching: false,
    isConversationStatusUpdating: false,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
