<template>
  <div
    class="message-text__wrap"
    :class="{
      'show--quoted': isQuotedContentPresent,
      'hide--quoted': !isQuotedContentPresent,
    }"
  >
    <template v-if="message">
      <div
        v-if="isEmail"
        v-dompurify-html="isHTMLEmail ? message.trim() : formattedMessage"
        class="text-content"
      />

      <div v-else v-dompurify-html="message.trim()" class="text-content" />
    </template>

    <button
      v-if="showQuoteToggle"
      class="text-muted cursor-pointer py-1 text-xs"
      @click="toggleQuotedContent"
    >
      <span v-if="showQuotedContent" class="flex items-center gap-0.5">
        <fluent-icon icon="chevron-up" size="16" />
        {{ $t('CHAT_LIST.HIDE_QUOTED_TEXT') }}
      </span>
      <span v-else class="flex items-center gap-0.5">
        <fluent-icon icon="chevron-down" size="16" />
        {{ $t('CHAT_LIST.SHOW_QUOTED_TEXT') }}
      </span>
    </button>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';

export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    isEmail: {
      type: Boolean,
      default: true,
    },
    displayQuotedButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showQuotedContent: false,
    };
  },
  computed: {
    isHTMLEmail() {
      const regExp = /<table(.){0,}>/gm;

      return this.isEmail && regExp.test(this.message);
    },
    isQuotedContentPresent() {
      if (!this.isEmail) {
        return this.message.includes('<blockquote');
      }
      return this.showQuotedContent;
    },
    showQuoteToggle() {
      if (!this.isEmail) {
        return false;
      }
      return this.displayQuotedButton;
    },

    formattedMessage() {
      if (!this.isEmail) {
        return '';
      }

      let content = DOMPurify.sanitize(
        this.message
          .trim()
          .replaceAll('\r\n', '<br>')
          .replaceAll('\r', '<br>')
          .replaceAll('\n', '<br>')
      );

      const regExp = /<br>(\s){0,}<br>/gm;

      while (regExp.test(content)) {
        content = content.replace(regExp, '<br>');
      }

      return content;
    },
  },
  methods: {
    toggleQuotedContent() {
      this.showQuotedContent = !this.showQuotedContent;
    },
  },
};
</script>
<style lang="scss">
.text-content {
  word-break: break-word;

  table {
    margin: 0;
    border: 0;

    td {
      margin: 0;
      border: 0;
    }

    tr {
      border-bottom: 0 !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-base;
  }
}

.show--quoted {
  blockquote {
    @apply block;
  }
}

.hide--quoted {
  blockquote {
    @apply hidden;
  }
}
</style>
