var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wizard-body flex-shrink-0 flex-grow-0 h-auto flex flex-col items-center justify-center"},[_c('div',{staticClass:"my-6"},[_c('p',{staticClass:"text-2xl text-center"},[_vm._v(_vm._s(_vm.$t('INBOX_MGMT.FINISH.TITLE')))]),_vm._v(" "),_c('p',{staticClass:"text-sm text-center text-muted"},[_vm._v(_vm._s(_vm.message))]),_vm._v(" "),_c('div',{staticClass:"w-full flex flex-col"},[(_vm.currentInbox.web_widget_script)?_c('div',{staticClass:"my-3 mx-auto w-2/3"},[_c('woot-code',{attrs:{"script":_vm.currentInbox.web_widget_script}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-2/3 mx-auto"},[(_vm.isATwilioInbox)?_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}):_vm._e()],1),_vm._v(" "),(_vm.isWhatsAppCloudInbox)?_c('div',{staticClass:"w-2/3 mx-auto"},[_c('p',{staticClass:"text-gray-700 dark:text-gray-200 font-medium mt-8"},[_vm._v("\n          "+_vm._s(_vm.$t('INBOX_MGMT.ADD.WHATSAPP.API_CALLBACK.WEBHOOK_URL'))+"\n        ")]),_vm._v(" "),_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}),_vm._v(" "),_c('p',{staticClass:"text-gray-700 dark:text-gray-200 font-medium mt-8"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'INBOX_MGMT.ADD.WHATSAPP.API_CALLBACK.WEBHOOK_VERIFICATION_TOKEN'
            ))+"\n        ")]),_vm._v(" "),_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.provider_config.webhook_verify_token}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-2/3 mx-auto"},[(_vm.isALineInbox)?_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"w-2/3 mx-auto"},[(_vm.isASmsInbox)?_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}):_vm._e()],1),_vm._v(" "),(_vm.isAEmailInbox && !_vm.currentInbox.provider)?_c('div',{staticClass:"w-2/3 mx-auto"},[_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.forward_to_email}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex justify-center gap-2 mt-4"},[_c('router-link',{staticClass:"button secondary smooth",attrs:{"to":{
            name: 'settings_inbox_show',
            params: { inboxId: _vm.$route.params.inbox_id },
          }}},[_vm._v("\n          "+_vm._s(_vm.$t('INBOX_MGMT.FINISH.MORE_SETTINGS'))+"\n        ")]),_vm._v(" "),_c('router-link',{staticClass:"button success",attrs:{"to":{
            name: 'inbox_dashboard',
            params: { inboxId: _vm.$route.params.inbox_id },
          }}},[_vm._v("\n          "+_vm._s(_vm.$t('INBOX_MGMT.FINISH.BUTTON_TEXT'))+"\n        ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }