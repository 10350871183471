<template>
  <formulate-form
    v-slot="{ hasErrors }"
    name="list-edit-form"
    @submit="editList"
  >
    <formulate-input
      v-model="name"
      class="medium-12 columns"
      :label="$t('CHATLYN_GENERAL.LABEL.NAME')"
      :placeholder="$t('CHATLYN_CONTACT_LISTS.PLACEHOLDERS.NAME')"
      validation="required|min:2"
      :validation-messages="{
        required: $t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
        min: $t('CHATLYN_GENERAL.MESSAGES.MIN_LENGTH_2'),
      }"
      :minlength="2"
      type="text"
    />
    <div class="modal-actions mt-4 flex w-full items-center justify-between">
      <woot-button
        color-scheme="alert"
        variant="smooth"
        type="button"
        @click="$emit('handle-delete-list')"
      >
        {{ $t('CHATLYN_GENERAL.BUTTON.DELETE') }}
      </woot-button>

      <woot-button :disabled="hasErrors || contactList.name === name">
        {{ $t('CHATLYN_GENERAL.BUTTON.SAVE') }}
      </woot-button>
    </div>
  </formulate-form>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'ListEdit',
  mixins: [alertMixin],
  props: {
    contactList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      name: '',
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
    }),
  },
  watch: {
    'contactList.name': {
      handler(newValue) {
        this.name = newValue;
      },
      immediate: true,
    },
  },
  methods: {
    editList() {
      const newRecord = {
        name: this.name,
        static: this.contactList.static,
      };
      this.$emit('handle-rename-list', newRecord);
    },
  },
};
</script>

<style lang="scss"></style>
