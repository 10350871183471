<template>
  <div :class="{ dynamic: isDynamicList }" class="list-settings-modal relative">
    <woot-modal-header
      :header-title="$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.TITLE')"
    />
    <div
      :class="
        isDynamicList &&
        'mx-6 my-3 mb-6 rounded-xl bg-gray-100 p-2 dark:bg-gray-900 dark:p-3'
      "
    >
      <div
        class="modal-body row flex flex-col flex-nowrap"
        :class="
          isDynamicList &&
          'rounded-lg border bg-white p-4 dark:border-gray-700 dark:bg-gray-800'
        "
      >
        <div
          v-if="isDynamicList"
          :class="{ 'mb-5': !dynamicExpanded }"
          class="font-base flex w-full cursor-pointer items-center justify-between"
          @click="onToggleExpanded"
        >
          {{ $t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.OPTIONS') }}
          <fluent-icon
            :icon="dynamicExpanded ? 'chevron-right' : 'chevron-down'"
            :size="12"
          />
        </div>
        <div v-if="!dynamicExpanded" class="w-full">
          <woot-tabs
            class="mb-5"
            :index="selectedSettingsTabIndex"
            :border="true"
            @change="selectSettingsTab"
          >
            <woot-tabs-item
              v-for="settingsTab in settingsTabs"
              :key="settingsTab.key"
              :name="settingsTab.name"
              :icon="settingsTab.icon"
              :show-badge="false"
              class="flex-1"
            />
          </woot-tabs>

          <Transition name="fade" mode="out-in">
            <list-edit
              v-if="selectedSettingsTabIndex === 0"
              :contact-list="contactList"
              @handle-rename-list="onListRename"
              @handle-delete-list="onToggleDeleteModal"
              @on-close="onClose"
            />

            <list-duplicate
              v-else-if="selectedSettingsTabIndex === 1"
              :contact-list="contactList"
              @handle-delete-list="onToggleDeleteModal"
              @on-close="onClose"
            />

            <list-merge
              v-else-if="selectedSettingsTabIndex === 2"
              :contact-list="contactList"
              @handle-delete-list="onToggleDeleteModal"
              @on-close="onClose"
            />

            <list-move
              v-else-if="selectedSettingsTabIndex === 3"
              :contact-list="contactList"
              @handle-delete-list="onToggleDeleteModal"
              @on-close="onClose"
            />
          </Transition>
        </div>
      </div>
      <div
        v-if="isDynamicList"
        class="mt-2 flex flex-col items-end gap-4 rounded-lg border bg-white p-4 dark:mt-3 dark:border-gray-700 dark:bg-gray-800"
      >
        <div
          :class="{ 'mb-0': dynamicExpanded }"
          class="flex w-full cursor-pointer items-center justify-between text-base"
          @click="onToggleExpanded"
        >
          {{ $t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.CONDITIONS') }}
          <fluent-icon
            :icon="dynamicExpanded ? 'chevron-down' : 'chevron-right'"
            :size="12"
          />
        </div>
        <template v-if="dynamicExpanded">
          <list-condition
            :list-condition="condition"
            class="w-full"
            @update:condition="onConditionUpdate"
          />
          <woot-button
            :disabled="!isValidCondition || !isDirtyCondition"
            @click="onUpdateCondition"
          >
            {{ $t('CHATLYN_GENERAL.BUTTON.SAVE') }}
          </woot-button>
        </template>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
      style="background: rgba(0, 0, 0, 0.05)"
    >
      <span class="spinner h-8 w-8" />
    </div>

    <delete-modal
      :show="isDeleteModalShown"
      :title="
        $t('CHATLYN_CONTACT_LISTS.REMOVE_LIST.TITLE', {
          list: contactList.name,
        })
      "
      :message="$t('CHATLYN_CONTACT_LISTS.REMOVE_LIST.MESSAGE')"
      :reject-text="$t('CHATLYN_CONTACT_LISTS.REMOVE_LIST.CANCEL')"
      :confirm-text="$t('CHATLYN_CONTACT_LISTS.REMOVE_LIST.CONFIRM')"
      :on-close="onToggleDeleteModal"
      :on-confirm="onListDelete"
      :has-buttons="!listEntrypoints.length"
    >
      <template v-if="listEntrypoints.length" #body>
        <div class="callout warning mb-4 flex flex-row gap-2 text-xs">
          <span>
            <fluent-icon icon="lock-closed" :size="12" />
          </span>

          <span>
            <div>
              {{ $t('CHATLYN_CONTACT_LISTS.REMOVE_LIST.ALERT.TITLE') }}

              <ul class="list-disc">
                <li
                  v-for="entrypoint in listEntrypoints"
                  :key="entrypoint.entrypointId"
                  class="ml-6"
                >
                  {{ entrypoint.name }}
                </li>
              </ul>

              {{ $t('CHATLYN_CONTACT_LISTS.REMOVE_LIST.ALERT.TEXT') }}
            </div>
          </span>
        </div>
      </template>
    </delete-modal>
  </div>
</template>
<script>
import DeleteModal from 'dashboard/components/widgets/modal/DeleteModal';
import alertMixin from 'shared/mixins/alertMixin';
import ListEdit from './components/ListEdit';
import ListDuplicate from './components/ListDuplicate.vue';
import ListMerge from './components/ListMerge.vue';
import ListCondition from './components/ListCondition';
import listActions from './mixins/listActions';
import ListMove from './components/ListMove.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ListSettings',
  components: {
    DeleteModal,
    ListEdit,
    ListDuplicate,
    ListMerge,
    ListCondition,
    ListMove,
  },
  mixins: [alertMixin, listActions],
  data() {
    return {
      selectedSettingsTabIndex: 0,
      dynamicExpanded: false,
      localCondition: null,
      isValidCondition: false,
      isDeleteModalShown: false,
    };
  },
  computed: {
    ...mapGetters({
      listEntrypoints: 'contactLists/getListEntrypoints',
    }),

    condition() {
      const { conditions, operator } = this.contactList.condition;
      return { conditions, operator };
    },

    settingsTabs() {
      return [
        {
          key: 'rename',
          name: this.$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.TABS.RENAME'),
          icon: 'text',
        },
        {
          key: 'duplicate',
          name: this.$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.TABS.DUPLICATE'),
          icon: 'copy',
        },
        {
          key: 'merge',
          name: this.$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.TABS.MERGE'),
          icon: 'merge',
        },
        {
          key: 'move',
          name: this.$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.TABS.MOVE'),
          icon: 'folder-arrow-right',
        },
      ];
    },
    isDirtyCondition() {
      const withoutIds = JSON.stringify(this.condition, (key, val) =>
        key === '_id' ? undefined : val
      );
      return JSON.stringify(this.localCondition) !== withoutIds;
    },
    isDynamicList() {
      return !this.contactList.static;
    },
  },
  watch: {
    'contactList._id': {
      async handler(newValue) {
        if (!newValue) {
          return;
        }

        await this.getListEntrypoints();
      },
      immediate: true,
    },
  },
  methods: {
    selectSettingsTab(index) {
      this.selectedSettingsTabIndex = index;
    },
    onConditionUpdate({ condition, isValid }) {
      this.localCondition = { ...condition };
      this.isValidCondition = isValid;
    },
    onClose() {
      this.$emit('close');
    },
    onToggleExpanded() {
      this.dynamicExpanded = !this.dynamicExpanded;
    },
    onToggleDeleteModal() {
      this.isDeleteModalShown = !this.isDeleteModalShown;
    },
    async onListRename(newRecord) {
      await this.onListAction(newRecord, 'updateList');
    },
    async onUpdateCondition() {
      const newRecord = {
        name: this.contactList.name,
        condition: this.localCondition,
        static: this.contactList.static,
      };
      await this.onListAction(newRecord, 'updateList');
    },

    async getListEntrypoints() {
      const result = await this.$store.dispatch(
        'contactLists/getListEntrypoints',
        {
          accountId: this.currentAccountId,
          // eslint-disable-next-line no-underscore-dangle
          listId: this.contactList._id,
        }
      );

      if (!result) {
        this.showAlert('CHATLYN_CONTACT_LISTS.ENTRYPOINTS_ERROR_MESSAGE');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-settings-modal {
  /*&.dynamic {
    width: 800px;
  }
  */
  ::v-deep .tabs {
    @apply w-full p-0;
    .tabs-title {
      a {
        @apply justify-center;
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
