<template>
  <header
    class="flex flex-col gap-2 border-b bg-white py-2 pe-2 ps-4 dark:border-gray-800 dark:bg-gray-900"
  >
    <div v-if="isContactListsPage" class="flex items-center justify-between">
      <h1 class="page-title flex flex-nowrap items-center gap-2">
        <woot-sidemenu-icon />
        {{ currentList.name }}
      </h1>

      <div class="flex items-center gap-2">
        <woot-button
          color-scheme="primary"
          icon="send"
          :is-disabled="
            contactListContacts.length === 0 || currentList.isArchived
          "
          @click="onToggleSendMessage"
        >
          {{ $t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.BUTTON') }}
        </woot-button>

        <woot-button
          color-scheme="secondary"
          icon="settings"
          class="clear"
          @click="onToggleEditList"
        />
      </div>
    </div>

    <div class="flex items-center justify-between">
      <div
        v-if="!isContactListsPage"
        class="me-4 flex flex-grow items-center gap-2"
      >
        <h1 class="page-title flex flex-nowrap items-center gap-2">
          <woot-sidemenu-icon />
          {{ headerTitle }}
        </h1>
      </div>

      <div
        class="flex flex-nowrap items-center gap-1"
        :class="{ 'w-full': isContactListsPage }"
      >
        <div
          v-if="!hasAppliedContactCondition && !hasAppliedListCondition"
          class="search-wrap relative flex min-w-[150px] items-center"
          :class="isContactListsPage ? 'flex-grow' : 'max-w-[400px]'"
        >
          <div class="absolute left-2.5 flex h-full items-center">
            <fluent-icon
              icon="search"
              class="h-5 text-sm leading-9 text-gray-700 dark:text-gray-200"
            />
          </div>

          <input
            type="text"
            :placeholder="$t('CONTACTS_PAGE.SEARCH_INPUT_PLACEHOLDER')"
            class="contact-search border dark:border-gray-600"
            :value="searchQuery"
            @keyup.enter="submitSearch"
            @input="inputSearch"
          />

          <woot-button
            :is-loading="false"
            class="clear"
            size="small"
            :class-names="searchButtonClass"
            @click="submitSearch"
          >
            {{ $t('CONTACTS_PAGE.SEARCH_BUTTON') }}
          </woot-button>
        </div>

        <div v-if="hasActiveSegments" class="flex gap-2">
          <woot-button
            class="clear"
            color-scheme="secondary"
            icon="edit"
            @click="onToggleEditSegmentsModal"
          >
            {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_EDIT') }}
          </woot-button>

          <woot-button
            class="clear"
            color-scheme="alert"
            icon="delete"
            @click="onToggleDeleteSegmentsModal"
          >
            {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_DELETE') }}
          </woot-button>
        </div>

        <div v-if="!hasActiveSegments" class="ml-auto flex flex-row">
          <div class="relative">
            <woot-button
              :class="{
                'rounded-br-none rounded-tr-none':
                  hasAppliedFilters ||
                  hasAppliedContactCondition ||
                  hasAppliedListCondition,
                smooth:
                  hasAppliedFilters ||
                  hasAppliedContactCondition ||
                  hasAppliedListCondition,
                clear:
                  (!hasAppliedFilters && !hasAppliedContactCondition) ||
                  hasAppliedListCondition,
              }"
              color-scheme="secondary"
              icon="filter"
              data-testid="toggle-show-filter-contacts-modal"
              @click="toggleFilter"
            >
              {{ $t('CONTACTS_PAGE.FILTER_CONTACTS') }}
            </woot-button>

            <div
              v-if="
                hasAppliedFilters ||
                hasAppliedContactCondition ||
                hasAppliedListCondition
              "
              class="dark:bg-500 absolute right-1 top-1 h-2 w-2 gap-1 rounded-full bg-gray-400"
            />
          </div>

          <woot-button
            v-if="
              hasAppliedFilters ||
              hasAppliedContactCondition ||
              hasAppliedListCondition
            "
            :class="
              hasAppliedFilters ||
              hasAppliedContactCondition ||
              hasAppliedListCondition
                ? 'smooth'
                : 'clear'
            "
            class="rounded-bl-none rounded-tl-none"
            color-scheme="secondary"
            icon="dismiss"
            data-testid="clear-contacts-filters"
            @click="onClearCondition"
          />
        </div>

        <template
          v-if="!hasAppliedContactCondition && !hasAppliedListCondition"
        >
          <woot-button
            color-scheme="secondary"
            icon="upload"
            class="clear"
            data-testid="toggle-show-import-contact-modal"
            @click="toggleImport"
          >
            {{ $t('IMPORT_CONTACTS.BUTTON_LABEL') }}
          </woot-button>

          <woot-button
            v-if="isAdmin"
            color-scheme="secondary"
            icon="download"
            class="clear"
            data-testid="toggle-show-export-contact-modal"
            @click="
              $route.name === 'contacts_lists_dashboard'
                ? onToggleExportContactsList()
                : onToggleExportContacts()
            "
          >
            {{ $t('EXPORT_CONTACTS.BUTTON_LABEL') }}
          </woot-button>

          <woot-button
            v-if="!isContactListsPage"
            color-scheme="primary"
            icon="person-add"
            data-testid="toggle-show-create-contact-modal"
            @click="$emit('toggle-show-create-contact-modal')"
          >
            {{ $t('CHATLYN_GENERAL.BUTTON.NEW_CONTACT') }}
          </woot-button>

          <woot-button
            v-if="isAdmin && isContactListsPage"
            v-tooltip="tooltipMessage"
            :is-disabled="!staticList"
            color-scheme="primary"
            icon="person-add"
            variant="clear"
            data-testid="toggle-show-add-to-contact-list-modal"
            @click="$emit('toggle-show-add-to-contact-list-modal')"
          >
            {{ $t('CHATLYN_CONTACT_LISTS.ADD_TO_LIST.ADD_BUTTON') }}
          </woot-button>
        </template>

        <div v-else class="flex flex-row">
          <woot-button
            class="w-auto rounded-br-none rounded-tr-none"
            color-scheme="success"
            icon="text-bullet-list-add"
            :is-disabled="!contactsCount"
            @click="onToggleShowCreateListByConditionModal"
          >
            {{ $t('CONDITION.CREATE_BY.TOGGLER_LABEL') }}
          </woot-button>

          <chatlyn-popover>
            <template #trigger>
              <woot-button
                class="rounded-bl-none rounded-tl-none"
                color-scheme="success"
                icon="chevron-down"
              />
            </template>

            <template #content="{ togglePopover }">
              <woot-button
                icon="person-add"
                color-scheme="secondary"
                variant="clear"
                :is-disabled="!contactsCount"
                @click="
                  togglePopover();
                  onToggleShowAddToListByConditionModal();
                "
              >
                {{ $t('CONDITION.ADD_BY.TOGGLER_LABEL') }}
              </woot-button>
            </template>
          </chatlyn-popover>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import ChatlynPopover from '../../../../components/ui/ChatlynPopover/ChatlynPopover.vue';

const DEFAULT_CONDITION = {
  conditions: [],
  operator: 'AND',
};

export default {
  components: { ChatlynPopover },
  mixins: [adminMixin],
  props: {
    headerTitle: {
      type: String,
      default: '',
    },

    searchQuery: {
      type: String,
      default: '',
    },

    segmentsId: {
      type: [String, Number],
      default: 0,
    },

    staticList: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showCreateModal: false,
      showImportModal: false,
    };
  },
  computed: {
    tooltipMessage() {
      return !this.staticList
        ? this.$t('CHATLYN_CONTACT_LISTS.ADD_TO_LIST.DYNAMIC_LIST')
        : '';
    },

    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },

    ...mapGetters({
      contacts: 'contacts/getContacts',
      getAppliedContactFilters: 'contacts/getAppliedContactFilters',
      appliedContactCondition: 'contacts/getAppliedContactCondition',
      currentList: 'contactLists/getList',
      currentMeta: 'contactLists/getMeta',
      contactsMeta: 'contacts/getMeta',
      contactListContacts: 'contactLists/getContacts',
      accountId: 'getCurrentAccountId',
    }),

    contactsCount() {
      return this.isContactListsPage
        ? this.currentMeta.itemCount
        : this.contactsMeta.count;
    },

    appliedListCondition() {
      return this.$store.getters['contactLists/getAppliedListCondition'](
        // eslint-disable-next-line no-underscore-dangle
        this.$route.params.listId
      );
    },

    hasAppliedFilters() {
      return (
        this.getAppliedContactFilters && this.getAppliedContactFilters.length
      );
    },

    hasAppliedListCondition() {
      return (
        this.isContactListsPage &&
        Boolean(this.appliedListCondition?.conditions?.length)
      );
    },

    hasAppliedContactCondition() {
      return (
        !this.isContactListsPage &&
        !!this.appliedContactCondition?.conditions?.length
      );
    },

    hasActiveSegments() {
      return this.segmentsId !== 0;
    },

    isContactListsPage() {
      return (
        this.$route.name === 'contacts_lists_dashboard' &&
        Object.keys(this.currentList).length > 0
      );
    },

    isStaticContactList() {
      return !!this.currentList?.static;
    },
  },
  methods: {
    onToggleSegmentsModal() {
      this.$emit('on-toggle-save-filter');
    },

    onToggleEditSegmentsModal() {
      this.$emit('on-toggle-edit-filter');
    },

    onToggleDeleteSegmentsModal() {
      this.$emit('on-toggle-delete-filter');
    },

    toggleCreate() {
      this.$emit('on-toggle-create');
    },

    toggleFilter() {
      this.$emit('on-toggle-filter');
    },

    toggleImport() {
      this.$emit('on-toggle-import');
    },

    submitExport() {
      this.$emit('on-export-submit');
    },

    submitSearch() {
      this.$emit('on-search-submit');
    },

    inputSearch(event) {
      this.$emit('on-input-search', event);
    },

    onToggleSendMessage() {
      this.$emit('on-toggle-send-message');
    },

    onToggleEditList() {
      this.$emit('on-toggle-edit-list');
    },

    onToggleExportContacts() {
      this.$emit('on-toggle-export-contacts');
    },

    onToggleExportContactsList() {
      this.$emit('on-toggle-export-contacts-list');
    },

    onToggleShowCreateListByConditionModal() {
      this.$emit('on-toggle-show-create-list-by-condition-modal');
    },

    onToggleShowAddToListByConditionModal() {
      this.$emit('on-toggle-show-add-to-list-by-condition-modal');
    },

    async onClearCondition() {
      if (this.isContactListsPage) {
        await this.$store.dispatch('contactLists/setAppliedListCondition', {
          listId: this.$route.params.listId,
          condition: DEFAULT_CONDITION,
        });
      } else {
        await this.$store.dispatch(
          'contacts/setContactCondition',
          DEFAULT_CONDITION
        );
      }

      this.$emit('on-clear-condition', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-wrap {
  .contact-search {
    @apply m-0 h-[2.375rem] w-full pl-9 pr-[3.75rem] text-sm;
  }

  .button {
    transition: transform 100ms linear;
    @apply invisible absolute right-1 ml-2 h-8 -translate-x-px px-2 py-0 opacity-0;
  }

  .button.show {
    @apply visible translate-x-0 opacity-100;
  }
}
</style>
