<template>
  <div>
    <woot-modal-header
      :header-title="
        $t('CONDITION.ADD_BY.TITLE', {
          COUNT: contactsCount,
        })
      "
    />

    <form
      name="add-to-list-by-condition"
      class="modal-body flex flex-col gap-3"
      @submit.prevent="handleSubmit"
    >
      <div>
        <multiselect-ch
          v-model="selectedContactList"
          :options="availableStaticListsOptions"
          :multiple="false"
          :max-height="180"
          :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.PLEASE_CHOOSE')"
          @blur="getFieldTouchByName('selectedContactList')"
        />
      </div>

      <div class="flex flex-row flex-nowrap items-center justify-end">
        <woot-button
          class="transition-all duration-300"
          :is-disabled="$v.$invalid"
          color-scheme="primary"
          type="submit"
        >
          {{ $t('CHATLYN_GENERAL.BUTTON.ADD') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import alertMixin from 'shared/mixins/alertMixin';

import MultiselectCh from 'shared/components/ui/Multiselect.vue';

export default {
  name: 'AddToListByConditionModal',

  components: {
    MultiselectCh,
  },

  mixins: [alertMixin],

  props: {
    isContactListsPage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedContactList: {},
    };
  },

  validations: {
    selectedContactList: {
      required,
    },
  },

  computed: {
    ...mapGetters({
      availableStaticLists: 'contactLists/getAvailableStaticLists',
      appliedContactCondition: 'contacts/getAppliedContactCondition',
      contactsMeta: 'contacts/getMeta',
      contactListMeta: 'contactLists/getMeta',
      contactList: 'contactLists/getList',
    }),

    contactListId() {
      // eslint-disable-next-line no-underscore-dangle
      return this.contactList?._id;
    },

    contactsCount() {
      return this.isContactListsPage
        ? this.contactListMeta.itemCount
        : this.contactsMeta.count;
    },

    appliedListCondition() {
      return this.$store.getters['contactLists/getAppliedListCondition'](
        this.contactListId
      );
    },

    availableStaticListsOptions() {
      return this.isContactListsPage
        ? this.availableStaticLists.filter(
            // eslint-disable-next-line no-underscore-dangle
            (list) => list._id !== this.contactListId
          )
        : this.availableStaticLists;
    },
  },

  methods: {
    getFieldTouchByName(name) {
      return this.$v[name]?.$touch();
    },

    getFieldErrorByName(name) {
      return !!this.$v[name]?.$error;
    },

    async handleSubmit() {
      try {
        await this.$v.$touch();

        if (this.$v.$invalid) {
          throw new Error();
        }

        const payload = {
          // eslint-disable-next-line no-underscore-dangle
          condition: this.appliedContactCondition,
        };

        if (this.isContactListsPage) {
          payload.condition = this.appliedListCondition;
          payload.listId = this.contactListId;
        }

        await this.$store.dispatch('contactLists/updateListByCondition', {
          // eslint-disable-next-line no-underscore-dangle
          listId: this.selectedContactList._id,
          payload,
        });

        this.$emit('on-close');

        this.showAlert(this.$t('CONDITION.ADD_BY.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('CONDITION.ADD_BY.ERROR_MESSAGE'));
      }
    },
  },
};
</script>

<style></style>
