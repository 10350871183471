<template>
  <div class="h-full flex-grow overflow-y-auto">
    <div class="chatlyn-container grid gap-8 md:grid-cols-3">
      <div class="col-span-2">
        <p
          v-if="!inboxesList.length"
          class="flex h-full flex-col items-center justify-center"
        >
          {{ $t('INBOX_MGMT.LIST.404') }}
          <router-link
            v-if="isAdmin"
            :to="addAccountScoping('settings/inboxes/new')"
          >
            {{ $t('SETTINGS.INBOXES.NEW_INBOX') }}
          </router-link>
        </p>

        <table v-if="inboxesList.length" class="woot-table -mt-2 w-full">
          <tbody>
            <tr v-for="item in inboxesList" :key="item.id">
              <td class="thumbnail-wrapper">
                <img
                  v-if="item.avatar_url"
                  class="woot-thumbnail"
                  :src="item.avatar_url"
                  alt="No Page Image"
                />
                <img
                  v-else
                  class="woot-thumbnail"
                  src="~dashboard/assets/images/flag.svg"
                  alt="No Page Image"
                />
              </td>
              <!-- Short Code  -->
              <td>
                <span class="agent-name">{{ item.name }}</span>
                <span
                  class="text-xs"
                  v-if="item.channel_type === 'Channel::FacebookPage'"
                >
                  Facebook
                </span>
                <span
                  class="text-xs"
                  v-if="item.channel_type === 'Channel::WebWidget'"
                >
                  Website
                </span>
                <span
                  class="text-xs"
                  v-if="item.channel_type === 'Channel::TwitterProfile'"
                >
                  Twitter
                </span>
                <span
                  class="text-xs"
                  v-if="item.channel_type === 'Channel::TwilioSms'"
                >
                  {{ twilioChannelName(item) }}
                </span>
                <span
                  class="text-xs"
                  v-if="item.channel_type === 'Channel::Whatsapp'"
                >
                  Whatsapp
                </span>
                <span
                  class="text-xs"
                  v-if="item.channel_type === 'Channel::Sms'"
                >
                  Sms
                </span>
                <span
                  class="text-xs"
                  v-if="item.channel_type === 'Channel::Email'"
                >
                  Email
                </span>
                <span
                  class="text-xs"
                  v-if="item.channel_type === 'Channel::Telegram'"
                >
                  Telegram
                </span>
                <span
                  class="text-xs"
                  v-if="item.channel_type === 'Channel::Line'"
                  >Line</span
                >
                <span
                  class="text-xs"
                  v-if="item.channel_type === 'Channel::Api'"
                >
                  <template
                    v-if="
                      item.additional_attributes &&
                      item.additional_attributes.apiInboxType === 'GBM'
                    "
                  >
                    Google Business Messages
                  </template>

                  <template
                    v-else-if="
                      item.additional_attributes &&
                      item.additional_attributes.apiInboxType === 'WeChat'
                    "
                  >
                    WeChat
                  </template>

                  <template
                    v-else-if="
                      item.additional_attributes &&
                      item.additional_attributes.apiInboxType === 'WhatsApp'
                    "
                  >
                    Whatsapp
                  </template>

                  <template v-else>
                    {{ globalConfig.apiChannelName || 'API' }}
                  </template>
                </span>
              </td>

              <!-- Action Buttons -->
              <td>
                <div class="flex items-center justify-end gap-2">
                  <router-link
                    :to="addAccountScoping(`settings/inboxes/${item.id}`)"
                  >
                    <woot-button
                      v-if="isAdmin"
                      v-tooltip.top="$t('INBOX_MGMT.SETTINGS')"
                      size="small"
                      icon="settings"
                      color-scheme="secondary"
                    />
                  </router-link>

                  <woot-button
                    v-if="isAdmin"
                    v-tooltip.top="$t('INBOX_MGMT.DELETE.BUTTON_TEXT')"
                    variant="smooth"
                    color-scheme="alert"
                    size="small"
                    :is-loading="loading[item.id]"
                    icon="delete"
                    @click="openDelete(item)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-span-1">
        <span
          v-dompurify-html="
            useInstallationName(
              $t('INBOX_MGMT.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>
    <settings
      v-if="showSettings"
      :show.sync="showSettings"
      :on-close="closeSettings"
      :inbox="selectedInbox"
    />

    <woot-confirm-delete-modal
      v-if="showDeletePopup"
      :show.sync="showDeletePopup"
      :title="$t('INBOX_MGMT.DELETE.CONFIRM.TITLE')"
      :message="confirmDeleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="selectedInbox.name"
      :confirm-place-holder-text="confirmPlaceHolderText"
      @on-confirm="confirmDeletion"
      @on-close="closeDelete"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Settings from './Settings.vue';
import adminMixin from '../../../../mixins/isAdmin';
import accountMixin from '../../../../mixins/account';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import GoogleBusinessMessages from '../../../../api/googleBusinessMessages';

export default {
  components: {
    Settings,
  },
  mixins: [adminMixin, accountMixin, globalConfigMixin],
  data() {
    return {
      loading: {},
      showSettings: false,
      showDeletePopup: false,
      selectedInbox: {},
    };
  },
  computed: {
    ...mapGetters({
      inboxesList: 'inboxes/getInboxes',
      globalConfig: 'globalConfig/get',
    }),
    // Delete Modal
    deleteConfirmText() {
      return this.$t('INBOX_MGMT.DELETE.CONFIRM.YES');
    },
    deleteRejectText() {
      return this.$t('INBOX_MGMT.DELETE.CONFIRM.NO');
    },
    confirmDeleteMessage() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.MESSAGE')} "${
        this.selectedInbox.name
      }"?`;
    },
    confirmPlaceHolderText() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.PLACE_HOLDER', {
        inboxName: this.selectedInbox.name,
      })}`;
    },
  },
  methods: {
    twilioChannelName(item) {
      const { medium = '' } = item;
      if (medium === 'whatsapp') return 'WhatsApp';
      return 'Twilio SMS';
    },
    openSettings(inbox) {
      this.showSettings = true;
      this.selectedInbox = inbox;
    },
    closeSettings() {
      this.showSettings = false;
      this.selectedInbox = {};
    },
    async deleteInbox({ id }) {
      try {
        await this.$store.dispatch('inboxes/delete', id);

        if (this.selectedInbox?.additional_attributes?.apiInboxType === 'GBM') {
          await GoogleBusinessMessages.deleteGoogleBusinessMessagesInbox(id);
        }

        bus.$emit(
          'newToastMessage',
          this.$t('INBOX_MGMT.DELETE.API.SUCCESS_MESSAGE')
        );
      } catch (error) {
        bus.$emit(
          'newToastMessage',
          this.$t('INBOX_MGMT.DELETE.API.ERROR_MESSAGE')
        );
      }
    },

    confirmDeletion() {
      this.deleteInbox(this.selectedInbox);
      this.closeDelete();
    },
    openDelete(inbox) {
      this.showDeletePopup = true;
      this.selectedInbox = inbox;
    },
    closeDelete() {
      this.showDeletePopup = false;
      this.selectedInbox = {};
    },
  },
};
</script>
