<template>
  <div class="mx-8 my-2">
    <settings-section
      :title="$t('INBOX_MGMT.BUSINESS_HOURS.TITLE')"
      :sub-title="$t('INBOX_MGMT.BUSINESS_HOURS.SUBTITLE')"
    >
      <form @submit.prevent="updateInbox">
        <label
          class="toggle-input-wrap flex items-center gap-2 dark:text-gray-100"
        >
          <input
            v-model="isBusinessHoursEnabled"
            type="checkbox"
            name="toggle-business-hours"
          />

          {{ $t('INBOX_MGMT.BUSINESS_HOURS.TOGGLE_AVAILABILITY') }}
        </label>

        <p class="text-muted my-1 text-xs">
          {{ $t('INBOX_MGMT.BUSINESS_HOURS.TOGGLE_HELP') }}
        </p>

        <div v-if="isBusinessHoursEnabled" class="my-3">
          <div>
            <label class="unavailable-input-wrap">
              {{ $t('INBOX_MGMT.BUSINESS_HOURS.UNAVAILABLE_MESSAGE_LABEL') }}
            </label>

            <div
              v-if="isRichEditorEnabled"
              class="mb-3 rounded border border-gray-200 bg-white p-0 dark:border-gray-600 dark:bg-gray-900"
            >
              <woot-message-editor
                v-model="unavailableMessage"
                :enable-variables="true"
                :is-format-mode="true"
                class="message-editor"
                :min-height="4"
              />
            </div>

            <textarea v-else v-model="unavailableMessage" type="text" />
          </div>

          <div class="timezone-input-wrap mb-3">
            <label>
              {{ $t('INBOX_MGMT.BUSINESS_HOURS.TIMEZONE_LABEL') }}
            </label>

            <multiselect
              v-model="timeZone"
              :options="timeZones"
              deselect-label=""
              select-label=""
              selected-label=""
              track-by="value"
              label="label"
              :close-on-select="true"
              :placeholder="$t('INBOX_MGMT.BUSINESS_HOURS.DAY.CHOOSE')"
              :allow-empty="false"
            />
          </div>

          <label>
            {{ $t('INBOX_MGMT.BUSINESS_HOURS.WEEKLY_TITLE') }}
          </label>

          <div class="mt-1 rounded border p-2 dark:border-gray-700">
            <business-day
              v-for="timeSlot in timeSlots"
              :key="timeSlot.day"
              :day-name="dayNames[timeSlot.day]"
              :time-slot="timeSlot"
              @update="(data) => onSlotUpdate(timeSlot.day, data)"
            />
          </div>

          <div
            v-show="!hasAtLeastOneValidDay"
            class="callout alert mt-2 p-2 text-center text-sm"
          >
            <div>
              {{
                $t('INBOX_MGMT.BUSINESS_HOURS.ERROR_MESSAGE.AT_LEAST_ONE_DAY')
              }}
            </div>
          </div>
        </div>

        <woot-submit-button
          :button-text="$t('INBOX_MGMT.BUSINESS_HOURS.UPDATE')"
          :loading="uiFlags.isUpdating"
          :disabled="hasError"
          class="mt-2"
        />
      </form>
    </settings-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import SettingsSection from 'dashboard/components/SettingsSection.vue';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import BusinessDay from './BusinessDay.vue';
import {
  timeSlotParse,
  timeSlotTransform,
  defaultTimeSlot,
  timeZoneOptions,
} from '../helpers/businessHour';

const DEFAULT_TIMEZONE = {
  label: 'Pacific Time (US & Canada) (GMT-07:00)',
  value: 'America/Los_Angeles',
};

export default {
  components: {
    SettingsSection,
    BusinessDay,
    WootMessageEditor,
  },

  mixins: [alertMixin, inboxMixin],

  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isBusinessHoursEnabled: false,
      unavailableMessage: '',
      timeZone: DEFAULT_TIMEZONE,
      dayNames: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
      },
      timeSlots: [...defaultTimeSlot],
    };
  },

  validations() {
    const validation = {};

    if (this.isBusinessHoursEnabled) {
      validation.checkIfWeekHasAtLeastOneValidDay = () =>
        this.checkIfWeekHasAtLeastOneValidDay(this.timeSlots);
    }

    return validation;
  },

  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags' }),

    hasAtLeastOneValidDay() {
      if (!this.isBusinessHoursEnabled) return true;

      return this.checkIfWeekHasAtLeastOneValidDay(this.timeSlots);
    },

    hasError() {
      if (!this.isBusinessHoursEnabled) return false;

      return (
        this.timeSlots.filter((slot) => slot.from && !slot.valid).length > 0 ||
        !this.hasAtLeastOneValidDay
      );
    },

    timeZones() {
      return [...timeZoneOptions()];
    },

    isRichEditorEnabled() {
      if (
        this.isATwilioChannel ||
        this.isATwitterInbox ||
        this.isAFacebookInbox
      )
        return false;
      return true;
    },
  },

  watch: {
    inbox() {
      this.setDefaults();
    },
  },

  mounted() {
    this.setDefaults();
  },

  methods: {
    setDefaults() {
      const {
        working_hours_enabled: isEnabled = false,
        out_of_office_message: unavailableMessage,
        working_hours: timeSlots = [],
        timezone: timeZone,
      } = this.inbox;
      const slots = timeSlotParse(timeSlots).length
        ? timeSlotParse(timeSlots)
        : defaultTimeSlot;
      this.isBusinessHoursEnabled = isEnabled;
      this.unavailableMessage = unavailableMessage || '';
      this.timeSlots = slots;
      this.timeZone =
        this.timeZones.find((item) => timeZone === item.value) ||
        DEFAULT_TIMEZONE;
    },

    onSlotUpdate(slotIndex, slotData) {
      this.timeSlots = this.timeSlots.map((item) =>
        item.day === slotIndex ? slotData : item
      );
    },

    checkIfWeekHasAtLeastOneValidDay(values) {
      return values?.some((value) => value.valid);
    },

    async updateInbox() {
      try {
        await this.$v.$touch();

        if (this.$v.$invalid) {
          throw new Error();
        }

        const payload = {
          id: this.inbox.id,
          formData: false,
          working_hours_enabled: this.isBusinessHoursEnabled,
          out_of_office_message: this.unavailableMessage,
          working_hours: timeSlotTransform(this.timeSlots),
          timezone: this.timeZone.value,
          channel: {},
        };
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message || this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.message-editor {
  @apply border-0;
}

.unavailable-input-wrap {
  textarea {
    @apply mt-2 min-h-[4rem];
  }
}
</style>
