<template>
  <div v-on-clickaway="onClose" class="bulk-action__teams">
    <div class="dropdown-pane-header">
      <span>{{ $t('BULK_ACTION.TEAMS.TEAM_SELECT_LABEL') }}</span>
      <woot-button
        size="tiny"
        variant="clear"
        color-scheme="secondary"
        icon="dismiss"
        @click="onClose"
      />
    </div>
    <div class="container">
      <div class="team__list-container">
        <ul>
          <li class="search-container mb-1">
              <input
                ref="search"
                v-model="query"
                type="search"
                placeholder="Search"
                class="small"
              />
          </li>
          <template v-if="filteredTeams.length">
            <li v-for="team in filteredTeams" :key="team.id">
              <div class="team__list-item" @click="assignTeam(team)">
                <span class="reports-option__title">{{ team.name }}</span>
              </div>
            </li>
          </template>
          <li v-else>
            <div class="text-muted text-center p-2">
              {{
                $t('BULK_ACTION.TEAMS.NO_TEAMS_AVAILABLE')
              }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';
export default {
  mixins: [clickaway],
  data() {
    return {
      query: '',
      selectedteams: [],
    };
  },
  computed: {
    ...mapGetters({ teams: 'teams/getTeams' }),
    filteredTeams() {
      return [
        { name: 'None', id: 0 },
        ...this.teams.filter(team =>
          team.name.toLowerCase().includes(this.query.toLowerCase())
        ),
      ];
    },
  },
  methods: {
    assignTeam(key) {
      this.$emit('assign-team', key);
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.bulk-action__teams {
  @apply max-w-[75%] absolute right-2 bottom-11 origin-top-right w-auto z-20 min-w-[15rem] bg-white dark:bg-gray-800 rounded-lg border dark:border-gray-700 shadow-lg;

  .container {
    @apply overflow-y-auto max-h-[15rem] px-1 pb-1;
  }
}
ul {
  @apply m-0 list-none flex flex-col gap-1;

  .team__list-item{
    @apply flex text-ellipsis gap-2 text-sm p-1 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200 rounded cursor-pointer;
  }
}

</style>
