<template>
  <div class="wizard-body flex-shrink-0 flex-grow flex h-auto">
    <div class="m-auto text-center">
    <p class="text-2xl">{{ $t('TEAMS_SETTINGS.FINISH.TITLE') }}</p>
    <p class="text-sm my-3 text-muted">{{ $t('TEAMS_SETTINGS.FINISH.MESSAGE') }}</p>
    <router-link
      class="button success"
      :to="{
        name: 'settings_teams_list',
      }"
    >
      {{ $t('TEAMS_SETTINGS.FINISH.BUTTON_TEXT') }}
    </router-link>
    </div>
  </div>
</template>

<script>
export default {

};
</script>
<style lang="scss" scoped>
.website--code {
  @apply my-4 mx-auto max-w-[70%];
}
</style>
