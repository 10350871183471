<template>
  <button
    class="channel relative"
    @click="$emit('click')"
  >
    <span v-if="isNew" class="channel__new-tag">
      {{ $t('INBOX_MGMT.ADD.NEW') }}
    </span>
    <img :src="src" :alt="title" />
    <h3 class="channel__title">
      {{ title }}
    </h3>
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },

    isNew: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">

.channel {
  @apply bg-white dark:bg-gray-800 cursor-pointer flex flex-col transition-all duration-200 ease-in py-4 px-0 items-center border dark:border-gray-700 rounded;

  &:hover {
    @apply border-woot-200 dark:border-woot-700 shadow-lg z-50 dark:bg-woot-800 ;
  }

  &.disabled {
    @apply opacity-60;
  }

  img {
    @apply w-1/2 my-4 mx-auto;
  }

  .channel__title {
    @apply text-gray-800 dark:text-gray-100 text-sm text-center capitalize;
  }

  p {
    @apply text-gray-600 dark:text-gray-300 w-full text-sm;
  }

  .channel__new-tag {
    @apply absolute border border-green-500 text-green-500 px-1.5 text-xs top-4 right-4 rounded-full;
  }
}

.inactive {
  @apply opacity-30 bg-gray-100;

  img {
    filter: grayscale(100%);
  }

  &.channel:hover {
    @apply border-gray-200 dark:border-gray-700 shadow-none cursor-not-allowed;
  }
}
</style>
