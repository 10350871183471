<template>
  <div class="flex-grow overflow-y-auto">
    <div class="chatlyn-container my-8 flex flex-col gap-8">
      <form @submit.prevent="updateUser('profile')">
        <div class="grid gap-8 md:grid-cols-3">
          <div class="col-span-1">
            <div class="block-title">
              {{ $t('PROFILE_SETTINGS.FORM.PROFILE_SECTION.TITLE') }}
            </div>
            <p>{{ $t('PROFILE_SETTINGS.FORM.PROFILE_SECTION.NOTE') }}</p>
          </div>
          <div class="col-span-2 flex flex-col gap-2">
            <div>
              <woot-avatar-uploader
                :label="$t('PROFILE_SETTINGS.FORM.PROFILE_IMAGE.LABEL')"
                :src="avatarUrl"
                @change="handleImageUpload"
              />
              <div v-if="showDeleteButton" class="avatar-delete-btn my-2">
                <woot-button
                  type="button"
                  color-scheme="alert"
                  variant="clear"
                  size="tiny"
                  @click="deleteAvatar"
                >
                  {{ $t('PROFILE_SETTINGS.DELETE_AVATAR') }}
                </woot-button>
              </div>
            </div>
            <label :class="{ error: $v.name.$error }">
              {{ $t('PROFILE_SETTINGS.FORM.NAME.LABEL') }}
              <input
                v-model="name"
                type="text"
                :placeholder="$t('PROFILE_SETTINGS.FORM.NAME.PLACEHOLDER')"
                @input="$v.name.$touch"
              />
              <span v-if="$v.name.$error" class="message">
                {{ $t('PROFILE_SETTINGS.FORM.NAME.ERROR') }}
              </span>
            </label>
            <label :class="{ error: $v.displayName.$error }">
              {{ $t('PROFILE_SETTINGS.FORM.DISPLAY_NAME.LABEL') }}
              <input
                v-model="displayName"
                type="text"
                :placeholder="
                  $t('PROFILE_SETTINGS.FORM.DISPLAY_NAME.PLACEHOLDER')
                "
                @input="$v.displayName.$touch"
              />
            </label>
            <label
              v-if="!globalConfig.disableUserProfileUpdate"
              :class="{ error: $v.email.$error }"
            >
              {{ $t('PROFILE_SETTINGS.FORM.EMAIL.LABEL') }}
              <input
                v-model.trim="email"
                type="email"
                :placeholder="$t('PROFILE_SETTINGS.FORM.EMAIL.PLACEHOLDER')"
                @input="$v.email.$touch"
              />
              <span v-if="$v.email.$error" class="message">
                {{ $t('PROFILE_SETTINGS.FORM.EMAIL.ERROR') }}
              </span>
            </label>
            <div>
              <woot-button type="submit" :is-loading="isProfileUpdating">
                {{ $t('PROFILE_SETTINGS.BTN_TEXT') }}
              </woot-button>
            </div>
          </div>
        </div>
      </form>
      <hr />

      <div class="grid gap-8 md:grid-cols-3">
        <div class="col-span-1">
          <div class="block-title">
            {{ $t('CHATLYN_LANGUAGES.INTERFACE_LANGUAGE.TITLE') }}
          </div>

          <p>
            {{ $t('CHATLYN_LANGUAGES.INTERFACE_LANGUAGE.NOTE') }}
          </p>
        </div>

        <div class="col-span-2 flex flex-col gap-2">
          <label :class="{ error: $v.locale.$error }">
            {{
              $t('CHATLYN_LANGUAGES.INTERFACE_LANGUAGE.LANGUAGE_SELECT_LABEL')
            }}

            <select v-model="locale">
              <option
                v-for="lang in languagesSortedByCode"
                :key="lang.iso_639_1_code"
                :value="lang.iso_639_1_code"
              >
                {{ lang.name }}
              </option>
            </select>
            <span v-if="$v.locale.$error" class="message">
              {{ $t('CHATLYN_GENERAL.MESSAGES.ERROR') }}
            </span>
          </label>
          <div>
            <woot-button
              type="button"
              :is-loading="isProfileUpdating"
              @click="updateUserLanguage"
            >
              {{ $t('CHATLYN_GENERAL.BUTTON.SAVE') }}
            </woot-button>
          </div>
        </div>
      </div>

      <hr />

      <message-signature />

      <hr />

      <div class="grid gap-8 md:grid-cols-3">
        <div class="col-span-1">
          <div class="block-title">
            {{ $t('PROFILE_SETTINGS.FORM.SEND_MESSAGE.TITLE') }}
          </div>
          <p>
            {{ $t('PROFILE_SETTINGS.FORM.SEND_MESSAGE.NOTE') }}
          </p>
        </div>
        <div class="col-span-2 flex flex-row items-start gap-4">
          <button
            v-for="keyOption in keyOptions"
            :key="keyOption.key"
            class="cursor-pointer"
            @click="toggleEditorMessageKey(keyOption.key)"
          >
            <preview-card
              :heading="keyOption.heading"
              :content="keyOption.content"
              :src="keyOption.src"
              :active="isEditorHotKeyEnabled(uiSettings, keyOption.key)"
            />
          </button>
        </div>
      </div>

      <hr />

      <change-password v-if="!globalConfig.disableUserProfileUpdate" />

      <hr />

      <notification-settings />

      <hr />

      <div class="grid gap-8 md:grid-cols-3">
        <div class="col-span-1">
          <div class="block-title">
            {{ $t('PROFILE_SETTINGS.FORM.ACCESS_TOKEN.TITLE') }}
          </div>
          <p>
            {{
              useInstallationName(
                $t('PROFILE_SETTINGS.FORM.ACCESS_TOKEN.NOTE'),
                globalConfig.installationName
              )
            }}
          </p>
        </div>
        <div class="col-span-2">
          <masked-text :value="currentUser.access_token" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { clearCookiesOnLogout } from '../../../../store/utils/api';
import { hasValidAvatarUrl } from 'dashboard/helper/URLHelper';
import NotificationSettings from './NotificationSettings.vue';
import alertMixin from 'shared/mixins/alertMixin';
import ChangePassword from './ChangePassword.vue';
import MessageSignature from './MessageSignature.vue';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import uiSettingsMixin, {
  isEditorHotKeyEnabled,
} from 'dashboard/mixins/uiSettings';
import MaskedText from 'dashboard/components/MaskedText.vue';
import PreviewCard from 'dashboard/components/ui/PreviewCard.vue';
import configMixin from 'shared/mixins/configMixin';
import rtlMixin from 'shared/mixins/rtlMixin';

export default {
  components: {
    NotificationSettings,
    ChangePassword,
    MessageSignature,
    PreviewCard,
    MaskedText,
  },
  mixins: [
    alertMixin,
    globalConfigMixin,
    uiSettingsMixin,
    configMixin,
    rtlMixin,
  ],
  data() {
    return {
      avatarFile: '',
      avatarUrl: '',
      name: '',
      displayName: '',
      email: '',
      isProfileUpdating: false,
      errorMessage: '',
      keyOptions: [
        {
          key: 'enter',
          src: '/assets/images/settings/send-enter.png',
          heading: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.ENTER_KEY.HEADING'
          ),
          content: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.ENTER_KEY.CONTENT'
          ),
        },
        {
          key: 'cmd_enter',
          src: '/assets/images/settings/send-cmd+enter.png',
          heading: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.CMD_ENTER_KEY.HEADING'
          ),
          content: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.CMD_ENTER_KEY.CONTENT'
          ),
        },
      ],
      locale: '',
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(1),
    },
    displayName: {},
    email: {
      required,
      email,
    },
    locale: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
      currentUserLanguage: 'management/getUserLanguage',
    }),

    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
    showDeleteButton() {
      return hasValidAvatarUrl(this.avatarUrl);
    },
  },
  watch: {
    currentUserId(newCurrentUserId, prevCurrentUserId) {
      if (prevCurrentUserId !== newCurrentUserId) {
        this.initializeUser();
      }
    },
  },
  mounted() {
    if (this.currentUserId) {
      this.initializeUser();
      this.initializeUserLanguage();
    }
  },
  methods: {
    isEditorHotKeyEnabled,

    initializeUser() {
      this.name = this.currentUser.name;
      this.email = this.currentUser.email;
      this.avatarUrl = this.currentUser.avatar_url;
      this.displayName = this.currentUser.display_name;
    },

    async updateUser() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.ERROR'));
        return;
      }

      this.isProfileUpdating = true;
      const hasEmailChanged = this.currentUser.email !== this.email;
      try {
        await this.$store.dispatch('updateProfile', {
          name: this.name,
          email: this.email,
          avatar: this.avatarFile,
          displayName: this.displayName,
        });
        this.isProfileUpdating = false;
        if (hasEmailChanged) {
          clearCookiesOnLogout();
          this.errorMessage = this.$t('PROFILE_SETTINGS.AFTER_EMAIL_CHANGED');
        }
        this.errorMessage = this.$t('PROFILE_SETTINGS.UPDATE_SUCCESS');
      } catch (error) {
        this.errorMessage = this.$t('RESET_PASSWORD.API.ERROR_MESSAGE');
        if (error?.response?.data?.error) {
          this.errorMessage = error.response.data.error;
        }
      } finally {
        this.isProfileUpdating = false;
        this.showAlert(this.errorMessage);
      }
    },

    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },

    async deleteAvatar() {
      try {
        await this.$store.dispatch('deleteAvatar');
        this.avatarUrl = '';
        this.avatarFile = '';
        this.showAlert(this.$t('PROFILE_SETTINGS.AVATAR_DELETE_SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('PROFILE_SETTINGS.AVATAR_DELETE_FAILED'));
      }
    },

    toggleEditorMessageKey(key) {
      this.updateUISettings({ editor_message_key: key });
      this.showAlert(
        this.$t('PROFILE_SETTINGS.FORM.SEND_MESSAGE.UPDATE_SUCCESS')
      );
    },

    async initializeUserLanguage() {
      await this.$store.dispatch('management/getUserLanguage', {
        currentUserId: this.currentUserId,
      });

      this.locale = this.currentUserLanguage;
    },

    async updateUserLanguage() {
      if (this.currentUserLanguage === this.locale) {
        return;
      }

      try {
        await this.$store.dispatch('management/setUserLanguage', {
          currentUserId: this.currentUserId,
          language: this.locale,
        });

        if (this.locale !== this.currentUserLanguage) {
          throw new Error();
        }

        this.$root.$i18n.locale = this.currentUserLanguage;
        this.updateRTLDirectionView(this.currentUserLanguage);
      } catch (error) {
        this.showAlert(this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN'));
        this.locale = this.currentUserLanguage;
      }
    },
  },
};
</script>
