var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.onCloseAgentList),expression:"onCloseAgentList"}],staticClass:"bulk-action__agents"},[_c('div',{staticClass:"dropdown-pane-header"},[_c('span',[_vm._v(_vm._s(_vm.$t('BULK_ACTION.AGENT_SELECT_LABEL')))]),_vm._v(" "),_c('woot-button',{attrs:{"size":"tiny","variant":"clear","color-scheme":"secondary","icon":"dismiss"},on:{"click":_vm.onClose}})],1),_vm._v(" "),_c('div',{staticClass:"container"},[(_vm.assignableAgentsUiFlags.isFetching)?_c('div',{staticClass:"agent__list-loading"},[_c('spinner'),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('BULK_ACTION.AGENT_LIST_LOADING')))])],1):_c('div',{staticClass:"agent__list-container"},[(!_vm.selectedAgent)?_c('ul',[_c('li',{staticClass:"search-container mb-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"search",staticClass:"small",attrs:{"type":"search","placeholder":"Search"},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing)return;_vm.query=$event.target.value}}})]),_vm._v(" "),_vm._l((_vm.filteredAgents),function(agent){return _c('li',{key:agent.id},[_c('div',{staticClass:"agent-list-item",on:{"click":function($event){return _vm.assignAgent(agent)}}},[_c('thumbnail',{staticClass:"flex-shrink-0",attrs:{"src":agent.thumbnail,"status":agent.availability_status,"username":agent.name,"size":"22px"}}),_vm._v(" "),_c('span',{staticClass:"reports-option__title"},[_vm._v(_vm._s(agent.name))])],1)])})],2):_c('div',{staticClass:"agent-confirmation-container"},[(_vm.selectedAgent.id)?_c('p',{staticClass:"px-1"},[_vm._v("\n          "+_vm._s(_vm.$t('BULK_ACTION.ASSIGN_CONFIRMATION_LABEL', {
              conversationCount: _vm.conversationCount,
              conversationLabel: _vm.conversationLabel,
            }))+"\n          "),_c('strong',[_vm._v("\n            "+_vm._s(_vm.selectedAgent.name)+"\n          ")]),_vm._v(" "),_c('span',[_vm._v("?")])]):_c('p',{staticClass:"px-1"},[_vm._v("\n          "+_vm._s(_vm.$t('BULK_ACTION.UNASSIGN_CONFIRMATION_LABEL', {
              conversationCount: _vm.conversationCount,
              conversationLabel: _vm.conversationLabel,
            }))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"agent-confirmation-actions"},[_c('woot-button',{attrs:{"color-scheme":"secondary","variant":"clear","size":"small"},on:{"click":_vm.goBack}},[_vm._v("\n            "+_vm._s(_vm.$t('BULK_ACTION.GO_BACK_LABEL'))+"\n          ")]),_vm._v(" "),_c('woot-button',{attrs:{"color-scheme":"primary","variant":"flat","size":"small","is-loading":_vm.uiFlags.isUpdating},on:{"click":_vm.submit}},[_vm._v("\n            "+_vm._s(_vm.$t('BULK_ACTION.YES'))+"\n          ")])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }