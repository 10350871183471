import { ATTRIBUTE_TYPE } from 'dashboard/constants/common';
import {
  AND,
  BOOLEAN_LIST,
  COMPARATOR,
  TRUE,
  FALSE,
} from './conditionConstants';

const {
  EMPTY,
  NOT_EMPTY,
  EQUAL,
  NOT_EQUAL,
  CONTAIN,
  NOT_CONTAIN,
  LESS,
  GREATER,
} = COMPARATOR;

const ANTONYMS = {
  [EQUAL]: NOT_EQUAL,
  [EMPTY]: NOT_EMPTY,
  [LESS]: GREATER,
};
const CONTAINS_COMPARATORS = [CONTAIN, NOT_CONTAIN];

const NUMBER_COMPARATORS = [LESS, GREATER];

const isAntonymComparators = (comparator1, comparator2) =>
  ANTONYMS[comparator1] === comparator2 ||
  ANTONYMS[comparator2] === comparator1;

const isContains = comparator => CONTAINS_COMPARATORS.includes(comparator);

const isGreaterLessValid = (comparator, value1, value2) =>
  comparator === GREATER ? value1 < value2 : value1 > value2;

const isEmpty = ({ comparator, value }) =>
  (comparator === EMPTY && value === TRUE) ||
  (comparator === NOT_EMPTY && value === FALSE);

const isEqualGreaterLess = (comparator1, comparator2) =>
  (NUMBER_COMPARATORS.includes(comparator1) && comparator2 === EQUAL) ||
  (NUMBER_COMPARATORS.includes(comparator2) && comparator1 === EQUAL);

const isSameValue = (value1, value2) => value1 === value2 && value1 && value2;

const valueToNumber = (attributeType, value) =>
  attributeType === ATTRIBUTE_TYPE.DATE && value ? +new Date(value) : +value;

const validateGreaterLess = (
  { attribute, comparator, value },
  { attribute: attribute2, comparator: comparator2, value: value2 }
) => {
  const isFirstEqual = comparator === EQUAL;
  const numberValue1 = valueToNumber(attribute.type, value);
  const numberValue2 = valueToNumber(attribute2.type, value2);
  const isValid = isFirstEqual
    ? isGreaterLessValid(comparator2, numberValue2, numberValue1)
    : isGreaterLessValid(comparator, numberValue1, numberValue2);
  return !(numberValue1 && numberValue2) || isValid;
};

const isContainsValid = (comparator, value1, value2) =>
  comparator === CONTAIN ? value2.includes(value1) : !value2.includes(value1);

const validateContains = (
  { comparator, value },
  { comparator: comparator2, value: value2 },
  checkForAnd = false
) => {
  const bothContains = isContains(comparator) && isContains(comparator2);
  if (bothContains) {
    return !isSameValue(value, value2);
  }
  if (checkForAnd) {
    const isFirstEqual = comparator === EQUAL;
    const isValid = isFirstEqual
      ? isContainsValid(comparator2, value2, value)
      : isContainsValid(comparator, value, value2);
    return !(value && value2) || isValid;
  }
  return true;
};
const validateAND = (condition1, condition2) => {
  const { comparator } = condition1;
  const { comparator: comparator2 } = condition2;

  const antonymComparator = isAntonymComparators(comparator, comparator2);
  const isGreaterLess =
    antonymComparator && NUMBER_COMPARATORS.includes(comparator);
  const isContainsComparator =
    isContains(comparator) || isContains(comparator2);

  if (isEmpty(condition1) || isEmpty(condition2)) return false;

  if (isContainsComparator) {
    return validateContains(condition1, condition2, true);
  }
  if (isGreaterLess || isEqualGreaterLess(comparator, comparator2)) {
    return validateGreaterLess(condition1, condition2);
  }

  return comparator !== comparator2 && !antonymComparator;
};

const validateOR = (condition1, condition2) => {
  const { comparator, value } = condition1;
  const { comparator: comparator2, value: value2 } = condition2;

  const valueConflicts =
    (BOOLEAN_LIST.includes(value) && BOOLEAN_LIST.includes(value2)) ||
    isSameValue(value, value2);
  const antonymComparatorConflicts =
    isAntonymComparators(comparator, comparator2) && valueConflicts;
  const sameComparatorConflicts = comparator === comparator2 && valueConflicts;
  const isContainsComparator =
    isContains(comparator) || isContains(comparator2);

  if (isContainsComparator) {
    return validateContains(condition1, condition2);
  }

  return !antonymComparatorConflicts && !sameComparatorConflicts;
};

const validatePair = (condition1, condition2, operator) => {
  const differentAttr = condition1.attribute.key !== condition2.attribute.key;
  const isValidConditions =
    operator === AND
      ? validateAND(condition1, condition2)
      : validateOR(condition1, condition2);
  return differentAttr || isValidConditions;
};

export const validateConditions = (conditions, operator) => {
  const duplicateConditions = [];

  const pushDuplicate = conditionToPush => {
    const alreadyPushed = duplicateConditions.find(
      condition => condition.id === conditionToPush.id
    );
    if (!alreadyPushed) {
      duplicateConditions.push(conditionToPush);
    }
  };

  // comparing all conditions with each other to check if there any contradictions
  // using two for loops to compare one pair of condition only once

  for (let i = 0; i < conditions.length - 1; i += 1) {
    for (let j = i + 1; j < conditions.length; j += 1) {
      const isValid = validatePair(conditions[i], conditions[j], operator);
      if (!isValid) {
        pushDuplicate(conditions[i]);
        pushDuplicate(conditions[j]);
      }
    }
  }

  return duplicateConditions;
};
