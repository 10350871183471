<template>
  <div class="report-card" :class="{ active: selected }" @click="click">
    <div class="flex items-center gap-1">
      <span class="heading">{{ heading }}</span>
      <fluent-icon
        v-if="infoText"
        v-tooltip="infoText"
        size="14"
        icon="info"
        class="text-muted"
      />
    </div>
    <div class="metric-wrap">
      <div class="metric">
        {{ point }}
      </div>
      <span v-if="trend !== 0" class="text-xs" :class="trendClass">{{
        trendValue
      }}</span>
    </div>
    <p class="desc m-0 text-xs capitalize">
      {{ desc }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    heading: { type: String, default: '' },
    infoText: { type: String, default: '' },
    point: { type: [Number, String], default: '' },
    trend: { type: Number, default: null },
    index: { type: Number, default: null },
    desc: { type: String, default: '' },
    selected: Boolean,
  },
  computed: {
    trendClass() {
      if (this.trend > 0) {
        return 'metric-trend metric-up';
      }

      return 'metric-trend metric-down';
    },
    trendValue() {
      if (this.trend > 0) {
        return `+${this.trend}%`;
      }

      return `${this.trend}%`;
    },
  },
  methods: {
    click() {
      this.$emit('click', this.index);
    },
  },
};
</script>
<style lang="scss" scoped>
.report-card {
  @apply m-0 flex-grow cursor-pointer border-t-2 border-transparent px-3 pb-3 pt-1;

  &.active {
    @apply border-woot-500 bg-white dark:bg-gray-800;
    .heading,
    .metric {
      @apply text-woot-500;
    }
  }

  .heading {
    @apply m-0 flex items-center gap-1 text-sm text-gray-800 dark:text-gray-100;
  }
  .metric {
    @apply m-0 text-3xl font-light font-medium text-gray-700 dark:text-gray-100;
  }

  .metric-wrap {
    @apply flex items-center gap-1;
  }

  .metric-up {
    @apply text-green-500;
  }

  .metric-down {
    @apply text-red-500;
  }
}
</style>
