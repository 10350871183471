var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isEditorVisible)?_c('div',{staticClass:"w-full"},[(_vm.selectedTranslation.lang !== _vm.mainLanguage)?_c('div',{staticClass:"callout primary mb-5 flex flex-row flex-nowrap items-center justify-between gap-2"},[_c('span',{staticClass:"flex flex-row flex-nowrap items-center gap-2.5"},[_c('fluent-icon',{attrs:{"icon":"document-copy","size":16,"type":"solid"}}),_vm._v(" "),_c('span',{staticClass:"text-xs"},[_vm._v("\n        "+_vm._s(_vm.$t(
            'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.COPY_FROM_PRIMARY_LANGUAGE.MESSAGE',
            {
              LANGUAGE: _vm.mainLanguage,
            }
          ))+"\n      ")])],1),_vm._v(" "),_c('span',{staticClass:"flex flex-row flex-nowrap items-center gap-2.5"},[_c('woot-button',{staticClass:"!h-auto !px-3 !py-2.5 !leading-none",attrs:{"type":"button","size":"tiny","variant":"smooth","color-scheme":"secondary"},on:{"click":function($event){return _vm.copyFromMainLanguage(false)}}},[_vm._v("\n        "+_vm._s(_vm.$t(
            'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.COPY_FROM_PRIMARY_LANGUAGE.INSERT'
          ))+"\n      ")]),_vm._v(" "),_c('woot-button',{staticClass:"!h-auto !px-3 !py-2.5 !leading-none",attrs:{"type":"button","size":"tiny"},on:{"click":function($event){return _vm.copyFromMainLanguage(true)}}},[_vm._v("\n        "+_vm._s(_vm.$t(
            'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.COPY_FROM_PRIMARY_LANGUAGE.INSERT_TRANSLATE'
          ))+"\n      ")])],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex w-full flex-row flex-nowrap gap-2"},[_c('span',{staticClass:"flex flex-1 flex-col flex-nowrap gap-2 rounded border p-2.5 dark:border-gray-700"},[_c('div',[_c('label',{staticClass:"mb-1",attrs:{"for":`ai-handover-${_vm.selectedTranslationLanguage}-body-text`}},[_vm._v("\n          "+_vm._s(_vm.$t('AGENT_BOTS.AI_HANGOVER_CONFIGURATION.MESSAGE.BODY.LABEL'))+"\n        ")]),_vm._v(" "),_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.hideBodyEmojiPicker),expression:"hideBodyEmojiPicker"}],staticClass:"ai-handover__body-text__container relative rounded border p-1 pt-2 dark:border-gray-700 dark:bg-gray-900"},[_c('div',{staticClass:"px-2"},[_c('resizable-text-area',{staticClass:"border-none",attrs:{"id":`ai-handover-${_vm.selectedTranslationLanguage}-body-text`,"is-formulate-form-component":"","placeholder":_vm.$t(
                  'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.MESSAGE.BODY.PLACEHOLDER'
                ),"min-height":3,"max-length":1024,"validation":"required|noMultipleNewLines|maxLength","validation-messages":{
                required: _vm.$t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
                noMultipleNewLines: _vm.$t(
                  'CHATLYN_GENERAL.MESSAGES.NO_DOUBLE_NEW_LINES'
                ),
                maxLength: _vm.$t('CHATLYN_GENERAL.MESSAGES.TOO_LONG'),
              },"validation-rules":{
                noMultipleNewLines: _vm.noMultipleNewLines,
                maxLength: () => _vm.maxLength(_vm.bodyText, 1024),
              },"show-emoji-picker":_vm.showBodyEmojiPicker},on:{"hide-emoji-picker":_vm.hideBodyEmojiPicker},model:{value:(_vm.bodyText),callback:function ($$v) {_vm.bodyText=$$v},expression:"bodyText"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.showBodyEmojiPicker)?_c('emoji-input',{staticClass:"emoji-dialog--expanded",attrs:{"on-click":_vm.handleOnClickEmoji}}):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"mt-1 px-2"},[_c('input-translator',{attrs:{"is-extended":"","is-hidden":!_vm.showBodyTranslationBox,"max-length":1024},on:{"action-after-translate":_vm.toggleShowBodyTranslationBox},model:{value:(_vm.bodyText),callback:function ($$v) {_vm.bodyText=$$v},expression:"bodyText"}})],1),_vm._v(" "),_c('reply-bottom-panel',{staticClass:"!px-2",attrs:{"hide-ai-suggestion-button":"","hide-ai-button":"","hide-send-button":"","hide-ai-assist-button":"","hide-signature-button":"","disable-drag-drop":"","show-immediate-translation":"","show-text-counter":"","portal-slug":"","message":_vm.selectedTranslation.body,"is-immediate-translation-disabled":!_vm.selectedTranslation.body,"show-emoji-picker":false,"translate-text-immediate-label":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.TRANSLATE_TO') +
              ' ' +
              `${_vm.selectedTranslation.lang.toUpperCase()}`,"conversation-id":-1,"current-length":_vm.selectedTranslation.body ? _vm.selectedTranslation.body.length : 0,"max-length":1024,"preferred-language":_vm.selectedTranslation.lang,"show-translate-box":_vm.showBodyTranslationBox},on:{"change-message":_vm.handleChangeBodyText,"toggle-translate-box":() => _vm.toggleShowBodyTranslationBox(),"toggle-emoji-picker":_vm.toggleShowBodyEmojiPicker,"change-is-translating":_vm.changeIsTranslating}})],1)]),_vm._v(" "),_c('input-with-translation',{attrs:{"is-formulate-form-component":"","type":"text","label":_vm.$t('AGENT_BOTS.AI_HANGOVER_CONFIGURATION.MESSAGE.BUTTON.LABEL'),"placeholder":_vm.$t(
            'AGENT_BOTS.AI_HANGOVER_CONFIGURATION.MESSAGE.BUTTON.PLACEHOLDER'
          ),"max-length":20,"preferred-language":_vm.selectedTranslationLanguage,"validation":"required|maxLength","validation-messages":{
          required: _vm.$t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
          maxLength: _vm.$t('CHATLYN_GENERAL.MESSAGES.TOO_LONG'),
        },"validation-rules":{
          maxLength: () => _vm.maxLength(_vm.buttonText, 20),
        }},model:{value:(_vm.buttonText),callback:function ($$v) {_vm.buttonText=$$v},expression:"buttonText"}})],1),_vm._v(" "),_c('span',{staticClass:"max-w-[17.875rem] flex-1"},[_c('message-preview',{attrs:{"current-translation":_vm.previewObject,"has-label":false}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }