<template>
  <div
    class="m-0 flex h-full flex-1 flex-col justify-between bg-white dark:bg-gray-900"
  >
    <settings-header
      button-route="new"
      :header-title="contact.name"
      show-back-button
      :back-button-label="$t('CONTACT_PROFILE.BACK_BUTTON')"
      :back-url="backUrl"
      :show-new-button="false"
    >
      <thumbnail
        v-if="contact.thumbnail"
        :src="contact.thumbnail"
        :username="contact.name"
        size="32px"
        class="mr-2 rtl:ml-2 rtl:mr-0"
      />
    </settings-header>

    <div v-if="uiFlags.isFetchingItem" class="h-full p-4 text-center text-base">
      <spinner size="" />
      <span>{{ $t('CONTACT_PROFILE.LOADING') }}</span>
    </div>
    <div v-else-if="contact.id" class="min-w-0 flex-1 overflow-hidden">
      <div
        class="relative h-full w-full flex-grow pr-[var(--contact-panel-width)]"
      >
        <woot-tabs :index="selectedTabIndex" @change="onClickTabChange">
          <woot-tabs-item
            v-for="tab in tabs"
            :key="tab.key"
            :name="tab.name"
            :show-badge="false"
          />
        </woot-tabs>
        <div
          class="h-full overflow-y-auto bg-gray-50 p-4 pb-10 dark:bg-gray-900"
        >
          <contact-notes
            v-if="selectedTabIndex === 0"
            :contact-id="Number(contactId)"
          />
        </div>

        <contact-info-panel
          class="top-0"
          :show-close-button="false"
          :show-avatar="false"
          :contact="contact"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContactInfoPanel from '../components/ContactInfoPanel.vue';
import ContactNotes from 'dashboard/modules/notes/NotesOnContactPage.vue';
import SettingsHeader from '../../settings/SettingsHeader.vue';
import Spinner from 'shared/components/Spinner.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';

export default {
  components: {
    ContactInfoPanel,
    ContactNotes,
    SettingsHeader,
    Spinner,
    Thumbnail,
  },
  props: {
    contactId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      selectedTabIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      accountId: 'getCurrentAccountId',
    }),
    tabs() {
      return [
        {
          key: 0,
          name: this.$t('NOTES.HEADER.TITLE'),
        },
      ];
    },
    showEmptySearchResult() {
      const hasEmptyResults = !!this.searchQuery && this.records.length === 0;
      return hasEmptyResults;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    backUrl() {
      return `/app/accounts/${this.$route.params.accountId}/contacts`;
    },
  },
  mounted() {
    this.fetchContactDetails();
    this.fetchContactLists();
  },
  methods: {
    onClickTabChange(index) {
      this.selectedTabIndex = index;
    },
    fetchContactDetails() {
      const { contactId: id } = this;
      this.$store.dispatch('contacts/show', { id });
    },
    fetchContactLists() {
      this.$store.dispatch('contactLists/getLists', {
        accountId: this.accountId,
      });
    },
  },
};
</script>
