<template>
  <div>
    <label
      class="chatlyn-drag-drop-file-upload group grid h-full w-full cursor-pointer items-center justify-center rounded-md border border-dashed py-2 transition-colors duration-200 hover:border-gray-400 dark:border-gray-500 dark:hover:border-gray-400"
      @dragenter.prevent="onDragEnter"
      @dragover.prevent="onDragEnter"
      @drop.prevent="onDrop"
      @dragleave.prevent="onDragLeave"
    >
      <span v-if="!isSmall" class="pointer-events-none m-auto">
        <fluent-icon
          class="text-muted group-hover:text-gray-600 dark:group-hover:text-gray-300"
          icon="cloud-arrow-up"
        />
      </span>

      <div
        class="text-muted pointer-events-none w-full overflow-hidden text-ellipsis whitespace-nowrap group-hover:text-gray-600 dark:group-hover:text-gray-300"
        :class="{
          'text-center': !isSmall,
          'text-left': isSmall,
        }"
      >
        <span
          v-dompurify-html="isSmall ? errorMessage || filename || text : text"
          class="chatlyn-drag-drop-file-upload__text"
          :class="{
            'text-red-500': errorMessage,
          }"
        />

        <input
          v-if="!isFormulateInput"
          :id="id"
          :filename="filename"
          class="hidden"
          type="file"
          :accept="inputAccept"
          @change="handleFileInputChange"
        />

        <formulate-input
          v-else
          :id="id"
          v-model="filename"
          :filename="filename"
          class="hidden"
          type="file"
          :validation="`required|mime:${accept.join(',')}`"
          :accept="inputAccept"
        />
      </div>

      <span
        v-if="!isSmall && filename"
        class="pointer-events-none w-full text-ellipsis px-3 text-center text-xs text-woot-400"
      >
        {{ filename }}
      </span>

      <span
        v-if="!isSmall && errorMessage"
        v-dompurify-html="errorMessage"
        class="error-message w-full text-center"
        @click.stop
      />
    </label>
  </div>
</template>

<script>
import getUuid from 'widget/helpers/uuid';
import { isValidRGB } from '../../../shared/helpers/imageHelper';

let dragLeaveDebounce;

export default {
  name: 'DropFileUpload',
  props: {
    value: {
      type: [File, Object, null],
      default: () => ({}),
    },

    accept: {
      type: Array,
      default: () => [],
    },

    isFormulateInput: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: getUuid(),
    },

    extensions: {
      type: Array,
      default: () => [],
    },

    isSmall: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },

    isVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text:
        this.label || this.$t('CHATLYN_GENERAL.BUTTON.CLICK_TO_UPLOAD_FILE'),
      errorMessage: '',
    };
  },
  computed: {
    filename: {
      get() {
        return this.value?.name;
      },
      set(newValue) {
        if (typeof newValue === 'string') {
          return;
        }

        const files = [];

        if (
          newValue?.files &&
          newValue.files[0] &&
          newValue.files[0].file?.name
        ) {
          files.push(newValue.files[0].file);
        }

        this.handleInput(files);
      },
    },

    isFileUploaded() {
      return Boolean(this.value?.name);
    },

    inputAccept() {
      if (this.extensions.length) {
        return this.extensions.map((extension) => `.${extension}`).join(',');
      }

      return this.accept.join(',');
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (!newValue) {
          this.text =
            this.label ||
            this.$t('CHATLYN_GENERAL.BUTTON.CLICK_TO_UPLOAD_FILE');
          this.errorMessage = '';
        }
      },
      deep: true,
    },
  },
  methods: {
    onDragEnter() {
      if (this.isSmall) {
        this.errorMessage = '';
      }

      clearTimeout(dragLeaveDebounce);
      this.text = this.$t('CHATLYN_GENERAL.BUTTON.RELEASE_TO_UPLOAD');
    },

    onDragLeave() {
      clearTimeout(dragLeaveDebounce);
      dragLeaveDebounce = setTimeout(() => {
        this.text =
          this.label || this.$t('CHATLYN_GENERAL.BUTTON.CLICK_TO_UPLOAD_FILE');
      }, 50);
    },

    onDrop(event) {
      clearTimeout(dragLeaveDebounce);

      this.handleInput(event?.dataTransfer?.files);
    },

    handleFileInputChange(event) {
      this.handleInput(event?.srcElement?.files);
    },

    handleUploadError(message) {
      this.$emit('input', {});
      this.text =
        this.label || this.$t('CHATLYN_GENERAL.BUTTON.CLICK_TO_UPLOAD_FILE');
      this.resetInputValue();
      this.errorMessage = message;
    },

    validateFile(file) {
      let isValid = this.accept.some((allowedFileType) => {
        if (allowedFileType.includes('/*')) {
          const partOfType = allowedFileType.replace('/*', '');
          return file.type.includes(partOfType);
        }

        return allowedFileType === file.type;
      });

      if (this.extensions.length) {
        isValid = this.extensions.includes(file.name.split('.').pop());
      }

      return isValid;
    },

    async handleInput(files) {
      if (!files || !files.length) {
        this.handleUploadError(this.$t('CHATLYN_GENERAL.MESSAGES.BAD_FILE'));
        return;
      }

      if (files.length > 1) {
        this.handleUploadError(
          this.$t('CHATLYN_GENERAL.MESSAGES.BAD_FILE_COUNT')
        );
        return;
      }

      const file = files[0];

      if (!this.validateFile(file)) {
        this.handleUploadError(
          this.$t('CHATLYN_GENERAL.MESSAGES.BAD_FILE_TYPE')
        );
        return;
      }

      if (!this.isVideo && !(await isValidRGB(file))) {
        this.handleUploadError(
          this.$t('CHATLYN_GENERAL.MESSAGES.BAD_IMAGE_COLOR_MODE')
        );
        return;
      }

      this.errorMessage = '';
      this.$emit('input', file);
      this.text =
        this.label || this.$t('CHATLYN_GENERAL.BUTTON.CLICK_TO_UPLOAD_FILE');
    },

    resetInputValue() {
      const element = document.getElementById(this.id);

      element.value = '';
    },
  },
};
</script>

<style lang="scss">
.chatlyn-drag-drop-file-upload {
  .error-message {
    a {
      @apply underline;
    }
  }

  .chatlyn-drag-drop-file-upload__text {
    a {
      @apply pointer-events-auto underline;
    }
  }
}
</style>
