<template>
  <div class="popover-select">
    <v-popover
      :placement="placement"
      popover-class="popover-select__container"
      trigger="manual"
      :offset="4"
      :open="isPopoverSelectOpen"
      :disabled="disabled"
      @apply-hide="handleAutoHide"
    >
      <div class="h-full" @click.stop.prevent="toggleIsPopoverSelectOpen">
        <slot />
      </div>

      <template slot="popover">
        <ul
          v-if="options && options.length"
          class="popover-select__container__list"
        >
          <li
            v-for="option in options"
            :key="option.value"
            class="popover-select__container__list__item flex flex-row items-center gap-2"
            :class="{
              'popover-select__container__list__item--selected':
                value === option.value,
            }"
          >
            <div
              v-if="option.value === 'separator'"
              class="h-[1px] w-full bg-gray-100 dark:bg-gray-700"
            />

            <woot-button
              v-else
              class="w-full"
              variant="clear"
              size="small"
              color-scheme="secondary"
              :icon="option.icon"
              :disabled="option.disabled"
              :data-testid="option.dataTestid"
              @click="handleOptionSelect(option.value)"
            >
              {{ option.label }}
            </woot-button>
          </li>
        </ul>

        <slot v-else name="empty-state" />
      </template>
    </v-popover>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },

    value: {
      type: [String, Number],
      default: '',
    },

    placement: {
      type: String,
      default: 'top-start',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPopoverSelectOpen: false,
    };
  },
  watch: {
    isPopoverSelectOpen: {
      handler(newValue) {
        this.$emit('change-is-popover-select-open', newValue);
      },
    },
  },
  methods: {
    handleOptionSelect(value) {
      this.$emit('input', value);
      this.toggleIsPopoverSelectOpen();
    },

    toggleIsPopoverSelectOpen() {
      this.isPopoverSelectOpen = !this.isPopoverSelectOpen;
    },

    handleAutoHide() {
      this.isPopoverSelectOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.popover-select {
  @apply h-full;

  ::v-deep .v-popover {
    @apply h-full;

    .trigger {
      @apply h-full;
    }
  }
}
</style>

<style lang="scss">
.popover-select__container {
  @apply max-h-64 overflow-y-auto overflow-x-hidden rounded-md border border-gray-100 bg-white p-1 shadow-xl dark:border-gray-700 dark:bg-gray-800 dark:shadow-md;

  .popover-select__container__list {
    @apply m-0 flex list-none flex-col gap-0.5 p-0;
  }
}
</style>
