<template>
  <div v-if="messageTemplate" class="w-full">
    <language-bar
      has-add-button
      has-right-side
      :has-color-mark="hasLanguageBarColorMark"
      :has-remove-button="hasRemoveButton"
      :languages="languages"
      :main-language="mainLanguage"
      :current-language="currentLanguage"
      :is-list-view-enabled="isListViewEnabled"
      :available-languages="availableLanguagesOptions"
      :has-additional-text="false"
      :translations="messageTemplate.translations"
      @change="selectCurrentLanguage"
      @handle-add-button-click="addNewLanguage"
      @handle-remove-button-click="toggleShowDeleteLanguageModal"
    >
      <template slot="right-side">
        <!-- <woot-button
          class="text-gray-700"
          color-scheme="secondary"
          variant="clear"
          :icon="isListViewEnabled ? 'text-collapse' : 'text-expand'"
          @click="toggleIsListViewEnabled"
        /> -->
      </template>
    </language-bar>

    <div v-if="currentTranslation" class="message-template-language-step-form">
      <template v-for="translation in messageTemplate.translations">
        <div
          v-show="
            isListViewEnabled ||
            currentTranslationLanguage === translation.language
          "
          :key="translation.language"
        >
          <div
            v-if="
              translation &&
              translation.language !== mainLanguage &&
              (!translation.locked || translation.status === 'rejected')
            "
            class="message-template-language-step-form__not-main-panel callout primary"
          >
            <span
              class="message-template-language-step-form__not-main-panel__column"
            >
              <fluent-icon icon="document-copy" :size="16" type="solid" />

              <span>
                {{
                  $t(
                    'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.COPY_FROM_PRIMARY_LANGUAGE.MESSAGE',
                    {
                      LANGUAGE: mainLanguage,
                    }
                  )
                }}
              </span>
            </span>

            <span
              class="message-template-language-step-form__not-main-panel__column"
            >
              <woot-button
                class-names="message-template-form-copy-button"
                type="button"
                size="small"
                variant="clear"
                color-scheme="primary"
                @click="copyFromMainToSelectedTranslation(translation.language)"
              >
                {{
                  $t(
                    'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.COPY_FROM_PRIMARY_LANGUAGE.INSERT'
                  )
                }}
              </woot-button>

              <woot-button
                v-show="isTranslatable"
                class-names="message-template-form-copy-button"
                type="button"
                size="small"
                @click="
                  copyFromMainToSelectedTranslation(translation.language, true)
                "
              >
                {{
                  $t(
                    'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.COPY_FROM_PRIMARY_LANGUAGE.INSERT_TRANSLATE'
                  )
                }}
              </woot-button>
            </span>
          </div>

          <div
            class="flex w-full flex-nowrap gap-5 pt-4"
            :class="{
              'flex-row': !isListViewEnabled,
              'flex-col': isListViewEnabled,
            }"
          >
            <formulate-form
              v-show="
                translation &&
                (!translation.locked || translation.status === 'rejected')
              "
              class="sticky top-4 w-full flex-1"
              :name="`create-message-template-form-${translation.language}`"
              :style="{
                padding: 0,
                alignSelf: 'flex-start',
              }"
            >
              <div
                v-if="isListViewEnabled"
                class="sticky top-0 mb-2 w-full border-b border-gray-100 bg-white py-1 text-base"
                :style="{
                  zIndex: 11,
                }"
              >
                {{ translation.language }}
              </div>

              <languages-form
                :current-translation="translation"
                :links-component="translation.links"
                :category="messageTemplate.category"
                :is-translation-attributes-synced="
                  isTranslationAttributesSynced
                "
                :message-template="messageTemplate"
                :message-template-type="messageTemplateType"
                :is-translatable="isTranslatable"
                @change-links-component="changeLinksComponent"
                @change-current-translation="changeCurrentTranslation"
                @toggle-is-translation-attributes-synced="
                  toggleIsTranslationAttributesSynced
                "
                @change-message-template="changeMessageTemplate"
              />
            </formulate-form>

            <div
              v-if="
                translation &&
                translation.locked &&
                translation.status !== 'rejected'
              "
              class="message-template-language-step-form__message callout flex flex-grow flex-col items-center justify-center gap-2"
            >
              <fluent-icon icon="warning" :size="20" class="flex-shrink-0" />

              <span class="text-sm">
                {{
                  $t(
                    'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.ALREADY_SUBMITTED_TRANSLATION'
                  )
                }}
              </span>
            </div>

            <message-preview
              v-if="!isListViewEnabled"
              :current-translation="currentTranslation"
              :empty-text="
                $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.FILL_TO_PREVIEW')
              "
            />
          </div>
        </div>
      </template>
    </div>

    <woot-delete-modal
      v-if="showDeleteLanguageModal"
      :show.sync="showDeleteLanguageModal"
      :title="
        $t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.TITLE', {
          LANGUAGE: currentLanguage.toUpperCase(),
        })
      "
      :message="$t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.MESSAGE')"
      :confirm-text="
        $t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.ACCEPT')
      "
      :reject-text="
        $t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.DECLINE')
      "
      :on-confirm="deleteCurrentLanguage"
      :on-close="toggleShowDeleteLanguageModal"
      :is-loading="isDeleting"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Deepl from '../../../../../../api/deepl';
import WootButton from '../../../../../../components/ui/WootButton.vue';
import {
  CH_I18N_TO_META_LANGUAGES_KEYS,
  MESSAGE_TEMPLATES_CHANNEL_TYPE,
} from '../../../constants';
import { CH_I18N_TO_DEEPL_LANGUAGES_KEYS } from '../../../../../../components/widgets/conversation/translator/constants';
import LanguageBar from '../../LanguageBar.vue';
import MessagePreview from '../../MessagePreview.vue';
import LanguagesForm from './components/LanguagesForm.vue';

export default {
  name: 'LanguagesStep',
  components: { MessagePreview, LanguagesForm, LanguageBar, WootButton },
  props: {
    messageTemplate: {
      type: Object,
      required: true,
    },

    hasErrors: {
      type: Boolean,
      default: false,
    },

    languages: {
      type: Array,
      default: () => [],
    },

    availableLanguages: {
      type: Array,
      default: () => [],
    },

    editMode: {
      type: Boolean,
      default: false,
    },

    currentLanguage: {
      type: String,
      default: '',
    },

    messageTemplateType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mainLanguage: this.languages[0].name,
      isListViewEnabled: false,
      showDeleteLanguageModal: false,
      isTranslationAttributesSynced: true,
    };
  },
  computed: {
    ...mapGetters({
      messageTemplatesUIFlags: 'messageTemplates/getUIFlags',
      deeplLanguages: 'deepl/getDeeplLanguages',
    }),

    currentTranslationIndex() {
      return this.messageTemplate.translations.findIndex(
        (translation) => translation.language === this.currentLanguage
      );
    },

    currentTranslation: {
      get() {
        return this.messageTemplate.translations.find(
          (translation) => translation.language === this.currentLanguage
        );
      },
      set(newValue) {
        this.changeMessageTemplate({
          ...this.messageTemplate,
          translations: this.messageTemplate.translations.map((translation) => {
            if (newValue.language === translation.language) {
              return newValue;
            }

            return translation;
          }),
        });
      },
      // return this.messageTemplate.translations.find(
      //   translation => translation.language === this.currentLanguage
      // );
    },

    currentTranslationLanguage() {
      return this.currentTranslation?.language || '';
    },

    availableLanguagesOptions() {
      return this.availableLanguages
        .filter((availableLanguage) => {
          return !this.languages.some(
            (language) => language.name === availableLanguage
          );
        })
        .map((availableLanguage) => ({
          value: availableLanguage,
          label: availableLanguage.toUpperCase(),
        }));
    },

    linksComponent: {
      get() {
        return this.currentTranslation.links;
      },

      set(newValue) {
        this.currentTranslation = JSON.parse(
          JSON.stringify({
            ...this.currentTranslation,
            links: newValue,
          })
        );
      },
    },

    isDeleting() {
      return this.messageTemplatesUIFlags.isDeleting;
    },

    hasRemoveButton() {
      if (!this.currentTranslation) {
        return false;
      }

      return (
        !this.currentTranslation.locked &&
        // eslint-disable-next-line no-underscore-dangle
        (!this.currentTranslation._id ||
          this.currentTranslation.status === 'draft')
      );
    },

    hasLanguageBarColorMark() {
      return [MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP].includes(
        this.messageTemplateType
      );
    },

    isTranslatable() {
      return this.deeplLanguages.some(
        (language) =>
          language.code ===
          (CH_I18N_TO_DEEPL_LANGUAGES_KEYS[this.currentTranslation.language] ||
            this.currentTranslation.language)
      );
    },
  },
  watch: {
    languages: {
      handler(newValue) {
        if (!newValue) {
          return;
        }

        if (newValue.some((language) => language.name === this.mainLanguage)) {
          return;
        }

        this.mainLanguage = newValue[0].name;
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch('inboxes/get');
  },
  methods: {
    toggleShowDeleteLanguageModal() {
      this.showDeleteLanguageModal = !this.showDeleteLanguageModal;
    },

    selectCurrentLanguage(newValue) {
      if (!this.languages.some((language) => language.name === newValue)) {
        return;
      }

      this.$emit('change-current-language', newValue);
    },

    toggleIsListViewEnabled() {
      this.isListViewEnabled = !this.isListViewEnabled;
    },

    async copyFromMainToSelectedTranslation(
      selectedLanguage,
      isMustToBeTranslated = false
    ) {
      const deeplLanguage =
        CH_I18N_TO_DEEPL_LANGUAGES_KEYS[selectedLanguage] || selectedLanguage;

      const mainTranslation = structuredClone(
        this.messageTemplate.translations.find(
          (translation) => translation.language === this.mainLanguage
        )
      );

      // eslint-disable-next-line no-underscore-dangle
      delete mainTranslation._id;
      delete mainTranslation.status;
      delete mainTranslation.rejectedReason;
      delete mainTranslation.locked;

      if (!mainTranslation) {
        return;
      }

      if (isMustToBeTranslated) {
        mainTranslation.components = await Promise.all(
          mainTranslation.components.map(async (component) => {
            if (component.text) {
              try {
                const { data } = await Deepl.translateText(
                  component.text,
                  deeplLanguage
                );

                if (!data.text) {
                  throw new Error();
                }

                component.text = data.text;
              } catch (error) {
                // TODO: Translation error
              }
            }

            return component;
          })
        );
      }

      this.changeMessageTemplate({
        ...this.messageTemplate,
        translations: this.messageTemplate.translations.map((translation) => {
          if (translation.language === selectedLanguage) {
            return structuredClone({
              ...translation,
              ...mainTranslation,
              language: selectedLanguage,
            });
          }

          return translation;
        }),
      });
    },

    changeLinksComponent(newValue) {
      this.linksComponent = newValue;
    },

    addNewLanguage(value) {
      this.changeMessageTemplate({
        ...this.messageTemplate,
        translations: [
          ...this.messageTemplate.translations,
          {
            language: CH_I18N_TO_META_LANGUAGES_KEYS[value] || value,
            components: [
              {
                type: 'BODY',
                text: '',
              },
            ],
            links: {
              body: [],
              buttons: [],
            },
          },
        ],
      });
    },

    async deleteCurrentLanguage() {
      if (!this.currentLanguage) {
        return;
      }

      await this.deleteLanguage(this.currentLanguage);
    },

    async deleteLanguage(value) {
      const matchedTranslation = this.messageTemplate.translations.find(
        (translation) => translation.language === value
      );

      try {
        if (matchedTranslation.status) {
          const deleteResponse = await this.$store.dispatch(
            'messageTemplates/deleteMessageTemplateTranslation',
            {
              // eslint-disable-next-line no-underscore-dangle
              templateId: this.messageTemplate._id,
              language: this.currentLanguage,
            }
          );

          if (!deleteResponse) {
            throw new Error();
          }
        }

        this.changeMessageTemplate({
          ...this.messageTemplate,
          translations: this.messageTemplate.translations.filter(
            (translation) => translation.language !== value
          ),
        });

        this.toggleShowDeleteLanguageModal();
      } catch (error) {
        //
      }
    },

    toggleIsTranslationAttributesSynced() {
      this.isTranslationAttributesSynced = !this.isTranslationAttributesSynced;
    },

    changeMessageTemplate(newValue) {
      this.$emit('change-message-template', newValue);
    },

    changeCurrentTranslation(translation) {
      this.currentTranslation = JSON.parse(JSON.stringify(translation));
    },
  },
};
</script>

<style lang="scss">
.message-template-language-step-form {
  @apply block;

  .message-template-language-step-form__not-main-panel {
    @apply mt-3 flex flex-row flex-nowrap items-center justify-between gap-2;

    .message-template-language-step-form__not-main-panel__column {
      @apply flex flex-row flex-nowrap items-center gap-1 text-xs;

      .message-template-form-copy-button {
        @apply p-3;

        .button__content {
          @apply text-xs leading-none;
        }
      }
    }
  }

  .message-preview-container {
    @apply sticky top-4 -z-10 h-full w-72;

    .message-preview-background {
      @apply min-h-[15rem] flex-grow-0;
    }
  }
}
</style>
