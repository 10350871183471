<template>
  <div class="wizard-body h-auto flex-shrink-0 flex-grow-0 xl:pe-64">
    <div v-if="currentUser.type !== 'SuperAdmin'">
      <h3 class="page-header">
        {{
          $t('INBOX_MGMT.ADD.ONLY_SUPER_ADMIN_MESSAGE.TITLE', {
            INBOX_NAME: 'WhatsApp',
          })
        }}
      </h3>

      <p>{{ $t('INBOX_MGMT.ADD.ONLY_SUPER_ADMIN_MESSAGE.MESSAGE') }}</p>
    </div>

    <div v-else>
      <page-header
        :header-title="$t('INBOX_MGMT.ADD.WHATSAPP.TITLE')"
        :header-content="$t('INBOX_MGMT.ADD.WHATSAPP.DESC')"
      />
      <div class="mb-3">
        <label>
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.LABEL') }}
          <select v-model="provider">
            <option value="whatsapp_cloud">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.WHATSAPP_CLOUD') }}
            </option>
            <option value="twilio">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.TWILIO') }}
            </option>
            <option value="360dialog">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.360_DIALOG') }}
            </option>
          </select>
        </label>
      </div>

      <twilio v-if="provider === 'twilio'" type="whatsapp" />
      <three-sixty-dialog-whatsapp v-else-if="provider === '360dialog'" />
      <cloud-whatsapp v-else />
    </div>
  </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader.vue';
import Twilio from './Twilio.vue';
import ThreeSixtyDialogWhatsapp from './360DialogWhatsapp.vue';
import CloudWhatsapp from './CloudWhatsapp.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    PageHeader,
    Twilio,
    ThreeSixtyDialogWhatsapp,
    CloudWhatsapp,
  },
  data() {
    return {
      provider: 'whatsapp_cloud',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
    }),
  },
};
</script>
