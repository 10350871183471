export const TRUE = 'true';
export const FALSE = 'false';
export const AND = 'AND';
export const OR = 'OR';
export const SELECTOR = 'selector';
export const INPUT = 'input';

export const COMPARATOR = {
  EMPTY: 'empty',
  NOT_EMPTY: 'notEmpty',
  EQUAL: 'equal',
  NOT_EQUAL: 'notEqual',
  CONTAIN: 'contain',
  NOT_CONTAIN: 'notContain',
  LESS: 'less',
  GREATER: 'greater',
};

export const ALL_COMPARATORS = Object.values(COMPARATOR);
export const BASE_COMPARATORS = [
  COMPARATOR.EQUAL,
  COMPARATOR.NOT_EQUAL,
  COMPARATOR.EMPTY,
  COMPARATOR.NOT_EMPTY,
];
export const BOOLEAN_LIST = [TRUE, FALSE];
