<template>
  <formulate-form
    v-slot="{ hasErrors }"
    class="list-move-form flex flex-col gap-3"
    name="list-merge-form"
    @submit="handleFormSubmit"
  >
    <div
      class="block"
      :class="{ error: $v.selectedFolder && $v.selectedFolder.$error }"
    >
      <label>
        {{ $t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.MOVE.FOLDER') }}
      </label>

      <multiselect
        v-model="selectedFolder"
        track-by="value"
        label="label"
        :allow-empty="false"
        :options="foldersOptions"
        :multiple="false"
        :max-height="75"
        :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.PLEASE_CHOOSE')"
        :close-on-select="true"
      />

      <span
        v-if="$v.selectedFolder && $v.selectedFolder.$error"
        class="message"
      >
        {{ $t('CHATLYN_GENERAL.MESSAGES.ERROR') }}
      </span>
    </div>

    <div class="modal-actions mt-1 flex w-full items-center justify-between">
      <woot-button
        color-scheme="alert"
        variant="smooth"
        type="button"
        @click="$emit('handle-delete-list')"
      >
        {{ $t('CHATLYN_GENERAL.BUTTON.DELETE') }}
      </woot-button>

      <woot-button :disabled="hasErrors || isTheSameFolderSelected">
        {{ $t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.TABS.MOVE') }}
      </woot-button>
    </div>
  </formulate-form>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';

import { mapGetters } from 'vuex';

export default {
  name: 'ListMove',
  mixins: [alertMixin],
  props: {
    contactList: {
      type: Object,
      default: () => ({}),
    },
  },
  validations: {
    selectedFolder: {
      required,
    },
  },
  data() {
    return {
      selectedFolder: {},
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      allFolders: 'folders/getAllFolders',
    }),

    availableFolders() {
      return this.allFolders.filter((folder) => !folder.isArchived);
    },

    foldersOptions() {
      return this.contactList.isArchived
        ? [
            {
              label: this.$t(
                'CHATLYN_CONTACT_LISTS.EDIT_MODAL.MOVE.OPTIONS.UNARCHIVE'
              ),
              value: 'unarchive',
            },
          ]
        : [
            {
              label: this.$t(
                'CHATLYN_CONTACT_LISTS.EDIT_MODAL.MOVE.OPTIONS.NO_FOLDER'
              ),
              value: 'no_folder',
            },
            {
              label: this.$t(
                'CHATLYN_CONTACT_LISTS.EDIT_MODAL.MOVE.OPTIONS.ARCHIVE'
              ),
              value: 'archive',
            },
            ...this.availableFolders.map((folder) => ({
              label: folder.name,
              // eslint-disable-next-line no-underscore-dangle
              value: folder._id,
            })),
          ];
    },

    isTheSameFolderSelected() {
      switch (this.selectedFolder.value) {
        case 'no_folder':
          return !this.contactList?.folderId;

        case 'archive':
          return this.contactList?.isArchived;

        case 'unarchive':
          return !this.contactList?.isArchived;

        default:
          return this.contactList?.folderId === this.selectedFolder?.value;
      }
    },
  },

  watch: {
    'contactList.folderId': {
      handler(newValue) {
        this.selectedFolder =
          this.foldersOptions.find((option) => option.value === newValue) ||
          this.foldersOptions[0];
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchFolders();
  },
  methods: {
    async fetchFolders() {
      await this.$store.dispatch('folders/getAllFolders', {
        clientId: this.currentAccountId,
      });
    },

    async handleFormSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          throw new Error();
        }

        let newList = structuredClone({
          ...this.contactList,
          order: 0,
          contacts: this.contactList.contacts.map((contact) => contact.id),
        });

        delete newList.meta;

        switch (this.selectedFolder.value) {
          case 'no_folder':
            newList.folderId = '';
            break;

          case 'archive':
            newList.folderId = '';
            newList.isArchived = true;
            break;

          case 'unarchive':
            newList.folderId = '';
            newList.isArchived = false;
            break;

          default:
            newList.folderId = this.selectedFolder.value;
            newList.isArchived = false;
            break;
        }

        const isSuccess = await this.$store.dispatch(
          'contactLists/updateList',
          {
            // eslint-disable-next-line no-underscore-dangle
            listId: newList._id,
            newRecord: newList,
          }
        );

        if (!isSuccess) {
          throw new Error();
        }

        await this.$store.dispatch('contactLists/getLists', {
          accountId: this.currentAccountId,
        });

        this.showAlert(
          this.$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.MOVE.SUCCESS_MESSAGE')
        );

        this.$emit('on-close');
      } catch (error) {
        const errorMessage =
          error.message || this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN');

        this.showAlert(errorMessage);
      }
    },
  },
};
</script>

<style></style>
