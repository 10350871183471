<template>
  <div
    v-if="parentContactName"
    class="mt-3 relative p-2 border rounded text-gray-700 dark:text-gray-200 dark:border-gray-700 dark:bg-gray-800"
  >
    <div class="leading-none font-semibold text-gray-900 dark:text-white mb-2">
      {{ $t('MERGE_CONTACTS.SUMMARY.TITLE') }}
    </div>
    <ul class="ml-0 list-none text-sm">
      <li class="flex gap-1">
        <span class="inline-block mr-1">❌</span>
        <span
          v-dompurify-html="
            $t('MERGE_CONTACTS.SUMMARY.DELETE_WARNING', {
              primaryContactName,
            })
          "
        />
      </li>
      <li class="flex gap-1">
        <span class="inline-block mr-1">✅</span>
        <span
          v-dompurify-html="
            $t('MERGE_CONTACTS.SUMMARY.ATTRIBUTE_WARNING', {
              primaryContactName,
              parentContactName,
            })
          "
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    primaryContactName: {
      type: String,
      default: '',
    },
    parentContactName: {
      type: String,
      default: '',
    },
  },
};
</script>
