<template>
  <div class="mb-1 text-sm">
    <button
      class="drag-handle flex w-full cursor-grab select-none items-center justify-between rounded-t border py-2 pe-2 ps-4 transition-colors duration-200"
      @click="$emit('click')"
      :class="
        isOpen
          ? 'border-woot-700 bg-woot-600 text-white dark:border-woot-600 dark:bg-woot-700'
          : 'rounded-b border-gray-100 bg-gray-50 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white'
      "
    >
      <emoji-or-icon class="inline-block w-5" :icon="icon" :emoji="emoji" />
      <span>{{ title }}</span>
      <div class="flex flex-row">
        <slot name="button" />
      </div>
      <div class="ms-auto flex justify-end">
        <fluent-icon v-if="isOpen" size="12" icon="chevron-down" />
        <fluent-icon v-else size="12" icon="chevron-right" />
      </div>
    </button>
    <div
      v-if="isOpen"
      class="rounded-b border bg-white dark:border-gray-700 dark:bg-gray-900"
      :class="compact ? 'p-0' : 'p-4'"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import EmojiOrIcon from 'shared/components/EmojiOrIcon.vue';

export default {
  components: {
    EmojiOrIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    emoji: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
