<template>
  <div
    class="inbox--name inline-flex items-center py-0.5 px-0 leading-3 bg-none text-muted text-xs m-0"
  >
    <fluent-icon
      class="me-0.5"
      :icon="computedInboxClass"
      size="12"
    />
    {{ inbox.name }}
  </div>
</template>
<script>
import { getInboxClassByType } from 'dashboard/helper/inbox';

export default {
  props: {
    inbox: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    computedInboxClass() {
      const { phone_number: phoneNumber, channel_type: type } = this.inbox;
      const classByType = getInboxClassByType(
        type,
        phoneNumber,
        this.inbox?.additional_attributes?.apiInboxType
      );
      return classByType;
    },
  },
};
</script>
