<template>
  <div class="overflow-auto grid grid-cols-4 flex-grow">
    <woot-wizard class="col-span-1" :items="items" />
    <router-view class="col-span-3 border-l dark:border-gray-800 bg-white dark:bg-gray-800" />
  </div>
</template>

<script>
export default {
  computed: {
    items() {
      const data = this.$t('TEAMS_SETTINGS.CREATE_FLOW.WIZARD');
      return data;
    },
  },
};
</script>
