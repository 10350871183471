<template>
  <div
    v-show="isBannerVisible"
    class="!z-20 flex items-center justify-center gap-1 rounded-bl-lg rounded-br-lg px-4 py-3 text-center text-sm text-white"
    :class="{
      'min-h-[3rem] min-w-[16rem] flex-row bg-green-500 dark:bg-green-600':
        !isExpiredComputed,
      'flex-col bg-red-500 dark:bg-red-700': isExpiredComputed,
    }"
  >
    <template v-if="isExpiredComputed">
      <span
        v-dompurify-html="
          $t('CONVERSATION.CAN_REPLY.TITLE', {
            LINK: 'https://academy.chatlyn.com/en/articles/24-hour-conversation-window-on-whatsapp-business/',
          })
        "
        class="text-sm font-semibold"
      />

      <span class="text-xs font-normal">
        {{ $t('CONVERSATION.CAN_REPLY.TEXT') }}
      </span>
    </template>

    <template v-else>
      <fluent-icon icon="timer" />

      <span>{{ timerTime }}</span>

      <span>
        {{ $t('CONVERSATION.CAN_REPLY.UNTIL_TEXT') }}
      </span>
    </template>
  </div>
</template>

<script>
import { formatDuration, intervalToDuration } from 'date-fns';

let expireTimeInterval;

export default {
  name: 'CantReplyBanner',

  props: {
    canReply: {
      type: Boolean,
      default: true,
    },

    expires: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      timerTime: '',
      isExpired: false,
    };
  },

  computed: {
    isExpiredComputed: {
      get() {
        if (!this.canReply) {
          return true;
        }

        return this.isExpired;
      },

      set(newValue) {
        this.isExpired = newValue;

        this.$emit('on-is-expired-change', newValue);
      },
    },

    isBannerVisible() {
      if (!this.isExpiredComputed && !this.timerTime) {
        return false;
      }

      return true;
    },
  },

  watch: {
    expires: {
      handler(newValue) {
        this.isExpiredComputed = false;
        clearInterval(this.expireTimeInterval);

        if (!newValue) {
          return;
        }

        this.processExpirationTime();

        expireTimeInterval = setInterval(() => {
          this.processExpirationTime();
        }, 1000);
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    clearInterval(expireTimeInterval);
  },

  methods: {
    processExpirationTime() {
      const now = new Date();
      const end = new Date(this.expires * 1000);

      const distance = end.getTime() - now.getTime();

      if (distance <= 0) {
        clearInterval(expireTimeInterval);
        this.isExpiredComputed = true;
      }

      const remaining = intervalToDuration({
        start: now,
        end,
      });

      this.timerTime = formatDuration(remaining, {
        format: ['hours', 'minutes', 'seconds'],
        zero: true,
        delimiter: ':',
        locale: {
          formatDistance: (_token, count) => String(count).padStart(2, '0'),
        },
      });
    },
  },
};
</script>

<style></style>
