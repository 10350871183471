var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"language-bar"},[(!_vm.isListViewEnabled && Array.isArray(_vm.languages) && _vm.languages.length)?_c('div',{staticClass:"flex flex-row flex-nowrap overflow-auto",style:({
      maxWidth: _vm.languagesListMaxWidth,
    })},_vm._l((_vm.languages),function(language){return _c('span',{key:language.name,staticClass:"language-bar-language",class:{
        'language-bar-language--selected': _vm.currentLanguage === language.name,
      },on:{"click":function($event){return _vm.onChange(language.name)}}},[_c('span',{staticClass:"language-bar-language-container"},[(_vm.hasColorMark)?_c('span',{staticClass:"status-indicator",style:({
            background: _vm.getLanguageMarkColor(language.name),
          })}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"language-bar-language-container-text uppercase"},[_vm._v("\n          "+_vm._s(_vm.LANGUAGES_LABELS[language.name] || language.name)+"\n\n          "),(_vm.hasAdditionalText)?[_vm._v("\n            "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.TEMPLATE_LANGUAGE'))+"\n          ")]:_vm._e()],2),_vm._v(" "),(_vm.getIsRemoveButtonVisible(language.name))?_c('span',{staticClass:"transition-colors duration-200 hover:bg-woot-50",style:({
            borderRadius: '0.157rem',
            padding: '0.188rem',
          }),on:{"click":function($event){$event.preventDefault();return _vm.handleRemoveButtonClick(language.name)}}},[_c('fluent-icon',{attrs:{"icon":"dismiss","size":12}})],1):_vm._e()]),_vm._v(" "),_c('span',{staticClass:"language-bar-language-b-line"})])}),0):_vm._e(),_vm._v(" "),(_vm.hasAddButton)?_c('span',[(_vm.hasPopover)?_c('popover-select',{attrs:{"options":_vm.availableLanguages},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('woot-button',{style:({
            padding: 0,
            height: '100%',
            margin: '0',
          }),attrs:{"color-scheme":"primary","variant":"clear","icon":"add-circle","icon-type":"solid","type":"button"}})]},proxy:true},{key:"empty-state",fn:function(){return [_c('div',{staticClass:"message-template_additional-languages--empty-state"},[_c('span',{staticClass:"message-template_additional-languages--empty-state__title text-sm"},[_vm._v("\n            "+_vm._s(_vm.$t(
                'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.NO_ADDITIONAL_LANGUAGES_MESSAGE.TITLE'
              ))+"\n          ")]),_vm._v(" "),_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
              _vm.$t(
                'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.NO_ADDITIONAL_LANGUAGES_MESSAGE.MESSAGE',
                { LINK: _vm.settingsRoute }
              )
            ),expression:"\n              $t(\n                'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.NO_ADDITIONAL_LANGUAGES_MESSAGE.MESSAGE',\n                { LINK: settingsRoute }\n              )\n            "}],staticClass:"message-template_additional-languages--empty-state__message text-xs"})])]},proxy:true}],null,false,1957519794),model:{value:(_vm.selectedLanguage),callback:function ($$v) {_vm.selectedLanguage=$$v},expression:"selectedLanguage"}}):_c('woot-button',{style:({
        padding: 0,
        height: '100%',
        margin: '0',
      }),attrs:{"color-scheme":"primary","variant":"clear","icon":"add-circle","icon-type":"solid","type":"button"},on:{"click":_vm.handleAddButtonClick}})],1):_vm._e(),_vm._v(" "),(_vm.hasRightSide)?_c('span',{staticClass:"ml-auto flex items-center"},[_vm._t("right-side")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }