<template>
  <div>
    <woot-modal-header
      :header-title="$t('CONTACTS_FILTER.TITLE')"
      :header-content="$t('CONTACTS_FILTER.SUBTITLE')"
    />

    <div class="p-8">
      <list-condition
        v-if="condition"
        class="mb-4"
        :has-clear-all-button="
          hasAppliedContactCondition || hasAppliedListCondition
        "
        :list-condition="condition"
        @update:condition="onConditionUpdate"
        @after-clearing-all="onClearCondition"
      />

      <div class="w-full">
        <div class="flex w-full flex-row justify-end gap-2 px-0 py-2">
          <woot-button class="button secondary clear" @click.prevent="onClose">
            {{ $t('CONTACTS_FILTER.CANCEL_BUTTON_LABEL') }}
          </woot-button>

          <woot-button
            :is-disabled="!isValidCondition"
            @click="onConditionSubmit"
          >
            {{ $t('CONTACTS_FILTER.SUBMIT_BUTTON_LABEL') }}
          </woot-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ListCondition from '../../../contactLists/components/ListCondition.vue';
import { AND } from '../../../contactLists/utils/conditionConstants';

const DEFAULT_CONDITION = {
  conditions: [
    { attribute: { key: '', type: '' }, comparator: 'equal', value: '' },
  ],
  operator: AND,
};

export default {
  name: 'ContactsCondition',
  components: { ListCondition },
  props: {
    isContactListsPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      condition: null,
      isValidCondition: false,
    };
  },
  computed: {
    ...mapGetters({
      contactList: 'contactLists/getList',
      appliedContactCondition: 'contacts/getAppliedContactCondition',
    }),

    contactListId() {
      // eslint-disable-next-line no-underscore-dangle
      return this.contactList?._id;
    },

    appliedListCondition() {
      return this.$store.getters['contactLists/getAppliedListCondition'](
        this.contactListId
      );
    },

    hasAppliedContactCondition() {
      return !!this.appliedContactCondition?.conditions?.length;
    },

    hasAppliedListCondition() {
      return !!this.appliedListCondition?.conditions?.length;
    },
  },
  mounted() {
    this.initialSetup();
  },
  methods: {
    initialSetup() {
      if (this.isContactListsPage && this.hasAppliedListCondition) {
        this.condition = structuredClone(this.appliedListCondition);

        return;
      }

      if (!this.isContactListsPage && this.hasAppliedContactCondition) {
        this.condition = structuredClone(this.appliedContactCondition);

        return;
      }

      this.condition = DEFAULT_CONDITION;
    },

    onClose(value = false) {
      this.$emit('on-close', value);
    },

    onConditionUpdate({ condition, isValid }) {
      this.condition = structuredClone(condition);
      this.isValidCondition = isValid;
    },

    async onClearCondition() {
      if (this.isContactListsPage) {
        await this.$store.dispatch('contactLists/setAppliedListCondition', {
          listId: this.contactListId,
          condition: this.condition,
        });
      } else {
        await this.$store.dispatch(
          'contacts/setContactCondition',
          this.condition
        );
      }

      this.$emit('on-clear-condition');
    },

    async onConditionSubmit() {
      if (this.isContactListsPage) {
        await this.$store.dispatch('contactLists/setAppliedListCondition', {
          listId: this.contactListId,
          condition: this.condition,
        });
      } else {
        await this.$store.dispatch(
          'contacts/setContactCondition',
          this.condition
        );
      }

      this.onClose(true);
    },
  },
};
</script>

<style></style>
