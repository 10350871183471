var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('woot-modal-header',{attrs:{"header-title":_vm.$t('CONDITION.CREATE_BY.TITLE')}}),_vm._v(" "),_c('formulate-form',{staticClass:"modal-body flex flex-col gap-3",attrs:{"name":"create-list-by-condition"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function({ hasErrors }){return [_c('div',{staticClass:"flex flex-col gap-1 rounded-md bg-woot-50 px-4 py-2.5 text-woot-500 dark:bg-woot-700 dark:text-woot-25"},[_c('span',{staticClass:"text-base font-bold"},[_vm._v("\n        "+_vm._s(_vm.modalTitle)+"\n      ")]),_vm._v(" "),_c('span',{staticClass:"text-sm"},[_vm._v("\n        "+_vm._s(_vm.modalText)+"\n      ")])]),_vm._v(" "),_c('formulate-input',{attrs:{"label":_vm.$t('CHATLYN_GENERAL.LABEL.NAME'),"placeholder":_vm.$t('CHATLYN_CONTACT_LISTS.PLACEHOLDERS.NAME'),"validation-messages":{
        required: _vm.$t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
        min: _vm.$t('CHATLYN_GENERAL.MESSAGES.MIN_LENGTH_2'),
      },"minlength":2,"validation":"required|min:2"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_vm._v(" "),(!_vm.isContactListsPage)?_c('div',{staticClass:"rounded-md p-2.5 transition-all duration-300",class:{
        'bg-gray-50 dark:bg-gray-900': !_vm.isDynamicList,
        'bg-woot-50 dark:bg-woot-700': _vm.isDynamicList,
      }},[_c('woot-switch',{attrs:{"label":_vm.$t('CHATLYN_CONTACT_LISTS.DYNAMIC_LABEL')},model:{value:(_vm.isDynamicList),callback:function ($$v) {_vm.isDynamicList=$$v},expression:"isDynamicList"}})],1):_vm._e(),_vm._v(" "),_c('slide-animation',{attrs:{"is-shown":_vm.isDynamicList}},[_c('div',{staticClass:"flex flex-col gap-1"},[_c('label',[_vm._v(_vm._s(_vm.$t('CONDITION.CREATE_BY.CONDITION_LABEL')))]),_vm._v(" "),_c('list-condition',{attrs:{"list-condition":_vm.appliedContactCondition,"is-editable":false,"has-add-condition-button":false}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-row flex-nowrap items-center justify-end"},[_c('woot-button',{staticClass:"transition-all duration-300",attrs:{"is-disabled":hasErrors,"color-scheme":"primary"}},[_vm._v("\n        "+_vm._s(_vm.$t('CHATLYN_GENERAL.BUTTON.CREATE'))+"\n      ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }