<template>
  <div class="flex flex-col">
    <woot-modal-header
      :header-title="$t('CHATLYN_CONTACT_LISTS.ADD_TO_OTHER_LIST_MODAL.TITLE')"
    />
    <form class="modal-body w-full" @submit.prevent="addList">
      <multiselect-ch
        v-model="selected"
        :title="
          $t('CHATLYN_CONTACT_LISTS.ADD_TO_OTHER_LIST_MODAL.RECIPIENT_LIST')
        "
        :options="contactLists"
        label="name"
        :multiple="false"
        :max-height="200"
      />
      <div class="flex w-full flex-row justify-end gap-2 pt-4">
        <woot-button
          color-scheme="secondary"
          variant="clear"
          @click.prevent="onClose"
        >
          {{ $t('CHATLYN_GENERAL.BUTTON.CANCEL') }}
        </woot-button>

        <woot-button color-scheme="primary" :is-disabled="!selected">
          {{ $t('CHATLYN_GENERAL.BUTTON.ADD') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import MultiselectCh from 'shared/components/ui/Multiselect.vue';

export default {
  name: 'AddToList',
  components: {
    MultiselectCh,
  },
  mixins: [alertMixin],
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: '',
      matchesTitle: '',
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      contactLists: 'contactLists/getAvailableLists',
      currentList: 'contactLists/getList',
    }),
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async addList() {
      try {
        await this.$store.dispatch('contactLists/addContacts', {
          // eslint-disable-next-line no-underscore-dangle
          listId: this.selected._id,
          contacts: this.contacts,
        });
        const existed = this.selected.contacts.filter((contact) =>
          this.contacts.includes(contact)
        );

        await this.$store.dispatch('contactLists/getLists', {
          accountId: this.currentAccountId,
        });

        // TODO: Uncomment if you need to get the list after adding contacts
        // await this.$store.dispatch('contactLists/getList', {
        //   // eslint-disable-next-line no-underscore-dangle
        //   listId: this.selected._id,
        //   page: 1,
        // });

        const numberOfMatches = existed.length;

        let successMessage = '';

        if (numberOfMatches > 0) {
          successMessage = this.$t(
            'CHATLYN_CONTACT_LISTS.ADD_TO_LIST.SUCCESS_WITH_EXISTING',
            {
              number: this.contacts.length - numberOfMatches,
              existing: numberOfMatches,
            }
          );
        } else {
          successMessage =
            this.contacts.length > 1
              ? this.$t('CHATLYN_CONTACT_LISTS.ADD_TO_LIST.SUCCESS_MESSAGE', {
                  number: this.contacts.length,
                  list: this.selected.name,
                })
              : this.$t(
                  'CHATLYN_CONTACT_LISTS.ADD_TO_LIST.SUCCESS_MESSAGE_ONE',
                  {
                    list: this.selected.name,
                  }
                );
        }

        this.showAlert(successMessage);
        this.onClose();
      } catch (error) {
        const errorMessage =
          error.message || this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN');
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>
