<template>
  <modal :show.sync="show" :on-close="cancel">
    <woot-modal-header :header-title="title" :header-content="description" />
    <div class="modal-footer">
      <woot-button color-scheme="secondary" variant="clear" @click="cancel">
        {{ cancelLabel }}
      </woot-button>
      <woot-button @click="confirm">
        {{ confirmLabel }}
      </woot-button>
    </div>
  </modal>
</template>
<script>
import Modal from '../../Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    title: {
      type: String,
      default: 'This is a title',
    },
    description: {
      type: String,
      default: 'This is your description',
    },
    confirmLabel: {
      type: String,
      default: 'Yes',
    },
    cancelLabel: {
      type: String,
      default: 'No',
    },
  },
  data: () => ({
    show: false,
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    showConfirmation() {
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    confirm() {
      this.resolvePromise(true);
      this.show = false;
    },

    cancel() {
      this.resolvePromise(false);
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-container .modal-footer {
  @apply py-4 px-6;
}
</style>
