<template>
  <formulate-form
    #default="{ hasErrors }"
    name="list-duplicate-form"
    class="flex flex-col gap-3"
    @submit="handleFormSubmit"
  >
    <formulate-input
      v-model="duplicateName"
      :label="$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.DUPLICATE.NAME')"
      :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.NAME')"
      validation="required|min:2"
      :validation-messages="{
        required: $t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
      }"
      :minlength="2"
    />
    <formulate-input
      v-if="isDynamicList"
      v-model="duplicateStatic"
      :help="$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.DUPLICATE.HELP_TEXT')"
      :label="$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.DUPLICATE.STATIC')"
      type="checkbox"
    />
    <div class="modal-actions mt-1 flex w-full items-center justify-between">
      <woot-button
        color-scheme="alert"
        variant="smooth"
        type="button"
        @click="$emit('handle-delete-list')"
      >
        {{ $t('CHATLYN_GENERAL.BUTTON.DELETE') }}
      </woot-button>

      <woot-button :disabled="hasErrors">
        {{ $t('CHATLYN_GENERAL.BUTTON.CREATE') }}
      </woot-button>
    </div>
  </formulate-form>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  name: 'ListDuplicate',
  mixins: [alertMixin],
  props: {
    contactList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      duplicateName: '',
      duplicateStatic: false,
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
    }),
    isDynamicList() {
      return !this.contactList.static;
    },
  },
  methods: {
    async handleFormSubmit() {
      const copyToList = {
        name: this.duplicateName,
        ...(this.isDynamicList && this.duplicateStatic && { isStatic: true }),
      };
      try {
        await this.$store.dispatch('contactLists/duplicateList', {
          listId: this.contactList.id,
          copyToList,
        });

        await this.$store.dispatch('contactLists/getLists', {
          accountId: this.currentAccountId,
        });

        this.showAlert(
          this.$t('CHATLYN_CONTACT_LISTS.EDIT_MODAL.DUPLICATE.SUCCESS_MESSAGE')
        );

        this.$emit('on-close');
      } catch (error) {
        const errorMessage =
          error.message || this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN');
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>

<style lang="scss"></style>
