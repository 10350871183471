<template>
  <div class="header-wrapper">
    <div class="header-branding">
      <div class="header">
        <img
          v-if="config.logo"
          :src="config.logo"
          class="logo"
          :class="{ small: !isDefaultScreen }"
        />
        <div v-if="!isDefaultScreen">
          <div class="title-block">
            <span>{{ config.websiteName }}</span>
            <div v-if="config.isOnline" class="online-dot" />
          </div>
          <p>{{ config.replyTime }}</p>
        </div>
      </div>
      <div v-if="isDefaultScreen" class="header-expanded">
        <h2>{{ config.welcomeHeading }}</h2>
        <p>{{ config.welcomeTagline }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isDefaultScreen() {
      return (
        this.config.isDefaultScreen &&
        ((this.config.welcomeHeading &&
          this.config.welcomeHeading.length !== 0) ||
          (this.config.welcomeTagLine &&
            this.config.welcomeTagline.length !== 0))
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  @apply rounded-t-lg p-4 bg-white text-gray-800 dark:text-gray-800 border-b border-gray-100;
  flex-shrink: 0;
  transition: max-height 300ms;

  p {
    @apply text-xs text-gray-500 dark:text-gray-500;
    overflow-wrap: break-word;
  }

  .header-branding {
    .header {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: start;

      .logo {
        @apply object-cover rounded-full h-8 w-8 transition-all duration-200 mr-2;

        &.small {
          @apply h-7 w-7
        }
      }
    }

    .header-expanded {
      @apply max-h-[5rem];
      overflow: auto;

      h2 {
        @apply text-lg m-0;
        overflow-wrap: break-word;
      }

      p {
        @apply text-sm text-gray-700 dark:text-gray-700;
        overflow-wrap: break-word;
      }


    }
  }

  .title-block {
    @apply text-base items-center flex;

    .online-dot {
      @apply bg-green-500 w-2 h-2 rounded-full mx-1
    }
  }
}
</style>
