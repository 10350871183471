<template>
  <div class="radio-group">
    <label class="radio-group__label">{{ label }}</label>

    <div class="radio-group__items">
      <div v-for="item in items" :key="item.id" class="radio-group__item">
        <label
          class="radio-group__item__label"
          :class="{
            'select-none opacity-75': item.disabled,
          }"
          :style="{
            cursor: item.disabled && 'not-allowed',
          }"
        >
          <input
            class="radio-group__item__input"
            :name="`${name} -radio-input`"
            type="radio"
            :checked="item.checked"
            :disabled="item.disabled"
            @change="action({ ...item, checked: true })"
          />
          <span>{{ item.title }}</span>
        </label>

        <slot :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'string',
    },
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-group {
  @apply flex flex-col flex-nowrap gap-2;

  .radio-group__label {
  }

  .radio-group__items {
    @apply mb-2 inline-block;

    .radio-group__item {
      @apply float-left mr-2;

      .radio-group__item__label {
        @apply flex cursor-pointer items-center;

        .radio-group__item__input {
          @apply m-0 mr-2;
        }
      }
    }
  }
}

// .radio-group-label {
//   margin-bottom: var(--space-smaller);
// }

// .radio-group {
//   display: inline-block;
//   margin-bottom: var(--space-small);
// }

// .radio-group__item {
//   float: left;
//   margin-right: var(--space-one);
//   .radio-group__item-label {
//     display: flex;
//     align-items: center;
//     cursor: pointer;

//     .radio-input {
//       margin: 0 var(--space-one) 0 0;
//     }
//   }
// }
</style>
