<template>
  <div
    v-tooltip="errorStatusTooltip()"
    class="first-letter-uppercase flex items-center gap-1"
  >
    <span
      :class="[
        checkStatuses
          ? 'status-indicator bg-green-500'
          : 'status-indicator bg-red-500',
      ]"
    />
    <span
      class="menu-label inline-block text-xs"
      :class="[
        checkStatuses
          ? 'text-green-700 dark:text-green-400'
          : 'text-red-700 dark:text-red-400',
      ]"
    >
      {{
        checkStatuses
          ? $t('CHATLYN_SENT_TEMPLATES_REPORTS.STATUS.SENT')
          : $t('CHATLYN_SENT_TEMPLATES_REPORTS.STATUS.FAILED')
      }}
    </span>
    <div
      v-if="data.statuses.length > 0"
      class="ml-3 inline-flex items-center gap-0"
    >
      <div
        v-if="showIcon('sent')"
        v-tooltip="showIcon('sent')"
        class="sent-indicator bg-gray-100 text-gray-600 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
      >
        <fluent-icon
          icon="checkmark-single"
          type="outline"
          class-name="visibility-check"
          size="12"
        />
      </div>
      <div
        v-if="showIcon('delivered')"
        v-tooltip="showIcon('delivered')"
        class="sent-indicator bg-woot-50 text-gray-900 hover:bg-woot-100 dark:bg-woot-700 dark:text-woot-100 dark:hover:bg-woot-600"
      >
        <fluent-icon
          icon="checkmark-double"
          type="outline"
          class-name="visibility-check"
          size="12"
        />
      </div>
      <div
        v-if="showIcon('read')"
        v-tooltip="showIcon('read')"
        class="sent-indicator bg-green-500 text-white hover:bg-green-600 dark:bg-green-600 dark:hover:bg-woot-500"
      >
        <fluent-icon
          icon="checkmark-double"
          type="outline"
          class-name="visibility-check"
          size="12"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'Statuses',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    checkStatuses() {
      if (
        !Array.isArray(this.data.statuses) ||
        this.data.statuses.length === 0
      ) {
        return false;
      }

      const hasFailedStatus = this.data.statuses.some(
        (status) => status.value === 'failed'
      );

      if (hasFailedStatus) {
        return false;
      }

      return this.data.statusCode === 201 || this.data.statusCode === 200;
    },
  },
  methods: {
    errorStatusTooltip() {
      if (this.checkStatuses) return '';

      const { statusCode, statuses, response } = this.data;
      const hasFailedStatus = statuses.some(
        (status) => status.value === 'failed'
      );
      const failedStatus = statuses.find((status) => status.value === 'failed');

      if (
        response.error &&
        response.error.error_data &&
        response.error.error_data.details
      ) {
        return response.error.error_data.details;
      }

      if (hasFailedStatus && failedStatus) {
        const errorDetailsPath =
          failedStatus.payload?.entry?.[0]?.changes?.[0]?.value?.statuses?.[0]
            ?.errors?.[0]?.error_data?.details;

        if (errorDetailsPath) {
          return errorDetailsPath;
        }
      }

      if (
        [555, 400].includes(statusCode) ||
        !statuses.length ||
        hasFailedStatus
      ) {
        return 'Server Error';
      }

      return 'Server Error';
    },
    showIcon(statusName) {
      const currentStatus = this.data.statuses.find(
        (status) => status.value === statusName
      );
      if (!currentStatus) return false;
      let name = '';
      switch (statusName) {
        case 'sent':
          name = this.$t('CHATLYN_SENT_TEMPLATES_REPORTS.STATUS.SENT');
          break;
        case 'delivered':
          name = this.$t('CHATLYN_SENT_TEMPLATES_REPORTS.STATUS.DELIVERED');
          break;
        case 'read':
          name = this.$t('CHATLYN_SENT_TEMPLATES_REPORTS.STATUS.READ');
          break;
        default:
          name = statusName;
          break;
      }
      return name + ': ' + moment(currentStatus.timestamp).format('LLL');
    },
  },
};
</script>

<style scoped lang="scss">
.sent-indicator {
  @apply flex h-6 w-6 scale-75 items-center justify-center rounded-full transition-all duration-200 hover:scale-100;
}
.first-letter-uppercase::first-letter {
  text-transform: uppercase;
}
</style>
