var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"note-wrap mb-2 flex flex-grow flex-col overflow-hidden rounded-md border border-solid border-gray-100 bg-white p-4 text-gray-700 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-100"},[_c('div',{staticClass:"flex justify-between gap-1 text-xs",class:{
      'items-center': _vm.isOnPanel,
      'items-end': !_vm.isOnPanel,
    }},[_c('div',{staticClass:"flex items-center"},[_c('thumbnail',{attrs:{"title":_vm.noteAuthorName,"src":_vm.noteAuthor.thumbnail,"username":_vm.noteAuthorName,"size":"20px"}}),_vm._v(" "),_c('div',{staticClass:"mx-1 my-0 flex gap-1 p-0.5",class:{
          'flex-col': _vm.isOnPanel,
          'flex-row': !_vm.isOnPanel,
        }},[_c('span',{},[_vm._v("\n          "+_vm._s(_vm.noteAuthorName)+"\n        ")]),_vm._v(" "),(!_vm.isOnPanel)?_c('span',{staticClass:"text-muted"},[_vm._v("\n          "+_vm._s(_vm.$t('NOTES.LIST.LABEL'))+"\n        ")]):_vm._e(),_vm._v(" "),_c('span',{},[_vm._v("\n          "+_vm._s(_vm.readableTime)+"\n        ")])])],1),_vm._v(" "),_c('div',{staticClass:"actions invisible flex"},[_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('NOTES.CONTENT_HEADER.DELETE')),expression:"$t('NOTES.CONTENT_HEADER.DELETE')"}],attrs:{"variant":"smooth","size":"tiny","icon":"delete","color-scheme":"alert"},on:{"click":_vm.toggleDeleteModal}})],1),_vm._v(" "),(_vm.showDeleteModal)?_c('woot-delete-modal',{attrs:{"show":_vm.showDeleteModal,"on-close":_vm.closeDelete,"on-confirm":_vm.confirmDeletion,"title":_vm.$t('DELETE_NOTE.CONFIRM.TITLE'),"message":_vm.$t('DELETE_NOTE.CONFIRM.MESSAGE'),"confirm-text":_vm.$t('DELETE_NOTE.CONFIRM.YES'),"reject-text":_vm.$t('DELETE_NOTE.CONFIRM.NO')},on:{"update:show":function($event){_vm.showDeleteModal=$event}}}):_vm._e()],1),_vm._v(" "),_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.formatMessage(_vm.note || '')),expression:"formatMessage(note || '')"}],staticClass:"note__content mb-0 mt-3"})])
}
var staticRenderFns = []

export { render, staticRenderFns }