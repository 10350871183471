<template>
  <div
    class="templates-table-container flex h-full min-h-0 flex-grow flex-col pt-3"
  >
    <div class="flex min-h-0 flex-1 flex-grow overflow-auto">
      <ve-table
        v-if="!isLoading && templates.length"
        :fixed-header="true"
        :columns="columns"
        :table-data="tableData"
        :sort-option="sortOption"
        :border-around="false"
        class="flex min-h-0 flex-1 flex-grow overflow-auto truncate"
      />
      <analytics-sidebar
        v-if="!isLoading && allTemplates.length"
        :status-counts="statusCounts"
        :total-count="total"
      />
      <div v-if="isLoading" class="flex flex-grow">
        <spinner class="m-auto" />
      </div>
      <empty-state
        v-else-if="!isLoading && !templates.length"
        transparent
        :title="$t('CHATLYN_SENT_TEMPLATES_REPORTS.NO_SENT_TEMPLATES')"
        class="m-auto w-full"
      />
    </div>
    <div
      v-if="templates.length > 0"
      class="table-pagination mt-auto flex-shrink-0 border-t p-3 dark:border-gray-800"
    >
      <div class="flex justify-end">
        <ve-pagination
          :total="total"
          :page-index="pageIndex"
          :page-size="50"
          :page-size-option="[50]"
          @on-page-number-change="onPageNumberChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { VeTable, VePagination } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import Statuses from './Statuses.vue';
import rtlMixin from 'shared/mixins/rtlMixin';
import timeMixin from 'dashboard/mixins/time';
import moment from 'moment';
import AnalyticsSidebar from './AnalyticsSidebar.vue';

export default {
  name: 'SentTemplateTable',
  components: {
    AnalyticsSidebar,
    EmptyState,
    Spinner,
    VeTable,
    VePagination,
    Statuses,
  },
  mixins: [rtlMixin, timeMixin],
  props: {
    templates: {
      type: Array,
      default: () => [],
    },
    allTemplates: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 1,
    },
    pageIndex: {
      type: Number,
      default: 1,
    },
    sortParam: {
      type: String,
      default: 'templateName',
    },
    sortOrder: {
      type: String,
      default: 'desc',
    },
    statusCounts: {
      type: Object,
      default: () => ({}),
    },
    whatsAppInboxes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortConfig: {},
      sortOption: {
        sortAlways: true,
        sortChange: (params) => this.$emit('sort-change', params),
      },
    };
  },
  computed: {
    tableData() {
      return this.templates.map((template) => {
        const templateInfo = this.getTemplateInfo(template.id);
        return {
          templateName: templateInfo.templateName || '---',
          from: templateInfo.from || '---',
          to: templateInfo.to || '---',
          statusCode: templateInfo.statusCode || '---',
          created_at: templateInfo.created_at ? templateInfo.created_at : '---',
          statuses: templateInfo.statuses || [],
          response: templateInfo.response || '',
        };
      });
    },
    columns() {
      return [
        {
          field: 'templateName',
          key: 'template_name',
          title: this.$t(
            'CHATLYN_SENT_TEMPLATES_REPORTS.TABLE_HEADERS.TEMPLATE_NAME'
          ),
          align: this.isRTLView ? 'right' : 'left',
          width: 400,
          sortBy: this.sortConfig.templateName || '',
          renderBodyCell: ({ row }) => (
            <div class="templates-table-container__template-name truncate">
              {row.templateName}
            </div>
          ),
        },
        {
          field: 'from',
          key: 'from',
          title: this.$t('CHATLYN_SENT_TEMPLATES_REPORTS.TABLE_HEADERS.INBOX'),
          align: this.isRTLView ? 'right' : 'left',
          width: '15%',
          renderBodyCell: ({ row }) => (
            <div v-tooltip={'+' + row.from}>{this.getInboxName(row.from)}</div>
          ),
        },
        {
          field: 'to',
          key: 'to',
          title: this.$t(
            'CHATLYN_SENT_TEMPLATES_REPORTS.TABLE_HEADERS.RECIPIENT'
          ),
          align: this.isRTLView ? 'right' : 'left',
          width: '15%',
          renderBodyCell: ({ row }) => <span>+{row.to}</span>,
        },
        {
          field: 'statusCode',
          key: 'statusCode',
          title: this.$t('CHATLYN_SENT_TEMPLATES_REPORTS.TABLE_HEADERS.STATUS'),
          align: this.isRTLView ? 'right' : 'left',
          width: '20%',
          renderBodyCell: ({ row }) => <statuses data={row} />,
        },
        {
          field: 'created_at',
          key: 'created_at',
          title: this.$t(
            'CHATLYN_SENT_TEMPLATES_REPORTS.TABLE_HEADERS.TIMESTAMP'
          ),
          align: this.isRTLView ? 'right' : 'left',
          width: '15%',
          // sortBy: this.sortConfig.created_at || '',
          renderBodyCell: ({ row }) => (
            <span>{moment(row.created_at).format('MM-DD-YYYY')}</span>
          ),
        },
      ];
    },
  },
  watch: {
    sortOrder() {
      this.setSortConfig();
    },
    sortParam() {
      this.setSortConfig();
    },
    selectedClean() {
      if (this.selectedClean) {
        this.checkboxOption.selectedRowKeys = [];
        this.$emit('disable-deselect');
      }
    },
  },
  mounted() {
    this.setSortConfig();
  },
  methods: {
    onPageNumberChange(pageIndex) {
      this.$emit('page-change', pageIndex);
    },
    getTemplateInfo(id) {
      return this.templates.find((agent) => agent.id === Number(id));
    },
    setSortConfig() {
      this.sortConfig = { [this.sortParam]: this.sortOrder };
    },
    getInboxName(phoneNumber) {
      const currentInbox = this.whatsAppInboxes.find(
        (inbox) =>
          '+' + phoneNumber === inbox.phone_number ||
          phoneNumber === inbox?.additional_attributes?.number
      );
      if (!currentInbox) {
        return '---';
      }

      return currentInbox.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.templates-table-container {
  &::v-deep .ve-pagination {
    @apply bg-transparent;
  }

  &::v-deep .ve-table-body-td {
    max-width: 400px;
  }
}

.table-pagination::v-deep .ve-pagination-select {
  @apply hidden;
}
</style>
