export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },

  getHandover($state) {
    return $state.record;
  },

  getConversationStatus($state) {
    return $state.conversationStatus;
  },

  getAINests($state) {
    return $state.aiNests;
  },

  getAISuggestions($state) {
    return $state.aiSuggestion;
  },
};
