import axios from 'axios';
import ApiClient from './ApiClient';
import AttributeAPI from './attributes';
import JwtAuth from './jwtAuth';
import { downloadCsvFile } from '../helper/downloadHelper';

const url = () =>
  process.env.FRONTEND_ENV === 'develop'
    ? process.env.IMPORTER_BACKEND_URL
    : window.location.protocol + '//' + window.location.hostname + '/importer';

class ImportContactsAPI extends ApiClient {
  constructor() {
    super('import_contacts', { accountScoped: true });
  }

  parseCSV = async (file, accountId, tryToParsePhoneNumber = false) => {
    try {
      const attributes = await AttributeAPI.getAttributesByModel();

      const jwtAuthTokens = await JwtAuth.getUserTokens();

      const formData = new FormData();
      formData.append('import_file', file);
      formData.append(
        'customAttributes',
        JSON.stringify(
          (attributes.data || []).filter(
            (attribute) => attribute.attribute_model === 'contact_attribute'
          )
        )
      );
      formData.append('tryToParsePhoneNumber', tryToParsePhoneNumber);

      const response = await axios.post(
        `${url()}/importer/parse-csv/${accountId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwtAuthTokens.access_token}`,
          },
        }
      );

      return response.data.result;
    } catch (error) {
      bus.$emit(
        'newToastMessage',
        error?.response?.data?.message || error?.message || error
      );
      return [];
    }
  };

  getBrokenContactsCsv = async (
    file,
    accountId,
    tryToParsePhoneNumber = false
  ) => {
    try {
      const attributes = await AttributeAPI.getAttributesByModel();

      const jwtAuthTokens = await JwtAuth.getUserTokens();

      const formData = new FormData();
      formData.append('import_file', file);
      formData.append(
        'customAttributes',
        JSON.stringify(
          (attributes.data || []).filter(
            (attribute) => attribute.attribute_model === 'contact_attribute'
          )
        )
      );
      formData.append('tryToParsePhoneNumber', tryToParsePhoneNumber);

      const response = await axios.post(
        `${url()}/importer/get-broken-contacts-csv/${accountId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwtAuthTokens.access_token}`,
          },
        }
      );

      downloadCsvFile('broken-contacts.csv', response.data);
    } catch (error) {
      //
    }
  };

  importContacts = async (contacts, accountId, contactList) => {
    try {
      const jwtAuthTokens = await JwtAuth.getUserTokens();

      const body = { contacts };

      if (contactList) {
        body.contactList = contactList;
      }

      const response = await axios.post(
        `${url()}/importer/import-contacts/${accountId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${jwtAuthTokens.access_token}`,
          },
        }
      );

      return response.data.result;
    } catch (error) {
      return false;
    }
  };

  removeContactsFromList = async (contacts, accountId, contactList) => {
    try {
      const jwtAuthTokens = await JwtAuth.getUserTokens();

      const response = await axios.post(
        `${url()}/importer/remove-contacts/${accountId}`,
        {
          contacts,
          contactList,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtAuthTokens.access_token}`,
          },
        }
      );

      if (!response.data.result) {
        throw new Error();
      }

      return true;
    } catch (error) {
      return false;
    }
  };
}

export default new ImportContactsAPI();
