<template>
  <section
    class="contact--form flex h-full w-full flex-1 flex-col"
    @submit.prevent="submit"
  >
    <div
      v-if="excludedContacts.items"
      class="relative z-10 flex flex-nowrap gap-2 px-4 pb-3 shadow-md"
    >
      <div
        class="flex flex-grow flex-nowrap rounded border focus-within:border-gray-300 dark:border-gray-700 dark:focus-within:border-gray-500"
      >
        <input
          type="text"
          :placeholder="$t('CONTACTS_PAGE.SEARCH_INPUT_PLACEHOLDER')"
          class="rounded-e-none border-none"
          :value="searchQuery"
          @keyup.enter="onSearchSubmit"
          @input="onInputSearch"
        />

        <woot-button
          v-if="searchQuery"
          :is-loading="false"
          :class-names="searchButtonClass"
          icon="search"
          variant="clear"
          color-scheme="secondary"
          class="rounded-s-none"
          @click="onSearchSubmit"
        />
      </div>

      <woot-button
        v-tooltip.left="$t('CHATLYN_GENERAL.BUTTON.NEW_CONTACT')"
        color-scheme="primary"
        icon="person-add"
        data-testid="create-new-contact"
        class="flex-shrink-0"
        @click="showCreateModal"
      />
    </div>

    <div class="flex flex-grow overflow-auto">
      <contacts-short-table
        :contacts="excludedContacts.items"
        :show-search-empty-state="showEmptySearchResult"
        :is-loading="uiFlags.isFetching"
        :on-click-contact="openContactInfoPanel"
        :sort-config="sortConfig"
        @on-sort-change="onSortChange"
        @selected-row="changeSelectedQuantity"
      />
    </div>
    <div
      class="sidebar-footer mt-auto flex items-center justify-between px-8 pt-4"
    >
      <woot-button :disabled="selectedQuantity === 0" @click="addToList">
        {{ $t('CHATLYN_CONTACT_LISTS.ADD_TO_LIST.ADD_BUTTON') }}
      </woot-button>
      <table-footer
        :current-page="Number(excludedContacts.meta.currentPage)"
        :total-count="excludedContacts.meta.itemCount"
        :page-size="Number(excludedContacts.meta.itemsPerPage)"
        hide-page-meta
        @page-change="onPageChange"
      />
    </div>
  </section>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import ContactsShortTable from './ContactsShortTable.vue';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import { mapGetters } from 'vuex';
import contactFilterItems from '../contacts/contactFilterItems';
import filterQueryGenerator from '../../../helper/filterQueryGenerator';

const DEFAULT_PAGE = 1;
export default {
  name: 'AddContactsToListForm',
  components: {
    ContactsShortTable,
    TableFooter,
  },
  mixins: [alertMixin],
  props: {
    name: {
      type: String,
      default: '',
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      searchQuery: '',
      selectedIds: [],
      showDeleteSegmentsModal: false,
      showFiltersModal: false,
      sortConfig: { name: 'asc' },
      selectedContactId: '',
      selectedQuantity: 0,
      contactFilterItems: contactFilterItems.map((filter) => ({
        ...filter,
        attributeName: this.$t(
          `CONTACTS_FILTER.ATTRIBUTES.${filter.attributeI18nKey}`
        ),
      })),
    };
  },
  computed: {
    ...mapGetters({
      getAppliedContactFilters: 'contacts/getAppliedContactFilters',
      records: 'contacts/getContacts',
      excludedContacts: 'contactLists/getExcludedContacts',
      uiFlags: 'contacts/getUIFlags',
      meta: 'contacts/getMeta',
      getContactLists: 'contacts/getLists',
      currentList: 'contactLists/getList',
      accountId: 'getCurrentAccountId',
      segments: 'customViews/getCustomViews',
    }),
    pageParameter() {
      const selectedPageNumber = Number(this.$route.query?.page);
      return !Number.isNaN(selectedPageNumber) &&
        selectedPageNumber >= DEFAULT_PAGE
        ? selectedPageNumber
        : DEFAULT_PAGE;
    },
    showEmptySearchResult() {
      const hasEmptyResults = !!this.searchQuery && this.records.length === 0;
      return hasEmptyResults;
    },
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
    segmentsId() {
      return this.$route.params.id || 0;
    },
    hasActiveSegments() {
      return !!this.segmentsId;
    },
    hasAppliedFilters() {
      return this.getAppliedContactFilters.length;
    },
    isContactDashboard() {
      return (
        this.$route.name === 'contacts_dashboard' ||
        this.$route.name === 'contacts_labels_dashboard' ||
        this.$route.name === 'contacts_lists_dashboard'
      );
    },
    activeSegment() {
      if (this.segmentsId) {
        const [firstValue] = this.segments.filter(
          (view) => view.id === Number(this.segmentsId)
        );
        return firstValue;
      }
      return undefined;
    },
  },
  mounted() {
    this.fetchExcludedContacts();
  },
  methods: {
    fetchExcludedContacts() {
      this.$store.dispatch('contactLists/getExcludedContacts', {
        listId: this.$route.params.listId,
        page: this.currentPage,
        pageParams: 'name',
        search: this.searchQuery,
      });
    },
    onApplyFilter(payload) {
      this.closeContactInfoPanel();
      this.segmentsQuery = filterQueryGenerator(payload);
      this.$store.dispatch('contacts/filter', {
        queryPayload: filterQueryGenerator(payload),
      });
      this.showFiltersModal = false;
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },
    showCreateModal() {
      this.$emit('show-create-modal');
    },
    openContactInfoPanel(contactId) {
      this.selectedContactId = contactId;
      this.showContactInfoPanelPane = true;
    },
    closeContactInfoPanel() {
      this.selectedContactId = '';
      this.showContactInfoPanelPane = false;
    },
    changeSelectedQuantity(keys) {
      this.selectedQuantity = keys.length;
      this.selectedIds = keys;
    },
    onSortChange(params) {
      this.sortConfig = params;
      this.fetchContacts(this.meta.currentPage);
    },
    updatePageParam(page) {
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
    },
    getSortAttribute() {
      let sortAttr = Object.keys(this.sortConfig).reduce((acc, sortKey) => {
        const sortOrder = this.sortConfig[sortKey];
        if (sortOrder) {
          const sortOrderSign = sortOrder === 'asc' ? '' : '-';
          return `${sortOrderSign}${sortKey}`;
        }
        return acc;
      }, '');
      if (!sortAttr) {
        this.sortConfig = { name: 'asc' };
        sortAttr = 'name';
      }
      return sortAttr;
    },
    onToggleDeleteSegmentsModal() {
      this.showDeleteSegmentsModal = !this.showDeleteSegmentsModal;
    },
    onToggleFilters() {
      this.showFiltersModal = !this.showFiltersModal;
    },
    onPageChange(page) {
      this.currentPage = page;
      this.fetchExcludedContacts();
      // this.selectedContactId = '';
      // if (this.segmentsId !== 0) {
      //   const payload = this.activeSegment.query;
      //   this.fetchSavedFilteredContact(payload, page);
      // }
      // if (this.hasAppliedFilters) {
      //   this.fetchFilteredContacts(page);
      // } else {
      //   this.fetchContacts(page);
      // }
    },
    fetchContacts(page) {
      if (this.isContactDashboard) {
        this.updatePageParam(page);
        let value = '';
        if (this.searchQuery.charAt(0) === '+') {
          value = this.searchQuery.substring(1);
        } else {
          value = this.searchQuery;
        }
        const requestParams = {
          page,
          sortAttr: this.getSortAttribute(),
          label: this.label,
        };
        if (!value) {
          this.$store.dispatch('contacts/get', requestParams);
        } else {
          this.$store.dispatch('contacts/search', {
            search: encodeURIComponent(value),
            ...requestParams,
          });
        }
      }
    },
    fetchSavedFilteredContact(payload, page) {
      if (this.hasActiveSegments) {
        this.updatePageParam(page);
        this.$store.dispatch('contacts/filter', {
          queryPayload: payload,
          page,
        });
      }
    },
    fetchFilteredContacts(page) {
      if (this.hasAppliedFilters) {
        const payload = this.segmentsQuery;
        this.updatePageParam(page);
        this.$store.dispatch('contacts/filter', {
          queryPayload: payload,
          page,
        });
      }
    },
    onInputSearch(event) {
      const newQuery = event.target.value;
      const refetchAllContacts = !!this.searchQuery && newQuery === '';
      this.searchQuery = newQuery;
      if (refetchAllContacts) {
        this.currentPage = 1;
        this.fetchExcludedContacts();
      }
    },
    onSearchSubmit() {
      this.selectedContactId = '';
      if (this.searchQuery) {
        this.fetchExcludedContacts();
      }
    },
    submit() {
      this.$emit('submit');
    },
    async addToList() {
      const listId = this.$route.params.listId;
      try {
        await this.$store.dispatch('contactLists/addContacts', {
          listId: listId,
          contacts: this.selectedIds,
        });

        const existed = this.currentList.contacts.filter((contact) =>
          this.selectedIds.includes(contact.id)
        );

        await this.$store.dispatch('contactLists/getLists', {
          accountId: this.$route.params.accountId,
        });

        await this.$store.dispatch('contactLists/getList', {
          listId: this.$route.params.listId,
          page: 1,
        });

        const numberOfMatches = existed.length;

        let successMessage = '';

        if (numberOfMatches > 0) {
          successMessage = this.$t(
            'CHATLYN_CONTACT_LISTS.ADD_TO_LIST.SUCCESS_WITH_EXISTING',
            {
              number: this.selectedIds.length - numberOfMatches,
              existing: numberOfMatches,
            }
          );
        } else {
          successMessage =
            this.selectedIds.length > 1
              ? this.$t('CHATLYN_CONTACT_LISTS.ADD_TO_LIST.SUCCESS_MESSAGE', {
                  number: this.selectedIds.length,
                  list: this.name,
                })
              : this.$t(
                  'CHATLYN_CONTACT_LISTS.ADD_TO_LIST.SUCCESS_MESSAGE_ONE',
                  {
                    list: this.name,
                  }
                );
        }

        this.showAlert(successMessage);
        this.submit();
      } catch (error) {
        const errorMessage =
          error.message || this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN');
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar-footer {
  &::v-deep {
    footer {
      @apply border-none;
      .footer__wrapper {
        @apply p-0;
      }
    }
  }
}
</style>
