<template>
  <div class="column content-box">
    <woot-modal-header
      :header-title="$t('CHATLYN_CHAT_BUBBLES.DELETE_MODAL.TITLE')"
      :header-content="
        $t(
          isLanguageDeleting
            ? 'CHATLYN_CHAT_BUBBLES.DELETE_MODAL.LANGUAGE_DELETING_TEXT'
            : 'CHATLYN_CHAT_BUBBLES.DELETE_MODAL.TEXT'
        )
      "
    />
    <div class="p-8 flex gap-2 justify-end">
      <woot-button
        color-scheme="secondary"
        variant="clear"
        type="button"
        @click="$emit('close-modal')"
      >
        {{ $t('CHATLYN_GENERAL.BUTTON.CANCEL') }}
      </woot-button>

      <woot-button
        color-scheme="alert"
        variant="smooth"
        type="button"
        @click="$emit('on-confirm')"
      >
        {{ $t('CHATLYN_GENERAL.BUTTON.DELETE') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteChatBubbleModal',
  props: {
    isLanguageDeleting: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
