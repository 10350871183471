<template>
  <div
    :id="mediaPlayerContainerId"
    class="chatlyn-media-player relative !z-10 flex h-full flex-col rounded-lg bg-gray-200 dark:bg-gray-500"
  >
    <div class="chatlyn-media-player__media h-[calc(100%_-_2.8125rem)] flex-1">
      <audio
        v-if="type === CHATLYN_MEDIA_PLAYER_TYPE.AUDIO"
        :id="mediaPlayerId"
        :preload="preload"
        @playing="
          () => {
            handleIsPausedChange(false);
          }
        "
        @pause="
          () => {
            handleIsPausedChange(true);
          }
        "
        @timeupdate="handleTimeUpdate"
        @loadeddata="handleOnLoadedData"
        @volumechange="handleVolumeChange"
        @error="handleOnError"
      >
        <source v-if="mediaPlayerElement && mediaSrc" :src="mediaSrc" />
      </audio>

      <video
        v-else-if="type === CHATLYN_MEDIA_PLAYER_TYPE.VIDEO"
        :id="mediaPlayerId"
        class="h-full w-full rounded-tl-lg rounded-tr-lg"
        :class="{
          'cursor-not-allowed': !canPlay,
          'cursor-pointer': canPlay,
        }"
        :preload="preload"
        @click="toggleIsPaused"
        @playing="
          () => {
            handleIsPausedChange(false);
          }
        "
        @pause="
          () => {
            handleIsPausedChange(true);
          }
        "
        @timeupdate="handleTimeUpdate"
        @loadeddata="handleOnLoadedData"
        @volumechange="handleVolumeChange"
        @error="handleOnError"
      >
        <source v-if="mediaPlayerElement && mediaSrc" :src="mediaSrc" />
      </video>
    </div>

    <div
      class="chatlyn-media-player__controls flex w-full flex-row flex-nowrap items-center gap-1 p-2 text-gray-800 dark:text-white"
      :class="{
        'border-t border-solid border-gray-100 dark:border-gray-400':
          type === CHATLYN_MEDIA_PLAYER_TYPE.VIDEO,
      }"
    >
      <woot-button
        class="!h-auto !w-auto !bg-transparent !p-1.5 !text-gray-800 dark:!text-white"
        :icon="playButtonIcon"
        variant="clear"
        size="small"
        type="button"
        :disabled="!canPlay"
        @click="toggleIsPaused"
      />

      <span
        class="inline-flex w-20 items-center justify-center overflow-hidden text-ellipsis whitespace-nowrap text-xs"
      >
        {{ formattedProgress }} / {{ formattedDuration }}
      </span>

      <input
        v-model="progressComputed"
        class="progressbar-light dark:progressbar-dark flex-1"
        :class="{
          'cursor-not-allowed': !canPlay,
          'cursor-pointer': canPlay,
        }"
        :min="0"
        :max="duration"
        :step="0.01"
        type="range"
        :disabled="!canPlay"
        :style="{
          '--filledTrack': progressFilledPercent,
        }"
      />

      <span class="chatlyn-media-player__controls__volume relative inline-flex">
        <woot-button
          class="chatlyn-media-player__controls__volume__button !h-auto !w-auto !bg-transparent !p-1.5 !text-gray-800 dark:!text-white"
          :icon="volumeButtonIcon"
          variant="clear"
          size="small"
          type="button"
          :disabled="!canPlay"
          @click="toggleIsMutedMedia"
        />

        <span
          v-show="canPlay"
          class="chatlyn-media-player__controls__volume__range absolute right-0 z-50 hidden w-20 rounded-bl-lg rounded-br-lg bg-gray-200 px-2 shadow-md dark:bg-gray-500"
          :class="{
            'top-full': !isFullscreen,
            'bottom-full': isFullscreen,
          }"
        >
          <input
            v-model="volume"
            class="progressbar-light dark:progressbar-dark h-full w-full"
            :min="0"
            :max="100"
            :step="1"
            :style="{
              '--filledTrack': volumeFilledPercent,
            }"
            :disabled="!canPlay"
            type="range"
          />
        </span>
      </span>

      <woot-button
        v-if="type === CHATLYN_MEDIA_PLAYER_TYPE.VIDEO"
        class="!h-auto !w-auto !bg-transparent !p-1.5 !text-gray-800 dark:!text-white"
        :icon="fullscreenButtonIcon"
        variant="clear"
        size="small"
        type="button"
        :disabled="!canPlay"
        @click="toggleIsFullscreen"
      />

      <woot-button
        class="!h-auto !w-auto !bg-transparent !p-1.5 !text-gray-800 dark:!text-white"
        icon="arrow-download"
        variant="clear"
        size="small"
        type="button"
        :disabled="!canPlay"
        @click="downloadMedia"
      />
    </div>
  </div>
</template>

<script>
import { intervalToDuration } from 'date-fns';

import getUuid from 'widget/helpers/uuid';
import { CHATLYN_MEDIA_PLAYER_TYPE } from './constants';
import { downloadURI } from '../../../helper/downloadHelper';

export default {
  name: 'MediaPlayer',

  props: {
    type: {
      type: String,
      default: CHATLYN_MEDIA_PLAYER_TYPE.AUDIO,
    },

    src: {
      type: String,
      default: '',
    },

    preload: {
      type: String,
      default: 'auto',
    },
  },

  data() {
    const mediaPlayerId = `chatlyn-media-player-${getUuid()}`;

    return {
      CHATLYN_MEDIA_PLAYER_TYPE,
      mediaPlayerId,
      mediaPlayerContainerId: `${mediaPlayerId}-container`,
      canPlay: false,
      duration: 0,
      progress: 0,
      volume: 100,
      isMuted: false,
      isPaused: true,
      mediaPlayerContainerElement: null,
      mediaPlayerElement: null,
      isFullscreen: false,
      mediaSrc: '',
    };
  },

  computed: {
    progressComputed: {
      get() {
        return this.progress || this.mediaPlayerElement?.currentTime || 0;
      },

      set(newValue) {
        if (!this.mediaPlayerElement) {
          return;
        }

        this.mediaPlayerElement.currentTime = newValue;
        this.progress = newValue;
      },
    },

    formattedProgress() {
      return this.formatSeconds(this.progress);
    },

    formattedDuration() {
      return this.formatSeconds(this.duration);
    },

    playButtonIcon() {
      if (this.isEnded) {
        return 'arrow-clockwise';
      }

      return this.isPaused ? 'play' : 'pause';
    },

    volumeButtonIcon() {
      const value = Number(this.volume);

      if (this.isMuted) {
        return 'speaker-mute';
      }

      if (value >= 60) {
        return 'speaker-2';
      }

      if (value >= 30) {
        return 'speaker-1';
      }

      return 'speaker-0';
    },

    fullscreenButtonIcon() {
      return `resize-${this.isFullscreen ? 'small' : 'large'}`;
    },

    isEnded() {
      return this.progress === this.duration;
    },

    progressFilledPercent() {
      return `${(this.progressComputed / this.duration) * 100 || 0}%`;
    },

    volumeFilledPercent() {
      return `${(this.volume / 100) * 100 || 0}%`;
    },

    localSrc() {
      return this.src.replace('0.0.0.0', 'localhost');
    },
  },

  watch: {
    src: {
      async handler(newValue, prevValue) {
        if (newValue === prevValue) {
          return;
        }

        if (!newValue) {
          this.duration = 0;
        }

        this.canPlay = false;
        this.isPaused = true;
        this.progressComputed = 0;

        const value = newValue || '';

        this.mediaSrc =
          process.env.FRONTEND_ENV === 'develop'
            ? value.replace('0.0.0.0', 'localhost')
            : value;
      },
      immediate: true,
    },

    mediaSrc: {
      handler() {
        if (!this.mediaPlayerElement) {
          return;
        }

        this.mediaPlayerElement.load();
      },
      immediate: true,
    },

    volume: {
      handler(newValue) {
        if (!this.mediaPlayerElement) {
          return;
        }

        this.mediaPlayerElement.volume = newValue / 100;
      },
      immediate: true,
    },

    isMuted: {
      handler(newValue) {
        if (!this.mediaPlayerElement) {
          return;
        }

        this.mediaPlayerElement.muted = newValue;
      },
      immediate: true,
    },

    mediaPlayerContainerElement: {
      handler(newValue) {
        if (!newValue) {
          return;
        }

        this.mediaPlayerContainerElement.addEventListener(
          'fullscreenchange',
          this.handleFullscreenChange
        );
      },
      immediate: true,
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.mediaPlayerElement = document.getElementById(this.mediaPlayerId);

      this.mediaPlayerContainerElement = document.getElementById(
        this.mediaPlayerContainerId
      );
    });
  },

  beforeDestroy() {
    this.mediaPlayerContainerElement.removeEventListener(
      'fullscreenchange',
      this.handleFullscreenChange
    );
  },

  methods: {
    zeroPad(num) {
      return String(num).padStart(2, '0');
    },

    formatSeconds(seconds) {
      const value = Number(seconds).toFixed(2);
      const duration = intervalToDuration({ start: 0, end: value * 1000 });

      const mm_ss = `${this.zeroPad(duration.minutes)}:${this.zeroPad(
        duration.seconds
      )}`;

      return duration.hours ? `${duration.hours}:${mm_ss}` : mm_ss;
    },

    toggleIsPaused() {
      if (!this.canPlay) {
        return;
      }

      if (this.isEnded && this.isPaused) {
        this.progressComputed = 0;
        this.mediaPlayerElement.play();
        return;
      }

      if (this.isPaused && !this.isEnded) {
        this.mediaPlayerElement.play();
        return;
      }

      this.mediaPlayerElement.pause();
    },

    handleTimeUpdate(event) {
      this.progress = event?.target?.currentTime || 0;
    },

    toggleIsMutedMedia() {
      this.isMuted = !this.isMuted;
    },

    downloadMedia() {
      downloadURI(this.src, 'chatlyn-media');
    },

    handleIsPausedChange(newValue) {
      this.isPaused = typeof newValue === 'boolean' ? newValue : !this.isPaused;
    },

    handleFullscreenChange(event) {
      this.isFullscreen = document.fullscreenElement?.id === event?.target?.id;
    },

    async toggleIsFullscreen() {
      if (!this.mediaPlayerContainerElement) {
        return;
      }

      if (!this.isFullscreen) {
        await this.mediaPlayerContainerElement.requestFullscreen();
        return;
      }

      await document.exitFullscreen();
    },

    handleOnLoadedData(event) {
      this.canPlay = event?.target?.readyState >= 3;
      this.duration = event?.target?.duration || 0;
    },

    handleVolumeChange(event) {
      this.volume = event.target.volume * 100;

      if (this.isMuted !== event.target.muted) {
        this.toggleIsMutedMedia();
      }
    },

    handleOnError(error) {
      this.$emit('error', error);
    },
  },
};
</script>

<style lang="scss">
.chatlyn-media-player {
  .chatlyn-media-player__controls {
    .chatlyn-media-player__controls__volume {
      .chatlyn-media-player__controls__volume__range {
        @apply h-6 items-center;
      }

      &:hover {
        .chatlyn-media-player__controls__volume__range {
          @apply inline-flex;
        }
      }
    }

    input[type='range'] {
      @apply h-0.5 min-w-0 rounded-md;

      -webkit-appearance: none;
      appearance: none;

      &::-webkit-slider-thumb {
        @apply h-2 w-2 rounded-full border border-solid border-gray-100 bg-white shadow-md;

        -webkit-appearance: none;
        appearance: none;
      }

      &::-moz-range-thumb {
        @apply h-2 w-2 rounded-full border border-solid border-gray-100 bg-white shadow-md;

        -webkit-appearance: none;
        appearance: none;
      }
    }
  }
}
</style>
