<!-- eslint-disable vue/no-mutating-props -->
<template>
  <woot-modal :show.sync="show" size="lg" :on-close="onClose">
    <div class="flex h-auto flex-col">
      <woot-modal-header
        :header-title="$t('NEW_CONVERSATION.TITLE')"
        :header-content="$t('NEW_CONVERSATION.DESC')"
      />

      <conversation-form
        :preselected-contact="contact"
        :preselected-inbox="inbox"
        :on-submit="onSubmit"
        @success="onSuccess"
        @cancel="onClose"
      />
    </div>
  </woot-modal>
</template>

<script>
import ConversationForm from './ConversationForm.vue';

export default {
  components: {
    ConversationForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onSuccess() {
      this.$emit('close');
    },
    async onSubmit(params, isFromWhatsApp) {
      return this.$store.dispatch('contactConversations/create', {
        params,
        isFromWhatsApp,
      });
    },
  },
};
</script>
