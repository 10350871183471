<template>
  <div class="">
    <div class="template__list-container">
      <div class="flex-1">
        <label for="templates-search">
          {{ $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.AVAILABLE_TEMPLATES') }}
        </label>

        <div class="templates__list-search my-2">
          <fluent-icon icon="search" class="search-icon" size="16" />
          <input
            id="templates-search"
            ref="search"
            v-model="queryComputed"
            type="search"
            :placeholder="$t('WHATSAPP_TEMPLATES.PICKER.SEARCH_PLACEHOLDER')"
            class="templates__search-input"
          />
        </div>

        <div class="templates__list px-1" style="max-height: 60vh">
          <div
            v-for="messageTemplate in matchedMessageTemplates"
            :key="messageTemplate._id"
            class="templates__list-item"
            :class="{
              'templates__list-item--selected':
                messageTemplate._id === selectedMessageTemplateId,
            }"
            @click="handleChangeSelectedMessageTemplateId(messageTemplate._id)"
          >
            <div class="templates__list-item__body">
              {{ messageTemplate.title }}
            </div>

            <div class="templates__list-item__footer">
              <span>{{ messageTemplate.category }}</span>

              <span>
                {{ $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.LANGUAGES') }}:

                <template v-for="translation in messageTemplate.translations">
                  {{ translation.language }}
                </template>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="templates__preview-container">
        <label>
          {{ $t('CHATLYN_MESSAGE_TEMPLATE.MESSAGE_PREVIEW') }}
        </label>

        <language-bar
          v-if="selectedMessageTemplate"
          has-color-mark
          :translations="selectedMessageTemplate.translations"
          :current-language="selectedLanguage"
          :languages="messageTemplatesLanguages"
          :has-additional-text="false"
          @change="handleChangeSelectedLanguage"
        />

        <message-preview
          class="mt-2 flex"
          :has-label="false"
          :current-translation="currentMessageTemplateTranslation"
          :empty-text="
            $t('CHATLYN_MESSAGE_TEMPLATE.SELECT_TEMPLATE_TO_PREVIEW')
          "
        />
      </div>
    </div>

    <div class="mt-6 flex w-full flex-row flex-nowrap justify-between gap-2">
      <woot-button
        type="button"
        color-scheme="secondary"
        variant="smooth"
        :is-loading="isLoading"
        @click="onGoBack"
      >
        {{ $t('CHATLYN_GENERAL.BUTTON.BACK') }}
      </woot-button>

      <woot-button
        color-scheme="primary"
        type="button"
        :disabled="isContinueButtonDisabled"
        :is-loading="isLoading"
        @click="$emit('pick-template')"
      >
        {{ $t('CHATLYN_MESSAGE_TEMPLATE.SEND_TO_CONTACT_FORM.CONTINUE') }}
      </woot-button>
    </div>

    <div
      v-if="isLoading"
      class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
      style="background: rgba(0, 0, 0, 0.05)"
    >
      <span class="spinner h-8 w-8" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LanguageBar from '../../../../routes/dashboard/messageTemplates/components/LanguageBar.vue';
import MessagePreview from '../../../../routes/dashboard/messageTemplates/components/MessagePreview.vue';

let queryDebounce;

export default {
  name: 'ChatlynMessageTemplatesPiker',
  components: { MessagePreview, LanguageBar },
  props: {
    inboxId: {
      type: Number,
      default: undefined,
    },

    selectedMessageTemplateId: {
      type: String,
      default: '',
    },

    messageTemplates: {
      type: Array,
      default: () => [],
    },

    isContinueButtonDisabled: {
      type: Boolean,
      default: false,
    },

    contactPreferredLanguage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      query: '',
      selectedLanguage: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'messageTemplates/getUIFlags',
    }),

    queryComputed: {
      get() {
        return this.query;
      },

      set(newValue) {
        clearTimeout(queryDebounce);

        queryDebounce = setTimeout(() => {
          this.query = newValue;
        }, 250);
      },
    },

    matchedMessageTemplates() {
      return this.messageTemplates.filter(
        (messageTemplate) =>
          messageTemplate.category !== 'CONSENT' &&
          messageTemplate.title.toLowerCase().includes(this.query.toLowerCase())
      );
    },

    selectedMessageTemplate() {
      return this.messageTemplates.find(
        (messageTemplate) =>
          // eslint-disable-next-line no-underscore-dangle
          messageTemplate._id === this.selectedMessageTemplateId
      );
    },

    messageTemplatesLanguages() {
      if (!this.selectedMessageTemplate) {
        return [];
      }

      return this.selectedMessageTemplate.translations.map((translation) => ({
        name: translation.language,
      }));
    },

    currentMessageTemplateTranslation() {
      if (!this.selectedMessageTemplate || !this.selectedLanguage) {
        return undefined;
      }

      return this.selectedMessageTemplate.translations.find(
        (translation) => translation.language === this.selectedLanguage
      );
    },

    isLoading() {
      return this.uiFlags.isFetching;
    },
  },
  watch: {
    selectedMessageTemplate: {
      handler(newValue) {
        if (!newValue) {
          this.selectedLanguage = '';
          return;
        }

        this.selectedLanguage = (
          newValue.translations.find(
            (translation) =>
              translation.language === this.contactPreferredLanguage
          ) || newValue.translations[0]
        ).language;
      },
      deep: true,
    },
  },
  methods: {
    onGoBack() {
      this.$emit('on-go-back');
    },

    handleChangeSelectedMessageTemplateId(newId) {
      this.$emit('on-change', newId);
    },

    handleChangeSelectedLanguage(newLanguage) {
      this.selectedLanguage = newLanguage;
    },
  },
};
</script>

<style scoped lang="scss">
.template__list-container {
  @apply grid grid-cols-2 gap-4;

  .templates__list-search {
    align-items: center;
    background-color: var(--s-25);
    border-radius: var(--border-radius-medium);
    border: 1px solid var(--s-100);
    display: flex;

    .search-icon {
      color: var(--s-400);
    }

    .templates__search-input {
      background-color: transparent;
      border: var(--space-large);
      font-size: var(--font-size-mini);
      height: unset;
      margin: var(--space-zero);
    }
  }

  .templates__list {
    @apply flex flex-col gap-2 overflow-y-auto overflow-x-hidden py-1;

    .templates__list-item {
      @apply cursor-pointer rounded-lg p-4 transition-all duration-200 odd:bg-gray-50 hover:bg-gray-100 dark:odd:bg-gray-800/50 dark:hover:bg-gray-700;
      &.templates__list-item--selected {
        @apply bg-woot-50/70 outline outline-woot-100 hover:bg-woot-50/90 dark:bg-woot-800 dark:outline-woot-500 hover:dark:bg-woot-800;
      }

      .templates__list-item__body {
        @apply text-woot-800 dark:text-white;
      }

      .templates__list-item__footer {
        @apply text-xs text-gray-500 dark:text-gray-400;
      }
    }
  }

  .templates__preview-container {
    @apply overflow-y-auto overflow-x-hidden;
  }
}
</style>
