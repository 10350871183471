<template>
  <woot-reports
    key="sent-templates-reports"
    type="sent-templates"
    getter-key="sent-templates/getSentTemplates"
    action-key="sent-templates/getSentTemplates"
    :download-button-label="
      $t('CHATLYN_SENT_TEMPLATES_REPORTS.DOWNLOAD_REPORTS')
    "
  />
</template>

<script>
import WootReports from './components/WootReports';

export default {
  components: {
    WootReports,
  },
};
</script>
