<template>
  <modal :size="size" :show="show" :on-close="onClose">
    <woot-modal-header
      :header-title="title"
      :header-content="message"
      :header-content-value="messageValue"
    />

    <div
      class="modal-body flex"
      :style="{
        justifyContent: buttonsAlignment,
      }"
    >
      <woot-button
        v-if="!hideReject"
        variant="clear"
        color-scheme="secondary"
        class="action-button mr-1"
        type="button"
        @click="onClose"
      >
        {{ rejectText }}
      </woot-button>

      <woot-button
        class="action-button"
        color-scheme="primary"
        type="button"
        @click="onConfirm"
      >
        <div v-if="successIcon">
          <div class="flex flex-row items-center gap-2">
            <fluent-icon :icon="successIcon" :size="successIconSize" />

            {{ confirmText }}
          </div>
        </div>

        <template v-else>
          {{ confirmText }}
        </template>
      </woot-button>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal.vue';

export default {
  name: 'ConfirmModal',
  components: {
    Modal,
  },
  props: {
    size: String,
    show: Boolean,
    onClose: { type: Function, default: () => {} },
    onConfirm: { type: Function, default: () => {} },
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    messageValue: { type: String, default: '' },
    confirmText: { type: String, default: '' },
    rejectText: { type: String, default: '' },
    hideReject: { type: Boolean, default: false },
    successIcon: { type: String, default: '' },
    successIconSize: { type: Number, default: 20 },
    buttonsAlignment: { type: String, default: 'start' },
  },
};
</script>
