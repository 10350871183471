<template>
  <div class="bg-[#f0f0f0] relative p-4 rounded">
    <div class="absolute flex gap-1 right-2 top-2">
    <woot-button size="tiny" @click="onCopy">
      {{ $t('COMPONENTS.CODE.BUTTON_TEXT') }}
    </woot-button>
    <woot-button
      size="tiny"
      color-scheme="secondary"
      :icon="masked ? 'eye-show' : 'eye-hide'"
      @click.prevent="toggleMasked"
    />
    </div>
    <highlightjs v-if="value" :code="masked ? '•'.repeat(10) : value" />
  </div>
</template>

<script>
import 'highlight.js/styles/default.css';
import { copyTextToClipboard } from 'shared/helpers/clipboard';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      masked: true,
    };
  },
  methods: {
    async onCopy(e) {
      e.preventDefault();
      await copyTextToClipboard(this.value);
      bus.$emit('newToastMessage', this.$t('COMPONENTS.CODE.COPY_SUCCESSFUL'));
    },
    toggleMasked() {
      this.masked = !this.masked;
    },
  },
};
</script>