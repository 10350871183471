<template>
  <div class="location message-text__wrap">
    <div class="icon-wrap">
      <fluent-icon icon="location" class="file--icon" size="32" />
    </div>

    <div class="meta">
      <h5
        class="text-block-title overflow-hidden text-ellipsis whitespace-nowrap"
      >
        {{ name }}
      </h5>
      <div class="link-wrap">
        <a
          class="download clear link button small"
          rel="noreferrer noopener nofollow"
          target="_blank"
          :href="mapUrl"
        >
          {{ $t('COMPONENTS.LOCATION_BUBBLE.SEE_ON_MAP') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    latitude: {
      type: Number,
      default: undefined,
    },
    longitude: {
      type: Number,
      default: undefined,
    },
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    mapUrl() {
      return `https://maps.google.com/?q=${this.latitude},${this.longitude}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.location {
  @apply flex cursor-pointer flex-row px-0 py-1;

  .icon-wrap {
    @apply my-0 mr-1 leading-none text-gray-600 dark:text-gray-200;
  }

  .text-block-title {
    @apply m-0 break-words text-gray-800 dark:text-gray-100;
  }

  .meta {
    @apply flex w-full min-w-0 flex-col items-start pr-4;

    .text-block-title,
    .link-wrap {
      @apply w-full;
    }
  }

  .link-wrap {
    @apply flex;
  }
}
</style>
