<template>
  <div>
    <form
      class="modal-body conversation-form w-full"
      @submit.prevent="handleSubmit"
    >
      <!-- <div v-if="showNoInboxAlert" class="callout warning">
        {{ $t('NEW_CONVERSATION.NO_INBOX') }}
      </div> -->

      <div class="flex flex-col flex-nowrap gap-3">
        <div
          class="position-static flex w-full flex-nowrap gap-3"
          :class="{
            'flex-col-reverse': isTargetContactPreselected,
            'flex-col': !isTargetContactPreselected,
          }"
        >
          <div class="w-full">
            <label>
              {{ $t('NEW_CONVERSATION.FORM.INBOX.LABEL') }}
            </label>

            <div
              class="multiselect-wrap--small"
              :class="{
                'has-multi-select-error': getFieldErrorByName('targetInbox'),
              }"
            >
              <multiselect
                v-model="targetInbox"
                track-by="id"
                label="name"
                :placeholder="$t('FORMS.MULTISELECT.SELECT')"
                selected-label=""
                select-label=""
                deselect-label=""
                :allow-empty="isSelectStep"
                :max-height="160"
                :close-on-select="true"
                :multiple="false"
                :options="[...inboxesOptions]"
                :disabled="isTargetInboxPreselected || !isSelectStep"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <inbox-dropdown-item
                    v-if="option.name"
                    :name="option.name"
                    :inbox-identifier="computedInboxSource(option)"
                    :api-inbox-type="
                      option.additional_attributes
                        ? option.additional_attributes.apiInboxType
                        : ''
                    "
                    :channel-type="option.channel_type"
                  />

                  <span v-else>
                    {{ $t('NEW_CONVERSATION.FORM.INBOX.PLACEHOLDER') }}
                  </span>
                </template>

                <template slot="option" slot-scope="{ option }">
                  <inbox-dropdown-item
                    :name="option.name"
                    :inbox-identifier="computedInboxSource(option)"
                    :channel-type="option.channel_type"
                    :api-inbox-type="
                      option.additional_attributes
                        ? option.additional_attributes.apiInboxType
                        : ''
                    "
                  />
                </template>
              </multiselect>
            </div>

            <label :class="{ error: getFieldErrorByName('targetInbox') }">
              <span v-if="getFieldErrorByName('targetInbox')" class="message">
                {{ $t('NEW_CONVERSATION.FORM.INBOX.ERROR') }}
              </span>
            </label>
          </div>

          <div
            v-if="
              isTargetInboxSelected ||
              isTargetContactSelected ||
              isTargetContactPreselected
            "
            class="w-full"
          >
            <label>
              {{ $t('NEW_CONVERSATION.FORM.TO.LABEL') }}
            </label>

            <div
              class="multiselect-wrap--small"
              :class="{
                'has-multi-select-error':
                  !isNewContact && getFieldErrorByName('targetInbox'),
              }"
            >
              <multiselect
                ref="conversation-form-target-contact-select"
                v-model="targetContact"
                track-by="id"
                label="name"
                :placeholder="$t('FORMS.MULTISELECT.SELECT')"
                selected-label=""
                select-label=""
                deselect-label=""
                :allow-empty="isSelectStep"
                :max-height="160"
                :close-on-select="true"
                :multiple="false"
                :options="contactsOptions"
                :disabled="isTargetContactPreselected || !isSelectStep"
                :internal-search="false"
                @search-change="queryAllContacts"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <contact-dropdown-item
                    v-if="option.name"
                    class="conversation-form__contact-option"
                    :identifier="option.identifier || ''"
                    :name="option.name"
                    :phone-number="option.phone_number"
                    :thumbnail="option.thumbnail"
                    :email="option.email"
                  />

                  <span v-else-if="isNewContact">
                    {{ $t('CREATE_CONTACT.BUTTON_LABEL') }}
                  </span>

                  <span v-else-if="targetContact.name || lastQuery">
                    {{ targetContact.name || lastQuery }}
                  </span>

                  <span v-else>
                    {{ $t('NEW_CONVERSATION.FORM.TO.PLACEHOLDER') }}
                  </span>
                </template>

                <template slot="option" slot-scope="{ option }">
                  <contact-dropdown-item
                    class="conversation-form__contact-option"
                    :identifier="option.identifier || ''"
                    :name="option.name"
                    :phone-number="option.phone_number"
                    :thumbnail="option.thumbnail"
                    :email="option.email"
                  />
                </template>

                <template v-if="isSelectStep" slot="noOptions">
                  <div class="flex w-full items-center justify-center">
                    <woot-button
                      type="button"
                      size="tiny"
                      icon="add"
                      @click="handleNewContactClick"
                    >
                      {{ $t('CREATE_CONTACT.BUTTON_LABEL') }}
                    </woot-button>
                  </div>
                </template>

                <template v-if="isSelectStep" slot="noResult">
                  <div class="flex w-full items-center justify-center">
                    <woot-button
                      type="button"
                      size="tiny"
                      icon="add"
                      @click="handleNewContactClick"
                    >
                      {{ $t('CREATE_CONTACT.BUTTON_LABEL') }}
                    </woot-button>
                  </div>
                </template>
              </multiselect>
            </div>

            <label
              :class="{
                error: !isNewContact && getFieldErrorByName('targetContact'),
              }"
            >
              <span
                v-if="!isNewContact && getFieldErrorByName('targetContact')"
                class="message"
              >
                {{ $t('NEW_CONVERSATION.FORM.TO.ERROR') }}
              </span>
            </label>
          </div>

          <div v-if="isNewContact" class="flex w-full flex-col gap-3">
            <span class="flex w-full flex-row flex-nowrap gap-3">
              <label
                v-if="targetContact.name || isNewContact"
                class="flex-1"
                :class="{
                  error: isNameNotValid,
                }"
              >
                <span> {{ $t('CONTACTS_FILTER.ATTRIBUTES.NAME') }} </span>

                <input
                  v-model="targetContact.name"
                  :disabled="!isNewContact"
                  @input="
                    $v.targetContact.name && $v.targetContact.name.$touch()
                  "
                />

                <span v-if="isNameNotValid" class="message">
                  {{ $t('CONTACT_FORM.FORM.NAME.ERROR') }}
                </span>
              </label>
            </span>

            <span class="flex w-full flex-row flex-nowrap gap-3">
              <label
                v-if="targetContact.email || isNewContact"
                class="flex-1"
                :class="{
                  error: isEmailNotValid,
                }"
              >
                <span> {{ $t('CONTACTS_FILTER.ATTRIBUTES.EMAIL') }} </span>

                <input
                  v-model="targetContact.email"
                  :disabled="!isNewContact"
                  @input="
                    $v.targetContact.email && $v.targetContact.email.$touch()
                  "
                />

                <span v-if="isEmailNotValid" class="message">
                  {{ $t('CONTACT_FORM.FORM.EMAIL_ADDRESS.ERROR') }}
                </span>
              </label>

              <label
                v-if="targetContact.phone_number || isNewContact"
                class="flex-1"
                :class="{
                  error: isPhoneNumberNotValid,
                }"
              >
                <span>
                  {{ $t('CONTACTS_FILTER.ATTRIBUTES.PHONE_NUMBER') }}
                </span>

                <input
                  v-model="targetContact.phone_number"
                  :disabled="!isNewContact"
                  @input="
                    $v.targetContact.phone_number &&
                      $v.targetContact.phone_number.$touch()
                  "
                />

                <span v-if="isPhoneNumberNotValid" class="message">
                  {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.ERROR') }}
                </span>
              </label>
            </span>
          </div>
        </div>

        <template v-if="currentStep === CONVERSATION_FORM_STEP.INPUT_STEP">
          <div v-if="isAnEmailInbox" class="w-full">
            <div class="w-full">
              <label :class="{ error: getFieldErrorByName('subject') }">
                {{ $t('NEW_CONVERSATION.FORM.SUBJECT.LABEL') }}
                <input-with-translation
                  v-model="subject"
                  type="text"
                  :placeholder="$t('NEW_CONVERSATION.FORM.SUBJECT.PLACEHOLDER')"
                  :has-counter="false"
                  @blur="getFieldTouchByName('subject')"
                />

                <span v-if="getFieldErrorByName('subject')" class="message">
                  {{ $t('NEW_CONVERSATION.FORM.SUBJECT.ERROR') }}
                </span>
              </label>
            </div>
          </div>

          <div class="w-full">
            <div class="w-full">
              <div class="relative">
                <canned-response
                  v-if="showCannedResponseMenu && hasSlashCommand"
                  :search-key="cannedResponseSearchKey"
                  @click="replaceTextWithCannedResponse"
                />
              </div>

              <div v-if="isEmailOrWebWidgetInbox">
                <label>
                  {{ $t('NEW_CONVERSATION.FORM.MESSAGE.LABEL') }}
                </label>

                <reply-email-head
                  v-if="isAnEmailInbox"
                  :cc-emails.sync="ccEmails"
                  :bcc-emails.sync="bccEmails"
                />

                <div class="editor-wrap mb-0">
                  <woot-message-editor
                    v-model="message"
                    class="message-editor"
                    :class="{ editor_warning: getFieldErrorByName('message') }"
                    :enable-variables="false"
                    :signature="signatureToApply"
                    :allow-signature="false"
                    :placeholder="
                      $t('NEW_CONVERSATION.FORM.MESSAGE.PLACEHOLDER')
                    "
                    @toggle-canned-menu="toggleCannedMenu"
                    @blur="getFieldTouchByName('message')"
                  >
                    <template #footer>
                      <message-signature-missing-alert
                        v-if="
                          isSignatureEnabledForInbox && !isSignatureAvailable
                        "
                        class="!mx-4 mb-1"
                      />

                      <div v-else>
                        <div
                          v-if="isSignatureEnabledForInbox"
                          v-tooltip="
                            $t('CONVERSATION.FOOTER.MESSAGE_SIGN_TOOLTIP')
                          "
                          class="message-signature-wrap"
                        >
                          <p
                            v-if="isSignatureAvailable"
                            v-dompurify-html="
                              formatMessage(selectedMessageSignature.signature)
                            "
                            class="message-signature"
                          />
                          <p v-else class="message-signature">
                            {{
                              $t(
                                'CONVERSATION.FOOTER.MESSAGE_SIGNATURE_NOT_CONFIGURED'
                              )
                            }}
                            <router-link :to="profilePath">
                              {{ $t('CONVERSATION.FOOTER.CLICK_HERE') }}
                            </router-link>
                          </p>
                        </div>
                      </div>

                      <div class="my-3 w-full px-4">
                        <input-translator
                          v-model="message"
                          is-extended
                          :is-hidden="!showMessageTranslateBox"
                          @action-after-translate="
                            toggleShowMessageTranslateBox
                          "
                        />
                      </div>

                      <div class="signature-toggle-container !mx-4 mb-3 mt-px">
                        <template v-if="isAnEmailInbox">
                          <woot-button
                            v-tooltip.top-end="signatureToggleTooltip"
                            icon="signature"
                            color-scheme="secondary"
                            variant="smooth"
                            size="small"
                            :title="signatureToggleTooltip"
                            @click.prevent="toggleMessageSignature"
                          />

                          <select
                            v-model="selectMessageSignature"
                            class="signature-select"
                            :disabled="!messageSignatures.length"
                          >
                            <option
                              v-for="messageSignature in messageSignatures"
                              :key="messageSignature.id"
                              :value="messageSignature.id"
                            >
                              {{ messageSignature.name }}
                            </option>
                          </select>
                        </template>

                        <translator-toggler
                          v-model="showMessageTranslateBox"
                          :has-immediate-translate-button="false"
                        />
                      </div>
                    </template>
                  </woot-message-editor>

                  <span
                    v-if="getFieldErrorByName('message')"
                    class="editor-warning__message"
                  >
                    {{ $t('NEW_CONVERSATION.FORM.MESSAGE.ERROR') }}
                  </span>
                </div>
              </div>
            </div>

            <div
              v-if="isEmailOrWebWidgetInbox"
              class="flex flex-col rounded border border-dashed py-2 transition-colors duration-200 hover:border-gray-400 dark:border-gray-500 dark:hover:border-gray-400"
            >
              <file-upload
                ref="uploadAttachment"
                input-id="newConversationAttachment"
                :size="4096 * 4096"
                :accept="allowedFileTypes"
                :multiple="true"
                :drop="true"
                :drop-directory="false"
                :data="{
                  direct_upload_url: '/rails/active_storage/direct_uploads',
                  direct_upload: true,
                }"
                @input-file="onFileUpload"
              >
                <woot-button
                  class-names="button--upload"
                  icon="attach"
                  color-scheme="secondary"
                  variant="smooth"
                  size="small"
                >
                  {{ $t('NEW_CONVERSATION.FORM.ATTACHMENTS.SELECT') }}
                </woot-button>

                <span
                  class="text-xs font-medium text-gray-500 ltr:ml-1 rtl:mr-1 dark:text-gray-400"
                >
                  {{ $t('NEW_CONVERSATION.FORM.ATTACHMENTS.HELP_TEXT') }}
                </span>
              </file-upload>

              <div
                v-if="hasAttachments"
                class="mb-4 mt-1.5 max-h-20 overflow-y-auto"
              >
                <attachment-preview
                  class="flex-row flex-wrap gap-x-3 gap-y-1 [&>.preview-item]:dark:bg-gray-700"
                  :attachments="attachedFiles"
                  :remove-attachment="removeAttachment"
                />
              </div>
            </div>

            <whatsapp-templates
              v-else-if="hasWhatsappTemplates"
              :inbox-id="targetInbox.id"
              :contact-preferred-language="contactPreferredLanguage"
              @on-select-template="toggleWaTemplate"
              @on-send="onSendWhatsAppReply"
              @on-go-back="goToSelectStep"
            />

            <label v-else :class="{ error: getFieldErrorByName('message') }">
              {{ $t('NEW_CONVERSATION.FORM.MESSAGE.LABEL') }}

              <resizable-text-area
                id="message-textarea"
                v-model="message"
                class="rounded !bg-white dark:!bg-gray-900"
                :has-footer="true"
                :placeholder="$t('NEW_CONVERSATION.FORM.MESSAGE.PLACEHOLDER')"
                :min-height="3"
                @input="getFieldTouchByName('message')"
                @blur="getFieldTouchByName('message')"
              >
                <template #footer>
                  <div class="flex flex-col gap-1 px-3 pb-2">
                    <input-translator
                      v-model="message"
                      class="mt-2"
                      is-extended
                      :is-hidden="!showMessageTranslateBox"
                      @action-after-translate="toggleShowMessageTranslateBox"
                    />

                    <translator-toggler
                      v-model="showMessageTranslateBox"
                      :has-immediate-translate-button="false"
                    />
                  </div>
                </template>
              </resizable-text-area>

              <span v-if="getFieldErrorByName('message')" class="message">
                {{ $t('NEW_CONVERSATION.FORM.MESSAGE.ERROR') }}
              </span>
            </label>
          </div>

          <transition v-if="isEmailOrWebWidgetInbox" name="modal-fade">
            <div
              v-show="
                $refs.uploadAttachment && $refs.uploadAttachment.dropActive
              "
              class="absolute bottom-0 left-0 right-0 top-0 z-30 flex h-full w-full flex-col items-center justify-center gap-2 bg-white/80 dark:bg-gray-700/80"
            >
              <fluent-icon icon="cloud-backup" size="40" />

              <h4 class="page-sub-title text-gray-600 dark:text-gray-200">
                {{ $t('CONVERSATION.REPLYBOX.DRAG_DROP') }}
              </h4>
            </div>
          </transition>
        </template>

        <div
          v-if="!(hasWhatsappTemplates && !isSelectStep)"
          class="modal-footer mt-5 !p-0"
        >
          <woot-button
            v-if="currentStep === CONVERSATION_FORM_STEP.INPUT_STEP"
            type="button"
            color-scheme="secondary"
            variant="smooth"
            :is-loading="conversationsUiFlags.isCreating"
            @click="goToSelectStep"
          >
            {{ $t('CHATLYN_GENERAL.BUTTON.BACK') }}
          </woot-button>

          <woot-button
            type="submit"
            :is-loading="conversationsUiFlags.isCreating"
            :is-disabled="$v.$invalid"
          >
            {{
              $t(
                isSelectStep
                  ? 'CHATLYN_GENERAL.BUTTON.NEXT'
                  : 'NEW_CONVERSATION.FORM.SUBMIT'
              )
            }}
          </woot-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  DuplicateContactException,
  ExceptionWithMessage,
} from 'shared/helpers/CustomErrors';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import ReplyEmailHead from 'dashboard/components/widgets/conversation/ReplyEmailHead.vue';
import CannedResponse from 'dashboard/components/widgets/conversation/CannedResponse.vue';
import MessageSignatureMissingAlert from 'dashboard/components/widgets/conversation/MessageSignatureMissingAlert';
import InboxDropdownItem from 'dashboard/components/widgets/InboxDropdownItem.vue';
import WhatsappTemplates from './WhatsappTemplates.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';
import { getInboxSource } from 'dashboard/helper/inbox';
import {
  required,
  requiredIf,
  email,
  requiredUnless,
} from 'vuelidate/lib/validators';
import inboxMixin from 'shared/mixins/inboxMixin';
import FileUpload from 'vue-upload-component';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';
import { ALLOWED_FILE_TYPES } from 'shared/constants/messages';
import fileUploadMixin from 'dashboard/mixins/fileUploadMixin';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import {
  appendSignature,
  removeSignature,
} from 'dashboard/helper/editorHelper';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';

import InputTranslator from '../../../../components/widgets/conversation/translator/InputTranslator.vue';
import { CONVERSATION_FORM_STEP } from './constants';
import ContactDropdownItem from '../../../../modules/contact/components/ContactDropdownItem.vue';
import { parsePhoneNumber } from 'libphonenumber-js';
import { isPhoneNumberValid } from 'shared/helpers/Validators';
import InputWithTranslation from '../../../../components/widgets/conversation/translator/InputWithTranslation.vue';
import TranslatorToggler from '../../../../components/widgets/conversation/translator/TranslatorToggler.vue';
import ResizableTextArea from '../../../../../shared/components/ResizableTextArea.vue';

import ContactAPI from '../../../../api/contacts';

let queryAllContactsTimeout = 0;

export default {
  components: {
    WootMessageEditor,
    ReplyEmailHead,
    CannedResponse,
    WhatsappTemplates,
    InboxDropdownItem,
    FileUpload,
    AttachmentPreview,
    MessageSignatureMissingAlert,
    InputTranslator,
    ContactDropdownItem,
    InputWithTranslation,
    TranslatorToggler,
    ResizableTextArea,
  },
  mixins: [
    alertMixin,
    uiSettingsMixin,
    inboxMixin,
    fileUploadMixin,
    messageFormatterMixin,
  ],
  props: {
    preselectedInbox: {
      type: Object,
      default: () => ({}),
    },
    preselectedContact: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      CONVERSATION_FORM_STEP,
      currentStep: CONVERSATION_FORM_STEP.SELECT_STEP,
      targetInbox: {},
      targetContact: {},
      isNewContact: false,
      //
      name: '',
      subject: '',
      message: '',
      showCannedResponseMenu: false,
      cannedResponseSearchKey: '',
      bccEmails: '',
      ccEmails: '',
      whatsappTemplateSelected: false,
      showMessageTranslateBox: false,
      isShowMessageTranslatorBoxButtonDisabled: false,
      attachedFiles: [],
      selectedMessageSignatureId: -1,
      lastQuery: '',
    };
  },
  validations() {
    const targetContactValidator = this.targetContactValidator();

    switch (this.currentStep) {
      case CONVERSATION_FORM_STEP.SELECT_STEP:
        return {
          targetContact: targetContactValidator,
          targetInbox: {
            required,
          },
        };

      case CONVERSATION_FORM_STEP.INPUT_STEP:
        return {
          subject: {
            required: requiredIf('isAnEmailInbox'),
          },
          message: {
            required,
          },
          targetContact: targetContactValidator,
          targetInbox: {
            required,
          },
        };

      default:
        return {
          targetContact: targetContactValidator,
          targetInbox: {
            required,
          },
        };
    }
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
      contactableInboxes: 'contacts/getContactableInboxes',
      allContacts: 'contacts/getAllContacts',
      //
      uiFlags: 'contacts/getUIFlags',
      conversationsUiFlags: 'contactConversations/getUIFlags',
      currentUser: 'getCurrentUser',
      currentAccountId: 'getCurrentAccountId',
      globalConfig: 'globalConfig/get',
      messageSignature: 'getMessageSignature',
      messageSignatures: 'management/getSignatures',
      currentUserId: 'getCurrentUserID',
    }),

    isTargetInboxPreselected() {
      return !!Object.keys(this.preselectedInbox).length;
    },

    isTargetContactPreselected() {
      return !!Object.keys(this.preselectedContact).length;
    },

    isSelectStep() {
      return this.currentStep === CONVERSATION_FORM_STEP.SELECT_STEP;
    },

    isTargetInboxSelected() {
      return !!Object.keys(this.targetInbox || {}).length;
    },

    isTargetContactSelected() {
      return !!Object.keys(this.targetContact || {}).length;
    },

    inboxesOptions() {
      const result = this.isTargetContactSelected
        ? this.contactableInboxes
            .map((contactableInbox) => ({
              ...contactableInbox.inbox,
              sourceId: contactableInbox.source_id,
            }))
            .filter(
              (inbox) => inbox?.additional_attributes?.apiInboxType !== 'GBM'
            )
        : this.inboxes.filter(
            (inbox) =>
              inbox?.additional_attributes?.apiInboxType === 'WhatsApp' ||
              [INBOX_TYPES.EMAIL, INBOX_TYPES.WHATSAPP].includes(
                inbox.channel_type
              )
          );

      return result;
    },

    isAllContactsIncludesSelectedContact() {
      if (!this.targetContact?.id) {
        return true;
      }

      return this.allContacts.some(
        (contact) => contact.id === this.targetContact.id
      );
    },

    contactsOptions() {
      const options = this.isTargetInboxSelected
        ? this.allContacts.filter((contact) => {
            if (this.isApiWhatsAppChannel) {
              return !!contact.phone_number?.trim();
            }

            switch (this.inbox?.channel_type) {
              case INBOX_TYPES.SMS:
              case INBOX_TYPES.WHATSAPP:
                return !!contact.phone_number?.trim();

              case INBOX_TYPES.EMAIL:
                return !!contact.email?.trim();

              default:
                return true;
            }
          })
        : this.allContacts;

      return this.isAllContactsIncludesSelectedContact
        ? options
        : [this.targetContact, ...options];
    },

    isNameNotValid() {
      return (
        this.$v.targetContact &&
        this.$v.targetContact.name &&
        this.$v.targetContact.name.$error
      );
    },

    isPhoneNumberNotValid() {
      return (
        this.$v.targetContact &&
        this.$v.targetContact.phone_number &&
        this.$v.targetContact.phone_number.$error
      );
    },

    isEmailNotValid() {
      return (
        this.$v.targetContact &&
        this.$v.targetContact.email &&
        this.$v.targetContact.email.$error
      );
    },

    contactableInbox() {
      return this.contactableInboxes.find(
        (inbox) => inbox?.inbox?.id === this.targetInbox.id
      );
    },

    inboxSourceId() {
      if (!this.inbox?.sourceId && !this.contactableInbox?.source_id) {
        return null;
      }

      return this.inbox.sourceId || this.contactableInbox?.source_id;
    },

    isQueryContainsOnlyLetters() {
      const regexp = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;

      return regexp.test(this.lastQuery);
    },

    //
    selectMessageSignature: {
      get() {
        return this.selectedMessageSignatureId;
      },
      set(newValue) {
        this.selectedMessageSignatureId = newValue;
      },
    },
    isSignatureAvailable() {
      return !!this.selectedMessageSignature?.signature;
    },
    selectedMessageSignature() {
      return this.messageSignatures.find(
        (messageSignature) =>
          messageSignature.id === this.selectedMessageSignatureId
      );
    },
    sendWithSignature() {
      return this.fetchSignatureFlagFromUiSettings(this.channelType);
    },
    signatureToApply() {
      return this.messageSignature;
    },
    newMessagePayload() {
      const payload = {
        inboxId: this.targetInbox.id,
        sourceId: this.inboxSourceId,
        contactId: this.targetContact.id,
        message: { content: this.message },
        mailSubject: this.subject,
        assigneeId: this.currentUser.id,
      };

      if (this.attachedFiles && this.attachedFiles.length) {
        payload.files = [];
        this.setAttachmentPayload(payload);
      }

      if (this.ccEmails) {
        payload.message.cc_emails = this.ccEmails;
      }

      if (this.bccEmails) {
        payload.message.bcc_emails = this.bccEmails;
      }

      if (
        this.isSignatureEnabledForInbox &&
        this.selectedMessageSignature?.signature
      ) {
        payload.message.content +=
          '\n\n' + this.selectedMessageSignature.signature;
      }
      return payload;
    },

    isSignatureEnabledForInbox() {
      return this.isAnEmailInbox && this.sendWithSignature;
    },
    signatureToggleTooltip() {
      return this.sendWithSignature
        ? this.$t('CONVERSATION.FOOTER.DISABLE_SIGN_TOOLTIP')
        : this.$t('CONVERSATION.FOOTER.ENABLE_SIGN_TOOLTIP');
    },
    isAnEmailInbox() {
      return (
        this.targetInbox && this.targetInbox.channel_type === INBOX_TYPES.EMAIL
      );
    },
    isAnWebWidgetInbox() {
      return (
        this.targetInbox && this.targetInbox.channel_type === INBOX_TYPES.WEB
      );
    },
    isEmailOrWebWidgetInbox() {
      return this.isAnEmailInbox || this.isAnWebWidgetInbox;
    },
    hasWhatsappTemplates() {
      return !!this.targetInbox?.message_templates || this.isApiWhatsAppChannel;
    },

    contactPreferredLanguage() {
      return this.targetContact?.custom_attributes?.ch_pref_lang || '';
    },
    hasAttachments() {
      return this.attachedFiles.length;
    },
    inbox() {
      return this.targetInbox;
    },
    allowedFileTypes() {
      return ALLOWED_FILE_TYPES;
    },

    subjectComputed: {
      get() {
        return this.subject;
      },

      set(newValue) {
        this.subject = newValue;
        this.getFieldTouchByName('subject');
      },
    },
  },
  watch: {
    preselectedInbox: {
      handler(newValue) {
        if (!Object.keys(newValue).length) {
          return;
        }

        this.targetInbox = structuredClone(newValue);
      },
      deep: true,
      immediate: true,
    },

    preselectedContact: {
      handler(newValue) {
        if (!Object.keys(newValue).length) {
          return;
        }

        this.targetContact = structuredClone(newValue);
      },
      deep: true,
      immediate: true,
    },
    //
    message(value) {
      this.hasSlashCommand = value[0] === '/' && !this.isEmailOrWebWidgetInbox;
      const hasNextWord = value.includes(' ');
      const isShortCodeActive = this.hasSlashCommand && !hasNextWord;
      if (isShortCodeActive) {
        this.cannedResponseSearchKey = value.substring(1);
        this.showCannedResponseMenu = true;
      } else {
        this.cannedResponseSearchKey = '';
        this.showCannedResponseMenu = false;
      }
    },
    targetInbox() {
      this.setSignature();
      this.setDefaultMessageSignature();
    },

    'targetContact.id': {
      async handler(newValue) {
        if (typeof newValue !== 'number') {
          return;
        }

        this.isNewContact = false;
        await this.fetchContactableInbox(newValue);
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchSignatures();
    this.setSignature();
    this.fetchInboxes();
    this.fetchAllContacts();
  },
  methods: {
    phoneNumberValidator(value) {
      try {
        if (!value) {
          return true;
        }

        const parsed = parsePhoneNumber(value[0] === '+' ? value : `+${value}`);

        return isPhoneNumberValid(
          parsed.number,
          `+${parsed.countryCallingCode}`
        );
      } catch (error) {
        return false;
      }
    },

    targetContactValidator() {
      let targetContact = {};

      if (!this.isNewContact) {
        targetContact = { required };
        return targetContact;
      }

      targetContact.name = { required };

      targetContact.email = { email };

      targetContact.phone_number = {
        phoneNumberValidator: this.phoneNumberValidator,
      };

      if (this.isAnEmailInbox) {
        targetContact.email = { required, email };
      }

      if (this.isAWhatsAppChannel || this.isASmsInbox) {
        targetContact.phone_number = {
          required,
          phoneNumberValidator: this.phoneNumberValidator,
        };
      }

      if (
        !this.isAnEmailInbox &&
        !this.isAWhatsAppChannel &&
        !this.isASmsInbox
      ) {
        targetContact.email = {
          requiredIf: requiredUnless(() => {
            return (
              this.targetContact?.email === '' &&
              this.targetContact?.phone_number !== ''
            );
          }),
          email,
        };

        targetContact.phone_number = {
          requiredIf: requiredUnless(() => {
            return (
              this.targetContact?.email !== '' &&
              this.targetContact?.phone_number === ''
            );
          }),
          phoneNumberValidator: this.phoneNumberValidator,
        };
      }

      return targetContact;
    },

    async fetchInboxes() {
      await this.$store.dispatch('inboxes/get');
    },

    async fetchContactableInbox(id) {
      try {
        await this.$store.dispatch('contacts/fetchContactableInbox', id);
      } catch (error) {
        // TODO
      }
    },

    async fetchAllContacts(query = '', limit = 75) {
      await this.$store.dispatch('contacts/getAll', {
        accountId: this.currentAccountId,
        search: query,
        limit,
        isAll: true,
      });
    },

    getFieldErrorByName(name) {
      return !!this.$v[name]?.$error;
    },

    getFieldTouchByName(name) {
      return this.$v[name]?.$touch();
    },

    handleNewContactClick() {
      this.isNewContact = true;

      const newContact = { name: '', email: '', phone_number: '' };

      if (this.isQueryContainsOnlyLetters) {
        newContact.name = this.lastQuery;
      }

      this.targetContact = newContact;

      this.$nextTick(() => {
        if (
          typeof this.$refs['conversation-form-target-contact-select']?.$refs
            ?.search?.blur === 'function'
        ) {
          this.$refs[
            'conversation-form-target-contact-select'
          ].$refs.search.blur();
        }
      });
    },

    goToSelectStep() {
      this.currentStep = CONVERSATION_FORM_STEP.SELECT_STEP;
    },

    async queryAllContacts(newValue) {
      clearTimeout(queryAllContactsTimeout);

      queryAllContactsTimeout = setTimeout(async () => {
        if (newValue) {
          this.lastQuery = newValue;
        }

        await this.fetchAllContacts(newValue);
      }, 250);
    },

    async handleSubmit() {
      await this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      switch (this.currentStep) {
        case CONVERSATION_FORM_STEP.SELECT_STEP:
          try {
            if (this.isNewContact) {
              this.targetContact = await this.$store.dispatch(
                'contacts/create',
                {
                  ...this.targetContact,
                }
              );
            }

            if (!this.inboxSourceId) {
              const { data } = await ContactAPI.createContactInbox(
                this.targetContact.id,
                {
                  inbox_id: this.targetInbox.id,
                  source_id: this.isAnEmailChannel
                    ? this.targetContact.email
                    : this.targetContact.phone_number.replace('+', ''),
                }
              );

              if (!data?.source_id) {
                throw new Error();
              }

              this.targetInbox.sourceId = data.source_id;
            }

            this.currentStep = CONVERSATION_FORM_STEP.INPUT_STEP;
          } catch (error) {
            if (error instanceof DuplicateContactException) {
              if (error.data.includes('phone_number')) {
                this.showAlert(
                  this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.DUPLICATE')
                );
              }

              if (error.data.includes('email')) {
                this.showAlert(
                  this.$t('CONTACT_FORM.FORM.EMAIL_ADDRESS.DUPLICATE')
                );
              }
            } else if (error instanceof ExceptionWithMessage) {
              this.showAlert(error.data);
            } else {
              this.showAlert(this.$t('CONTACT_FORM.ERROR_MESSAGE'));
            }
          }
          break;

        case CONVERSATION_FORM_STEP.INPUT_STEP:
          await this.createConversation({
            payload: this.newMessagePayload,
            isFromWhatsApp: false,
          });
          break;

        default:
          break;
      }
    },
    //
    setDefaultMessageSignature() {
      const inboxMessageSignature = this.messageSignatures.find(
        (messageSignature) =>
          messageSignature.inboxIds.includes(this.targetInbox.id)
      );

      if (inboxMessageSignature) {
        this.selectedMessageSignatureId = inboxMessageSignature.id;
      }
    },
    fetchSignatures() {
      this.$store.dispatch('management/getSignatures', {
        userId: this.currentUserId,
      });
    },
    setSignature() {
      if (this.messageSignature) {
        if (this.isSignatureEnabledForInbox) {
          this.message = appendSignature(this.message, this.signatureToApply);
        } else {
          this.message = removeSignature(this.message, this.signatureToApply);
        }
      }
    },
    setAttachmentPayload(payload) {
      this.attachedFiles.forEach((attachment) => {
        if (this.globalConfig.directUploadsEnabled) {
          payload.files.push(attachment.blobSignedId);
        } else {
          payload.files.push(attachment.resource.file);
        }
      });
    },
    attachFile({ blob, file }) {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        this.attachedFiles.push({
          currentChatId: this.targetContact.id,
          resource: blob || file,
          isPrivate: this.isPrivate,
          thumb: reader.result,
          blobSignedId: blob ? blob.signed_id : undefined,
        });
      };
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },
    replaceTextWithCannedResponse(message) {
      this.message = message;
    },
    toggleCannedMenu(value) {
      this.showCannedMenu = value;
    },
    prepareWhatsAppMessagePayload({ message: content, templateParams }) {
      const payload = {
        inboxId: this.targetInbox.id,
        sourceId: this.inboxSourceId,
        contactId: this.targetContact.id,
        message: { content, template_params: templateParams },
        assigneeId: this.currentUser.id,
      };
      return payload;
    },
    async onFormSubmit() {
      const isFromWhatsApp = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      await this.createConversation({
        payload: this.newMessagePayload,
        isFromWhatsApp,
      });
    },
    async createConversation({ payload, isFromWhatsApp }) {
      try {
        const data = await this.onSubmit(payload, isFromWhatsApp);
        const action = {
          type: 'link',
          to: `/app/accounts/${data.account_id}/conversations/${data.id}`,
          message: this.$t('NEW_CONVERSATION.FORM.GO_TO_CONVERSATION'),
        };
        this.onSuccess();
        this.showAlert(
          this.$t('NEW_CONVERSATION.FORM.SUCCESS_MESSAGE'),
          action
        );
        this.lastQuery = '';
      } catch (error) {
        if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data);
        } else {
          this.showAlert(this.$t('NEW_CONVERSATION.FORM.ERROR_MESSAGE'));
        }
      }
    },

    toggleWaTemplate(val) {
      this.whatsappTemplateSelected = val;
    },

    async onSendWhatsAppReply(
      language,
      newLinks,
      templateId,
      phoneNumber,
      date
    ) {
      try {
        const links = {
          language: language,
          body: [],
          buttons: [],
          header: [],
        };

        ['header', 'body', 'buttons'].forEach((key) => {
          [...newLinks].forEach((newLink) => {
            if (newLink.linkType === key) {
              if (
                ['image', 'video'].includes(newLink.valueType) &&
                !newLink.value
              ) {
                newLink.value = newLink.example;
              }

              delete newLink.linkType;
              delete newLink.valueType;
              delete newLink.id;

              links[key].push(newLink);
            }
          });
        });

        await this.$store.dispatch('messages/sendMessage', {
          data: {
            clientId: this.currentAccountId,
            // eslint-disable-next-line no-underscore-dangle
            templateId: templateId,
            // eslint-disable-next-line no-underscore-dangle
            contactId: this.targetContact.id,
            date: date || new Date(),
            number: phoneNumber.replace(/\+/g, ''),
            links: [links],
          },
        });

        this.onSuccess();
        this.showAlert(this.$t('NEW_CONVERSATION.FORM.SUCCESS_MESSAGE'));
      } catch (error) {
        if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data);
        } else {
          this.showAlert(this.$t('NEW_CONVERSATION.FORM.ERROR_MESSAGE'));
        }
      }
    },
    inboxReadableIdentifier(inbox) {
      return `${inbox.name} (${inbox.channel_type})`;
    },
    computedInboxSource(inbox) {
      if (!inbox.channel_type) return '';
      const classByType = getInboxSource(
        inbox.channel_type,
        inbox.phone_number || `+${inbox?.additional_attributes?.number}`,
        inbox
      );
      return classByType;
    },

    toggleShowMessageTranslateBox() {
      this.showMessageTranslateBox = !this.showMessageTranslateBox;

      this.$nextTick(() => {
        this.isShowMessageTranslatorBoxButtonDisabled = true;
        setTimeout(() => {
          this.isShowMessageTranslatorBoxButtonDisabled = false;
        }, 250);
      });
    },

    toggleMessageSignature() {
      this.setSignatureFlagForInbox(this.channelType, !this.sendWithSignature);
      this.setSignature();
    },
  },
};
</script>

<style scoped lang="scss">
.conversation-form {
  .canned-response {
    position: relative;
  }

  .message-editor {
    ::v-deep {
      .ProseMirror-menubar {
        // @apply rounded-tl-[4px];
      }
    }
  }

  .row.gutter-small {
    gap: var(--space-small);
  }
  .file-uploads {
    // @apply text-start;
  }
  .multiselect-wrap--small.has-multi-select-error {
    ::v-deep {
      .multiselect__tags {
        // @apply border-red-500;
      }
    }
  }

  ::v-deep {
    .conversation-form__contact-option {
      .user-thumbnail-box {
        @apply ml-1.5;

        height: 1.5rem !important;
        width: 1.5rem !important;
      }

      .option__user-data {
        .option__title,
        .option__body {
          @apply m-0;
        }

        .option__title {
          @apply text-xs;
        }

        .option__body {
          @apply text-xxs;
        }
      }
    }

    .mention--box {
      // @apply left-0 m-auto right-0 top-auto h-fit;
    }
    .multiselect .multiselect__content .multiselect__option span {
      // @apply inline-flex w-6 text-gray-600 dark:text-gray-400;
    }
    .multiselect .multiselect__content .multiselect__option {
      @apply py-0.5;
    }

    .multiselect__content-wrapper {
      max-height: 5.75rem !important;
    }
  }

  .signature-toggle-container {
    @apply flex flex-row flex-nowrap items-center rounded-lg;

    .signature-toggle {
      @apply rounded-e-none border-none;
    }

    .signature-select {
      @apply m-0 h-8 w-0 border-none;

      font-size: 0;
      align-self: stretch;
      padding: 0;
      padding-right: 0px;
      padding-right: 2.25rem;
      background-color: transparent;

      option {
        font-size: 1.6rem;
      }
    }
  }

  .message-signature-wrap {
    @apply relative z-10 mx-4 my-2 flex max-h-[8vh] items-baseline justify-between overflow-auto rounded-sm border border-dashed p-2 text-gray-500 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800;
  }
}
</style>
