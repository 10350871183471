<template>
  <formulate-form class="p-0" @submit="onAddList">
    <div clasS="modal-header">
      <div class="modal-header-title">
        {{ $t('CHATLYN_CONTACT_LISTS.NEW_MODAL.TITLE') }}
      </div>
    </div>

    <div class="modal-body flex flex-col gap-2">
      <formulate-input
        v-model="name"
        :label="$t('CHATLYN_GENERAL.LABEL.NAME')"
        :placeholder="$t('CHATLYN_CONTACT_LISTS.PLACEHOLDERS.NAME')"
        :validation-messages="{
          required: $t('CHATLYN_GENERAL.MESSAGES.INPUT_REQUIRED'),
          min: $t('CHATLYN_GENERAL.MESSAGES.MIN_LENGTH_2'),
        }"
        :minlength="2"
        data-testid="label-title"
        validation="required|min:2"
      />
      <div
        :class="
          isDynamicList
            ? 'bg-woot-50 dark:bg-woot-700/50'
            : 'bg-gray-50 dark:bg-gray-700/30'
        "
        class="w-full rounded-lg p-4"
      >
        <woot-switch
          id="dynamic-list-switch"
          v-model="isDynamicList"
          :label="$t('CHATLYN_CONTACT_LISTS.DYNAMIC_LABEL')"
          size="small"
        />
      </div>
      <template v-if="isDynamicList">
        <div class="mt-3">
          {{ $t('CHATLYN_CONTACT_LISTS.ADD_CONDITION_TITLE') }}
        </div>
        <list-condition @update:condition="onConditionUpdate" />
      </template>
    </div>

    <div class="modal-footer">
      <woot-button
        :is-disabled="!isValid || disabled"
        data-testid="label-submit"
      >
        {{ $t('CHATLYN_GENERAL.BUTTON.CREATE') }}
      </woot-button>
    </div>

    <chatlyn-spinner :is-visible="isLoading" />
  </formulate-form>
</template>

<script>
import WootSwitch from 'shared/components/ui/Switch';
import ListCondition from './components/ListCondition';
import alertMixin from 'shared/mixins/alertMixin';
import listActions from './mixins/listActions';
import ChatlynSpinner from '../../../../shared/components/ChatlynSpinner.vue';

export default {
  name: 'AddList',
  components: {
    ListCondition,
    WootSwitch,
    ChatlynSpinner,
  },
  mixins: [alertMixin, listActions],
  props: {
    folderId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      isDynamicList: false,
      condition: null,
      isConditionValid: false,
      disabled: false,
    };
  },
  computed: {
    isValid() {
      return (
        !this.isDynamicList || (this.isConditionValid && this.name.length > 2)
      );
    },
  },
  methods: {
    onAddList() {
      this.disabled = true;
      const newRecord = {
        name: this.name,
        clientId: this.currentAccountId,
        static: !this.isDynamicList,
        ...(this.isDynamicList
          ? { condition: this.condition }
          : { contacts: [] }),
      };

      if (this.folderId) {
        newRecord.folderId = this.folderId;
      }

      this.onListAction(newRecord);
    },
    onConditionUpdate({ condition, isValid }) {
      this.isConditionValid = isValid;
      this.condition = { ...condition };
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-wrapper {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
