<template>
  <div class="modal-mask">
    <div
      v-on-clickaway="closeNotificationPanel"
      class="absolute left-14 z-10 m-4 flex h-[90vh] w-[32.5rem] flex-col justify-between rounded-md bg-white shadow-md rtl:left-auto rtl:right-14 dark:bg-gray-800"
    >
      <div
        class="flex w-full flex-row items-center justify-between border-b border-solid border-gray-50 px-6 pb-3 pt-5 dark:border-gray-700"
      >
        <div class="flex items-center">
          <span class="text-xl font-semibold text-gray-800 dark:text-gray-100">
            {{ $t('NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.TITLE') }}
          </span>
          <span
            v-if="totalUnreadNotifications"
            class="ml-2 mr-2 rounded-md bg-gray-50 px-2 py-1 text-xxs font-medium text-gray-700 dark:bg-gray-700 dark:text-gray-200"
          >
            {{ totalUnreadNotifications }}
          </span>
        </div>
        <div class="flex gap-2">
          <woot-button
            v-if="!noUnreadNotificationAvailable"
            color-scheme="primary"
            variant="smooth"
            size="tiny"
            :is-loading="uiFlags.isUpdating"
            @click="onMarkAllDoneClick"
          >
            {{ $t('NOTIFICATIONS_PAGE.MARK_ALL_DONE') }}
          </woot-button>
          <woot-button
            color-scheme="secondary"
            variant="smooth"
            size="tiny"
            icon="settings"
            @click="openAudioNotificationSettings"
          />
          <woot-button
            color-scheme="secondary"
            variant="link"
            size="tiny"
            icon="dismiss"
            @click="closeNotificationPanel"
          />
        </div>
      </div>
      <notification-panel-list
        :notifications="getUnreadNotifications"
        :is-loading="uiFlags.isFetching"
        :on-click-notification="openConversation"
        :in-last-page="inLastPage"
      />
      <div
        v-if="records.length !== 0"
        class="flex items-center justify-between px-5 py-1"
      >
        <div class="flex">
          <woot-button
            variant="clear"
            color-scheme="secondary"
            :is-disabled="inFirstPage"
            @click="onClickFirstPage"
          >
            <fluent-icon icon="chevron-left" size="16" />
            <fluent-icon icon="chevron-left" size="16" class="-ml-4" />
          </woot-button>
          <woot-button
            color-scheme="secondary"
            variant="clear"
            icon="chevron-left"
            :disabled="inFirstPage"
            @click="onClickPreviousPage"
          />
        </div>
        <span class="text-xxs font-medium text-gray-500 dark:text-gray-400">
          {{ currentPage }} - {{ lastPage }}
        </span>
        <div class="flex">
          <woot-button
            color-scheme="secondary"
            variant="clear"
            icon="chevron-right"
            :disabled="inLastPage"
            @click="onClickNextPage"
          />
          <woot-button
            variant="clear"
            color-scheme="secondary"
            :disabled="inLastPage"
            @click="onClickLastPage"
          >
            <fluent-icon icon="chevron-right" size="16" />
            <fluent-icon icon="chevron-right" size="16" class="-ml-4" />
          </woot-button>
        </div>
      </div>
      <div v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import rtlMixin from 'shared/mixins/rtlMixin';
import NotificationPanelList from './NotificationPanelList.vue';

import { ACCOUNT_EVENTS } from '../../../../helper/AnalyticsHelper/events';

export default {
  components: {
    NotificationPanelList,
  },
  mixins: [clickaway, rtlMixin],
  data() {
    return {
      pageSize: 15,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      meta: 'notifications/getMeta',
      records: 'notifications/getNotifications',
      uiFlags: 'notifications/getUIFlags',
    }),
    notificationPanelFooterIconClass() {
      return this.isRTLView
        ? 'margin-right-minus-slab'
        : 'margin-left-minus-slab';
    },
    totalUnreadNotifications() {
      return this.meta.unreadCount;
    },
    noUnreadNotificationAvailable() {
      return this.meta.unreadCount === 0;
    },
    getUnreadNotifications() {
      return this.records.filter(
        (notification) => notification.read_at === null
      );
    },
    currentPage() {
      return Number(this.meta.currentPage);
    },
    lastPage() {
      if (this.totalUnreadNotifications > 15) {
        return Math.ceil(this.totalUnreadNotifications / this.pageSize);
      }
      return 1;
    },
    inFirstPage() {
      const page = Number(this.meta.currentPage);
      return page === 1;
    },
    inLastPage() {
      return this.currentPage === this.lastPage;
    },
  },
  mounted() {
    this.$store.dispatch('notifications/get', { page: 1 });
  },
  methods: {
    onPageChange(page) {
      this.$store.dispatch('notifications/get', { page });
    },
    openConversation(notification) {
      const {
        primary_actor_id: primaryActorId,
        primary_actor_type: primaryActorType,
        primary_actor: { id: conversationId },
        notification_type: notificationType,
      } = notification;

      this.$track(ACCOUNT_EVENTS.OPEN_CONVERSATION_VIA_NOTIFICATION, {
        notificationType,
      });

      this.$store.dispatch('notifications/read', {
        primaryActorId,
        primaryActorType,
        unreadCount: this.meta.unreadCount,
      });

      const { params, name } = this.$route;

      if (
        name !== 'inbox_conversation' ||
        params.conversation_id !== `${conversationId}` ||
        params.accountId !== `${this.accountId}`
      ) {
        this.$router.push({
          name: 'inbox_conversation',
          params: { conversation_id: conversationId },
        });
      }

      this.$emit('close');
    },
    onClickNextPage() {
      if (!this.inLastPage) {
        const page = this.currentPage + 1;
        this.onPageChange(page);
      }
    },
    onClickPreviousPage() {
      if (!this.inFirstPage) {
        const page = this.currentPage - 1;
        this.onPageChange(page);
      }
    },
    onClickFirstPage() {
      if (!this.inFirstPage) {
        const page = 1;
        this.onPageChange(page);
      }
    },
    onClickLastPage() {
      if (!this.inLastPage) {
        const page = this.lastPage;
        this.onPageChange(page);
      }
    },
    onMarkAllDoneClick() {
      this.$track(ACCOUNT_EVENTS.MARK_AS_READ_NOTIFICATIONS);
      this.$store.dispatch('notifications/readAll');
    },
    openAudioNotificationSettings() {
      const { name } = this.$route;

      if (name !== 'profile_settings_index') {
        this.$router.push({ name: 'profile_settings_index' });
      }

      this.closeNotificationPanel();
      this.$nextTick(() => {
        const audioSettings = document.getElementById(
          'profile-settings-notifications'
        );
        if (audioSettings) {
          // TODO [ref](https://github.com/chatwoot/chatwoot/pull/6233#discussion_r1069636890)
          audioSettings.scrollIntoView(
            { behavior: 'smooth', block: 'start' },
            150
          );
        }
      });
    },
    closeNotificationPanel() {
      this.$emit('close');
    },
  },
};
</script>
