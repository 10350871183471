<template>
  <div class="relative">
    <formulate-form
      v-slot="{ hasErrors }"
      class="settings--content"
      @submit="updateInbox"
    >
      <settings-section
        class="border-none"
        :title="$t('CHATLYN_INBOXES.GBM.CONFIG.TITLE')"
        :sub-title="$t('CHATLYN_INBOXES.GBM.CONFIG.DESC')"
      >
        <div>
          <div class="google-business-account-inbox-private-key">
            <label
              class="flex flex-col flex-nowrap gap-2"
              :for="contentTextAreaId"
            >
              <div class="google-business-account-inbox-private-key-title">
                {{
                  $t('CHATLYN_INBOXES.GBM.SETUP.PRIVATE_KEY_CLIENT_EMAIL.TITLE')
                }}
              </div>

              <div
                class="google-business-account-inbox-private-key-description text-muted my-1 text-xs"
              >
                {{
                  $t('CHATLYN_INBOXES.GBM.SETUP.PRIVATE_KEY_CLIENT_EMAIL.DESC')
                }}
              </div>
            </label>

            <drag-drop-file-upload
              v-model="file"
              :extensions="ALLOWED_FILE_EXTENSIONS"
            />

            <resizable-text-area
              :id="contentTextAreaId"
              v-model="formattedFileContent"
              is-formulate-form-component
              class="google-business-account-inbox-private-content"
              validation="emptyOrJson"
              :validation-messages="{
                emptyOrJson: $t('CHATLYN_GENERAL.MESSAGES.JSON_INVALID'),
              }"
              :validation-rules="{
                emptyOrJson: (event) =>
                  emptyOrJson(event, ['private_key', 'client_email']),
              }"
              :min-height="7"
              :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.PASTE_CONTENT')"
            />
          </div>

          <formulate-input
            v-model="newInbox.brandId"
            :label="$t('CHATLYN_INBOXES.GBM.SETUP.BRAND_ID')"
            validation="required"
            type="text"
          />

          <formulate-input
            v-model="newInbox.agentId"
            :label="$t('CHATLYN_INBOXES.GBM.SETUP.AGENT_ID')"
            validation="required"
            type="text"
          />

          <formulate-input
            v-model="newInbox.partnerId"
            :label="$t('CHATLYN_INBOXES.GBM.SETUP.PARTNER_ID')"
            validation="required"
            type="text"
          />

          <formulate-input
            v-model="newInbox.webhookToken"
            :label="$t('CHATLYN_INBOXES.GBM.SETUP.WEBHOOK_TOKEN')"
            validation="required"
            type="text"
          />
        </div>

        <div class="mt-2 flex w-full flex-row flex-nowrap justify-end">
          <woot-button :disabled="hasErrors">
            {{ $t('CHATLYN_GENERAL.BUTTON.UPDATE') }}
          </woot-button>
        </div>
      </settings-section>
    </formulate-form>

    <div
      v-if="isUpdating"
      class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
      style="background: rgba(0, 0, 0, 0.05)"
    >
      <span class="spinner h-8 w-8" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getUuid from 'widget/helpers/uuid';
import { emptyOrJson } from '../../../../../helper/formulateValidation';

import ResizableTextArea from '../../../../../../shared/components/ResizableTextArea.vue';
import GoogleBusinessMessagesAPI from '../../../../../api/googleBusinessMessages';
import SettingsSection from '../../../../../components/SettingsSection.vue';
import DragDropFileUpload from '../../../../../components/ui/DragDropFileUpload.vue';

const ALLOWED_FILE_EXTENSIONS = ['json'];

export default {
  name: 'GoogleBusinessMessagesConfigurationPage',
  components: { ResizableTextArea, SettingsSection, DragDropFileUpload },
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ALLOWED_FILE_EXTENSIONS,
      isUpdating: false,
      fileContent: '',
      file: null,
      newInbox: {
        clientEmail: '',
        privateKey: '',
        brandId: '',
        agentId: '',
        partnerId: '',
        webhookToken: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
    }),

    contentTextAreaId() {
      return getUuid();
    },

    formattedFileContent: {
      get() {
        try {
          const value = JSON.stringify(
            JSON.parse(this.fileContent),
            undefined,
            4
          );

          return value;
        } catch (error) {
          return this.fileContent;
        }
      },

      set(newValue) {
        if (!newValue) {
          this.file = null;
        }

        this.fileContent = newValue;
      },
    },
  },
  watch: {
    file: {
      async handler(file) {
        this.formattedFileContent = await this.getFileContent(file);
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchInbox();
  },
  methods: {
    emptyOrJson,

    async getFileContent(file) {
      if (!file || !file.name) {
        return Promise.resolve('');
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          try {
            resolve(event.target.result);
          } catch (error) {
            reject(error);
          }
        };

        reader.readAsText(file);
      });
    },

    async fetchInbox() {
      try {
        this.isUpdating = true;

        const { data } =
          await GoogleBusinessMessagesAPI.getGoogleBusinessMessagesInbox(
            this.inbox.id
          );

        if (!data) {
          return;
        }

        this.newInbox = data;
      } catch (error) {
        bus.$emit(
          'newToastMessage',
          this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN')
        );
      } finally {
        this.isUpdating = false;
      }
    },

    async updateInbox() {
      try {
        this.isUpdating = true;

        const body = {
          ...this.newInbox,
          accountId: this.currentAccountId,
        };

        if (this.fileContent) {
          const parsed = JSON.parse(this.fileContent);

          body.clientEmail = parsed.client_email;
          body.privateKey = parsed.private_key;
        }

        await GoogleBusinessMessagesAPI.updateGoogleBusinessMessagesInbox(
          this.inbox.id,
          body
        );

        bus.$emit(
          'newToastMessage',
          this.$t('CHATLYN_INBOXES.GBM.CONFIG.SUCCESS_MESSAGE')
        );
      } catch (error) {
        bus.$emit(
          'newToastMessage',
          this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN')
        );
      } finally {
        this.isUpdating = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.google-business-account-inbox-private-key {
  @apply flex flex-col flex-nowrap gap-4;

  label {
    @apply flex flex-col flex-nowrap gap-2;
  }

  .google-business-account-inbox-private-content {
    &::v-deep {
      textarea {
        @apply rounded-lg;

        max-height: 14rem;
        min-height: 7rem;
        resize: none;
      }
    }
  }
}
</style>
