/* global axios */
import ApiClient from './ApiClient';
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? 'https://staging.chatlyn.com/automation/contacts'
    : window.location.protocol +
      '//' +
      window.location.hostname +
      '/automation/contacts';

export const buildContactParams = (page, sortAttr, label, search) => {
  let params = `include_contact_inboxes=false&page=${page}&sort=${sortAttr}`;
  if (search) {
    params = `${params}&q=${search}`;
  }
  if (label) {
    params = `${params}&labels[]=${label}`;
  }
  return params;
};

export const buildAllContactParams = (search, limit, page, sortAttr, label) => {
  let params = ``;

  if (search) {
    params += `q=${search}&`;
  }

  if (limit) {
    params += `limit=${limit}&`;
  }

  if (page) {
    params += `page=${page}&`;
  }

  if (sortAttr) {
    params += `sort=${sortAttr}&`;
  }

  if (label) {
    params += `labels[]=${label}`;
  }

  if (params[params.length - 1] === '&') {
    params = params.substring(0, params.length - 1);
  }

  return params;
};

class ContactAPI extends ApiClient {
  constructor() {
    super('contacts', { accountScoped: true });
  }

  get(page, sortAttr = 'name', label = '') {
    let requestURL = `${this.url}?${buildContactParams(
      page,
      sortAttr,
      label,
      ''
    )}`;
    return axios.get(requestURL);
  }

  // eslint-disable-next-line class-methods-use-this
  async getAll(accountId, search, limit, page, sortAttr, label, condition) {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    const body = {};

    if (condition && !!Object.keys(condition).length) {
      body.condition = condition;
    }

    return axios.post(
      `${url}/${accountId}?${buildAllContactParams(
        search,
        limit,
        page,
        sortAttr,
        label
      )}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  }

  getConversations(contactId) {
    return axios.get(`${this.url}/${contactId}/conversations`);
  }

  getContactableInboxes(contactId) {
    return axios.get(`${this.url}/${contactId}/contactable_inboxes`);
  }

  getContactLabels(contactId) {
    return axios.get(`${this.url}/${contactId}/labels`);
  }

  createContactInbox(contactId, body) {
    return axios.post(`${this.url}/${contactId}/contact_inboxes`, body);
  }

  updateContactLabels(contactId, labels) {
    return axios.post(`${this.url}/${contactId}/labels`, { labels });
  }

  search(search = '', page = 1, sortAttr = 'name', label = '') {
    let requestURL = `${this.url}/search?${buildContactParams(
      page,
      sortAttr,
      label,
      search
    )}`;
    return axios.get(requestURL);
  }

  // eslint-disable-next-line default-param-last
  filter(page = 1, sortAttr = 'name', queryPayload) {
    let requestURL = `${this.url}/filter?${buildContactParams(page, sortAttr)}`;
    return axios.post(requestURL, queryPayload);
  }

  importContacts(file) {
    const formData = new FormData();
    formData.append('import_file', file);
    return axios.post(`${this.url}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  destroyCustomAttributes(contactId, customAttributes) {
    return axios.post(`${this.url}/${contactId}/destroy_custom_attributes`, {
      custom_attributes: customAttributes,
    });
  }

  destroyAvatar(contactId) {
    return axios.delete(`${this.url}/${contactId}/avatar`);
  }

  exportContacts() {
    return axios.get(`${this.url}/export`);
  }
}

export default new ContactAPI();
