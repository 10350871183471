<template>
  <div class="importer-page">
    <header class="importer-page__header">
      <h1 class="importer-page__title page-title">
        <woot-sidemenu-icon />
        {{ $t('IMPORT_CONTACTS.BUTTON_LABEL') }}
      </h1>
      <woot-button
        class="importer-page__close-btn"
        variant="clear"
        type="button"
        color-scheme="secondary"
        icon="dismiss"
        @click="onCloseImporter"
      />
    </header>
    <div class="importer-page__content">
      <spinner
        v-if="isIframeLoading"
        class="importer-page__loader"
        size="large"
      />
      <iframe
        v-show="!isIframeLoading"
        ref="importer"
        :src="iframeSrc"
        height="100%"
        width="100%"
        @load="onLoadIframe"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { stagingUrl } from '../../../../api/automation.stub';
import alertMixin from 'shared/mixins/alertMixin';
import { setColorTheme } from 'dashboard/helper/themeHelper.js';
import Spinner from 'shared/components/Spinner.vue';

export default {
  name: 'ContactImporter',
  components: { Spinner },
  mixins: [alertMixin],
  data() {
    return {
      isIframeLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      isDark: 'theme/isDark',
      lists: 'contactLists/getLists',
      folders: 'folders/getAllFolders',
    }),

    currentLanguage() {
      return this.$root.$i18n.locale;
    },

    iframeSrc() {
      const url =
        process.env.FRONTEND_ENV === 'develop'
          ? stagingUrl + '/contact-importer'
          : window.location.protocol +
            '//' +
            window.location.hostname +
            '/contact-importer';
      return `${url}?accountId=${this.accountId}&lang=${this.currentLanguage}`;
    },
  },
  watch: {
    isDark: {
      handler(val) {
        const iframeDocument = this.$refs.importer?.contentDocument;
        setColorTheme(val, iframeDocument);
      },
      immediate: true,
    },
  },
  async mounted() {
    window.addEventListener('message', this.onReceiveMessage);
    await this.fetchSideMenuData();
  },
  destroyed() {
    window.removeEventListener('message', this.onReceiveMessage);
  },
  methods: {
    onCloseImporter() {
      this.$router.back();
    },
    async onLoadIframe() {
      this.isIframeLoading = false;
    },
    async fetchSideMenuData() {
      if (!this.folders.length) {
        await this.$store.dispatch('folders/getAllFolders', {
          clientId: this.accountId,
        });
      }
      if (!this.lists.length) {
        await this.$store.dispatch('contactLists/getLists', {
          accountId: this.accountId,
        });
      }
    },
    onReceiveMessage(e) {
      if (e.data === 'close') {
        this.showAlert(this.$t('IMPORT_CONTACTS.SUCCESS_MESSAGE'));
        this.onCloseImporter();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.importer-page {
  @apply flex h-full w-full flex-col;
  &__header {
    @apply flex items-center justify-between border-b bg-white py-2 pe-2 ps-4 dark:border-gray-800 dark:bg-gray-900;
  }
  &__title {
    @apply flex items-center gap-2;
  }
  &__content {
    @apply relative h-full;
  }
  &__loader {
    @apply absolute left-0 top-0 z-10 flex h-full w-full justify-center pt-8 dark:bg-gray-900;
  }
}
</style>
