var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conversation-ai-toggler !z-20 flex cursor-pointer select-none flex-row flex-nowrap items-center justify-center gap-1 rounded-2xl border border-solid px-3 py-1.5 text-xs leading-none transition-all duration-300",class:{
    'border-violet-300 bg-violet-400 text-white dark:border-violet-400 dark:bg-violet-500 dark:text-violet-100':
      _vm.isAIEnabled,
    'border-violet-200 bg-violet-100 text-violet-600 dark:border-violet-800 dark:bg-violet-900 dark:text-violet-200':
      !_vm.isAIEnabled,
  },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleIsAIEnabled.apply(null, arguments)}}},[_c('span',{staticClass:"pointer-events-none inline-flex flex-row flex-nowrap items-center gap-1"},[_c('fluent-icon',{staticClass:"-mt-0.5",attrs:{"icon":"wand","size":16}}),_vm._v(" "),_c('span',[_vm._v("\n      "+_vm._s(_vm.isAIEnabled
          ? _vm.$t('CONVERSATION.AI_TOGGLER.LABEL.ACTIVE')
          : _vm.$t('CONVERSATION.AI_TOGGLER.LABEL.INACTIVE'))+"\n    ")])],1),_vm._v(" "),_c('span',{staticClass:"pointer-events-none"},[_c('woot-switch',{staticClass:"conversation-ai-toggler__switch",attrs:{"id":"conversation-ai-toggler","label":" "},model:{value:(_vm.isAIEnabled),callback:function ($$v) {_vm.isAIEnabled=$$v},expression:"isAIEnabled"}})],1),_vm._v(" "),_c('chatlyn-spinner',{attrs:{"is-visible":_vm.isLoading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }