<template>
  <div
    class="csat--metric-card"
    :class="{
      disabled: disabled,
    }"
  >
    <div class="heading">
      <span>{{ label }}</span>
      <fluent-icon
        v-tooltip="infoText"
        size="14"
        icon="info"
        class="csat--icon"
      />
    </div>
    <div class="metric">
      {{ value }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    infoText: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.csat--metric-card {
  @apply m-0 p-2;

  &.disabled {
    @apply pointer-events-none opacity-40;
    // grayscale everything
    filter: grayscale(100%);
  }

  .heading {
    @apply text-gray-800 dark:text-gray-100 text-sm flex items-center m-0 gap-1;
  }

  .metric {
    @apply font-medium text-gray-700 dark:text-gray-100 text-3xl font-light m-0 whitespace-nowrap;
  }
}

.csat--icon {
  @apply text-gray-500 dark:text-gray-200 my-0 mx-0.5;
}
</style>
