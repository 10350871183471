<template>
  <div class="relative flex flex-grow flex-col align-top">
    <div
      class="absolute flex w-full flex-col items-center justify-center gap-2.5"
    >
      <can-reply-banner
        v-if="isAWhatsAppChannel && isExpiresTimeFetched"
        :can-reply="canReply"
        :expires="windowsExpiration.expires"
        @on-is-expired-change="onIsExpiredChange"
      />

      <!--
    <div class="sidebar-toggle__wrap flex justify-end">
      <button @click="onToggleContactPanel" class="fixed top-[9.5rem] md:top-[6.25rem] z-10 bg-white p-2 shadow-xl">
        <fluent-icon
          class="select-none m-1"
          size="16"
          :icon="isRightOrLeftIcon"
        />
      </button>
    </div>
    -->

      <conversation-ai-toggler
        v-if="currentChat && isConversationHasAI && canReply"
        :conversation-id="currentChat.id"
        :messages="getAiHandoverMessages"
      />
    </div>

    <ul class="conversation-panel flex flex-grow flex-col overflow-y-auto">
      <transition name="slide-up">
        <li class="min-h-[4rem]">
          <span v-if="shouldShowSpinner" class="spinner message" />
        </li>
      </transition>

      <message
        v-for="message in getReadMessages"
        :key="message.id"
        class="message--read ph-no-capture"
        data-clarity-mask="True"
        :data="message"
        :is-a-tweet="isATweet"
        :is-a-whatsapp-channel="isAWhatsAppChannel"
        :is-api-whats-app-channel="isApiWhatsAppChannel"
        :has-instagram-story="hasInstagramStory"
        :is-web-widget-inbox="isAWebWidgetInbox"
        :inbox-supports-reply-to="inboxSupportsReplyTo"
        :in-reply-to="getInReplyToMessage(message)"
        :inbox="inbox"
        :contact-preferred-language="contactPreferredLanguage"
      />

      <li v-show="unreadMessageCount != 0" class="unread--toast">
        <span>
          {{ unreadMessageCount > 9 ? '9+' : unreadMessageCount }}
          {{
            unreadMessageCount > 1
              ? $t('CONVERSATION.UNREAD_MESSAGES')
              : $t('CONVERSATION.UNREAD_MESSAGE')
          }}
        </span>
      </li>

      <message
        v-for="message in getUnReadMessages"
        :key="message.id"
        class="message--unread ph-no-capture"
        data-clarity-mask="True"
        :data="message"
        :is-a-tweet="isATweet"
        :is-a-whatsapp-channel="isAWhatsAppChannel"
        :is-web-widget-inbox="isAWebWidgetInbox"
        :inbox-supports-reply-to="inboxSupportsReplyTo"
        :in-reply-to="getInReplyToMessage(message)"
        :inbox="inbox"
        :contact-preferred-language="contactPreferredLanguage"
      />

      <conversation-label-suggestion
        v-if="shouldShowLabelSuggestions"
        :suggested-labels="labelSuggestions"
        :chat-labels="currentChat.labels"
        :conversation-id="currentChat.id"
      />
    </ul>

    <div class="mt-auto" :class="{ 'modal-mask': isPopoutReplyBox }">
      <div
        v-if="isAnyoneTyping"
        class="flex gap-1 px-4 py-2 text-xs font-semibold text-gray-500 dark:text-gray-400"
      >
        {{ typingUserNames }}
        <div class="typing">
          <span />
          <span />
          <span />
        </div>
      </div>

      <reply-box
        :conversation-id="currentChat.id"
        :popout-reply-box.sync="isPopoutReplyBox"
        :can-reply="canReply"
        :preferred-language="contactPreferredLanguage"
        @click="showPopoutReplyBox"
      />
    </div>
  </div>
</template>

<script>
// components
import ReplyBox from './ReplyBox.vue';
import Message from './Message.vue';
import ConversationLabelSuggestion from './conversation/LabelSuggestion.vue';
import ConversationAiToggler from './ConversationAiToggler.vue';
import CanReplyBanner from './CanReplyBanner.vue';

// stores and apis
import { mapGetters } from 'vuex';
import WhatsAppAPI from '../../../api/whatsApp';

// mixins
import conversationMixin, {
  filterDuplicateSourceMessages,
} from '../../../mixins/conversations';
import inboxMixin, { INBOX_FEATURES } from 'shared/mixins/inboxMixin';
import configMixin from 'shared/mixins/configMixin';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import aiMixin from 'dashboard/mixins/aiMixin';

// utils
import { getTypingUsersText } from '../../../helper/commons';
import { calculateScrollTop } from './helpers/scrollTopCalculationHelper';
import { isEscape } from 'shared/helpers/KeyboardHelpers';
import { LocalStorage } from 'shared/helpers/localStorage';
import { detectAll } from 'tinyld';

// constants
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { REPLY_POLICY } from 'shared/constants/links';
import wootConstants from 'dashboard/constants/globals';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';
import { MESSAGE_TYPE } from 'shared/constants/messages';

export default {
  components: {
    Message,
    ReplyBox,
    ConversationLabelSuggestion,
    ConversationAiToggler,
    CanReplyBanner,
  },
  mixins: [
    conversationMixin,
    inboxMixin,
    eventListenerMixins,
    configMixin,
    aiMixin,
  ],
  props: {
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoadingPrevious: true,
      heightBeforeLoad: null,
      conversationPanel: null,
      hasUserScrolled: false,
      isProgrammaticScroll: false,
      isPopoutReplyBox: false,
      updateConversationInterval: null,
      checkCanReplyTimeout: null,
      messageSentSinceOpened: false,
      labelSuggestions: [],
      detectedLanguage: '',
      windowsExpiration: {
        expires: null,
      },
      isExpired: false,
      isExpiresTimeFetched: false,
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentChat: 'getSelectedChat',
      allConversations: 'getAllConversations',
      inboxesList: 'inboxes/getInboxes',
      listLoadingStatus: 'getAllMessagesLoaded',
      loadingChatList: 'getChatListLoadingStatus',
      appIntegrations: 'integrations/getAppIntegrations',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      currentAccountId: 'getCurrentAccountId',
      conversationStatus: 'chatbot/getConversationStatus',
    }),

    isConversationHasAI() {
      return !!this.conversationStatus?.successful;
    },

    isOpen() {
      return this.currentChat?.status === wootConstants.STATUS_TYPE.OPEN;
    },

    shouldShowLabelSuggestions() {
      return (
        this.isOpen &&
        this.isEnterprise &&
        this.isAIIntegrationEnabled &&
        !this.messageSentSinceOpened
      );
    },

    inboxId() {
      return this.currentChat.inbox_id;
    },

    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },

    typingUsersList() {
      const userList = this.$store.getters[
        'conversationTypingStatus/getUserList'
      ](this.currentChat.id);
      return userList;
    },

    isAnyoneTyping() {
      const userList = this.typingUsersList;
      return userList.length !== 0;
    },

    typingUserNames() {
      const userList = this.typingUsersList;

      if (this.isAnyoneTyping) {
        const userListAsName = getTypingUsersText(userList);
        return userListAsName;
      }

      return '';
    },

    getMessages() {
      const messages = this.currentChat.messages || [];
      if (this.isAWhatsAppChannel) {
        return filterDuplicateSourceMessages(messages);
      }

      return messages;
    },

    getAiHandoverMessages() {
      return this.getMessages.filter(
        (message) =>
          message.content_type === 'text' &&
          ['Handover requested, AI deactivated', 'AI activated'].includes(
            message.content
          )
      );
    },

    getReadMessages() {
      return this.readMessages(
        this.getMessages,
        this.currentChat.agent_last_seen_at
      );
    },

    getUnReadMessages() {
      return this.unReadMessages(
        this.getMessages,
        this.currentChat.agent_last_seen_at
      );
    },

    shouldShowSpinner() {
      return (
        (this.currentChat && this.currentChat.dataFetched === undefined) ||
        (!this.listLoadingStatus && this.isLoadingPrevious)
      );
    },

    conversationType() {
      const { additional_attributes: additionalAttributes } = this.currentChat;
      const type = additionalAttributes ? additionalAttributes.type : '';
      return type || '';
    },

    isATweet() {
      return this.conversationType === 'tweet';
    },

    isRightOrLeftIcon() {
      if (this.isContactPanelOpen) {
        return 'arrow-chevron-right';
      }
      return 'arrow-chevron-left';
    },

    getLastSeenAt() {
      const { contact_last_seen_at: contactLastSeenAt } = this.currentChat;
      return contactLastSeenAt;
    },

    replyWindowBannerMessage() {
      if (this.isAWhatsAppChannel) {
        return this.$t('CONVERSATION.TWILIO_WHATSAPP_CAN_REPLY');
      }

      if (this.isAPIInbox) {
        const { additional_attributes: additionalAttributes = {} } = this.inbox;
        if (additionalAttributes) {
          const {
            agent_reply_time_window_message: agentReplyTimeWindowMessage,
          } = additionalAttributes;
          return agentReplyTimeWindowMessage;
        }
        return '';
      }

      return this.$t('CONVERSATION.CANNOT_REPLY');
    },
    replyWindowLink() {
      if (this.isAWhatsAppChannel || this.isAFacebookInbox) {
        return REPLY_POLICY.FACEBOOK;
      }

      if (!this.isAPIInbox) {
        return REPLY_POLICY.TWILIO_WHATSAPP;
      }

      return '';
    },
    replyWindowLinkText() {
      if (this.isAWhatsAppChannel) {
        return this.$t('CONVERSATION.24_HOURS_WINDOW');
      }

      if (!this.isAPIInbox) {
        return this.$t('CONVERSATION.TWILIO_WHATSAPP_24_HOURS_WINDOW');
      }

      return '';
    },
    unreadMessageCount() {
      return this.currentChat.unread_count || 0;
    },
    inboxSupportsReplyTo() {
      return {
        incoming: this.inboxHasFeature(INBOX_FEATURES.REPLY_TO),
        outgoing: this.inboxHasFeature(INBOX_FEATURES.REPLY_TO_OUTGOING),
      };
    },

    hasInstagramStory() {
      return this.conversationType === 'instagram_direct_message';
    },

    incomingMessages() {
      return (
        this.currentChat?.messages?.filter(
          (message) => message.message_type === MESSAGE_TYPE.INCOMING
        ) || []
      );
    },

    latestIncomingMessages() {
      return (
        this.incomingMessages?.slice(
          Math.max(this.incomingMessages.length - 3, 0)
        ) || []
      );
    },

    contactId() {
      return this.currentChat?.meta?.sender?.id;
    },

    contactPhoneNumber() {
      return this.currentChat?.meta?.sender?.phone_number || '';
    },

    currentContact() {
      if (!this.contactId) {
        return {};
      }

      return this.$store.getters['contacts/getContact'](this.contactId);
    },

    isContactExist() {
      return !!Object.keys(this.currentContact).length;
    },

    contactPreferredLanguage() {
      return (
        this.currentContact?.custom_attributes?.ch_pref_lang ||
        this.currentChat?.meta?.sender?.custom_attributes?.ch_pref_lang ||
        this.detectedLanguage ||
        ''
      );
    },

    canReply() {
      if (!this.windowsExpiration?.expires) {
        return this.currentChat.can_reply;
      }

      return !this.isExpired;
    },
  },

  watch: {
    currentChat(newChat, oldChat) {
      if (newChat.id === oldChat.id) {
        return;
      }
      this.fetchAllAttachmentsFromCurrentChat();
      this.fetchSuggestions();
      this.messageSentSinceOpened = false;
      this.detectedLanguage = '';

      this.setupDetectedLanguage();
    },

    'currentChat.last_non_activity_message.id': {
      handler(id) {
        if (!id || !this.isApiWhatsAppChannel) {
          return;
        }

        this.changeCanReply();
      },
      immediate: true,
    },

    'currentChat.id': {
      async handler(newValue) {
        if (!newValue) {
          return;
        }

        await this.fetchConversationStatus();

        this.windowsExpiration = {
          expires: null,
        };

        if (this.isAWhatsAppChannel) {
          await this.fetchWhatsAppWindowExpiration();
        }
      },
      immediate: true,
    },
  },

  created() {
    bus.$on(BUS_EVENTS.SCROLL_TO_MESSAGE, this.onScrollToMessage);
    // when a new message comes in, we refetch the label suggestions
    bus.$on(BUS_EVENTS.FETCH_LABEL_SUGGESTIONS, this.fetchSuggestions);
    // when a message is sent we set the flag to true this hides the label suggestions,
    // until the chat is changed and the flag is reset in the watch for currentChat
    bus.$on(BUS_EVENTS.MESSAGE_SENT, () => {
      this.messageSentSinceOpened = true;
    });
  },

  mounted() {
    this.addScrollListener();
    this.fetchAllAttachmentsFromCurrentChat();
    this.fetchSuggestions();

    this.detectedLanguage = '';

    this.setupDetectedLanguage();

    if (this.isApiWhatsAppChannel) {
      this.updateConversationInterval = setInterval(async () => {
        await this.updateConversation();
      }, 10 * 1000);
    }
  },

  beforeDestroy() {
    this.removeBusListeners();
    this.removeScrollListener();

    if (this.isApiWhatsAppChannel) {
      clearInterval(this.updateConversationInterval);
      clearTimeout(this.checkCanReplyTimeout);
    }
  },

  methods: {
    async fetchConversationStatus() {
      await this.$store.dispatch('chatbot/getConversationStatus', {
        accountId: this.accountId,
        conversationId: this.currentChat.id,
      });
    },

    async fetchWhatsAppWindowExpiration() {
      try {
        this.isExpiresTimeFetched = false;
        this.isExpired = false;
        const { data } = await WhatsAppAPI.getWhatsAppWindowExpiration(
          this.contactId,
          this.inboxId
        );

        if (!data) {
          throw new Error();
        }

        this.windowsExpiration = data;
      } catch (error) {
        //
      } finally {
        this.isExpiresTimeFetched = true;
      }
    },

    onIsExpiredChange(newValue) {
      this.isExpired = newValue;
    },

    async fetchSuggestions() {
      // start empty, this ensures that the label suggestions are not shown
      this.labelSuggestions = [];

      if (this.isLabelSuggestionDismissed()) {
        return;
      }

      if (!this.isEnterprise) {
        return;
      }

      // method available in mixin, need to ensure that integrations are present
      await this.fetchIntegrationsIfRequired();

      if (!this.isLabelSuggestionFeatureEnabled) {
        return;
      }

      this.labelSuggestions = await this.fetchLabelSuggestions({
        conversationId: this.currentChat.id,
      });

      // once the labels are fetched, we need to scroll to bottom
      // but we need to wait for the DOM to be updated
      // so we use the nextTick method
      this.$nextTick(() => {
        // this param is added to route, telling the UI to navigate to the message
        // it is triggered by the SCROLL_TO_MESSAGE method
        // see setActiveChat on ConversationView.vue for more info
        const { messageId } = this.$route.query;

        // only trigger the scroll to bottom if the user has not scrolled
        // and there's no active messageId that is selected in view
        if (!messageId && !this.hasUserScrolled) {
          this.scrollToBottom();
        }
      });
    },
    isLabelSuggestionDismissed() {
      return LocalStorage.getFlag(
        LOCAL_STORAGE_KEYS.DISMISSED_LABEL_SUGGESTIONS,
        this.currentAccountId,
        this.currentChat.id
      );
    },
    fetchAllAttachmentsFromCurrentChat() {
      this.$store.dispatch('fetchAllAttachments', this.currentChat.id);
    },
    removeBusListeners() {
      bus.$off(BUS_EVENTS.SCROLL_TO_MESSAGE, this.onScrollToMessage);
    },
    onScrollToMessage({ messageId = '' } = {}) {
      this.$nextTick(() => {
        const messageElement = document.getElementById('message' + messageId);
        if (messageElement) {
          this.isProgrammaticScroll = true;
          messageElement.scrollIntoView({ behavior: 'smooth' });
          this.fetchPreviousMessages();
        } else {
          this.scrollToBottom();
        }
      });
      this.makeMessagesRead();
    },
    showPopoutReplyBox() {
      this.isPopoutReplyBox = !this.isPopoutReplyBox;
    },
    closePopoutReplyBox() {
      this.isPopoutReplyBox = false;
    },
    handleKeyEvents(e) {
      if (isEscape(e)) {
        this.closePopoutReplyBox();
      }
    },
    addScrollListener() {
      this.conversationPanel = this.$el.querySelector('.conversation-panel');
      this.setScrollParams();
      this.conversationPanel.addEventListener('scroll', this.handleScroll);
      this.$nextTick(() => this.scrollToBottom());
      this.isLoadingPrevious = false;
    },
    removeScrollListener() {
      this.conversationPanel.removeEventListener('scroll', this.handleScroll);
    },
    scrollToBottom() {
      this.isProgrammaticScroll = true;
      let relevantMessages = [];

      // label suggestions are not part of the messages list
      // so we need to handle them separately
      let labelSuggestions =
        this.conversationPanel.querySelector('.label-suggestion');

      // if there are unread messages, scroll to the first unread message
      if (this.unreadMessageCount > 0) {
        // capturing only the unread messages
        relevantMessages =
          this.conversationPanel.querySelectorAll('.message--unread');
      } else if (labelSuggestions) {
        // when scrolling to the bottom, the label suggestions is below the last message
        // so we scroll there if there are no unread messages
        // Unread messages always take the highest priority
        relevantMessages = [labelSuggestions];
      } else {
        // if there are no unread messages or label suggestion, scroll to the last message
        // capturing last message from the messages list
        relevantMessages = Array.from(
          this.conversationPanel.querySelectorAll('.message--read')
        ).slice(-1);
      }

      this.conversationPanel.scrollTop = calculateScrollTop(
        this.conversationPanel.scrollHeight,
        this.$el.scrollHeight,
        relevantMessages
      );
    },
    onToggleContactPanel() {
      this.$emit('contact-panel-toggle');
    },
    setScrollParams() {
      this.heightBeforeLoad = this.conversationPanel.scrollHeight;
      this.scrollTopBeforeLoad = this.conversationPanel.scrollTop;
    },

    async fetchPreviousMessages(scrollTop = 0) {
      this.setScrollParams();
      const shouldLoadMoreMessages =
        this.currentChat.dataFetched === true &&
        !this.listLoadingStatus &&
        !this.isLoadingPrevious;

      if (
        scrollTop < 100 &&
        !this.isLoadingPrevious &&
        shouldLoadMoreMessages
      ) {
        this.isLoadingPrevious = true;
        try {
          await this.$store.dispatch('fetchPreviousMessages', {
            conversationId: this.currentChat.id,
            before: this.currentChat.messages[0].id,
          });
          const heightDifference =
            this.conversationPanel.scrollHeight - this.heightBeforeLoad;
          this.conversationPanel.scrollTop =
            this.scrollTopBeforeLoad + heightDifference;
          this.setScrollParams();
        } catch (error) {
          // Ignore Error
        } finally {
          this.isLoadingPrevious = false;
        }
      }
    },

    handleScroll(e) {
      if (this.isProgrammaticScroll) {
        // Reset the flag
        this.isProgrammaticScroll = false;
        this.hasUserScrolled = false;
      } else {
        this.hasUserScrolled = true;
      }
      bus.$emit(BUS_EVENTS.ON_MESSAGE_LIST_SCROLL);
      this.fetchPreviousMessages(e.target.scrollTop);
    },

    makeMessagesRead() {
      this.$store.dispatch('markMessagesRead', { id: this.currentChat.id });
    },

    getInReplyToMessage(parentMessage) {
      if (!parentMessage) return {};
      const inReplyToMessageId = parentMessage.content_attributes?.in_reply_to;
      if (!inReplyToMessageId) return {};

      return this.currentChat?.messages.find((message) => {
        if (message.id === inReplyToMessageId) {
          return true;
        }
        return false;
      });
    },

    async updateConversation() {
      if (!this.currentChat?.id) {
        return;
      }

      await this.$store.dispatch('syncActiveConversationMessages', {
        conversationId: this.currentChat.id,
      });
    },

    changeCanReply() {
      if (
        !this.currentChat?.last_non_activity_message?.created_at ||
        !this.canReply
      ) {
        return;
      }

      const last_date = new Date(
        this.currentChat.last_non_activity_message.created_at * 1000
      );

      last_date.setHours(last_date.getHours() + 24);
      last_date.setMinutes(last_date.getMinutes() - 1);

      const now = new Date();

      const timeoutTime = last_date.getTime() - now.getTime();

      this.checkCanReplyTimeout = setTimeout(
        () => {
          this.$store.dispatch('setConversationCanReply', {
            conversationId: this.currentChat.id,
            canReply: false,
          });
        },
        timeoutTime > 0 ? timeoutTime : 0
      );
    },

    detectContactLanguage() {
      const contents =
        this.latestIncomingMessages
          ?.map((message) => message.content)
          ?.filter((content) => !!content) || [];

      const span = document.createElement('span');
      span.innerText = contents.join(' ').trim() || '';

      const content = span.textContent;

      span.remove();

      return (detectAll(content) || [])[0];
    },

    async setupDetectedLanguage() {
      if (this.contactPreferredLanguage) {
        return;
      }

      const language = this.detectContactLanguage();

      if (!language || !language.accuracy || !language.lang) {
        return;
      }

      if (language.accuracy >= 0.7) {
        const oldAttributes = structuredClone(
          (this.isContactExist
            ? this.currentContact.custom_attributes
            : this.currentChat?.meta?.sender?.custom_attributes) || {}
        );

        if (!this.contactId) {
          return;
        }

        await this.$store.dispatch('contacts/update', {
          id: this.contactId,
          custom_attributes: { ...oldAttributes, ch_pref_lang: language.lang },
        });
      }

      this.detectedLanguage = language.lang;
    },
  },
};
</script>
<style scoped lang="scss">
.modal-mask {
  &::v-deep {
    .ProseMirror-woot-style {
      @apply max-h-[25rem];
    }

    .reply-box {
      @apply w-[70%] max-w-[75rem] border border-solid dark:border-gray-600;
    }

    .reply-box .reply-box__top {
      @apply relative min-h-[27.5rem];

      .input,
      .audio-recorder-container {
        @apply min-h-[27.5rem];
      }
    }

    .emoji-dialog {
      @apply absolute bottom-1 left-auto;
    }
  }
}

@keyframes jump {
  25% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-0.5em);
  }

  75% {
    transform: translateY(0);
  }
}

.typing {
  @apply inline-flex;
  height: 14px;
  width: min-content;
  align-items: flex-end;
}

.typing span {
  @apply mx-0.5 h-1 w-1 rounded-full bg-gray-500 dark:bg-gray-400;
  animation: jump 1500ms infinite;
}

.typing span:nth-child(2) {
  animation-delay: 250ms;
}

.typing span:nth-child(3) {
  animation-delay: 500ms;
}
</style>
