<template>
    <div class="chatlyn-container max-w-2xl m-auto flex flex-col gap-6">

        <div class="features-item">
          <h1 class="page-title">
            <span>{{
              $t('ONBOARDING.TITLE', {
                installationName: globalConfig.installationName,
              })
            }}</span>
          </h1>
          <p class="intro-body">
            {{
              $t('ONBOARDING.DESCRIPTION', {
                installationName: globalConfig.installationName,
              })
            }}
          </p>
          <p v-if="globalConfig.installationName === 'Chatwoot'">
            <a
              href="https://www.chatwoot.com/changelog"
              target="_blank"
              rel="noopener nofollow noreferrer"
              class="button smooth small secondary"
            >
              {{ $t('ONBOARDING.READ_LATEST_UPDATES') }}
            </a>
            <span>🎉</span>
          </p>
        </div>
        <div class="features-item">
          <h2 class="block-title text-gray-900 dark:text-gray-200">
            <span class="emoji">💬</span>
            <span class="conversation--title">{{
              $t('ONBOARDING.ALL_CONVERSATION.TITLE')
            }}</span>
          </h2>
          <p class="intro-body">
            {{ $t('ONBOARDING.ALL_CONVERSATION.DESCRIPTION') }}
          </p>
        </div>
        <div class="features-item">
          <h2 class="block-title text-gray-900 dark:text-gray-200">
            <span class="emoji">👥</span>
            {{ $t('ONBOARDING.TEAM_MEMBERS.TITLE') }}
          </h2>
          <p class="intro-body">
            {{ $t('ONBOARDING.TEAM_MEMBERS.DESCRIPTION') }}
          </p>
          <router-link :to="newAgentURL" class="button smooth small secondary">
            {{ $t('ONBOARDING.TEAM_MEMBERS.NEW_LINK') }}
          </router-link>
        </div>
        <div class="features-item">
          <h2 class="block-title text-gray-900 dark:text-gray-200">
            <span class="emoji">📥</span>{{ $t('ONBOARDING.INBOXES.TITLE') }}
          </h2>
          <p class="intro-body">
            {{ $t('ONBOARDING.INBOXES.DESCRIPTION') }}
          </p>
          <router-link :to="newInboxURL" class="button smooth small secondary">
            {{ $t('ONBOARDING.INBOXES.NEW_LINK') }}
          </router-link>
        </div>
        <div class="features-item">
          <h2 class="block-title text-gray-900 dark:text-gray-200">
            <span class="emoji">🔖</span>{{ $t('ONBOARDING.LABELS.TITLE') }}
          </h2>
          <p class="intro-body">
            {{ $t('ONBOARDING.LABELS.DESCRIPTION') }}
          </p>
          <router-link :to="newLabelsURL" class="button smooth small secondary">
            {{ $t('ONBOARDING.LABELS.NEW_LINK') }}
          </router-link>
        </div>

    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import accountMixin from '../../../mixins/account';

export default {
  mixins: [accountMixin],
  computed: {
    ...mapGetters({ globalConfig: 'globalConfig/get' }),
    newInboxURL() {
      return this.addAccountScoping('settings/inboxes/new');
    },
    newAgentURL() {
      return this.addAccountScoping('settings/agents/list');
    },
    newLabelsURL() {
      return this.addAccountScoping('settings/labels/list');
    },
  },
};
</script>
<style lang="scss" scoped>
.emoji {
  width: var(--space-large);
  display: inline-block;
}
</style>
