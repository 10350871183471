import { render, staticRenderFns } from "./MultiselectDropdown.vue?vue&type=template&id=38f8642b&scoped=true&"
import script from "./MultiselectDropdown.vue?vue&type=script&lang=js&"
export * from "./MultiselectDropdown.vue?vue&type=script&lang=js&"
import style0 from "./MultiselectDropdown.vue?vue&type=style&index=0&id=38f8642b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38f8642b",
  null
  
)

export default component.exports