<template>
  <woot-modal
    modal-type="right-aligned"
    class="text-left"
    show
    :on-close="onClose"
  >
    <div class="modal-header">
      <span class="text-base">{{ $t('TRANSLATE_MODAL.ORIGINAL_CONTENT') }}</span>
    </div>
    <div class="modal-body">

      <p v-dompurify-html="content" />
      
      <div v-if="translationsAvailable">
      
        <hr class="my-3" />
      
        <div class="text-base">
        {{ $t('TRANSLATE_MODAL.TRANSLATED_CONTENT') }}
        </div>
      
        <div v-for="(translation, language) in translations" :key="language">
          <div class="font-semibold">
            {{ language }}:
          </div>
          <p v-dompurify-html="translation" />
        </div>
      </div>
      <div class="callout text-center text-sm" v-else>
        {{ $t('TRANSLATE_MODAL.NO_TRANSLATIONS_AVAILABLE') }}
      </div>
    </div>
  </woot-modal>
</template>
<script>
export default {
  props: {
    contentAttributes: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: String,
      default: '',
    },
  },
  computed: {
    translationsAvailable() {
      return !!Object.keys(this.translations).length;
    },
    translations() {
      return this.contentAttributes.translations || {};
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
