<template>
  <div class="relative flex flex-col flex-nowrap gap-2 p-4">
    <div class="flex flex-col flex-nowrap gap-2">
      <woot-button
        variant="smooth"
        color-scheme="secondary"
        class="label--add w-max"
        icon="add"
        size="tiny"
        :is-disabled="isAddNewNote"
        @click="addToggle"
      >
        {{ $t('CONTACT_PANEL.NOTES.ADD_BUTTON') }}
      </woot-button>

      <add-note
        v-if="isAddNewNote"
        class="contact-info-panel__note-editor !m-0 !p-2"
        @add="addNote"
      />
    </div>

    <div
      v-if="!isNotesEmpty"
      class="contact-info-panel__notes flex flex-col flex-nowrap gap-2"
    >
      <contact-note
        v-for="note in filteredNotes"
        :id="note.id"
        :key="note.id"
        class="m-0 border-none p-3 !shadow-none odd:!bg-transparent even:!bg-gray-50 dark:even:!bg-gray-800"
        :note="note.content"
        :user="note.user"
        :created-at="note.created_at"
        is-on-panel
        @edit="editNote"
        @delete="deleteNote"
      />

      <woot-button
        v-if="isMoreButtonVisible"
        variant="smooth"
        color-scheme="secondary"
        class="label--add z-10 mx-auto w-max"
        size="tiny"
        @click="showMore"
      >
        {{
          isMoreButtonVisible && maxViewableNotesCount === 2
            ? $t('CONTACT_PANEL.NOTES.SHOW_MORE')
            : $t('CONTACT_PANEL.NOTES.VIEW_ALL')
        }}
      </woot-button>
    </div>

    <div
      v-if="isMoreButtonVisible && maxViewableNotesCount === 2"
      class="absolute bottom-0 left-0 h-28 w-full bg-gradient-to-t from-white from-25% to-transparent dark:from-gray-900"
    />
  </div>
</template>

<script>
import AddNote from '../../../../../modules/notes/components/AddNote.vue';
import ContactNote from '../../../../../modules/notes/components/ContactNote.vue';

export default {
  name: 'ContactNotes',
  components: { ContactNote, AddNote },
  props: {
    contactId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isAddNewNote: false,
      maxViewableNotesCount: 2,
    };
  },
  computed: {
    notes() {
      return this.$store.getters['contactNotes/getAllNotesByContact'](
        this.contactId
      );
    },

    isNotesEmpty() {
      return !Array.isArray(this.notes) || !this.notes.length;
    },

    filteredNotes() {
      if (this.isNotesEmpty) {
        return [];
      }

      return this.notes.slice(0, this.maxViewableNotesCount);
    },

    isMoreButtonVisible() {
      if (this.isNotesEmpty) {
        return false;
      }

      if (this.maxViewableNotesCount === 2 && this.notes.length > 2) {
        return true;
      }

      if (this.notes.length <= 5) {
        return false;
      }

      return true;
    },

    contactProfileLink() {
      return `/app/accounts/${this.$route.params.accountId}/contacts/${this.contactId}`;
    },
  },
  mounted() {
    if (this.$route.name === 'contact_profile_dashboard') {
      return;
    }

    this.fetchContactNotes();
  },
  methods: {
    async fetchContactNotes() {
      const { contactId } = this;

      if (contactId) {
        await this.$store.dispatch('contactNotes/get', { contactId });
      }
    },

    addToggle() {
      this.isAddNewNote = !this.isAddNewNote;
    },

    showMore() {
      if (this.maxViewableNotesCount === 2) {
        this.maxViewableNotesCount = 5;
        return;
      }

      window.open(this.contactProfileLink, '_blank');
    },

    async addNote(content) {
      const { contactId } = this;
      await this.$store.dispatch('contactNotes/create', { content, contactId });
      this.isAddNewNote = false;
    },

    editNote() {},

    async deleteNote(noteId) {
      const { contactId } = this;
      await this.$store.dispatch('contactNotes/delete', { noteId, contactId });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-info-panel__note-editor {
  ::v-deep {
    .ProseMirror-menuitem {
      @apply mr-1;
    }

    div[contenteditable='true'].ProseMirror {
      @apply min-h-[3rem] px-2;
    }
  }
}
</style>
