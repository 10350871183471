<template>
  <div
    class="menu-with-submenu min-width-calc relative flex h-7 w-full cursor-pointer items-center justify-between rounded-md bg-white p-1 hover:bg-woot-75 dark:bg-gray-700 dark:hover:bg-gray-800"
    :class="!subMenuAvailable ? 'cursor-not-allowed opacity-50' : ''"
  >
    <div class="flex items-center">
      <fluent-icon :icon="option.icon" size="14" class="menu-icon" />
      <p class="menu-with-submenu__text mx-2 my-0 text-xs">
        {{ option.label }}
      </p>
    </div>
    <fluent-icon icon="chevron-right" size="12" />
    <div
      v-if="subMenuAvailable"
      class="submenu absolute left-full top-0 hidden max-h-[15rem] min-h-min cursor-pointer overflow-y-auto overflow-x-hidden rounded-md bg-white p-1 shadow-lg dark:bg-gray-700"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      default: () => {},
    },
    subMenuAvailable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.menu-with-submenu {
  min-width: calc(6.25rem * 2);

  &:hover {
    .submenu {
      @apply block;
    }
  }
}
</style>
