<template>
  <section
    class="message-templates-table flex h-full w-full overflow-auto bg-white dark:bg-gray-900"
  >
    <ve-table
      v-if="!uiFlags.isFetching"
      class="message-templates-table-wrap"
      :class="isEmpty ? 'hidden' : 'h-full flex-1 overflow-x-hidden'"
      :fixed-header="true"
      scroll-width="80rem"
      :columns="columns"
      :table-data="messageTemplates"
      :border-around="false"
      row-key-field-name="_id"
      :sort-option="sortOption"
      :cell-selection-option="cellSelectionOption"
      :event-custom-option="eventCustomOption"
    />
    <loading-state
      v-if="uiFlags.isFetching"
      class="w-full"
      :message="$t('CHATLYN_MESSAGE_TEMPLATE.LIST.LOADING_MESSAGE')"
    />
    <empty-state
      v-if="isEmpty"
      :title="$t('CHATLYN_MESSAGE_TEMPLATE.LIST.NO_MESSAGE_TEMPLATES')"
    />
  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';
import { format, parseISO } from 'date-fns';
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import {
  MESSAGE_TEMPLATES_CHANNEL_TYPE,
  MESSAGE_TEMPLATES_STATUSES_OPTION,
} from '../constants';
import { mapGetters } from 'vuex';

export default {
  name: 'MessageTemplatesTable',
  components: {
    LoadingState,
    VeTable,
    EmptyState,
  },
  props: {
    sortConfig: {
      type: Object,
      default: () => ({}),
    },

    messageTemplates: {
      type: Array,
      default: () => [],
    },

    messageTemplateType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: true,
      sortOption: {
        sortAlways: true,
        sortChange: (params) => this.$emit('on-sort-change', params),
      },
      cellSelectionOption: {
        // disble cell selection
        enable: false,
      },
      eventCustomOption: {
        // eslint-disable-next-line no-unused-vars

        bodyRowEvents: ({ row }) => {
          return {
            click: () => {
              // eslint-disable-next-line no-underscore-dangle
              const currentRowKey = row._id;
              this.onMessageTemplateClick(currentRowKey);
            },
          };
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      whatsAppInboxes: 'inboxes/getWhatsAppInboxes',
      uiFlags: 'messageTemplates/getUIFlags',
    }),

    columns() {
      return [
        {
          field: 'title',
          key: 'title',
          rowKey: 'title',
          title: this.$t('CHATLYN_MESSAGE_TEMPLATE.LIST.TABLE_HEADER.TITLE'),
          fixed: 'left',
          align: 'left',
          sortBy: this.sortConfig.title || '',
          width: 300,
          renderBodyCell: ({ row }) => (
            <a
              class="stretched-link flex items-center gap-2"
              // eslint-disable-next-line no-underscore-dangle
            >
              <div class="overflow-hidden text-ellipsis whitespace-nowrap text-left text-base">
                {row.title}
              </div>
            </a>
          ),
        },
        {
          field: 'translations',
          rowKey: 'translations',
          key: 'translations',
          title: this.$t(
            'CHATLYN_MESSAGE_TEMPLATE.LIST.TABLE_HEADER.LANGUAGES'
          ),
          align: 'left',
          sortBy: this.sortConfig.translations || '',
          width: 188,
          renderBodyCell: ({ row }) => {
            return (
              <div class="message-cell-languages">
                {row.translations.map((translation) => {
                  const status = (
                    translation?.status ||
                    row?.status ||
                    ''
                  ).toUpperCase();

                  const statusOption =
                    MESSAGE_TEMPLATES_STATUSES_OPTION[status];

                  return (
                    <v-popover
                      trigger="hover"
                      placement="top"
                      offset="10"
                      disabled={!statusOption?.title}
                    >
                      <span
                        class="message-template-lang-badge"
                        onClick={() =>
                          this.onMessageTemplateClick(
                            // eslint-disable-next-line no-underscore-dangle
                            row._id,
                            translation.language
                          )
                        }
                      >
                        {statusOption?.color ? (
                          <span
                            class="status-indicator "
                            style={`background-color: ${statusOption?.color}`}
                          />
                        ) : (
                          ''
                        )}

                        <span class="lang-name">
                          {translation.language.toUpperCase()}
                        </span>
                      </span>

                      <template slot="popover">
                        <div class="tooltip-content">
                          <span>{this.$t(statusOption?.title)}</span>
                        </div>
                      </template>
                    </v-popover>
                  );
                })}
              </div>

              // <v-popover
              //   trigger="hover"
              //   disabled={!row.rejectedReason || row.status !== 'REJECTED'}
              //   placement="top"
              //   offset="10"
              // >
              //   <span class="message-cell-row-status w-full tooltip-target">
              //     <span
              //       class="message-cell-row-status-point"
              //       style={`background-color: ${
              //         MESSAGE_TEMPLATES_STATUSES_OPTION[row.status]?.color
              //       }`}
              //     ></span>
              //     <span class="text-truncate">
              //       {this.$t(
              //         `${MESSAGE_TEMPLATES_STATUSES_OPTION[row.status]?.title ||
              //           ''}`
              //       )}
              //     </span>
              //   </span>

              //   <template slot="popover">
              //     <div class="tooltip-content">
              //       {this.$t('CHATLYN_MESSAGE_TEMPLATE.REJECT_MESSAGE', {
              //         REASON: row.rejectedReason,
              //       })}
              //     </div>
              //   </template>
              // </v-popover>
            );
          },
        },
        {
          field: 'inboxType',
          rowKey: 'inboxType',
          key: 'inboxType',
          title: this.$t(
            'CHATLYN_MESSAGE_TEMPLATE.LIST.TABLE_HEADER.INBOX_TYPE'
          ),
          align: 'left',
          sortBy: this.sortConfig.inboxType || '',
          width: 188,
          renderBodyCell: ({ row }) => {
            let inboxType = '';
            let text = '';

            switch (this.messageTemplateType) {
              case MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP: {
                inboxType = this.$t(
                  'CHATLYN_MESSAGE_TEMPLATE.LIST.INBOX_TYPE.WHATSAPP'
                );

                if (this.whatsAppInboxes.length > 1) {
                  const whatsAppInbox = this.getWhatsAppInboxByNumber(
                    `+${row.phoneNumber}`
                  );

                  const whatsAppInboxPhoneNumber =
                    whatsAppInbox?.phone_number ||
                    `+${whatsAppInbox?.additional_attributes?.number}`;

                  text = whatsAppInbox
                    ? `${whatsAppInbox.name} ${whatsAppInboxPhoneNumber}`
                    : this.$t(
                        'CHATLYN_MESSAGE_TEMPLATE.LIST.NO_WHATS_APP_INBOX'
                      );
                }

                break;
              }

              case MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS: {
                inboxType = this.$t(
                  'CHATLYN_MESSAGE_TEMPLATE.LIST.INBOX_TYPE.SMS'
                );
                break;
              }

              default:
                break;
            }

            return (
              <span class="message-cell-inbox w-full">
                <span>{inboxType}</span>
                {text ? <span class="text-gray-500">({text})</span> : ''}
              </span>
            );
          },
        },

        {
          field: 'createdAt',
          rowKey: 'createdAt',
          key: 'createdAt',
          title: this.$t(
            'CHATLYN_MESSAGE_TEMPLATE.LIST.TABLE_HEADER.CREATED_AT'
          ),
          align: 'left',
          sortBy: this.sortConfig.createdAt || '',
          width: 188,
          renderBodyCell: ({ row }) => {
            return format(parseISO(row.createdAt), 'dd-MM-yyyy');
          },
        },
      ];
    },

    isEmpty() {
      return Boolean(
        !this.uiFlags.isFetching && !this.messageTemplates?.length
      );
    },
  },
  methods: {
    getWhatsAppInboxByNumber(number) {
      if (!this.whatsAppInboxes.length) {
        return null;
      }

      return this.whatsAppInboxes.find(
        (whatsAppInbox) =>
          whatsAppInbox.phone_number === number ||
          `+${whatsAppInbox?.additional_attributes?.number}` === number
      );
    },

    onMessageTemplateClick(messageTemplateId, language) {
      if (language) {
        this.$emit('change-selected-translation-language', language);
      }

      this.$emit('select-message-template', messageTemplateId);
    },
  },
};
</script>

<style lang="scss" scoped>
.message-templates-table-wrap {
  ::v-deep {
    .message-template-lang-badge {
      @apply py-0.5 ps-1;
    }
    .message-cell-inbox {
      @apply flex flex-col flex-nowrap;
    }

    .message-cell-languages {
      @apply flex flex-row gap-2;
    }
  }
}
</style>
