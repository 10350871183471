/* global axios */
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? 'https://staging.chatlyn.com/smswamw/wa/history'
    : window.location.protocol +
      '//' +
      window.location.hostname +
      '/smswamw/wa/history';

class SentTemplatesAPI {
  getSentTemplates = async data => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(`${url}`, data, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };
}

export default new SentTemplatesAPI();
